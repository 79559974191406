import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { AirlineSingleOption } from 'utils/api/AirlinesAPI';
import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import {
	AccountTypeCode,
	Country,
	CustomerTypeOption,
	DeleteSchema,
} from 'utils/api/common';
import {
	CreateCustomerModel,
	CreateDemoCustomerModel,
	UpdateCustomerModel,
} from 'utils/schemas/customerSchema';
import { LabeledValue, Paginated } from 'utils/types/common';
import { Contact } from '../components/ContactManager/ContactManager';
import { CustomerStatus } from '../routes/customers/model';

export type SalesforceIdOption = LabeledValue & {
	customer: string | null;
};

export type GetCustomerOptionsResponse = {
	salesforceIdOptions: SalesforceIdOption[];
	customerTypeOptions: CustomerTypeOption[];
	customerStatusOptions: LabeledValue[];
	accountManagerOptions: LabeledValue[];
};

export type CreateCustomerResponse = {
	customerId: string;
};

export type UpdateCustomerResponse = {
	id: string;
};

export type SubscriptionCount = {
	active: number;
	expiring: number;
	expired: number;
};

export type UserCount = {
	active: number;
	expiring: number;
	expired: number;
};

export type CustomerItem = {
	id: string;
	customerNumber: string;
	name: string;
	salesforceId: string;
	customerTypeName: string;
	customerStatus: CustomerStatus;
	accountManagerId: string | null;
	accountManagerName: string | null;
	countryCode: string;
	countryName: string;
	regionCode: string;
	regionName: string;
	subscriptionCount: SubscriptionCount;
	userCount: UserCount;
};

type GetCustomerRequest = {
	keyword?: string;
	customerTypes?: string[];
	customerStatus?: string[];
	accountManagers?: string[];
	countries?: string[];
	regions?: string[];
	pageSize?: number;
	page?: number;
};

export type GetCustomersResponse = {
	keyword: string | null;
	customerTypes: string[];
	customerTypeOptions: CustomerTypeOption[];
	customerStatus: string[];
	customerStatusOptions: LabeledValue[];
	accountManagers: string[];
	accountManagerOptions: LabeledValue[];
	countries: string[];
	countriesSelected: Country[];
	regions: string[];
	regionOptions: LabeledValue[];
	customers: Paginated<CustomerItem>;
	filterOptions: LabeledValue[];
};

export type GetCustomerResponse = {
	id: string;
	name: string;
	customerNumber: string;
	customerStatus: LabeledValue;
	customerTypeId: string;
	customerType: CustomerTypeOption;
	accountManagerId: string;
	accountManager: LabeledValue;
	countryCode?: string;
	country?: Country;
	contacts: Contact[];
	notes?: string;
	freightForwarders: FreightForwarderSingleOption[] | null;
	airlines?: AirlineSingleOption[];
	version: number;
};

export type CustomerOption = {
	id: string;
	name: string;
	customerNumber: string;
	accountType: AccountTypeCode;
};

export type CustomerLookupResponse = {
	keyword: string;
	options: CustomerOption[];
};

export type GetCustomerDetailRequest = {
	id: string;
	show?: string;
};

export type GetCustomerDownloadResponse = {
	url: string;
	fileName: string;
};

class CustomerAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getCustomerOptions() {
		return CustomerAPI.get<GetCustomerOptionsResponse>('/customers/options');
	}

	static createCustomer(data: CreateCustomerModel | CreateDemoCustomerModel) {
		return CustomerAPI.post<CreateCustomerResponse>('/customers', data);
	}

	static updateCustomer(id: string, data: UpdateCustomerModel) {
		return CustomerAPI.put<UpdateCustomerResponse>(`/customers/${id}`, data);
	}

	static deleteCustomer(id: string, data: DeleteSchema) {
		return CustomerAPI.delete(`/customers/${id}`, data);
	}

	static getCustomers(request: GetCustomerRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return CustomerAPI.get<GetCustomersResponse>(`/customers?${params}`);
	}

	static getCustomersDownloadData(request: URLSearchParams) {
		return CustomerAPI.get<GetCustomerDownloadResponse>(
			`/customers/download?${request}`
		);
	}

	static lookup({ keyword }: { keyword: string }) {
		return CustomerAPI.get<CustomerLookupResponse>(
			`/customers/lookup?keyword=${keyword}`
		);
	}

	static getCustomer(request: GetCustomerDetailRequest) {
		return CustomerAPI.get<GetCustomerResponse>(
			`/customers/${request.id}?show=${request.show}`
		);
	}

	static getCustomerDownloadData(customerId: string) {
		return CustomerAPI.get<GetCustomerDownloadResponse>(
			`/customers/${customerId}/download`
		);
	}
}

export default CustomerAPI;
