import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import {
	UpdateCustomerTeamModel,
	CreateCustomerTeamModel,
} from 'utils/schemas/customerTeamSchema';
import { Paginated } from 'utils/types/common';
import { UserOption } from './UserAPI';

export type CustomerTeamListItem = {
	id: string;
	name: string;
	description?: string;
	createdAt: Date;
	customerId: string;
	members: string[];
};

export type GetCustomerTeamsResponse = {
	customerId: string;
	userId?: string;
	totalUsers: number;
	teams: Paginated<CustomerTeamListItem>;
};

export type GetCustomerTeamResponse = {
	id: string;
	name: string;
	description?: string;
	createdAt: Date;
	customerId: string;
	customer: string;
	userId?: string;
	user?: string;
	selectedUsers: UserOption[];
	users: UserOption[];
};

export type GetCustomerTeamOptionsResponse = {
	customerId: string;
	customer: string;
	userId?: string;
	user?: string;
	selectedUsers: UserOption[];
	users: UserOption[];
};

export type CreateCustomerTeamResponse = {
	id: string;
};

export type UpdateCustomerTeamResponse = {
	id: string;
};

class CustomerTeamAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getCustomerTeam(id: string, params: URLSearchParams) {
		return CustomerTeamAPI.get<GetCustomerTeamResponse>(
			`/customer-teams/${id}?${params}`
		);
	}

	static getCustomerTeams(request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return CustomerTeamAPI.get<GetCustomerTeamsResponse>(
			`/customer-teams?${params}`
		);
	}

	static getCustomerTeamOptions(request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return CustomerTeamAPI.get<GetCustomerTeamOptionsResponse>(
			`/customer-teams/options?${params}`
		);
	}

	static createCustomerTeam(data: CreateCustomerTeamModel) {
		return CustomerTeamAPI.post<CreateCustomerTeamResponse>(
			'/customer-teams',
			data
		);
	}

	static updateCustomerTeam(id: string, data: UpdateCustomerTeamModel) {
		return CustomerTeamAPI.put<UpdateCustomerTeamResponse>(
			`/customer-teams/${id}`,
			data
		);
	}

	static deleteCustomerTeam(id: string) {
		return CustomerTeamAPI.delete(`/customer-teams/${id}`);
	}
}

export default CustomerTeamAPI;
