import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Pencil } from 'lucide-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import AttachmentLink from 'ui/components/AttachmentLink';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import { AdhocReportListItem } from '../../api/AdhocReportsAPI';

const columnHelper = createColumnHelper<AdhocReportListItem>();

export const reportIdColumn = columnHelper.accessor('adhocReportId', {
	header: 'Report Id',
});

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
	cell: (info) => {
		const { id } = useParams<{ id: string }>();

		return (
			<Link to={AdhocReportEditUrl(info)}>
				{id === info.row.original.id ? (
					<b>{info.getValue()}</b>
				) : (
					info.getValue()
				)}
			</Link>
		);
	},
	enableSorting: true,
});

export const customerColumn = columnHelper.accessor('customer', {
	header: 'Customer',
	cell: (info) => (
		<Link to={`/customers/${info.row.original.customerId}`}>
			{info.getValue()} ({info.row.original.customerNumber})
		</Link>
	),
	enableSorting: true,
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date/Time (UTC)',
	cell: (info) => (
		<DateFragment date={info.getValue()} includeTime={true} timezone="utc" />
	),
	enableSorting: true,
});

export const releaseDateColumn = columnHelper.accessor('releaseDate', {
	header: 'Release Date',
	cell: (info) => <DateFragment date={info.getValue()} timezone="utc" />,
	enableSorting: true,
});

export const statusColumn = columnHelper.accessor('status', {
	header: 'Status',
	cell: (info) => (
		<BulletIndicator
			intent={
				info.getValue().name.toLocaleLowerCase() === 'active'
					? 'success'
					: 'error'
			}
			label={info.getValue().name}
		/>
	),
});

export const userCountColumn = columnHelper.accessor('userCount', {
	header: 'Total Users',
});

export const attachmentColumn = columnHelper.accessor('file', {
	header: 'Attachment',
	cell: (info) =>
		info.row.original.file ? (
			<AttachmentLink
				url={`/adhoc-reports/download/${info.row.original.id}`}
				target="_blank"
				name={info.row.original.file.originalName}
				size={info.row.original.file.fileLength}
			/>
		) : (
			'-'
		),
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: function AdhocReportEditColumn(info) {
		return (
			<Button
				variant="secondary"
				size="small"
				icon={Pencil}
				to={AdhocReportEditUrl(info)}
			>
				Edit
			</Button>
		);
	},
});

function AdhocReportEditUrl(info: CellContext<AdhocReportListItem, string>) {
	const location = useLocation();

	const userId = location.pathname.includes('/users/')
		? location.pathname.split('/')[2]
		: null;

	const url =
		userId !== null
			? `/adhoc-reports/${info.row.original.id}/edit?userId=${userId}`
			: `/adhoc-reports/${info.row.original.id}/edit`;

	return url;
}
