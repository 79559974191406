import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import UserAPI, { UserOption } from '../../api/UserAPI';
import UserPickerItem from './UserPickerItem';

export type UserPickerProps = {
	name: string;
	label?: string;
	isMulti?: boolean;
	isRequired?: boolean;
	initialValues?: UserOption[];
	onChange?: (user: UserOption | null) => void;
};

const UserPicker = ({
	name,
	label,
	isMulti = false,
	initialValues,
	isRequired,
	onChange,
}: UserPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);

	const getUsers = async (searchTerm: string) => {
		const data = await UserAPI.lookup({ keyword: searchTerm });
		return data.options;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{isMulti ? (
				<AsyncMultiSelect<UserOption>
					onSearch={getUsers}
					identifierKey="id"
					initialValues={initialValues ?? []}
					onOptionsSelected={revalidate}
					contentSource={(user) => <UserPickerItem user={user} />}
					pillComponent={({ value, onRemove }) => (
						<Pill
							name={value.name}
							title={`${value.email}\n${value.customerName}`}
							onRemove={onRemove}
						/>
					)}
					placeholder="Select users..."
					name={name}
				/>
			) : (
				<AsyncSelect<UserOption>
					onSearch={getUsers}
					initialValue={initialValues ? initialValues[0] : null}
					onOptionSelected={(newValue) => {
						if (onChange) {
							onChange(newValue);
						}
						revalidate();
					}}
					identifierKey="id"
					contentSource={(user) => <UserPickerItem user={user} />}
					isClearable={true}
					placeholder="Select a user..."
					name={name}
				/>
			)}
		</FormField>
	);
};

export default UserPicker;
