import { ClipboardSignature } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { PublishWeek } from '../../api/DataManagementAPI';
import { WeeklyPublishAction } from './summary-dashboard';

type PublishWeeklyDataModalProps = {
	publishWeek: PublishWeek;
} & Omit<ModalProps, 'title'>;

const PublishWeeklyDataModal = ({
	publishWeek,
	...modalProps
}: PublishWeeklyDataModalProps) => {
	return (
		<ConfirmationModal
			fieldName="week"
			title="Publish Weekly Data"
			submitIcon={ClipboardSignature}
			submitText="Publish Weekly Data"
			confirmationText={publishWeek.description}
			formMethod="post"
			formAction="/publish/summary"
			{...modalProps}
		>
			<input type="hidden" name="action" value={WeeklyPublishAction.Publish} />
			<input
				type="hidden"
				name="yearMonthWeek"
				value={publishWeek.yearMonthWeek}
			/>

			<p>
				Are you sure you wish to publish the data for{' '}
				<b>{publishWeek.description}</b>?
			</p>
		</ConfirmationModal>
	);
};

export default PublishWeeklyDataModal;
