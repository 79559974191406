/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import {
	zArrayOfStringRequired,
	zBoolPreprocessor,
	zEnsureArrayPreprocessor,
	zFilesRequiredRefinement,
} from 'utils/zod/zodValidation';
import z from 'zod';

export const adhocReportSchema = z.object({
	id: z.string(),
	customerId: z.string({ required_error: 'CustomerId is required' }),
	title: z
		.string()
		.min(2, { message: 'Title must be at least 2 characters' })
		.max(200, { message: 'Title must not be more than 200 characters' }),
	releaseDate: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date().optional()),
	reportFile: z.object({
		fileName: z.string(),
		originalName: z.string(),
		length: z.number(),
	}),
	userIds: z.preprocess(
		zEnsureArrayPreprocessor,
		zArrayOfStringRequired('At least 1 user is required')
	),
	notifyUsers: z.preprocess(zBoolPreprocessor, z.boolean()),
	isArchived: z.preprocess(zBoolPreprocessor, z.boolean()),
});

export type AdhocReportModel = z.infer<typeof adhocReportSchema>;

export const createAdhocReportSchema = adhocReportSchema
	.pick({
		customerId: true,
		title: true,
		releaseDate: true,
		reportFile: true,
		userIds: true,
		notifyUsers: true,
	})
	.extend({
		reportFile: z
			.any()
			.superRefine(zFilesRequiredRefinement('Report file is required.')),
	});

export type CreateAdhocReportModel = z.infer<typeof createAdhocReportSchema>;

export const updateAdhocReportSchema = adhocReportSchema
	.pick({
		title: true,
		releaseDate: true,
		reportFile: true,
		userIds: true,
		notifyUsers: true,
		isArchived: true,
	})
	.extend({ reportFile: z.any() });

export type UpdateAdhocReportModel = z.infer<typeof updateAdhocReportSchema>;
