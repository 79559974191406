import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import {
	CreateAccountManagerModel,
	UpdateAccountManagerModel,
} from 'utils/schemas/accountManagerSchema';
import { Paginated } from 'utils/types/common';

export type AccountManagerItem = {
	id: string;
	firstName: string;
	lastName: string;
	title: string;
	email: string;
};

type GetAccountManagersRequest = {
	keyword?: string;
	pageSize?: number;
	page?: number;
};

export type GetAccountManagersResponse = {
	keyword: string | null;
	accountManagers: Paginated<AccountManagerItem>;
};

export type GetAccountManagerResponse = {
	id: string;
	firstName: string;
	lastName: string;
	name: string;
	title: string;
	email: string;
	version: number;
};

export type CreateAccountManagerResponse = {
	accountManagerId: string;
};

export type UpdateAccountManagerResponse = {
	id: string;
};

export type DeleteAccountManagerResponse = {
	accountManagerId: string;
};

class AccountManagerAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAccountManagers(
		request: GetAccountManagersRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return AccountManagerAPI.get<GetAccountManagersResponse>(
			`/account-managers?${params}`
		);
	}

	static getAccountManager(accountManagerId: string) {
		return AccountManagerAPI.get<GetAccountManagerResponse>(
			`/account-managers/${accountManagerId}`
		);
	}

	static deleteAccountManager(id: string) {
		return AccountManagerAPI.delete<DeleteAccountManagerResponse>(
			`/account-managers/${id}`
		);
	}

	static createAccountManager(data: CreateAccountManagerModel) {
		return AccountManagerAPI.post<CreateAccountManagerResponse>(
			'/account-managers',
			data
		);
	}

	static updateAccountManager(id: string, data: UpdateAccountManagerModel) {
		return AccountManagerAPI.put<UpdateAccountManagerResponse>(
			`/account-managers/${id}`,
			data
		);
	}
}

export default AccountManagerAPI;
