import { createColumnHelper } from '@tanstack/react-table';
import DateFragment from 'ui/components/DateFragment';
import {
	AdminAuditEventDetailItem,
	AdminAuditEventListItem,
} from '../../api/AdminAuditLogApi';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<AdminAuditEventListItem>();

export const timeStampColumn = columnHelper.accessor('createdAt', {
	header: 'Event Time',
	meta: {
		shrink: true,
	},
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const adminUserColumn = columnHelper.accessor('adminUser', {
	header: 'Admin User',
	cell: (info) => (
		<Link to={`/admin-users/${info.getValue().id}`}>
			{info.getValue().email}
		</Link>
	),
	enableSorting: true,
});

export const roleColumn = columnHelper.accessor('role', {
	header: 'User Type',
	cell: (info) => info.getValue().name,
	enableSorting: true,
});

export const actionColumn = columnHelper.accessor('action', {
	header: 'Action',
	cell: (info) => info.getValue().name,
	enableSorting: true,
});

export const entityColumn = columnHelper.accessor('entity', {
	header: 'Entity',
	cell: (info) => {
		let url = '';
		if (info.row.original.entity.value === 'subscription') {
			url = `/subscriptions/${info.row.original.eventDetail.subscriptionId}`;
		} else if (info.row.original.entity.value === 'customer') {
			url = `/customers/${info.row.original.customerId}`;
		} else if (info.row.original.entity.value === 'user') {
			url = `/users/${info.row.original.eventDetail.userId}`;
		}
		return <Link to={url}>{info.row.original.entityName}</Link>;
	},
	enableSorting: true,
});

export const detailColumn = columnHelper.accessor('eventDetail', {
	header: 'Detail',
	cell: (info) => (
		<div>
			{info
				.getValue()
				.details.map((item: AdminAuditEventDetailItem, index: number) => (
					<div key={`${info.row.original.id}-${index}`}>
						{item.label && <strong>{item.label}: </strong>}
						{item.description}
					</div>
				))}
		</div>
	),
});

export const adminCommentColumn = columnHelper.accessor('adminComment', {
	header: 'Admin Comment',
	cell: (info) => (info.getValue() ? info.getValue() : '-'),
});
