import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetNotificationResponse } from '../../api/MonitoringAPI';

type DeleteNotificationModalProps = {
	notification: GetNotificationResponse;
} & Omit<ModalProps, 'title'>;

const DeleteNotificationModal = ({
	notification,
	...modalProps
}: DeleteNotificationModalProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete Notification"
			submitText="Delete Notification"
			submitIcon={Trash2}
			intent="danger"
			confirmationText="delete"
			formMethod="delete"
			formAction={`/system-notifications/${notification.id}`}
			{...modalProps}
		>
			<input type="hidden" name="adminId" value={notification.adminId} />

			<p>
				Are you sure you wish to delete the notification subscription? This
				action cannot be undone.
			</p>
		</ConfirmationModal>
	);
};

export default DeleteNotificationModal;
