import { createColumnHelper } from '@tanstack/react-table';
import DateFragment from 'ui/components/DateFragment';
import {
	UsageTrackingDataRow,
	UsageTrackingType,
} from '../../api/UsageTrackingApi';

const columnHelper = createColumnHelper<UsageTrackingDataRow>();

export const timeStampColumn = columnHelper.accessor('timestamp', {
	header: 'Timestamp (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const eventTypeColumn = columnHelper.accessor('eventType', {
	header: 'Event Type',
	cell: (info) => info.getValue().name,
});

export const eventDetailColumn = columnHelper.accessor('eventDetail', {
	header: 'Event Detail',
});

export const parametersColumn = columnHelper.accessor('parameters', {
	header: 'Parameters',
	cell: (info) => {
		const trackingEntry = info.row.original;

		if (trackingEntry.eventType.value === UsageTrackingType.EXTERNAL_URL) {
			return trackingEntry.url;
		}

		return trackingEntry.parameters;
	},
});

export const userColumn = columnHelper.accessor('user', {
	header: 'User',
});

export const customerColumn = columnHelper.accessor('customer', {
	header: 'Customer',
});
