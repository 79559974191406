import { ReactElement } from 'react';
import { toast, ToastOptions } from 'react-hot-toast';
import Toast, { ToastAction, ToastType } from 'ui/components/Toaster/Toast';
import APIError from '../errors/APIError';

export const TOAST_SUCCESS_DEFAULT_DURATION = 2000;
export const TOAST_FAILURE_DEFAULT_DURATION = 4000;

export type ToastMessage = string | ReactElement;
export type ToastHint = string | string[] | ReactElement;
type ToastAdvancedData = {
	message: ToastMessage;
	hint?: ToastHint;
	actions?: ToastAction[];
	canDismiss?: boolean;
};

type ToastData = ToastMessage | ToastAdvancedData;

export const createToast = (
	type: ToastType,
	data: ToastData,
	options?: ToastOptions
) => {
	let message: ToastMessage;
	let hint: ToastHint | undefined;
	let actions: ToastAction[] | undefined;
	let canDismiss: boolean = true;

	if (typeof data === 'object' && 'message' in data) {
		message = data.message;
		hint = data.hint;
		actions = data.actions;
		canDismiss = data.canDismiss ?? true;
	} else {
		message = data;
	}

	return toast.custom(
		(t) => (
			<Toast
				message={message}
				type={type}
				hint={hint}
				actions={actions}
				rawToast={t}
				canDismiss={canDismiss}
			/>
		),
		options
	);
};

export const updateToast = (
	id: string,
	type: ToastType,
	data: ToastData,
	options?: ToastOptions
) => {
	return createToast(type, data, { ...options, id });
};

export const dismissToast = (id: string) => {
	toast.dismiss(id);
};

export const createToastFromError = (
	error: unknown,
	options?: ToastOptions
): ReturnType<typeof createToast> => {
	let toastData: ToastData;

	if (error instanceof APIError) {
		toastData = {
			message: error.message,
			hint: error.fieldErrors?.map((error) => error[1]).map((arr) => arr[0]),
		};
	} else if (error instanceof Error && error.message) {
		toastData = error.message;
	} else if (typeof error === 'string') {
		toastData = error;
	} else {
		toastData = 'An unknown error occurred';
	}

	return createToast(ToastType.ERROR, toastData, options);
};
