import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { updateAccountManagerSchema } from 'utils/schemas/accountManagerSchema';

import requireAuthentication from 'utils/helpers/requireAuthentication';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import AccountManagerAPI from '../../api/AccountManagersAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const accountManagerData = await AccountManagerAPI.getAccountManager(
		params.accountManagerId as string
	);

	return {
		accountManager: accountManagerData,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const accountManagerId = params.accountManagerId as string;
	const formData = await request.formData();

	const updateAccountManagerModel = updateAccountManagerSchema.parse(
		formDataAsObject(formData)
	);

	const data = await AccountManagerAPI.updateAccountManager(
		accountManagerId,
		updateAccountManagerModel
	);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'Account Manager updated successfully');
	return redirect(`/account-managers/${accountManagerId}`);
};

export function AccountManagerEdit() {
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateAccountManagerSchema}>
				<Grid>
					<PageHeader title={`Edit ${data.accountManager.name}`}>
						<Button
							variant="secondary"
							to={`/account-managers/${data.accountManager.id}`}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="First Name"
									name="firstName"
									isRequired={true}
									defaultValue={data.accountManager.firstName}
								/>
								<TextField
									label="Last Name"
									name="lastName"
									isRequired={true}
									defaultValue={data.accountManager.lastName}
								/>
							</Grid>
							<Grid>
								<TextField
									label="Title"
									name="title"
									isRequired={true}
									defaultValue={data.accountManager.title}
								/>
								<TextField
									label="Email"
									name="email"
									isRequired={true}
									defaultValue={data.accountManager.email}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_ACCOUNT_MANAGER_ROUTE: LayoutRouteProps = {
	loader: loader,
	action: action,
	element: <AccountManagerEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return `Edit: ${data.accountManager.firstName} ${data.accountManager.lastName}`;
		},
	},
};
