import classNames from 'classnames';
import {
	ComponentProps,
	useId,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import FormField, { FormFieldProps } from '../FormField';
import useFormReset from '../ValidatedForm/useFormReset';
import useValidation from '../ValidatedForm/useValidation';

export type TextFieldProps = {
	name: string;
	className?: string;
	resetValue?: string;
	suffix?: string;
} & ComponentProps<'input'> &
	FormFieldProps;

const TextField = ({
	resetValue,
	label,
	value: propsValue,
	defaultValue,
	onChange,
	suffix,
	...restProps
}: TextFieldProps) => {
	const id = useId();
	const { errorTree, revalidate } = useValidation(restProps.name);
	const [stateValue, setStateValue] = useState(defaultValue ?? '');
	const inputRef = useRef<HTMLInputElement>(null);

	const handleClear = () => {
		setStateValue('');
	};

	useLayoutEffect(() => {
		if (restProps.autoFocus) {
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}, 0);
		}
	}, []);

	useFormReset(handleClear);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setStateValue(e.target.value);
		onChange?.(e);
	};

	return (
		<FormField label={label} id={id} {...restProps} errors={errorTree?._errors}>
			<input
				{...restProps}
				value={propsValue ?? stateValue}
				onChange={handleChange}
				id={id}
				onBlur={revalidate}
				ref={inputRef}
				className={classNames('control__input', restProps.className)}
			/>
			{suffix && <span className="control__suffix">{suffix}</span>}
		</FormField>
	);
};

export default TextField;
