import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue, Paginated } from 'utils/types/common';
import { FormatType } from '../cddUtil/helpers';

export type SubscriptionStatus = {
	name: string;
	status: LabeledValue;
};

export type CodeSummary = {
	code: string;
	value: string;
};

export type CddSubmissionModel = {
	jobId: string;
	externalFileId: string;
	externalFile: string;
	contributorCode?: string;
	contributorPrefix?: string;
	receivedAt: Date;
	status: string;
	statusContributor: string;
	startedAt: Date;
	completedAt: Date;
	rowCount: number | null;
	validRowCount: number | null;
	invalidRowCount: number | null;
	errorCodes: string[];
	errorCodeSummary: CodeSummary[];
	alertCodeSummary: CodeSummary[];
	canDownload: boolean;
};

type GetCddContributorListRequest = {
	pageSize?: number;
	page?: number;
};

export type CddSubmissionPending = {
	id: string;
	externalFile: string;
	receivedAt: Date;
};

export type CddErrorCode = {
	code: string;
	name: string;
	description: string;
	codeType: string;
};

export type GetCddContributorResponse = {
	submissions: Paginated<CddSubmissionModel>;
	paused: boolean;
	pending: CddSubmissionPending[];
	errorCodes: CddErrorCode[];
	subscriptions: SubscriptionStatus[];
};

export type GetCddContributorAdminResponse = {
	submissions: Paginated<CddSubmissionModel>;
	errorCodes: CddErrorCode[];
	statusCodes: LabeledValue[];
	receivedDateStart: Date;
	receivedDateEnd: Date;
	startedDateStart: Date;
	startedDateEnd: Date;
};

export type GenerateUploadLinkResponse = {
	fileName: string;
	uploadUrl: string;
	uploadKey: string;
};

class CddContributorAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getCddContributorList(params: URLSearchParams) {
		return CddContributorAPI.get<GetCddContributorResponse>(
			`/cdd-contributor?${params}`
		);
	}

	static getCddContributorAdminList(params: URLSearchParams) {
		return CddContributorAPI.get<GetCddContributorAdminResponse>(
			`/cdd-contributor/admin?${params}`
		);
	}

	static generateUploadLink(filename: string, size: number) {
		return CddContributorAPI.post<GenerateUploadLinkResponse>(
			`/cdd-contributor/upload-link/${filename}?size=${size}`
		);
	}

	static downloadFile(id: string) {
		return CddContributorAPI.post<{ filename: string; url: string }>(
			`/cdd-contributor/download-link/${id}`
		);
	}

	static downloadFileAdmin(id: string) {
		return CddContributorAPI.post<{ filename: string; url: string }>(
			`/cdd-contributor/admin-download-link/${id}`
		);
	}

	static downloadReport(id: string, format: FormatType) {
		return CddContributorAPI.post<{
			url: string;
			filename: string;
			delayed: boolean;
		}>(`/cdd-contributor/report-link/${id}?format=${format}`);
	}

	static downloadReportAdmin(id: string, format: FormatType) {
		return CddContributorAPI.post<{
			url: string;
			filename: string;
			delayed: boolean;
		}>(`/cdd-contributor/admin-report-link/${id}?format=${format}`);
	}

	static async uploadFile(
		apiUrl: string,
		data: BodyInit | null | undefined
	): Promise<Response> {
		const customHeaders: { [key: string]: string } = {};

		customHeaders['Content-Type'] = 'multipart/form-data';

		const requestInit: RequestInit = {
			method: 'PUT',
			body: data,
		};

		requestInit.headers = { ...requestInit.headers, ...customHeaders };

		return await fetch(apiUrl, requestInit);
	}

	static completeUpload(id: string) {
		return CddContributorAPI.post(`/cdd-contributor/upload-complete/${id}`);
	}
}

export default CddContributorAPI;
