import { FileSpreadsheet } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';

type RunReportsModalProps = { totalReports: number; ids: string[] } & Omit<
	ModalProps,
	'title'
>;

const RunReportsModal = ({
	totalReports,
	ids,
	...modalProps
}: RunReportsModalProps) => {
	return (
		<ConfirmationModal
			fieldName="generate"
			title="Run Reports"
			confirmationText="Generate"
			submitText="Run Reports"
			submitIcon={FileSpreadsheet}
			formMethod="post"
			{...modalProps}
		>
			{ids.map((id) => (
				<input type="hidden" name="selectedRows" key={id} value={id} />
			))}

			<p>Are you sure you wish to generate {totalReports} reports?</p>
		</ConfirmationModal>
	);
};

export default RunReportsModal;
