/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import {
	zBoolPreprocessor,
	zFilesMaxSizeRefinement,
	zFilesRequiredRefinement,
	zFilesTypesRefinement,
} from 'utils/zod/zodValidation';
import z from 'zod';

export const airImpactReportSchema = z.object({
	id: z.string(),
	edition: z
		.string({ required_error: 'Edition is required' })
		.min(1, { message: 'Edition is required' })
		.max(100, { message: 'Edition must not be more than 100 characters' }),
	title: z
		.string({ required_error: 'Title is required' })
		.min(2, { message: 'Title must be at least 2 characters' })
		.max(200, { message: 'Title must not be more than 200 characters' }),
	summary: z
		.string({ required_error: 'Summary is required' })
		.min(2, { message: 'Summary must be at least 2 characters' })
		.max(3000, { message: 'Summary must not be more than 3000 characters' }),
	publicationDate: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date({ required_error: 'Active Date is required' })),
	banner: z.object({
		fileName: z.string(),
		originalName: z.string(),
		length: z.number(),
	}),
	pdf: z.object({
		fileName: z.string(),
		originalName: z.string(),
		length: z.number(),
	}),
	showInHomepage: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
	isArchived: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
});

export type AirImpactReportModel = z.infer<typeof airImpactReportSchema>;

// CREATE ANNOUNCEMENT

export const createAirImpactReportSchema = airImpactReportSchema
	.pick({
		edition: true,
		title: true,
		summary: true,
		publicationDate: true,
		showInHomepage: true,
		banner: true,
		pdf: true,
	})
	.extend({
		pdf: z
			.any()
			.superRefine(zFilesRequiredRefinement('PDF is required.'))
			.refine(
				zFilesTypesRefinement(['application/pdf']),
				'File must be a PDF file.'
			)
			.refine(
				zFilesMaxSizeRefinement(10 * 1024 * 1024),
				'PDF must be less than 10MB.'
			),
		banner: z
			.any()
			.superRefine(zFilesRequiredRefinement('Banner is required.'))
			.refine(
				zFilesTypesRefinement(['image/jpg', 'image/jpeg', 'image/png']),
				'File must be a JPG, JPEG or PNG file.'
			)
			.refine(
				zFilesMaxSizeRefinement(10 * 1024 * 1024),
				'Banner must be less than 10MB.'
			),
	});

export type CreateAirImpactReportModel = z.infer<
	typeof createAirImpactReportSchema
>;

// UPDATE USER SCHEMA

export const updateAirImpactReportSchema = airImpactReportSchema
	.pick({
		edition: true,
		title: true,
		summary: true,
		publicationDate: true,
		showInHomepage: true,
		isArchived: true,
		banner: true,
		pdf: true,
	})
	.extend({
		pdf: z
			.any()
			.refine(
				zFilesTypesRefinement(['application/pdf']),
				'File must be a PDF file.'
			)
			.refine(
				zFilesMaxSizeRefinement(10 * 1024 * 1024),
				'PDF must be less than 10MB.'
			),
		banner: z
			.any()
			.refine(
				zFilesTypesRefinement(['image/jpg', 'image/jpeg', 'image/png']),
				'File must be a JPG, JPEG or PNG file.'
			)
			.refine(
				zFilesMaxSizeRefinement(10 * 1024 * 1024),
				'Banner must be less than 10MB.'
			),
	});

export type UpdateAirImpactReportModel = z.infer<
	typeof updateAirImpactReportSchema
>;
