import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import adminExportsAPI from '../../api/AdminExportsAPI';
import * as adminExportsColumns from '../../util/columns/adminExportsColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return await adminExportsAPI.getAdminExports();
};

export function AdminExportsIndexPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		adminExportsColumns.exportTypeColumn,
		adminExportsColumns.statusColumn,
		adminExportsColumns.createdByAdminUserColumn,
		adminExportsColumns.createdAtColumn,
		adminExportsColumns.startedAtColumn,
		adminExportsColumns.completedAtColumn,
		adminExportsColumns.exportFileNameColumn,
		adminExportsColumns.titleColumn,
	];

	return (
		<>
			<div className="content">
				<PageHeader title="Admin Exports">
					<RevalidationButton>Refresh</RevalidationButton>
				</PageHeader>

				<Table identifierKey="id" columns={columns} data={data.exports.items} />

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.exports.page}
					pageParameterName="page"
					pageSize={data.exports.pageSize}
					itemCount={data.exports.totalCount}
				/>
			</div>
		</>
	);
}

export const ADMIN_EXPORTS_MAIN_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <AdminExportsIndexPage />,
};
