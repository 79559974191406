import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import {
	UpdateAdhocReportModel,
	CreateAdhocReportModel,
} from 'utils/schemas/adhocReportSchema';
import { Paginated } from 'utils/types/common';
import { CustomerStatus } from '../routes/customers/model';
import { UserOption } from './UserAPI';

export interface AdhocReportStatus {
	name: string;
	value: string;
}

export type AdhocReportListItem = {
	id: string;
	adhocReportId: number;
	title: string;
	createdAt: Date;
	releaseDate: Date;
	status: AdhocReportStatus;
	file: AdhocReportFile;
	customer: string;
	customerId: string;
	customerNumber: string;
	customerStatus: CustomerStatus;
	userCount: number;
};

export type AdhocReportFile = {
	id: string;
	fileName: string;
	originalName: string;
	fileLength: number;
};

export type GetAdhocReportsResponse = {
	customerId?: string;
	userId?: string;
	totalCustomerUsers: number;
	reports: Paginated<AdhocReportListItem>;
};

export type GetAdhocReportResponse = {
	id: string;
	adhocReportId: number;
	title: string;
	createdAt: Date;
	releaseDate: Date;
	status: AdhocReportStatus;
	file: AdhocReportFile;
	customerId: string;
	customer: string;
	userId?: string;
	user?: string;
	selectedUsers: UserOption[];
	users: UserOption[];
	notifyUsers: boolean;
	isArchived: boolean;
};

export type GetAdhocReportOptionsResponse = {
	customerId: string;
	customer: string;
	userId?: string;
	user?: string;
	selectedUsers: UserOption[];
	users: UserOption[];
};

export type CreateAdhocReportResponse = {
	id: string;
	reportUrl: string;
};

export type UpdateAdhocReportResponse = {
	id: string;
	reportUrl: string;
};

export type FileUpload = {
	fileName: string;
	length: number;
};

class AdhocReportsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static downloadAdhocReport(id: string) {
		return AdhocReportsAPI.get<{ filename: string; url: string }>(
			`/adhoc-reports/${id}/download`
		);
	}

	static deleteAdhocReport(id: string) {
		return AdhocReportsAPI.delete(`/adhoc-reports/${id}`);
	}

	static getAdhocReport(id: string, params: URLSearchParams) {
		return AdhocReportsAPI.get<GetAdhocReportResponse>(
			`/adhoc-reports/${id}?${params}`
		);
	}

	static getAdhocReports(request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return AdhocReportsAPI.get<GetAdhocReportsResponse>(
			`/adhoc-reports?${params}`
		);
	}

	static getAdhocReportOptions(request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return AdhocReportsAPI.get<GetAdhocReportOptionsResponse>(
			`/adhoc-reports/options?${params}`
		);
	}

	static createAdhocReport(data: CreateAdhocReportModel, file: FileUpload) {
		data.reportFile = file;

		return AdhocReportsAPI.post<CreateAdhocReportResponse>(
			'/adhoc-reports',
			data
		);
	}

	static updateAdhocReport(
		id: string,
		data: UpdateAdhocReportModel,
		file: FileUpload
	) {
		data.reportFile = file;

		return AdhocReportsAPI.put<UpdateAdhocReportResponse>(
			`/adhoc-reports/${id}`,
			data
		);
	}

	static async uploadFile(
		apiUrl: string,
		data: BodyInit | null | undefined
	): Promise<Response> {
		const customHeaders: { [key: string]: string } = {};

		customHeaders['Content-Type'] = 'multipart/form-data';

		const requestInit: RequestInit = {
			method: 'PUT',
			body: data,
		};

		requestInit.headers = { ...requestInit.headers, ...customHeaders };

		return await fetch(apiUrl, requestInit);
	}
}

export default AdhocReportsAPI;
