import { createContext, useContext } from 'react';

type ConfirmationModalContextValue = {
	formId: string;
};

const ConfirmationModalContext = createContext<ConfirmationModalContextValue>({
	formId: '',
});

export const useConfirmationModalContext = () =>
	useContext(ConfirmationModalContext);

export default ConfirmationModalContext;
