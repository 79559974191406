import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI from '../../api/SubscriptionAPI';
import * as reportColumns from '../../util/columns/subscriptionReportColumns';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const subscriptionId = params.subscriptionId as string;

	return SubscriptionAPI.getSubscriptionReports(
		subscriptionId,
		new URL(request.url).searchParams
	);
};

const SubscriptionReports = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		reportColumns.generationStatusColumn,
		reportColumns.distributionStatusColumn,
		reportColumns.reportDateColumn,
		reportColumns.versionColumn,
		reportColumns.createdAtColumn,
		reportColumns.attachmentColumn,
	];

	if (!data.showVariantColumn) {
		const index = columns.indexOf(reportColumns.versionColumn, 0);
		columns.splice(index, 1);
	}

	if (!data.showDistributionColumn) {
		const index = columns.indexOf(reportColumns.distributionStatusColumn, 0);
		columns.splice(index, 1);
	}

	return (
		<>
			<Flex direction="column" gap={16}>
				<Table
					identifierKey="id"
					columns={columns}
					data={data.reports.items}
					emptyText="No reports found"
				/>

				<TabActions>
					<RevalidationButton size="small">Refresh</RevalidationButton>
				</TabActions>

				{data.reports.totalCount > data.reports.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const SUBSCRIPTION_DETAILS_REPORTS_ROUTE: LayoutRouteProps = {
	loader,
	element: <SubscriptionReports />,
};
