import { createColumnHelper } from '@tanstack/react-table';
import { Pencil } from 'lucide-react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import AttachmentLink from 'ui/components/AttachmentLink';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';

const columnHelper = createColumnHelper<AirImpactReportModel>();

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
	cell: (info) => {
		const { id } = useParams<{ id: string }>();
		const [searchParams] = useSearchParams();

		return (
			<Link to={`/air-impact-reports/${info.row.original.id}?${searchParams}`}>
				{id === info.row.original.id ? (
					<b>{info.getValue()}</b>
				) : (
					info.getValue()
				)}
			</Link>
		);
	},
});

export const editionColumn = columnHelper.accessor('edition', {
	header: 'Edition',
});

export const publicationDateColumn = columnHelper.accessor('publicationDate', {
	header: 'Publication Date',
	cell: (info) => <DateFragment date={info.getValue()} timezone="utc" />,
});

export const isArchivedColumn = columnHelper.accessor('isArchived', {
	header: 'Archived',
	cell: (info) => (info.getValue() ? 'Yes' : 'No'),
});

export const showHomepageColumn = columnHelper.accessor('showInHomepage', {
	header: 'Show  on Homepage',
	cell: (info) => (info.getValue() ? 'Yes' : 'No'),
});

export const attachmentColumn = columnHelper.accessor('pdf', {
	header: 'Attachment',
	cell: (info) => (
		<AttachmentLink
			name="PDF"
			title={info.getValue().originalName}
			url={info.getValue().fileName}
			size={info.getValue().length}
		/>
	),
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => (
		<Button
			variant="secondary"
			size="small"
			icon={Pencil}
			to={`/air-impact-reports/${info.getValue()}/edit`}
		>
			Edit
		</Button>
	),
});
