import AirlinePicker from 'ui/components/AirlinePicker';
import FreightForwarderPicker from 'ui/components/FreightForwarderPicker';
import { AirlineOption } from 'utils/api/AirlinesAPI';
import { CustomerTypeOption } from 'utils/api/common';
import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import { Option } from 'utils/types/common';

interface CustomerTypeFieldsProps {
	customerType: CustomerTypeOption | null;
	initialAirlines?: AirlineOption[];
	initialFreightForwarders?: Option<FreightForwarderSingleOption[]>;
}

export const CustomerTypeFields = ({
	customerType,
	initialAirlines,
	initialFreightForwarders,
}: CustomerTypeFieldsProps) => {
	if (customerType?.accountType === 'airline')
		return (
			<>
				<AirlinePicker
					name="airlines"
					label="Airlines"
					initialValues={initialAirlines || []}
					isMulti={true}
				/>
			</>
		);

	if (customerType?.accountType === 'freight-forwarder') {
		return (
			<>
				<input
					type="hidden"
					name="accountType"
					value={customerType.accountType}
				/>
				<FreightForwarderPicker
					name="freightForwarders"
					label="Freight Forwarder"
					initialValues={initialFreightForwarders ?? []}
					isMulti
				/>
			</>
		);
	}

	return null;
};
