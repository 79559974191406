import BulletIndicator from 'ui/components/BulletIndicator';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import { ProcessRegulator } from '../../api/DataManagementAPI';
import { listenOrPausedIntent } from './system-status';
import SystemProcess from './SystemProcess';
import SystemProcessToggle from './SystemProcessToggle';

type SystemProcessRegulatorProps = {
	regulator: ProcessRegulator;
};

const SystemProcessRegulator = ({ regulator }: SystemProcessRegulatorProps) => {
	return (
		<>
			<Flex gap={16} alignItems="center">
				<h3>{regulator.name}</h3>
				<BulletIndicator
					intent={listenOrPausedIntent(regulator.status.code)}
					label={regulator.status.name}
				/>
				<SystemProcessToggle
					systemProcessCode={regulator.code}
					status={regulator.status}
					activeStatus="listening"
					validStatus={regulator.validStatus}
				/>
			</Flex>

			<Grid columns={2}>
				{regulator.childProcesses.map((childProcess) => (
					<SystemProcess systemProcess={childProcess} key={childProcess.code} />
				))}
			</Grid>
		</>
	);
};

export default SystemProcessRegulator;
