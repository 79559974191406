import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { Day } from 'date-fns';
import { Paginated } from 'utils/types/common';

export type NotificationScheduleEntryCreated = {
	frequency: 'immediate-notification';
};

export type NotificationScheduleDaily = {
	frequency: 'daily';
	time: number;
};

export type NotificationScheduleWeekly = {
	frequency: 'weekly';
	dayOfWeek: Day;
	time: number;
};

export type CreateNotificationModel = {
	adminId: string;
	name: string | undefined;
	frequency: string;
	dayOfWeek: Day;
	time: number;
	notifications: NotificationType[];
};

export type EditNotificationModel = {
	id: string;
	name: string | undefined;
	frequency: string;
	dayOfWeek: Day;
	time: number;
	notifications: NotificationType[];
};

export type CreateNotificationResponse = {
	id: string;
};

export type NotificationSchedule = {
	lastExecution: Date | null;
	nextExecution: Date | null;
} & (
	| NotificationScheduleEntryCreated
	| NotificationScheduleDaily
	| NotificationScheduleWeekly
);

export type NotificationItem = {
	id: string;
	name: string;
	notifications: NotificationTypeAndOptionItem[];
	schedule: NotificationSchedule;
	createdAt: Date;
	updatedAt: Date;
};

export type GetNotificationsResponse = {
	results: Paginated<NotificationItem>;
};

export type NotificationOption = 'Successes' | 'Errors' | 'All';

export type GetNotificationResponse = {
	id: string;
	adminId: string;
	adminName: string;
	name: string;
	schedule: NotificationSchedule;
	notifications: NotificationTypeAndOptionItem[];
	createdAt: Date;
	updatedAt: Date;
};

export type AlertJobItem = {
	value: string;
	name: string;
	acceptableNotificationOptions: NotificationOptionItem[];
};

export type NotificationOptionItem = {
	value: NotificationOption;
	name: string;
};

export type NotificationTypeAndOptionItem = {
	name: string;
	notificationType: AlertJobItem;
	notificationOption: NotificationOptionItem;
	selected: boolean;
};

export type NotificationType = {
	value: string;
	selected: boolean;
	option: string;
};

export type GetNotificationOptions = {
	notificationTypes: NotificationTypeAndOptionItem[];
};

class MonitoringAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getNotifications(adminId: string) {
		return MonitoringAPI.get<GetNotificationsResponse>(
			`/system-notifications?adminId=${adminId}`
		);
	}

	static getNotification(id: string) {
		return MonitoringAPI.get<GetNotificationResponse>(
			`/system-notifications/${id}`
		);
	}

	static getNotificationOptions() {
		return MonitoringAPI.get<GetNotificationOptions>(
			`/system-notifications/options`
		);
	}

	static createNotification(data: CreateNotificationModel) {
		return MonitoringAPI.post<CreateNotificationResponse>(
			'/system-notifications',
			data
		);
	}

	static updateNotification(data: EditNotificationModel) {
		return MonitoringAPI.put<CreateNotificationResponse>(
			`/system-notifications/${data.id}`,
			data
		);
	}

	static deleteNotification(id: string) {
		return MonitoringAPI.delete(`/system-notifications/${id}`);
	}
}

export default MonitoringAPI;
