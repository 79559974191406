import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetCustomerTeamResponse } from '../../api/CustomerTeamsAPI';

type DeleteCustomerTeamProps = {
	team: GetCustomerTeamResponse;
	userId: string;
} & Omit<ModalProps, 'title'>;

const DeleteCustomerTeamModal = ({
	team,
	userId,
	...modalProps
}: DeleteCustomerTeamProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete Team"
			confirmationText="Delete"
			submitText="Delete Team"
			submitIcon={Trash2}
			intent="danger"
			formAction={`/customer-teams/${team.id}/edit`}
			formMethod="delete"
			{...modalProps}
		>
			<input type="hidden" name="customerId" value={team.customerId} />
			<input type="hidden" name="userId" value={userId} />

			<p>
				Are you sure you wish to delete the team <b>{team.name}</b>?
			</p>
		</ConfirmationModal>
	);
};

export default DeleteCustomerTeamModal;
