import { PlusCircle, RefreshCcw, Search } from 'lucide-react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import AdminUserAPI from '../../api/AdminUserAPI';
import { DropdownField } from '../../forms/DropdownField';
import * as adminUserColumns from '../../util/columns/adminUserColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getAdminUsersResponse = await AdminUserAPI.getAdminUsers(
		new URL(request.url).searchParams
	);

	return {
		getAdminUsersResponse: getAdminUsersResponse,
	};
};

export function AdminUsersListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		adminUserColumns.nameColumn,
		adminUserColumns.emailColumn,
		adminUserColumns.roleColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Admin Users">
					<RevalidationButton>Refresh</RevalidationButton>

					<Button
						variant="secondary"
						icon={PlusCircle}
						to="/admin-users/create"
					>
						Add Admin User
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.getAdminUsersResponse.adminUsers.items}
				/>

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.getAdminUsersResponse.adminUsers.page}
					pageParameterName="page"
					pageSize={data.getAdminUsersResponse.adminUsers.pageSize}
					itemCount={data.getAdminUsersResponse.adminUsers.totalCount}
				/>
			</div>

			<Sidebar title="User Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />
					<DropdownField
						label="Admin Role"
						name="role"
						options={data.getAdminUsersResponse.roles}
						contentSource={(option) => option.name}
						identifierKey="value"
						placeholder="Please select..."
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_ADMIN_USERS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <AdminUsersListPage />,
	handle: {
		breadcrumbs: { label: 'Users' },
	},
};
