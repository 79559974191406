import classNames from 'classnames';
import { Check, Mail, Pencil, Phone, PlusCircle, Trash, X } from 'lucide-react';
import { useState } from 'react';
import FormField from 'ui/components/FormField';
import useValidation from 'ui/components/ValidatedForm/useValidation';

export type Contact = {
	name: string;
	email: string;
	phone: string;
};

type ContactManagerProps = {
	name: string;
	defaultValue?: Contact[];
};

const ContactManager = ({ name, defaultValue = [] }: ContactManagerProps) => {
	const [editingIndex, setEditingIndex] = useState<number | null>(null);
	const [editingContact, setEditingContact] = useState<Contact | null>(null);

	const { errorTree, revalidate } = useValidation(name);

	const [contacts, setContacts] = useState<Contact[]>(defaultValue);

	const handleChange =
		(key: keyof Contact) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const text = e.target.value;
			if (text !== null && editingContact) {
				setEditingContact({ ...editingContact, [key]: text });
			}
		};

	const handleNameChange = handleChange('name');
	const handleEmailChange = handleChange('email');
	const handlePhoneChange = handleChange('phone');

	const addContact = () => {
		const newContact: Contact = {
			name: '',
			email: '',
			phone: '',
		};

		setContacts([...contacts, newContact]);
		setEditingContact(newContact);
		setEditingIndex(contacts.length);
	};

	const saveEdit = () => {
		if (editingContact && editingIndex !== null) {
			const newContacts = [...contacts];
			newContacts[editingIndex] = editingContact;
			setContacts(newContacts);
			setEditingIndex(null);
			setEditingContact(null);
			revalidate();
		}
	};

	const cancelEdit = () => {
		setEditingIndex(null);
		setEditingContact(null);
		revalidate();
	};

	const editContact = (index: number) => {
		setEditingIndex(index);
		setEditingContact({ ...contacts[index] });
	};

	const deleteContact = (index: number) => {
		const newContacts = [...contacts];
		newContacts.splice(index, 1);
		setContacts(newContacts);
		revalidate();
	};

	return (
		<FormField label="Contacts" errors={errorTree?._errors}>
			<div className="contacts">
				<ul className="contacts__list">
					{contacts.map((contact, index) => {
						const isEditing = editingIndex === index;

						return (
							<li
								className={classNames(
									'contacts__item',
									isEditing && 'contacts__item--editing'
								)}
								key={index}
							>
								{isEditing ? (
									<div className="contacts__info">
										<input
											type="text"
											className="contacts__name"
											value={editingContact?.name}
											onChange={handleNameChange}
										/>
										<div className="contacts__details">
											<div className="contacts__detail contacts__detail--email">
												<Mail size={14} />
												<input
													type="text"
													value={editingContact?.email}
													onChange={handleEmailChange}
												/>
											</div>
											<div className="contacts__detail contacts__detail--phone">
												<Phone size={14} />
												<input
													type="text"
													value={editingContact?.phone}
													onChange={handlePhoneChange}
												/>
											</div>
										</div>
									</div>
								) : (
									<div className="contacts__info">
										{contact.name && (
											<span className="contacts__name">{contact.name}</span>
										)}
										<div className="contacts__details">
											{contact.email && (
												<a
													href={`tel:${contact.phone}`}
													className="contacts__detail contacts__detail--email"
												>
													<Mail size={14} />
													<span>{contact.email}</span>
												</a>
											)}
											{contact.phone && (
												<a
													href={`mailto:${contact.email}`}
													className="contacts__detail contacts__detail--phone"
												>
													<Phone size={14} />
													<span>{contact.phone}</span>
												</a>
											)}
										</div>
										{errorTree?.[index]?.name?._errors.length && (
											<div className="contacts__error">
												{errorTree?.[index]?.name?._errors}
											</div>
										)}
										{errorTree?.[index]?.email?._errors.length && (
											<div className="contacts__error">
												{errorTree?.[index]?.email?._errors}
											</div>
										)}
										{errorTree?.[index]?.phone?._errors.length && (
											<div className="contacts__error">
												{errorTree?.[index]?.phone?._errors}
											</div>
										)}
									</div>
								)}
								{isEditing ? (
									<div className="contacts__actions">
										<button
											type="button"
											className="contacts__action contacts__action--edit"
											onClick={saveEdit}
										>
											<Check size={18} />
										</button>
										<button
											type="button"
											className="contacts__action contacts__action--delete"
											onClick={cancelEdit}
										>
											<X size={18} />
										</button>
									</div>
								) : (
									<div className="contacts__actions">
										<button
											type="button"
											className="contacts__action contacts__action--edit"
											onClick={() => editContact(index)}
										>
											<Pencil size={18} />
										</button>
										<button
											type="button"
											className="contacts__action contacts__action--delete"
											onClick={() => deleteContact(index)}
										>
											<Trash size={18} />
										</button>
									</div>
								)}
								<input
									type="hidden"
									name={`${name}.${index}.name`}
									value={contact.name}
								/>
								<input
									type="hidden"
									name={`${name}.${index}.email`}
									value={contact.email}
								/>
								<input
									type="hidden"
									name={`${name}.${index}.phone`}
									value={contact.phone}
								/>
							</li>
						);
					})}
				</ul>
				<button className="contacts__add" type="button" onClick={addContact}>
					<PlusCircle size={18} />
					<span>Add Contact</span>
				</button>
			</div>
		</FormField>
	);
};

export default ContactManager;
