import useValidation from '../ValidatedForm/useValidation';
import { flattenErrors } from 'utils/zod/zodErrors';

type ValidatedFormErrorProps = {
	name: string;
	includeChildren?: boolean;
};

const ValidatedFormError = ({
	name,
	includeChildren,
}: ValidatedFormErrorProps) => {
	const { errorTree } = useValidation(name);
	const errors = includeChildren
		? flattenErrors(errorTree)
		: errorTree?._errors;

	return (
		<>
			{errors && errors.length > 0 && (
				<div className="control__error">
					{typeof errors === 'string' ? errors : errors.join(', ')}
				</div>
			)}
		</>
	);
};

export default ValidatedFormError;
