export const IdTokenClaims = (props: { idTokenClaims: any }) => {
	return (
		<div id="token-div">
			<p>
				<strong>Name: </strong> {props.idTokenClaims.name}
			</p>
			<p>
				<strong>UPN: </strong> {props.idTokenClaims.preferred_username}
			</p>
		</div>
	);
};
