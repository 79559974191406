import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import Grid from 'ui/components/Grid';
import APIError from 'utils/errors/APIError';
import { clearMsalCache } from 'utils/helpers/msal';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import AdminUserAPI from '../../api/AdminUserAPI';
import { msalInstance } from '../../auth/authConfig';
import { IdTokenClaims } from './IdTokenClaims';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	try {
		await AdminUserAPI.getAdminProfile();
	} catch (err) {
		if (err instanceof APIError && err.status === 401) {
			clearMsalCache(window.msal.instance);
			sessionStorage.setItem('notAuthorized', 'true');
			return redirect('/login');
		}
	}

	return null;
};

export default function Home() {
	const accounts = msalInstance.getAllAccounts();

	return (
		<div className="content">
			<Grid columns={2}>
				<div>
					<h2>CargoIS Admin</h2>
					<p>Welcome to the CargoIS Admin</p>

					{accounts.length > 0 ? (
						<IdTokenClaims idTokenClaims={accounts[0].idTokenClaims} />
					) : null}
				</div>
			</Grid>
		</div>
	);
}

export const HOME_ROUTE: DecentralizedRouteProps = {
	element: <Home />,
	loader,
};
