import { z } from 'zod';
import { zAuditable } from '../zod/zodValidation';

export type AdminRoleOption = {
	name: string;
	value: 'cargois-team' | 'sales-team';
};

export type AccountTypeCode =
	| 'airline'
	| 'demo'
	| 'freight-forwarder'
	| 'third-party';

export type CustomerTypeOption = {
	id: string;
	name: string;
	accountType: AccountTypeCode;
};

export type StationOption = {
	naturalKey: string;
	code: string;
	name: string;
	cityCode: string;
	cityName: string;
	countryCode: string;
	countryName: string;
	itemType: 'station';
	locationId: `station-${string}`;
};

export type CityOption = {
	naturalKey: string;
	code: string;
	name: string;
	countryCode: string;
	countryName: string;
	stations: {
		code: string;
		name: string;
	}[];
	itemType: 'city';
	locationId: `city-${string}`;
};

export type ProvinceOption = {
	naturalKey: string;
	code: string;
	name: string;
	itemType: 'province';
	locationId: `province-${string}`;
};

export type RegionOption = {
	naturalKey: string;
	code: string;
	name: string;
	countries: {
		code: string;
		name: string;
	}[];
	itemType: 'region';
	locationId: `region-${string}`;
};

export type LocationCustomGroupOption = {
	code: string;
	name: string;
	naturalKey: string;
	itemType: 'custom-group';
	locationId: `custom-group-${string}`;
};

export type Location =
	| StationOption
	| CityOption
	| ProvinceOption
	| Country
	| RegionOption
	| LocationCustomGroupOption;

export type LocationGroup = {
	label: string;
	itemType: string;
	options: Location[];
};

export type Country = {
	naturalKey: string;
	name: string;
	code: string;
	itemType: 'country';
	locationId: `country-${string}`;
};

export type LocationTypeCode =
	| 'station'
	| 'city'
	| 'province'
	| 'country'
	| 'region';

export type ReportCountryVariant = {
	id: number;
	key: string;
	name: string;
};

export const deleteSchema = zAuditable;

export type DeleteSchema = z.infer<typeof deleteSchema>;
