import { SystemProcess as SystemProcessType } from '../../api/DataManagementAPI';
import BlockingSystemProcess from './BlockingSystemProcess';
import QueueSystemProcess from './QueueSystemProcess';
import SystemProcessRegulator from './SystemProcessRegulator';

type SystemProcessProps = {
	systemProcess: SystemProcessType;
};

const SystemProcess = ({ systemProcess }: SystemProcessProps) => {
	switch (systemProcess.type) {
		case 'regulator':
			return <SystemProcessRegulator regulator={systemProcess} />;
		case 'queue':
			return <QueueSystemProcess queue={systemProcess} />;
		case 'blocking-process':
			return <BlockingSystemProcess blockingProcess={systemProcess} />;
		default:
			throw new Error('Unknown system process type');
	}
};

export default SystemProcess;
