import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import {
	AirImpactReportModel,
	CreateAirImpactReportModel,
	UpdateAirImpactReportModel,
} from 'utils/schemas/airImpactReportSchema';
import { Paginated } from 'utils/types/common';

export type GetAirImpactReportsResponse = {
	keyword: string;
	reports: Paginated<AirImpactReportModel>;
};

export type CreateAirImpactReportResponse = {
	reportId: string;
	bannerUrl: string;
	pdfUrl: string;
};

export type UpdateAirImpactReportResponse = {
	reportId: string;
	bannerUrl: string;
	pdfUrl: string;
};

export type FileUpload = {
	fileName: string;
	length: number;
};

class AirImpactReportsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAirImpactReports(params: URLSearchParams) {
		return AirImpactReportsAPI.get<GetAirImpactReportsResponse>(
			`/air-impact-reports?${params}`
		);
	}

	static getAirImpactReport(id: string) {
		return AirImpactReportsAPI.get<AirImpactReportModel>(
			`/air-impact-reports/${id}`
		);
	}

	static createAirImpactReport(
		data: CreateAirImpactReportModel,
		banner: FileUpload,
		pdf: FileUpload
	) {
		data.banner = banner;
		data.pdf = pdf;
		return AirImpactReportsAPI.post<CreateAirImpactReportResponse>(
			'/air-impact-reports',
			data
		);
	}

	static updateWebAnnouncement(
		id: string,
		data: UpdateAirImpactReportModel,
		banner: FileUpload,
		pdf: FileUpload
	) {
		data.banner = banner;
		data.pdf = pdf;
		return AirImpactReportsAPI.put<UpdateAirImpactReportResponse>(
			`/air-impact-reports/${id}`,
			data
		);
	}

	static deleteAirImpactReport(id: string) {
		return AirImpactReportsAPI.delete(`/air-impact-reports/${id}`);
	}

	static async uploadFile(
		apiUrl: string,
		data: BodyInit | null | undefined
	): Promise<Response> {
		const customHeaders: { [key: string]: string } = {};

		customHeaders['Content-Type'] = 'multipart/form-data';

		const requestInit: RequestInit = {
			method: 'PUT',
			body: data,
		};

		requestInit.headers = { ...requestInit.headers, ...customHeaders };

		return await fetch(apiUrl, requestInit);
	}
}

export default AirImpactReportsAPI;
