import { Pencil, Trash2 } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useLoaderData,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import WebAnnouncementsAPI from '../../api/WebAnnouncementsAPI';
import DeleteWebAnnouncementModal from '../../components/DeleteWebAnnouncementModal';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const announcement = await WebAnnouncementsAPI.getAnnouncement(
		params.id as string
	);

	return {
		webAnnouncement: announcement,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		await WebAnnouncementsAPI.deleteWebAnnouncement(params.id as string);
		createToast(ToastType.SUCCESS, 'Web announcement deleted successfully');
		return redirect('/web-announcements');
	}

	return null;
};

export function WebAnnouncementDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const { webAnnouncement } = useLoaderData() as LoaderData<typeof loader>;

	return (
		<Flex direction="column" gap={16}>
			<Card className="web-announcement">
				<span className="web-announcement__date">
					<DateFragment date={webAnnouncement.releaseDate} timezone="utc" /> -{' '}
					<DateFragment date={webAnnouncement.expiryDate} timezone="utc" />
				</span>
				<h2 className="web-announcement__title">{webAnnouncement.title}</h2>
				<div className="web-announcement__separator" />
				<div
					className="web-announcement__content html-content"
					dangerouslySetInnerHTML={{
						__html: webAnnouncement.body,
					}}
				/>
				<div className="web-announcement__separator" />
				<Flex gap={8}>
					<Button
						size="small"
						variant="secondary"
						to={`/web-announcements/${webAnnouncement.id}/edit`}
						icon={Pencil}
					>
						Edit
					</Button>
					<Button
						size="small"
						variant="secondary"
						intent="danger"
						onClick={() => setDeleteModalOpen(true)}
						icon={Trash2}
					>
						Delete
					</Button>

					<DeleteWebAnnouncementModal
						announcement={webAnnouncement}
						isOpen={isDeleteModalOpen}
						onClose={() => setDeleteModalOpen(false)}
					/>
				</Flex>
			</Card>
		</Flex>
	);
}

export const DETAILS_WEB_ANNOUNCEMENT_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <WebAnnouncementDetails />,
	handle: {
		breadcrumbs: { label: 'Details' },
	},
};
