import { Mail, Phone } from 'lucide-react';
import Card from 'ui/components/Card';
import { Contact } from '../ContactManager/ContactManager';

type CustomerContactsProps = {
	contacts: Contact[];
};

const CustomerContacts = ({ contacts }: CustomerContactsProps) => {
	return (
		<Card className="customer-contacts" label="Contacts">
			<ul className="customer-contacts__items">
				{contacts.map((c, i) => (
					<li key={i} className="customer-contacts__item contact">
						<div className="contact__name">{c.name}</div>
						<ul className="contact__details">
							{c.email && (
								<li className="contact__detail contact__detail--email">
									<Mail size={18} />
									<span>
										<a href={`mailto:${c.email}`}>{c.email}</a>
									</span>
								</li>
							)}
							{c.phone && (
								<li className="contact__detail contact__detail--phone">
									<Phone size={18} />
									<span>
										<a href={`tel:${c.phone}`}>{c.phone}</a>
									</span>
								</li>
							)}
						</ul>
					</li>
				))}
			</ul>
		</Card>
	);
};

export default CustomerContacts;
