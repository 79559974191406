import { DecentralizedRouteProps } from 'utils/types/common';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import MonitoringAPI, {
	EditNotificationModel,
	NotificationSchedule,
} from '../../api/MonitoringAPI';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import ValidatedForm from 'ui/components/ValidatedForm';
import React, { useState } from 'react';
import PageHeader from 'ui/components/PageHeader';
import Button from 'ui/components/Button/Button';
import { Trash2, X } from 'lucide-react';
import Grid from 'ui/components/Grid';
import ErrorList from 'ui/components/ErrorList';
import NotificationScheduleForm from './NotificationScheduleForm';
import APIError from 'utils/errors/APIError';
import toast from 'react-hot-toast';
import DeleteNotificationModal from '../../components/DeleteNotificationModal';
import TextField from 'ui/components/TextField';
import {
	EditSystemNotificationSubscriptionModel,
	editSystemSubscriptionSchema,
} from 'utils/schemas/systemNotificationSchema';
import { formDataAsObject } from 'utils/formData/formData';
import { createToastFromError } from 'utils/helpers/toast';
import {
	getDayOffsetForLocalHour,
	localHourToUTC,
	modDaysOfWeek,
	utcHourToLocal,
} from 'utils/helpers/timezone';
import Flex from 'ui/components/Flex/Flex';
import NotificationSelector from './NotificationsSelector';

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const id = params.id as string;
	return await MonitoringAPI.getNotification(id);
};

const payloadFromData = (model: EditSystemNotificationSubscriptionModel) => {
	let payload: EditNotificationModel = {
		id: model.id,
		name: model.name,
		frequency: model.schedule.frequency,
		dayOfWeek: 0,
		time: 0,
		notifications: model.notifications,
	};

	if (model.schedule.frequency === 'weekly') {
		const dow = model.schedule.dayOfWeek || '0';
		payload.dayOfWeek = parseInt(dow) as Day;
	}
	if (
		model.schedule.frequency === 'daily' ||
		model.schedule.frequency === 'weekly'
	) {
		const time = model.schedule.time || '';
		const seg = time.split(':');

		payload.time = parseInt(seg[0]);

		const utcHour = localHourToUTC(payload.time);

		if (model.schedule.frequency === 'weekly') {
			const dayOffset = getDayOffsetForLocalHour(payload.time);

			payload.dayOfWeek = modDaysOfWeek(payload.dayOfWeek + dayOffset) as Day;
		}

		payload.time = utcHour;
	}

	return payload;
};

const adjustedSchedule = (
	schedule: NotificationSchedule
): NotificationSchedule => {
	if (schedule.frequency === 'weekly') {
		const localHour = utcHourToLocal(schedule.time);
		const dayOffset = getDayOffsetForLocalHour(localHour);

		return {
			frequency: schedule.frequency,
			lastExecution: schedule.lastExecution,
			nextExecution: schedule.nextExecution,
			dayOfWeek: modDaysOfWeek(schedule.dayOfWeek - dayOffset) as Day,
			time: localHour,
		};
	}
	if (schedule.frequency === 'daily') {
		const localHour = utcHourToLocal(schedule.time);

		return {
			frequency: schedule.frequency,
			lastExecution: schedule.lastExecution,
			nextExecution: schedule.nextExecution,
			time: localHour,
		};
	}

	return schedule;
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	if (request.method === 'DELETE') {
		const result = await MonitoringAPI.deleteNotification(params.id as string);

		if (result instanceof APIError) {
			createToastFromError(result);

			return;
		}

		toast.success('Notification successfully deleted');

		return redirect(`/admin-users/${formData.get('adminId')}`);
	}

	const model: EditSystemNotificationSubscriptionModel =
		editSystemSubscriptionSchema.parse(formDataAsObject(formData));

	if (request.method === 'POST') {
		const payload: EditNotificationModel = payloadFromData(model);
		const result = await MonitoringAPI.updateNotification(payload);

		if (result instanceof APIError) {
			return result;
		}

		toast.success('Subscription successfully saved');

		return redirect(`/admin-users/${model.adminId}`);
	}

	return null;
};

export function NotificationEditPage() {
	const navigation = useNavigation();

	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const scheduled = adjustedSchedule(data.schedule);
	const [schedule, setSchedule] = useState(scheduled);

	return (
		<div className="content">
			<ValidatedForm method="post" validator={editSystemSubscriptionSchema}>
				<input type="hidden" name="id" value={data.id} />
				<input type="hidden" name="adminId" value={data.adminId} />
				<Grid>
					<PageHeader title="Edit Notification">
						<Button
							isDisabled={navigation.state !== 'idle'}
							variant="secondary"
							to={`/admin-users/${data.adminId}`}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isDisabled={navigation.state !== 'idle'}
							type="button"
							variant="secondary"
							icon={Trash2}
							onClick={() => setDeleteModalOpen(true)}
							data-testid="delete-button"
						>
							Delete
						</Button>
						<Button
							type="submit"
							isDisabled={navigation.state !== 'idle'}
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							data-testid="edit-button"
						>
							Update
						</Button>
					</PageHeader>
					<DeleteNotificationModal
						notification={data}
						isOpen={deleteModalOpen}
						onClose={() => setDeleteModalOpen(false)}
					/>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<TextField
								label="Name"
								name="name"
								isRequired={true}
								defaultValue={data.name}
							/>
							<NotificationScheduleForm
								schedule={schedule}
								onScheduleChange={setSchedule}
							/>
						</Grid>
					</Grid>
					<Grid columns={2} isBox>
						<Grid>
							<Flex direction="column" gap={16}>
								<NotificationSelector types={data.notifications} />
							</Flex>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const NOTIFICATION_EDIT_ROUTE: DecentralizedRouteProps = {
	loader,
	action: action,
	element: <NotificationEditPage />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return [
				{
					label: 'Admin Users',
					path: '/admin-users',
				},
				{
					label: data.adminName,
					path: `/admin-users/${data.adminId}`,
				},
			];
		},
	},
};
