import { Save } from 'lucide-react';
import { useId, useState } from 'react';
import AuditCommentField from 'ui/components/AuditCommentField';
import Button from 'ui/components/Button';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import IntegerField from 'ui/components/IntegerField';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import ValidatedForm from 'ui/components/ValidatedForm';
import pluralize from 'utils/helpers/pluralize';
import useSubmitting from 'utils/hooks/useSubmitting';
import { monthPickerSchema } from 'utils/schemas/subscriptionSchema';
import { zAuditable, zEnsureArrayPreprocessor } from 'utils/zod/zodValidation';
import { z } from 'zod';
import { RenewSubscriptionsResponse } from '../../api/SubscriptionAPI';
import { DropdownField } from '../../forms/DropdownField';
import SubscriptionRenewalSummary from './SubscriptionRenewalSummary';

export const renewSubscriptionsSchema = z.discriminatedUnion('mode', [
	z
		.object({
			endPeriod: monthPickerSchema('End period'),
			subscriptionIds: z.preprocess(
				zEnsureArrayPreprocessor,
				z.array(z.string()).nonempty()
			),
			customerId: z.string(),
			mode: z.literal('date'),
		})
		.merge(zAuditable),
	z
		.object({
			months: z.coerce.number().int().min(1).max(24),
			subscriptionIds: z.preprocess(
				zEnsureArrayPreprocessor,
				z.array(z.string()).nonempty()
			),
			customerId: z.string(),
			mode: z.literal('months'),
		})
		.merge(zAuditable),
]);

export type RenewSubscriptionsModel = z.infer<typeof renewSubscriptionsSchema>;

const MODE_OPTIONS = [
	{ value: 'date' as const, label: 'Specific Date' },
	{ value: 'months' as const, label: 'Months' },
];

interface UpdateSubscriptionExpiryModalProps extends Omit<ModalProps, 'title'> {
	customerId: string;
	subscriptionIds: Array<string>;
	responseData?: RenewSubscriptionsResponse;
}

const UpdateSubscriptionExpiryModal = ({
	customerId,
	subscriptionIds,
	responseData,
	...modalProps
}: UpdateSubscriptionExpiryModalProps) => {
	const isSubmitting = useSubmitting();
	const formId = useId();

	const [mode, setMode] =
		useState<(typeof MODE_OPTIONS)[number]['value']>('date');

	return (
		<Modal
			title="Subscription Renewal"
			size={responseData ? 'lg' : 'md'}
			{...modalProps}
		>
			<Modal.Body>
				<ValidatedForm
					method="POST"
					validator={renewSubscriptionsSchema}
					id={formId}
				>
					{!responseData && (
						<Flex direction="column" gap={16}>
							<p>
								You are about to update the subscription end period for{' '}
								{subscriptionIds.length}{' '}
								{pluralize('subscription', subscriptionIds.length)}.
							</p>
							<Grid columns={2} gap="16px">
								<DropdownField
									isDisabled={isSubmitting}
									isClearable={false}
									options={MODE_OPTIONS}
									label="Mode"
									identifierKey="value"
									name="mode"
									contentSource={(o) => o.label}
									selectedOption={
										MODE_OPTIONS.find((option) => option.value === mode) ?? null
									}
									onOptionSelected={(o) => setMode(o?.value ?? 'date')}
								/>

								{mode === 'date' && (
									<YearMonthPicker
										disabled={isSubmitting}
										label="End Period"
										name="endPeriod"
										initialValue={{
											year: new Date().getFullYear(),
											month: new Date().getMonth() + 1,
										}}
									/>
								)}
								{mode === 'months' && (
									<IntegerField
										disabled={isSubmitting}
										label="Months"
										name="months"
										min={1}
										max={24}
										maxLength={3}
										defaultValue={12}
										suffix="months"
									/>
								)}
							</Grid>
							<input type="hidden" name="action" value="updateExpiry" />
							{subscriptionIds.map((subscriptionId) => (
								<input
									key={subscriptionId}
									type="hidden"
									name="subscriptionIds"
									value={subscriptionId}
								/>
							))}
							<input type="hidden" name="customerId" value={customerId} />
							<AuditCommentField disabled={isSubmitting} />
						</Flex>
					)}

					{responseData && (
						<Flex direction="column" gap={16}>
							<p>
								Successfully updated the subscription end period for{' '}
								{responseData.renewedSubscriptions.length}{' '}
								{pluralize(
									'subscription',
									responseData.renewedSubscriptions.length
								)}
								.
							</p>

							<SubscriptionRenewalSummary responseData={responseData} />
						</Flex>
					)}
				</ValidatedForm>
			</Modal.Body>
			<Modal.Actions alignment="between">
				{!responseData && (
					<>
						<Button
							variant="secondary"
							onClick={modalProps.onClose}
							isDisabled={isSubmitting}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							isLoading={isSubmitting}
							icon={Save}
							form={formId}
						>
							Renew
						</Button>
					</>
				)}

				{responseData && (
					<Button variant="secondary" onClick={modalProps.onClose}>
						Close
					</Button>
				)}
			</Modal.Actions>
		</Modal>
	);
};

export default UpdateSubscriptionExpiryModal;
