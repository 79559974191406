import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import DateFragment from 'ui/components/DateFragment';
import TrafficLight from 'ui/components/TrafficLight';
import { UserItem } from '../../api/UserAPI';

const columnHelper = createColumnHelper<UserItem>();

export const fullNameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (item) => (
		<Link to={`/users/${item.row.original.id}`}>
			{`${item.row.original.firstName} ${item.row.original.lastName}`}
		</Link>
	),
	enableSorting: true,
});

export const customerNameColumn = columnHelper.accessor('customerName', {
	header: 'Customer',
	cell: (item) => (
		<Link to={`/customers/${item.row.original.customerId}`}>
			{`${item.getValue()} (${item.row.original.customerNumber})`}
		</Link>
	),
});

export const customerTypeColumn = columnHelper.accessor('customerType', {
	header: 'Customer Type',
});

export const emailColumn = columnHelper.accessor('email', {
	header: 'Email',
	enableSorting: true,
});

export const activeFromColumn = columnHelper.accessor('activeFrom', {
	header: 'Access Start',
	cell: (item) => <DateFragment date={item.getValue()} timezone="utc" />,
	enableSorting: true,
});

export const activeToColumn = columnHelper.accessor('activeTo', {
	header: 'Access Expiry',
	cell: (item) => <DateFragment date={item.getValue()} timezone="utc" />,
	enableSorting: true,
});

function SubscriptionCountsColumnDisplay(info: CellContext<UserItem, unknown>) {
	const navigate = useNavigate();
	return (
		<TrafficLight
			onGreenClick={() => navigate(`/users/${info.row.id}?show=active`)}
			onAmberClick={() => navigate(`/users/${info.row.id}?show=expiring`)}
			onRedClick={() => navigate(`/users/${info.row.id}?show=expired`)}
			greenCount={info.row.original.subscriptionCount.active}
			amberCount={info.row.original.subscriptionCount.expiring}
			redCount={info.row.original.subscriptionCount.expired}
		/>
	);
}

export const subscriptionsColumn = columnHelper.accessor('subscriptionCount', {
	header: 'Subscriptions',
	cell: SubscriptionCountsColumnDisplay,
});
