import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import CustomerAPI, { CustomerOption } from '../../api/CustomerAPI';

type CustomerPickerProps = {
	name: string;
	label?: string;
	isMulti?: boolean;
	isRequired?: boolean;
	initialValues?: CustomerOption[];
	onChange?: (customer: CustomerOption | null) => void;
};

const CustomerPicker = ({
	name,
	label,
	isMulti = false,
	initialValues,
	isRequired,
	onChange,
}: CustomerPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);

	const getCustomers = async (searchTerm: string) => {
		const data = await CustomerAPI.lookup({ keyword: searchTerm });
		return data.options;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{isMulti ? (
				<AsyncMultiSelect<CustomerOption>
					defaultOptions={[]}
					onSearch={getCustomers}
					identifierKey="id"
					initialValues={initialValues || []}
					onOptionsSelected={revalidate}
					contentSource={(customer) =>
						`${customer.name} (${customer.customerNumber})`
					}
					pillComponent={({ value, onRemove }) => (
						<Pill name={value.name} onRemove={onRemove} />
					)}
					placeholder="Select..."
					name={name}
				/>
			) : (
				<AsyncSelect<CustomerOption>
					defaultOptions={[]}
					onSearch={getCustomers}
					initialValue={initialValues ? initialValues[0] : null}
					onOptionSelected={(newValue) => {
						if (onChange) {
							onChange(newValue);
						}
						revalidate();
					}}
					identifierKey="id"
					contentSource={(customer) =>
						`${customer.name} (${customer.customerNumber})`
					}
					isClearable={true}
					placeholder="Select..."
					name={name}
				/>
			)}
		</FormField>
	);
};

export default CustomerPicker;
