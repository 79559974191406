import Flex from 'ui/components/Flex';
import { AdminUserItem } from '../../api/AdminUserAPI';

type AdminUserPickerItemProps = {
	user: AdminUserItem;
};

const AdminUserPickerItem = ({ user }: AdminUserPickerItemProps) => {
	return (
		<Flex className="user-picker__item" direction="column" gap={2}>
			<div className="user-picker__info">
				<span className="user-picker__name">{user.name}</span>
				<span className="user-picker__email">&nbsp;({user.email})</span>
			</div>
			<span className="user-picker__role">Role: {user.adminRole.name}</span>
		</Flex>
	);
};

export default AdminUserPickerItem;
