import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useLoaderData,
} from 'react-router-dom';
import Flex from 'ui/components/Flex';
import ImpactReportPanel from 'ui/components/ImpactReportPanel';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { LoaderData } from 'utils/types/loaderData';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const report = await AirImpactReportsAPI.getAirImpactReport(
		params.id as string
	);

	return {
		report: report,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		await AirImpactReportsAPI.deleteAirImpactReport(params.id as string);
		createToast(
			ToastType.SUCCESS,
			'Air Cargo Impact Report deleted successfully'
		);
		return redirect('/air-impact-reports');
	}

	return null;
};

export function AirImpactReportDetails() {
	const { report } = useLoaderData() as LoaderData<typeof loader>;

	return (
		<Flex direction="column" gap={16}>
			<ImpactReportPanel
				impactReport={report}
				showDownloadLink={false}
				showPreviousLink={false}
			/>
		</Flex>
	);
}

export const DETAILS_AIR_IMPACT_REPORTS_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <AirImpactReportDetails />,
	handle: {
		crumb: () => 'Details',
	},
};
