import { ENVIRONMENT_SHARED } from 'environment';
import { SubscriptionLocations } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import API from 'utils/api/API';
import { Country, CustomerTypeOption, DeleteSchema } from 'utils/api/common';
import { CreateUserModel, UpdateUserModel } from 'utils/schemas/userSchema';
import { LabeledValue, Paginated } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import { UpdateUserExpiryModel } from '../routes/customers/UpdateUserExpiryModal';
import { CustomerOption } from './CustomerAPI';
import {
	ProductOption,
	SubscriptionItem,
	SubscriptionStatus,
} from './SubscriptionAPI';

export type UserOption = {
	id: string;
	name: string;
	email: string;
	customerName: string;
};

export type UserLookupResponse = {
	keyword: string;
	options: UserOption[];
};

export type SubscriptionCount = {
	active: number;
	expiring: number;
	expired: number;
};

export type UserItem = {
	id: string;
	customerId: string;
	customerNumber?: string;
	customerName: string;
	customerType: string;
	name?: string;
	firstName: string;
	lastName: string;
	email: string;
	subscriptionCount: SubscriptionCount;
	activeFrom: Date;
	activeTo: Date;
};

type GetUsersRequest = {
	show?: string;
	keyword?: string;
	customerIds?: string[];
	customerTypes?: string[];
	customerStatus?: string[];
	accountManagers?: string[];
	countries?: string[];
	regions?: string[];
	pageSize?: number;
	page?: number;
};

export type GetUserOptionsResponse = {
	customerOptions: CustomerOption[];
};

export type GetUsersResponse = {
	keyword: string | null;
	customerTypes: string[];
	customerTypeOptions: CustomerTypeOption[];
	customerStatus: string[];
	customerStatusOptions: LabeledValue[];
	accountManagers: string[];
	accountManagerOptions: LabeledValue[];
	countries: string[];
	countriesSelected: Country[];
	regions: string[];
	regionOptions: LabeledValue[];
	users: Paginated<UserItem>;
	filterOptions: LabeledValue[];
};

export type GetUserResponse = {
	id: string;
	firstName: string;
	lastName: string;
	userStatus: LabeledValue;
	salesforceStatus: LabeledValue;
	customerId: string;
	customerName: string;
	customerNumber: string;
	email: string;
	activeFrom: Date;
	activeTo: Date;
	version: number;
};

export type CreateUserResponse = {
	userId: string;
};

export type UpdateUserResponse = {
	id: string;
};

export type UpdateUserExpiryResponse = {
	expiryDate: Date;
	totalUpdatedUsers: number;
};

export type GetUserSubscriptionsResponse = {
	subscriptions: Paginated<SubscriptionItem>;
};

export type AvailableSubscriptionItem = {
	id: string;
	subscriptionNumber: string;
	name: string;
	productName: string;
	customer: {
		id: string;
		name: string;
		customerNumber: string;
	};
	product: {
		id: string;
		name: string;
		productType: string;
		forAccountType?: string;
	};
	origin: SubscriptionLocations | null;
	destination: SubscriptionLocations | null;
	origin2: SubscriptionLocations | null;
	destination2: SubscriptionLocations | null;
	origin3: SubscriptionLocations | null;
	destination3: SubscriptionLocations | null;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	status: SubscriptionStatus;
	isSubscribed: boolean;
	userCount: {
		active: number;
		expiring: number;
		expired: number;
	};
};

export type GetAvailableUserSubscriptionsResponse = {
	keyword: string | null;
	subscriptionDeliveryStatusCodes: string[];
	subscriptionDeliveryStatusOptions: LabeledValue[];
	productIds: string[];
	productOptions: ProductOption[];
	manageSubscriptions: true;
	subscriptions: Paginated<AvailableSubscriptionItem>;
};

export type UpdateUserSubscriptionsResponse = {
	addedTo: number;
	removedFrom: number;
};

export type GetUserDownloadResponse = {
	url: string;
	fileName: string;
};

class UserAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static lookup({ keyword }: { keyword: string }) {
		return UserAPI.get<UserLookupResponse>(`/users/lookup?keyword=${keyword}`);
	}

	static getUsers(request: GetUsersRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return UserAPI.get<GetUsersResponse>(`/users?${params}`);
	}

	static getUsersDownloadData(request: URLSearchParams) {
		return UserAPI.get<GetUserDownloadResponse>(`/users/download?${request}`);
	}

	static getUserOptions(customerId?: string | null) {
		return UserAPI.get<GetUserOptionsResponse>(
			`/users/options?customerId=${customerId}`
		);
	}

	static getUser(userId: string) {
		return UserAPI.get<GetUserResponse>(`/users/${userId}`);
	}

	static getUserDownloadData(userId: string) {
		return UserAPI.get<GetUserDownloadResponse>(`/users/${userId}/download`);
	}

	static deleteUser(id: string, data: DeleteSchema) {
		return UserAPI.delete(`/users/${id}`, data);
	}

	static createUser(data: CreateUserModel) {
		return UserAPI.post<CreateUserResponse>('/users', data);
	}

	static updateUser(id: string, data: UpdateUserModel) {
		return UserAPI.put<UpdateUserResponse>(`/users/${id}`, data);
	}

	static updateUserExpiry(data: UpdateUserExpiryModel) {
		return UserAPI.put<UpdateUserExpiryResponse>(`/users/expiry`, data);
	}

	static getSubscriptionsForUser(id: string) {
		return UserAPI.get<GetUserSubscriptionsResponse>(
			`/users/${id}/subscriptions`
		);
	}

	static getAvailableSubscriptionsForUser(
		id: string,
		searchParams?: URLSearchParams
	) {
		const searchParamsString =
			searchParams && searchParams.toString().length > 1
				? `&${searchParams}`
				: '';
		return UserAPI.get<GetAvailableUserSubscriptionsResponse>(
			`/users/${id}/subscriptions?manageSubscriptions=true${searchParamsString}`
		);
	}

	static updateSubscriptionsForUser(
		id: string,
		subscriptionIds: string[],
		auditComment: string
	) {
		return UserAPI.put<UpdateUserSubscriptionsResponse>(
			`/users/${id}/subscriptions`,
			{
				subscriptionIds,
				auditComment,
			}
		);
	}
}

export default UserAPI;
