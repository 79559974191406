import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { DropdownField } from '../../forms/DropdownField';
import z from 'zod';
import { useState } from 'react';
import WebToolAPI from 'utils/api/WebToolAPI';

const periodOptions = [
	{ label: 'Monthly', value: 'monthly' },
	{ label: 'Weekly', value: 'weekly' },
];

export const zPeriod = z.object({
	period: z.string({
		required_error: 'A publication Period option must be selected',
	}),
});

type ExecuteWebToolScheduledModalProps = {
	onSubmit?: () => void;
} & Omit<ModalProps, 'title'>;

const ExecuteWebToolScheduledModal = ({
	onSubmit,
	...modalProps
}: ExecuteWebToolScheduledModalProps) => {
	let [periodValue, setPeriodValue] = useState('');
	const handleSubmit = async () => {
		await WebToolAPI.executeReportNotifications(periodValue);

		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<ConfirmationModal
			fieldName="confirm-distribution"
			title="'On New Data' Schedule"
			submitText="Start 'On New Data' Schedule"
			confirmationText="confirm"
			onSubmit={handleSubmit}
			additionalValidation={zPeriod}
			secondaryChildren={
				<DropdownField
					isRequired
					label="Publication Period"
					contentSource={(option) => option.label}
					options={periodOptions}
					identifierKey="value"
					name="period"
					onOptionSelected={(opt) => {
						if (opt) {
							setPeriodValue(opt.value);
						}
					}}
				></DropdownField>
			}
			{...modalProps}
		>
			<p>Are you sure you wish to start schedule?</p>
		</ConfirmationModal>
	);
};

export default ExecuteWebToolScheduledModal;
