import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { YearAndMonthApi } from 'utils/types/helpers';

export type Metric = {
	executionPeriods: number[];
	metrics: MetricEntry[];
};

export type MetricEntry = {
	label: string;
	decimalDigits: number;
	type: 'percentage' | 'number';
	items: Array<MetricItem>;
};

type MetricItem = {
	label: string;
	data: Array<MetricData>;
};

type MetricData = {
	value: number;
	change: number | null;
};

export type SubmissionMissing = {
	cassArea: string;
	submission: number;
};

export type SubmissionPending = {
	cassArea: string;
	submission: string;
	receivedAt: Date | null;
};

export type PedingCassMonths = {
	submissionMonth: number;
	count: number;
	submissions: SubmissionPending[];
};

export type CassCalendarSummary = {
	billingFilesMissingCount: number;
	billingFilesMissing: SubmissionMissing[];
	correctionFilesMissingCount: number;
	correctionFilesMissing: SubmissionMissing[];
	pendingFileCount: number;
	pendingSubmissions: PedingCassMonths[];
};

export type CddCalendarSummary = {
	missingSubmissionsCount: number;
	missingSubmissions: {
		codes: string[];
	}[];
	pendingConsolidationFileCount: number;
};

type YearMonthInt = number; // Year and Month combined into a single number in the format YYYYMM, e.g. 202301

export type PublishMonth = {
	year: number;
	month: number;
	yearMonth: YearMonthInt;
	description: string;
};

export type PublishWeek = {
	year: number;
	month: number;
	week: number;
	yearMonthWeek: number;
	description: string;
};

export type MonthSummaryResponse = {
	systemOverview: SystemOverview;
	processes: SystemProcess[];
	ingestionQueues: {
		name: string;
		type: string;
		lastIngested: Date | null;
		count: number;
	}[];
	publishMonth: PublishMonth;
	publishWeek: PublishWeek;
	publishOptions: { value: string; label: string }[];
	publishWeekOptions: { value: string; label: string }[];
	monthlyMetrics: Metric;
	weeklyMetrics: Metric;
	cassCalendarSummary: CassCalendarSummary;
	cddCalendarSummary: CddCalendarSummary;
};

type CommonLambdaErrorResponse = {
	errorCode: string;
	message: string;
};

type CommonLambdaSuccessResponse = {
	successCode: string;
	message: string;
};

export type CommonLambdaResponse =
	| CommonLambdaErrorResponse
	| CommonLambdaSuccessResponse;

export type PublishMonthlyDataRequest = {
	startExecutionByMonth: YearMonthInt;
	endExecutionPeriod: YearMonthInt;
	publishAtTimestamp: string;
	copySummaryReports: boolean;
};

export type PublishWeeklyDataRequest = {
	startExecutionByMonth: YearMonthInt;
	endExecutionPeriod: number;
	publishAtTimestamp: string;
};

export type ProcessStatusUpdateRequest = {
	statusCode: string;
};

export type CassHotFileResponse = {
	months: HotfileMonthAreas[];
	yearAndMonth: YearAndMonthApi;
};

export type HotfileMonthAreas = {
	month: number;
	year: number;
	areas: {
		[key: string]: HotFile[];
	};
};

export type HotFile = {
	submissionKey: string;
	type: 'billing' | 'correction';
	exists: boolean;
	fileName: string;
	deliveryDate: Date | null;
};

export type CddResponse = {
	months: CddMonthAreas[];
	yearAndMonths: YearAndMonthApi[];
	yearAndMonth: YearAndMonthApi;
};

export type CddMonthAreas = {
	airlineNaturalKey: string;
	airlineName: string;
	areas: Cdd[];
};

export type Cdd = {
	year: number;
	month: number;
	active: boolean;
	awbs: number;
	compareAwbs: number;
	awbsPrevious: number;
	segments: number;
	compareSegments: number;
	segmentsPrevious: number;
};

// System Process API Models

export type SystemStatusCodes =
	| 'idle'
	| 'cass-consolidation'
	| 'publish-monthly'
	| 'paused'
	| 'system';

export type SystemOverview = {
	code: SystemStatusCodes;
	name: string;
	status: ProcessStatus;
	type: string;
};

export type StatusCodes =
	| 'idle'
	| 'cass-consolidation'
	| 'publish-monthly'
	| 'running'
	| 'failed'
	| 'listening'
	| 'paused';

export type ValidStatus = {
	code: StatusCodes;
	name: string;
	fromCodes: StatusCodes[] | ['*'];
};

export type ProcessStatus = {
	code: StatusCodes;
	name: string;
	updatedAt: Date | null;
	previous: {
		code: StatusCodes;
		name: string;
	} | null;
};

export type ProcessRegulator = {
	type: 'regulator';
	code: string;
	name: string;
	status: ProcessStatus;
	childProcesses: SystemProcess[];
	validStatus: ValidStatus[];
};

export type ProcessLastRun = {
	timestamp: Date;
	jobId: number;
};

export type BlockingProcess = {
	type: 'blocking-process';
	code: string;
	name: string;
	status: ProcessStatus;
	lastRun: ProcessLastRun | null;
	validStatus: ValidStatus[];
};

export type Queue = {
	type: 'queue';
	code: string;
	name: string;
	status: ProcessStatus;
	length: number;
	incompleteJobs: {
		id: number;
		externalFileId: number;
		type: string;
		name: string;
		fileName: string;
		status: string;
		startedAt: Date;
		updatedAt: Date | null;
		awsIdentifier: string;
	}[];
	validStatus: ValidStatus[];
};

export type SystemProcess = ProcessRegulator | BlockingProcess | Queue;

export type SystemProcessesResponse = {
	systemProcess: SystemOverview;
	processes: SystemProcess[];
};

class DataManagementAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static publishSummary(
		yearMonth: YearMonthInt | null,
		yearMonthWeek: number | null
	) {
		if (yearMonth !== null && yearMonthWeek !== null) {
			return DataManagementAPI.get<MonthSummaryResponse>(
				`/data-management/publish-summary?yearMonth=${yearMonth}&yearMonthWeek=${yearMonthWeek}`
			);
		}

		return DataManagementAPI.get<MonthSummaryResponse>(
			`/data-management/publish-summary`
		);
	}

	static startCassConsolidation() {
		return DataManagementAPI.post<CommonLambdaResponse>(
			`/data-management/cass-consolidation`,
			{}
		);
	}

	static startPublishMonthlyData(yearMonth: YearMonthInt) {
		return DataManagementAPI.post<CommonLambdaResponse>(
			`/data-management/publish-monthly-data`,
			{
				startExecutionByMonth: 201301,
				endExecutionPeriod: yearMonth,
				publishAtTimestamp: '',
			} as PublishMonthlyDataRequest
		);
	}

	static startPublishWeeklyData(yearMonthWeek: number) {
		return DataManagementAPI.post<CommonLambdaResponse>(
			`/data-management/publish-weekly-data`,
			{
				startExecutionByMonth: 201301,
				endExecutionPeriod: yearMonthWeek,
				publishAtTimestamp: '',
			} as PublishWeeklyDataRequest
		);
	}

	static updateProcessStatus(processCode: string, statusCode: string) {
		return DataManagementAPI.post<CommonLambdaResponse>(
			`/system-process/${processCode}/status`,
			{
				statusCode: statusCode,
			} as ProcessStatusUpdateRequest
		);
	}

	static cassHotFileMonitor(year: number, month: number) {
		return DataManagementAPI.get<CassHotFileResponse>(
			`/data-management/cass-hotfile-monitor?reportDate.Year=${year}&reportDate.Month=${month}`
		);
	}

	static cddMonitor(year: number | null, month: number | null) {
		if (year === null && month === null) {
			return DataManagementAPI.get<CddResponse>(`/data-management/cdd-monitor`);
		}
		return DataManagementAPI.get<CddResponse>(
			`/data-management/cdd-monitor?reportDate.Year=${year}&reportDate.Month=${month}`
		);
	}

	static systemProcesses() {
		return DataManagementAPI.get<SystemProcessesResponse>(`/system/processes`);
	}
}

export default DataManagementAPI;
