import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextAreaField from 'ui/components/TextAreaField';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { pickUpdateCustomerValidator } from 'utils/schemas/customerSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import AuditCommentField from 'ui/components/AuditCommentField';
import CountryPicker from 'ui/components/CountryPicker';
import { ToastType } from 'ui/components/Toaster/Toast';
import { AccountTypeCode } from 'utils/api/common';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import CustomerAPI from '../../api/CustomerAPI';
import ContactManager from '../../components/ContactManager';
import { DropdownField } from '../../forms/DropdownField';
import { CustomerTypeFields } from './CustomerTypeFields';

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	let searchParams = new URL(request.url).searchParams;
	const customerId = params.customerId as string;
	const show = searchParams.get('show') as string;

	const customerData = await CustomerAPI.getCustomer({
		id: customerId,
		show: show,
	});
	const customerOptions = await CustomerAPI.getCustomerOptions();

	return {
		customer: customerData,
		options: customerOptions,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const customerId = params.customerId as string;
	const formData = await request.formData();
	const updateCustomerValidator = pickUpdateCustomerValidator(
		formData.get('accountType') as AccountTypeCode
	);
	const updateCustomerModel = updateCustomerValidator.parse(
		formDataAsObject(formData)
	);

	const data = await CustomerAPI.updateCustomer(
		customerId,
		updateCustomerModel
	);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'Customer updated successfully');
	return redirect(`/customers/${customerId}`);
};

export function CustomerEdit() {
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	const updateCustomerValidator = pickUpdateCustomerValidator(
		data.customer.customerType.accountType
	);

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateCustomerValidator}>
				<Grid>
					<PageHeader title={`Edit ${data.customer.name}`}>
						<Button
							variant="secondary"
							to={`/customers/${data.customer.id}`}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Name"
									name="name"
									defaultValue={data.customer?.name}
									isRequired
								/>

								<DropdownField
									label="Customer Status"
									name="customerStatusId"
									isRequired
									placeholder="Please select..."
									options={data.options.customerStatusOptions}
									identifierKey="value"
									contentSource={(data) => data.label}
									isClearable={false}
									initialValue={data.customer.customerStatus}
								/>

								<DropdownField
									label="Account Manager"
									name="accountManagerId"
									placeholder="Please select..."
									options={data.options.accountManagerOptions}
									identifierKey="value"
									contentSource={(data) => data.label}
									typeaheadSource={(data) => data.label}
									isClearable={true}
									initialValue={data.customer.accountManager}
								/>

								<CountryPicker
									name="countryCode"
									label="Country"
									initialValue={data.customer.country}
								/>
							</Grid>
							<Grid>
								<TextAreaField
									label="Notes"
									placeholder="Notes..."
									name="notes"
									defaultValue={data.customer.notes}
								/>

								<CustomerTypeFields
									customerType={data.customer.customerType}
									initialAirlines={data.customer.airlines}
									initialFreightForwarders={data.customer.freightForwarders}
								/>

								<ContactManager
									name="contacts"
									defaultValue={data.customer.contacts}
								/>

								<AuditCommentField />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_CUSTOMER_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <CustomerEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return {
				label: `${data.customer.name} (${data.customer.customerNumber})`,
				path: `/customers/${data.customer.id}`,
			};
		},
	},
};
