import { ENVIRONMENT_ADMIN } from 'environment';
import API from 'utils/api/API';
import { Option, Paginated } from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import { CustomerOption } from './CustomerAPI';

export type ReportNotificationsItem = {
	id: string;
	status: string;
	createdAt: Date;
	startedAt: Date | null;
	completedAt: Date | null;
	reportDate: YearAndMonth | null;
	releaseDate: Date | null;
	subscriptionId: string;
	subscriptionNumber: string;
	reportId: string;
	report: string;
};

export type StatusOption = {
	id: string;
	name: string;
};

export type ReportNotificationsRecipientItem = {
	id: string;
	status: string;
	createdAt: Date;
	userId: string;
	user: string;
	failureMessage: string;
};

type GetReportNotificationsRequest = {
	status?: string[];
	customerIds?: string[];
	createdBeforeDate?: Date;
	createdAfterDate?: Date;
	completedBeforeDate?: Date;
	completedAfterDate?: Date;
	pageSize?: number;
	page?: number;
};

export type ReportNotificationsDetail = {
	id: string;
	status: string;
	createdAt: Date;
	startedAt: Date;
	completedAt: Date;
	reportDate: YearAndMonth;
	releaseDate: Date;
	subscriptionId: string;
	subscriptionNumber: string;
	reportId: string;
	report: string;
	recipients: ReportNotificationsRecipientItem[];
};

export type GetReportNotificationsResponse = {
	customersSelected: CustomerOption[];
	status: string[];
	createdBeforeDate: Option<Date>;
	createdAfterDate: Option<Date>;
	completedBeforeDate: Option<Date>;
	completedAfterDate: Option<Date>;
	notifications: Paginated<ReportNotificationsItem>;
	statusOptions: StatusOption[];
};

export type ExecuteReportsSchedule = {
	arn: string;
	success: boolean;
};

class ReportNotificationsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_ADMIN.staticReportEndpoint;

	static getReportNotifications(
		request: GetReportNotificationsRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return ReportNotificationsAPI.get<GetReportNotificationsResponse>(
			`/report-notifications/v1?${params}`
		);
	}

	static getReportNotification(id: string) {
		return ReportNotificationsAPI.get<ReportNotificationsDetail>(
			`/report-notifications/${id}/v1?`
		);
	}

	static executeReportNotifications() {
		return ReportNotificationsAPI.post<ExecuteReportsSchedule>(
			'/report-notifications/execute/v1'
		);
	}
}
export default ReportNotificationsAPI;
