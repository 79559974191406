import { useMemo } from 'react';
import AirlinePicker from 'ui/components/AirlinePicker';
import CheckboxField from 'ui/components/CheckboxField';
import FreightForwarderPicker from 'ui/components/FreightForwarderPicker';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import TextField from 'ui/components/TextField';
import { FreightForwarderType } from 'utils/api/FreightForwarderAPI';
import { LocationType } from 'utils/api/LocationAPI';
import {
	HistoricalDataSettings,
	ProductTypeCode,
} from 'utils/schemas/subscriptionSchema';
import {
	AggregationCode,
	CurrencyCode,
	LabeledValue,
	Separator,
	TimePeriodInterval,
} from 'utils/types/common';
import {
	AllProductDetails,
	GetSubscriptionOptionsResponse,
	ProductOption,
} from '../../api/SubscriptionAPI';
import { DropdownField } from '../../forms/DropdownField';

const defaultMinimumChargeableCompetitiveWeight = 260000;

type ProductEditorSettings = {
	airlines?: {
		isMulti: boolean;
	};
	freightForwarders?: {
		isMulti: boolean;
		types: FreightForwarderType[];
		isRequired: boolean;
	};
	origin?: {
		isMulti: boolean;
		isRequired: boolean;
		locationTypes: LocationType[];
	};
	destination?: {
		isMulti: boolean;
		isRequired: boolean;
		locationTypes: LocationType[];
	};
	currency?: {
		isRequired: boolean;
		exclude?: CurrencyCode[];
	};
	aggregation?: {
		isRequired: boolean;
		exclude?: AggregationCode[];
	};
	timePeriodInterval?: {
		isRequired: boolean;
	};
	weightBreaks?: true;
	weightBreaksCdd?: true;
	iiNetAccountId?: true;
	includeOtherCharges?: true;
	yearsOfHistoricalData?: HistoricalDataSettings;
	groupConsortium?: true;
	isSpecialBipReport?: true;
	lengthOfRanking?: true;
	minimumChargeableCompetitiveWeight?: true;
	scopeType?: true;
	webToolDataSource?: true;
	webToolDataSubscription?: true;
	webToolHiddenFields?: true;
	webToolDataGroups?: 'airline' | 'freight-forwarder' | 'third-party' | 'demo';
	marketBreakdown?: true;
	submissionStatus?: true;
	dataFileSeparator?: true;
	referenceFileSeparator?: true;
	gsa?: true;
	contributorCode?: true;
	contributorName?: true;
};

// BIP baseline
const allBipEditorSettings: ProductEditorSettings = {
	origin: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	destination: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	weightBreaks: true,
	iiNetAccountId: true,
	includeOtherCharges: true,
	dataFileSeparator: true,
	referenceFileSeparator: true,
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// BIP CDD baseline
const allBipCddEditorSettings: ProductEditorSettings = {
	origin: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	destination: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	weightBreaks: true,
	weightBreaksCdd: true,
	iiNetAccountId: true,
	dataFileSeparator: true,
	referenceFileSeparator: true,
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Capacity BIP baseline
const allCapacityBipEditorSettings: ProductEditorSettings = {
	origin: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	destination: {
		isRequired: false,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	iiNetAccountId: true,
	dataFileSeparator: true,
	referenceFileSeparator: true,
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Market Focus Baseline
const allMfSettings: ProductEditorSettings = {
	origin: {
		isRequired: true,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Market Focus CDD Baseline
const allMfCddSettings: ProductEditorSettings = {
	origin: {
		isRequired: true,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Airport Financial Data Report Baseline
const afdSettings: ProductEditorSettings = {
	origin: {
		isRequired: true,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	currency: { isRequired: true, exclude: ['EUR', 'USD'] },
	yearsOfHistoricalData: {
		type: 'not-available',
		label: 'Historical data is not available for this product',
		numYearsAvailable: 0,
	},
};

// Top Baseline
const allTopSettings: ProductEditorSettings = {
	origin: {
		isRequired: true,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Ranking Baseline
const allRankingSettings: ProductEditorSettings = {
	origin: {
		isRequired: true,
		isMulti: true,
		locationTypes: [LocationType.COUNTRY],
	},
};

// Standard Report Baseline
const standardReportLocationTypes = [
	LocationType.COUNTRY,
	LocationType.CITY,
	LocationType.PROVINCE,
	LocationType.STATION,
];

const allStandardReportSettings: ProductEditorSettings = {
	origin: {
		isMulti: false,
		isRequired: true,
		locationTypes: standardReportLocationTypes,
	},
	destination: {
		isMulti: false,
		isRequired: false,
		locationTypes: standardReportLocationTypes,
	},
	currency: { isRequired: true, exclude: ['EUR', 'USD'] },
	yearsOfHistoricalData: {
		type: 'checkbox',
		label: 'Include one years worth of historical data?',
		numYearsAvailable: 1,
	},
};

// Market Share Baseline
const marketShareLocationTypes = [LocationType.CITY, LocationType.COUNTRY];

const allMarketShareSettings: ProductEditorSettings = {
	origin: {
		isMulti: true,
		isRequired: false,
		locationTypes: marketShareLocationTypes,
	},
	destination: {
		isMulti: true,
		isRequired: false,
		locationTypes: marketShareLocationTypes,
	},
	lengthOfRanking: true,
	minimumChargeableCompetitiveWeight: true,
	scopeType: true,
};

// Cdd Contributor Baseline
const allCddContributorSettings: ProductEditorSettings = {
	submissionStatus: true,
};

// Adding Subscriptions: Product schema you must add it in two places
// 1. Add it to this record so we can inspect the schema to konw whether fields are relevant
export const productConfigEditorSettings: Record<
	ProductTypeCode,
	ProductEditorSettings
> = {
	'bip-airline': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-airline-weekly': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-airline-weekly-plus': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-airline-cdd': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-airline-cdd-weekly': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-airline-cdd-weekly-plus': {
		airlines: {
			isMulti: true,
		},
		groupConsortium: true,
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-freight-forwarder': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true },
		isSpecialBipReport: true,
		...allBipEditorSettings,
	},
	'bip-freight-forwarder-weekly': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-freight-forwarder-weekly-plus': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-freight-forwarder-cdd': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-freight-forwarder-cdd-weekly': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-freight-forwarder-cdd-weekly-plus': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipCddEditorSettings,
	},
	'bip-third-party-revenue': {
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-third-party-revenue-weekly': {
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-third-party-weight': {
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'bip-third-party-weight-weekly': {
		currency: { isRequired: true, exclude: ['EUR', 'USD'] },
		...allBipEditorSettings,
	},
	'capacity-bip': {
		...allCapacityBipEditorSettings,
	},
	'market-focus-airline': {
		airlines: {
			isMulti: false,
		},
		...allMfSettings,
	},
	'market-focus-airline-cdd': {
		airlines: {
			isMulti: false,
		},
		...allMfCddSettings,
	},
	'market-focus-freight-forwarder': {
		freightForwarders: {
			types: ['head-office'],
			isMulti: true,
			isRequired: true,
		},
		...allMfSettings,
	},
	'market-focus-freight-forwarder-cdd': {
		freightForwarders: {
			types: ['head-office'],
			isMulti: true,
			isRequired: true,
		},
		...allMfCddSettings,
	},
	'market-focus-third-party': { ...allMfSettings },
	'market-focus-third-party-cdd': { ...allMfCddSettings },
	'capacity-market-focus': { ...allMfSettings },
	'airport-financial-data-third-party': { ...afdSettings },
	'top-airline': {
		airlines: { isMulti: false },
		...allTopSettings,
	},
	'top-freight-forwarder': {
		freightForwarders: {
			types: ['head-office', 'group'],
			isMulti: true,
			isRequired: true,
		},
		...allTopSettings,
	},
	'ranking-airline': {
		yearsOfHistoricalData: {
			type: 'dropdown',
			label: 'Include Historical Data?',
			numYearsAvailable: 3,
			defaultNumYears: 3,
		},
		...allRankingSettings,
	},
	'ranking-freight-forwarder': {
		timePeriodInterval: { isRequired: true },
		...allRankingSettings,
	},
	'standard-airline': {
		airlines: {
			isMulti: false,
		},
		aggregation: {
			isRequired: true,
			exclude: [
				'all-freight-forwarders',
				'all-destination-airports',
				'all-destination-cities',
				'all-destination-countries',
				'all-weightbreaks',
			],
		},
		...allStandardReportSettings,
	},
	'standard-freight-forwarder': {
		freightForwarders: {
			types: ['head-office'],
			isMulti: false,
			isRequired: true,
		},
		aggregation: {
			isRequired: true,
			exclude: [
				'all-airlines',
				'all-destination-airports',
				'all-destination-cities',
				'all-destination-countries',
				'all-weightbreaks',
			],
		},
		...allStandardReportSettings,
	},
	'standard-third-party': {
		aggregation: {
			isRequired: true,
			exclude: [
				'all-destination-airports',
				'all-destination-cities',
				'all-destination-countries',
				'all-weightbreaks',
			],
		},
		...allStandardReportSettings,
	},
	'market-share-airline': {
		airlines: {
			isMulti: false,
		},
		...allMarketShareSettings,
	},
	'market-share-freight-forwarder': {
		freightForwarders: {
			isMulti: true,
			types: ['head-office'],
			isRequired: false,
		},
		...allMarketShareSettings,
	},
	'web-tool-airline': {
		webToolDataSource: true,
		webToolDataSubscription: true,
		webToolDataGroups: 'airline',
		webToolHiddenFields: true,
		marketBreakdown: true,
	},
	'web-tool-freight-forwarder': {
		webToolDataSource: true,
		webToolDataSubscription: true,
		webToolDataGroups: 'freight-forwarder',
		webToolHiddenFields: true,
		marketBreakdown: true,
	},
	'web-tool-third-party-operational': {
		webToolDataSource: true,
		webToolDataSubscription: true,
		webToolDataGroups: 'third-party',
		webToolHiddenFields: true,
	},
	'web-tool-third-party-revenue': {
		webToolDataSource: true,
		webToolDataSubscription: true,
		webToolDataGroups: 'third-party',
		webToolHiddenFields: true,
	},
	'web-tool-iata': {
		webToolDataSource: true,
		webToolDataSubscription: true,
		webToolDataGroups: 'demo',
		marketBreakdown: true,
	},
	'cdd-contributor-airline': {
		airlines: {
			isMulti: true,
		},
		gsa: true,
		...allCddContributorSettings,
	},
	'ndd-contributor': {
		contributorCode: true,
		contributorName: true,
		submissionStatus: true,
		webToolDataGroups: 'third-party',
	},
};

export const productTypesWithSettings = Object.keys(
	productConfigEditorSettings
) as ProductTypeCode[];

export function doesProductHave(
	productType: ProductTypeCode | undefined,
	property: keyof ProductEditorSettings
): boolean | undefined {
	if (!productType) return undefined;

	return property in productConfigEditorSettings[productType];
}

export function doesProductHaveFactory(
	productType: ProductTypeCode | undefined
): (property: keyof ProductEditorSettings) => boolean | undefined {
	if (!productType) return () => undefined;

	return (property) => doesProductHave(productType, property);
}

function TimePeriodIntervalsDropdown({
	initialValue,
	options,
}: {
	options: LabeledValue<TimePeriodInterval>[];
	initialValue?: LabeledValue<TimePeriodInterval>;
}) {
	return (
		<DropdownField
			label="Time Period Interval"
			name="productConfiguration.timePeriodInterval"
			isRequired
			options={options}
			identifierKey="value"
			contentSource={(data) => data.label}
			initialValue={initialValue}
			placeholder="Select"
		/>
	);
}

function SeparatorsDropdown({
	label,
	name,
	initialValue = { label: 'Semicolon', value: 'semicolon' },
	options,
}: Readonly<{
	label: string;
	name: string;
	options: LabeledValue<Separator>[];
	initialValue?: LabeledValue<Separator>;
}>) {
	return (
		<DropdownField
			label={label}
			name={name}
			isRequired
			options={options}
			identifierKey="value"
			contentSource={(data) => data.label}
			initialValue={initialValue}
			placeholder="Select"
		/>
	);
}

function YearsOfHistoricDataDropdown({
	label,
	initialValue,
	numYearsToDisplay,
}: {
	label: string;
	initialValue?: number;
	numYearsToDisplay: number;
}) {
	const options: LabeledValue[] = useMemo(() => {
		const options = [] as LabeledValue[];

		options.push({ value: '0', label: 'No Historical Data' });

		for (let year = 1; year <= numYearsToDisplay; year++) {
			const yearLabel = year === 1 ? 'Year' : 'Years';
			options.push({
				value: year.toString(),
				label: `${year.toString()} ${yearLabel}`,
			});
		}

		return options;
	}, [numYearsToDisplay]);

	return (
		<DropdownField
			label={label}
			name="productConfiguration.yearsOfHistoricalData"
			isRequired
			options={options}
			identifierKey="value"
			contentSource={(data) => data.label}
			initialValue={options.find(
				(option) => option.value === initialValue?.toString()
			)}
			placeholder="Select"
		/>
	);
}

const ProductConfigurationEditor = ({
	product,
	productConfig,
	options,
}: {
	product: ProductOption | null;
	productConfig?: Partial<AllProductDetails> & { type: ProductTypeCode };
	options: GetSubscriptionOptionsResponse;
}) => {
	const productType = product?.productType;

	const editorSettings = productType
		? productConfigEditorSettings[productType]
		: null;

	return (
		<>
			<input
				name="productConfiguration.type"
				value={productType}
				type="hidden"
			/>

			{editorSettings?.airlines &&
				(editorSettings?.airlines?.isMulti ? (
					<AirlinePicker
						name="productConfiguration.airlines"
						label={'Airlines'}
						isRequired
						initialValues={productConfig?.airlines}
						isMulti
					/>
				) : (
					<AirlinePicker
						name="productConfiguration.airlines"
						label={'Airline'}
						isRequired
						initialValue={productConfig?.airlines?.[0]}
					/>
				))}

			{editorSettings?.freightForwarders &&
				(editorSettings?.freightForwarders?.isMulti ? (
					<FreightForwarderPicker
						name="productConfiguration.freightForwarders"
						label={'Freight Forwarders'}
						isRequired={editorSettings?.freightForwarders?.isRequired}
						freightForwarderTypes={editorSettings?.freightForwarders?.types}
						isMulti
						initialValues={productConfig?.freightForwarders}
					/>
				) : (
					<FreightForwarderPicker
						name="productConfiguration.freightForwarders"
						label={'Freight Forwarder'}
						isRequired={editorSettings?.freightForwarders?.isRequired}
						freightForwarderTypes={editorSettings?.freightForwarders?.types}
						initialValue={productConfig?.freightForwarders?.[0]}
					/>
				))}

			{editorSettings?.origin &&
				(editorSettings?.origin?.isMulti ? (
					<LocationPicker
						name="productConfiguration.origin"
						label={'Origins'}
						isRequired={editorSettings?.origin?.isRequired}
						isMulti
						initialValues={productConfig?.origin}
						locationTypes={editorSettings?.origin.locationTypes ?? []}
					/>
				) : (
					<LocationPicker
						name="productConfiguration.origin"
						label={'Origin'}
						isRequired={editorSettings?.origin?.isRequired}
						initialValue={productConfig?.origin?.[0]}
						locationTypes={editorSettings?.origin.locationTypes ?? []}
					/>
				))}

			{editorSettings?.destination &&
				(editorSettings?.destination?.isMulti ? (
					<LocationPicker
						name="productConfiguration.destination"
						label={'Destinations'}
						isRequired={editorSettings?.destination?.isRequired}
						isMulti
						initialValues={productConfig?.destination}
						locationTypes={editorSettings?.destination.locationTypes ?? []}
					/>
				) : (
					<LocationPicker
						name="productConfiguration.destination"
						label={'Destination'}
						isRequired={editorSettings?.destination?.isRequired}
						initialValue={productConfig?.destination?.[0]}
						locationTypes={editorSettings?.destination.locationTypes ?? []}
					/>
				))}

			{productType?.startsWith('market-share-') && (
				<>
					<LocationPicker
						name="productConfiguration.origin2"
						label="Origin 2"
						isMulti
						initialValues={productConfig?.origin2 ?? []}
						locationTypes={[LocationType.CITY, LocationType.COUNTRY]}
					/>
					<LocationPicker
						name="productConfiguration.destination2"
						label="Destination 2"
						isMulti
						initialValues={productConfig?.destination2 ?? []}
						locationTypes={[LocationType.CITY, LocationType.COUNTRY]}
					/>

					<LocationPicker
						name="productConfiguration.origin3"
						label="Origin 3"
						isMulti
						initialValues={productConfig?.origin3 ?? []}
						locationTypes={[LocationType.CITY, LocationType.COUNTRY]}
					/>
					<LocationPicker
						name="productConfiguration.destination3"
						label="Destination 3"
						isMulti
						initialValues={productConfig?.destination3 ?? []}
						locationTypes={[LocationType.CITY, LocationType.COUNTRY]}
					/>
				</>
			)}

			{editorSettings?.groupConsortium && (
				<CheckboxField
					label="Group Consortium?"
					name="productConfiguration.groupConsortium"
					value="true"
					defaultChecked={productConfig?.groupConsortium}
				/>
			)}

			{editorSettings?.includeOtherCharges && (
				<CheckboxField
					label="Include customer and market other charges?"
					name="productConfiguration.includeOtherCharges"
					value="true"
					defaultChecked={productConfig?.includeOtherCharges}
				/>
			)}

			{editorSettings?.lengthOfRanking && (
				<TextField
					label="Length of ranking"
					name="productConfiguration.lengthOfRanking"
					defaultValue={productConfig?.lengthOfRanking}
					isRequired
				/>
			)}

			{editorSettings?.minimumChargeableCompetitiveWeight && (
				<TextField
					maxLength={20}
					label="Minimum chargeable competitive weight (kg/year)"
					name="productConfiguration.minimumChargeableCompetitiveWeight"
					defaultValue={
						productConfig?.minimumChargeableCompetitiveWeight ??
						defaultMinimumChargeableCompetitiveWeight
					}
					isRequired
				/>
			)}

			{editorSettings?.scopeType && (
				<DropdownField
					label="Scope type"
					name="productConfiguration.scopeType"
					isRequired
					identifierKey="value"
					contentSource={(data) => data.label}
					placeholder="Select"
					options={options.marketShareScopeTypeOptions}
					initialValue={productConfig?.scopeType}
				/>
			)}

			{editorSettings?.gsa && (
				<>
					<TextField
						maxLength={3}
						label="GSA Code"
						name="productConfiguration.gsaCode"
						defaultValue={productConfig?.gsa?.code}
					/>

					<TextField
						label="GSA Name"
						name="productConfiguration.gsaName"
						defaultValue={productConfig?.gsa?.name}
					/>
				</>
			)}

			{editorSettings?.submissionStatus && (
				<DropdownField
					label="Submission Status"
					name="productConfiguration.submissionStatus"
					isRequired
					identifierKey="value"
					contentSource={(data) => data.label}
					placeholder="Select"
					options={options.submissionStatusOptions}
					initialValue={productConfig?.submissionStatus}
				/>
			)}

			{editorSettings?.yearsOfHistoricalData?.type === 'checkbox' && (
				<div className="">
					<CheckboxField
						label={editorSettings?.yearsOfHistoricalData?.label}
						name="productConfiguration.yearsOfHistoricalData"
						value={1}
						defaultChecked={
							productConfig ? productConfig.yearsOfHistoricalData === 1 : true
						}
					/>
				</div>
			)}

			{editorSettings?.yearsOfHistoricalData?.type === 'dropdown' && (
				<div className="">
					<YearsOfHistoricDataDropdown
						label={
							editorSettings?.yearsOfHistoricalData?.label ??
							'Include Historical Data?'
						}
						numYearsToDisplay={
							editorSettings?.yearsOfHistoricalData?.numYearsAvailable ?? 0
						}
						initialValue={
							productConfig?.yearsOfHistoricalData ??
							editorSettings.yearsOfHistoricalData.defaultNumYears
						}
					/>
				</div>
			)}

			{editorSettings?.currency && (
				<DropdownField
					label="Currency"
					name="productConfiguration.currency"
					isRequired
					identifierKey="value"
					contentSource={(data) => data.label}
					options={options.currencyOptions.filter(
						(currency) =>
							!editorSettings?.currency?.exclude ||
							editorSettings.currency.exclude.indexOf(currency.value) > -1
					)}
					initialValue={productConfig?.currency}
					placeholder="Select"
				/>
			)}

			{editorSettings?.timePeriodInterval && (
				<div className="">
					<TimePeriodIntervalsDropdown
						options={options.timePeriodIntervalOptions}
						initialValue={productConfig?.timePeriodInterval}
					/>
				</div>
			)}

			{editorSettings?.aggregation && (
				<DropdownField
					label="Aggregate By"
					name="productConfiguration.aggregation"
					isRequired
					identifierKey="value"
					contentSource={(data) => data.label}
					placeholder="Select"
					options={options.aggregationOptions.filter(
						(aggregation) =>
							!editorSettings?.aggregation?.exclude ||
							editorSettings.aggregation.exclude.indexOf(aggregation.value) > -1
					)}
					initialValue={productConfig?.aggregation}
				/>
			)}

			{editorSettings?.dataFileSeparator && (
				<SeparatorsDropdown
					label="Data File Separator"
					name="productConfiguration.dataFileSeparator"
					options={options.separatorOptions}
					initialValue={productConfig?.dataFileSeparator}
				/>
			)}

			{editorSettings?.referenceFileSeparator && (
				<SeparatorsDropdown
					label="Reference File Separator"
					name="productConfiguration.referenceFileSeparator"
					options={options.separatorOptions}
					initialValue={productConfig?.referenceFileSeparator}
				/>
			)}

			{editorSettings?.iiNetAccountId && (
				<TextField
					label="iiNET Account ID"
					name="productConfiguration.iiNetAccountId"
					defaultValue={productConfig?.iiNetAccountId}
					isRequired
				/>
			)}

			{editorSettings?.isSpecialBipReport && (
				<CheckboxField
					label="Is this a special format BIP report?"
					name="productConfiguration.isSpecialBipReport"
					value="true"
					description="(The special report format generates a .txt file instead of a .csv
						file. The CASS country is used as a basis of aggregation instead of
						Origin Country.)"
					defaultChecked={productConfig?.isSpecialBipReport}
				/>
			)}

			{editorSettings?.yearsOfHistoricalData?.type === 'not-available' && (
				<p>{editorSettings?.yearsOfHistoricalData?.label}</p>
			)}
		</>
	);
};

export default ProductConfigurationEditor;
