import DateFragment from 'ui/components/DateFragment';
import { RenewSubscriptionsResponse } from '../../api/SubscriptionAPI';
import Accordion from '../../components/Accordion';
import { YearMonthFragment } from '../../components/YearMonth/YearMonthFragment';

interface SubscriptionRenewalSummaryProps {
	responseData: RenewSubscriptionsResponse;
}

const SubscriptionRenewalSummary = ({
	responseData,
}: SubscriptionRenewalSummaryProps) => {
	return (
		<div className="customer-subscriptions__renewal-summary">
			<Accordion type="multiple" defaultValue={['users']}>
				{responseData.renewedSubscriptions.map((subscription) => (
					<Accordion.Item
						key={subscription.id}
						value={subscription.id}
						disabled={!subscription.webToolGroups?.length}
					>
						<Accordion.Trigger
							hint={
								<>
									<YearMonthFragment
										yearMonth={subscription.oldEndPeriod.yearMonth!}
									/>{' '}
									→{' '}
									<YearMonthFragment
										yearMonth={subscription.newEndPeriod.yearMonth!}
									/>
								</>
							}
						>
							{subscription.name}
						</Accordion.Trigger>
						<Accordion.Content>
							<div>
								{subscription.webToolGroups?.length && (
									<>
										<b>Geographical Groups:</b>
										<ul>
											{subscription.webToolGroups.map((group, groupIndex) => (
												<li key={groupIndex}>
													Geographical Group {groupIndex + 1}:{' '}
													<YearMonthFragment
														yearMonth={group.oldEndPeriod.yearMonth!}
													/>{' '}
													→{' '}
													<YearMonthFragment
														yearMonth={group.newEndPeriod.yearMonth!}
													/>
												</li>
											))}
										</ul>
									</>
								)}
							</div>
						</Accordion.Content>
					</Accordion.Item>
				))}

				{responseData.extendedUsers.length > 0 && (
					<Accordion.Item value="users">
						<Accordion.Trigger>
							Extended Users ({responseData.extendedUsers.length})
						</Accordion.Trigger>
						<Accordion.Content>
							<ul>
								{responseData.extendedUsers.map((user, userIndex) => (
									<li key={userIndex}>
										User {user.name}:{' '}
										<DateFragment timezone="utc" date={user.oldActiveTo} /> →{' '}
										<DateFragment timezone="utc" date={user.newActiveTo} />
									</li>
								))}
							</ul>
						</Accordion.Content>
					</Accordion.Item>
				)}
			</Accordion>
		</div>
	);
};

export default SubscriptionRenewalSummary;
