import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue, Paginated } from 'utils/types/common';
import { AdminRoleOption } from 'utils/api/common';
import { CustomerOption } from './CustomerAPI';
import { SubscriptionOption } from './SubscriptionAPI';
import { UserOption } from './UserAPI';
import { AdminUserItem } from './AdminUserAPI';

export type AdminAuditEventActionOption = {
	name: string;
	value: 'added' | 'updated' | 'deleted';
};

export type AdminAuditEventEntityOption = {
	name: string;
	value: 'customer' | 'subscription' | 'user';
};

export type AdminAuditEventListItem = {
	id: string;
	createdAt: Date;
	customerId: string;
	adminUser: AdminUserItem;
	role: AdminRoleOption;
	entity: AdminAuditEventEntityOption;
	entityName: string;
	action: AdminAuditEventActionOption;
	adminComment: string;
	eventDetail: AdminAuditEventDataDetails;
};

export type AdminAuditEventDataDetails = {
	subscriptionId?: string;
	userId?: string;
	details: AdminAuditEventDetailItem[];
};

export type AdminAuditEventDetailItem = {
	label?: string;
	description?: string;
};

export type GetAdminAuditEventsResponse = {
	dateRangeSelected: LabeledValue;
	entitiesSelected: LabeledValue[];
	actionsSelected: LabeledValue[];
	rolesSelected: LabeledValue[];
	adminUsersSelected: AdminUserItem[];
	customersSelected: CustomerOption[];
	subscriptionsSelected: SubscriptionOption[];
	usersSelected: UserOption[];
	dateRangeOptions: LabeledValue[];
	entityOptions: LabeledValue[];
	actionOptions: LabeledValue[];
	roleOptions: LabeledValue[];
	auditEvents: Paginated<AdminAuditEventListItem>;
};

class AdminAuditLogApi extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAdminAuditEvents(request: URLSearchParams) {
		return AdminAuditLogApi.get<GetAdminAuditEventsResponse>(
			`/admin-audit-events?${request}`
		);
	}
}

export default AdminAuditLogApi;
