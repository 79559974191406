/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';
import { zAuditable } from '../zod/zodValidation';

export const userSchema = z.object({
	id: z.string(),
	customerId: z
		.string({ required_error: 'Customer is required' })
		.min(1, 'Customer is required'),
	firstName: z
		.string({ required_error: 'First Name is required' })
		.min(2, { message: 'First Name must be at least 2 characters' })
		.max(100, { message: 'First Name must not be more than 100 characters' }),
	lastName: z
		.string({ required_error: 'Last Name is required' })
		.min(2, { message: 'Last Name must be at least 2 characters' })
		.max(100, { message: 'Last Name must not be more than 100 characters' }),
	email: z
		.string({ required_error: 'Email is required' })
		.max(200, { message: 'Email must not be more than 200 characters' })
		.email(),
	activeFrom: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date({ required_error: 'Active From is required' })),
	activeTo: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date({ required_error: 'Active To is required' })),
	version: z.number(), // Provided by the backend
});

export type UserModel = z.infer<typeof userSchema>;

// CREATE USER SCHEMA

export const createUserSchema = userSchema
	.pick({
		customerId: true,
		firstName: true,
		lastName: true,
		email: true,
		activeFrom: true,
		activeTo: true,
	})
	.merge(zAuditable)
	// TODO: Work out how to share this across the two crud schemas
	.superRefine((data, ctx) => {
		if (data.activeFrom > data.activeTo) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['activeFrom'],
				message: 'Active From must be before the Active To',
				fatal: true,
			});
		}
	});

export type CreateUserModel = z.infer<typeof createUserSchema>;

// UPDATE USER SCHEMA

export const updateUserSchema = userSchema
	.pick({
		firstName: true,
		lastName: true,
		activeFrom: true,
		activeTo: true,
	})
	.merge(zAuditable)
	// TODO: Work out how to share this across the two crud schemas
	.superRefine((data, ctx) => {
		if (data.activeFrom > data.activeTo) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['activeFrom'],
				message: 'Active From must be before the Active To',
				fatal: true,
			});
		}
	});

export type UpdateUserModel = z.infer<typeof updateUserSchema>;
