import { LoaderFunctionArgs, RouteProps, redirect } from 'react-router-dom';
import { clearMsalCache } from 'utils/helpers/msal';
import { msalInstance } from '../../auth/authConfig';

export const loader = async ({}: LoaderFunctionArgs) => {
	clearMsalCache(msalInstance);

	return redirect('/');
};

export default function LoggedOutPage() {
	return null;
}

export const LOGGED_OUT_ROUTE: RouteProps = {
	element: <LoggedOutPage />,
	loader,
};
