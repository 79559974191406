/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import { zBoolPreprocessor } from 'utils/zod/zodValidation';
import z from 'zod';

export const webAnnouncementSchema = z.object({
	id: z.string(),
	title: z
		.string({ required_error: 'Name is required' })
		.min(2, { message: 'Name must be at least 2 characters' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	body: z
		.string({ required_error: 'Body is required' })
		.min(2, { message: 'Body must be at least 2 characters' }),
	releaseDate: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date({ required_error: 'Active Date is required' })),
	expiryDate: z.preprocess((arg) => {
		if (typeof arg === 'string' || arg instanceof Date) return new Date(arg);
		else return arg;
	}, z.date({ required_error: 'Expiry Date is required' })),
	products: z.array(z.string()).optional(),
	customerTypes: z.array(z.string()).optional(),
	isArchived: z.preprocess(zBoolPreprocessor, z.boolean()).optional(),
});

export type WebAnnouncementModel = z.infer<typeof webAnnouncementSchema>;

// CREATE ANNOUNCEMENT

export const createWebAnnouncementSchema = webAnnouncementSchema.pick({
	title: true,
	body: true,
	releaseDate: true,
	expiryDate: true,
	products: true,
	customerTypes: true,
	isArchived: true,
});

export type CreateWebAnnouncementModel = z.infer<
	typeof createWebAnnouncementSchema
>;

// UPDATE USER SCHEMA

export const updateWebAnnouncementSchema = webAnnouncementSchema.pick({
	title: true,
	body: true,
	releaseDate: true,
	expiryDate: true,
	products: true,
	customerTypes: true,
	isArchived: true,
});

export type UpdateWebAnnouncementModel = z.infer<
	typeof updateWebAnnouncementSchema
>;
