import { BarChartHorizontal, Download, Pencil, Trash2 } from 'lucide-react';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	Link,
	LoaderFunctionArgs,
	isRouteErrorResponse,
	redirect,
	useLoaderData,
	useRouteError,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import Tabs from 'ui/components/Tabs';
import { ToastType } from 'ui/components/Toaster/Toast';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import DeleteUserModal from '../../components/DeleteUserModal';
import { deleteSchema } from 'utils/api/common';
import { formDataAsObject } from 'utils/formData/formData';

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		const formData = await request.formData();
		const deleteModel = deleteSchema.parse(formDataAsObject(formData));

		const response = await UserAPI.deleteUser(
			params.userId as string,
			deleteModel
		);

		if (response instanceof Error) {
			createToast(ToastType.ERROR, 'Deletion failed');
			return response;
		}

		createToast(ToastType.SUCCESS, 'User deleted successfully');
		return redirect('/users');
	}

	return null;
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const userId = params.userId as string;

	const userData = await UserAPI.getUser(userId);

	return {
		user: userData,
	};
};

export function UserDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const handleDownload = async (userId: string) => {
		toast.loading('Exporting...');

		const response = await UserAPI.getUserDownloadData(userId);

		downloadFile(response.url, response.fileName);
	};

	return (
		<Fragment>
			<div className="content">
				<PageHeader title={`${data.user.firstName} ${data.user.lastName}`}>
					<Button
						to={`/admin-audit-log/?userIds=${data.user.id}&dateRange=0`}
						variant="secondary"
						icon={BarChartHorizontal}
					>
						Audit Log
					</Button>
					<Button
						variant="secondary"
						icon={Trash2}
						onClick={() => setDeleteModalOpen(true)}
						data-cy="userDetailsDeleteButton"
					>
						Delete
					</Button>
					<Button
						variant="secondary"
						icon={Pencil}
						to={`/users/${data.user.id}/edit`}
					>
						Edit
					</Button>
				</PageHeader>

				<DeleteUserModal
					user={data.user}
					isOpen={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
				/>

				<KeyValuePairs
					entries={[
						{ key: 'Email', value: data.user.email },
						{
							key: 'Customer',
							value: (
								<Link to={`/customers/${data.user.customerId}`}>
									{data.user.customerName} ({data.user.customerNumber})
								</Link>
							),
						},
						{ key: 'CargoIS Status', value: data.user.userStatus.label },
						{
							key: 'Salesforce Status',
							value: data.user.salesforceStatus.label,
						},
					]}
				/>

				<Grid columns="minmax(0, 3fr) 1fr">
					<Tabs
						baseUrl={`/users/${data.user.id}`}
						tabs={[
							{ name: 'Subscriptions', to: '' },
							{ name: 'Adhoc Reports', to: '/reports' },
							{ name: 'Teams', to: '/teams' },
						]}
					/>

					<Grid columns={1}>
						<Card label="Access Information">
							<KeyValuePairs
								layout="vertical"
								entries={[
									{
										key: 'Access Start',
										value: (
											<DateFragment
												date={data.user.activeFrom}
												timezone="utc"
											/>
										),
									},
									{
										key: 'Access Expiry',
										value: (
											<DateFragment date={data.user.activeTo} timezone="utc" />
										),
									},
								]}
							/>
						</Card>

						<Button
							icon={Download}
							onClick={() => {
								handleDownload(data.user.id).finally(() => {
									toast.remove();
								});
							}}
						>
							Download data
						</Button>
					</Grid>
				</Grid>
			</div>
		</Fragment>
	);
}

export function UserErrorBoundary() {
	const error = useRouteError();

	// re-throw to let the RootErrorBoundary handle it if it's a route error
	if (!isRouteErrorResponse(error)) {
		throw error;
	}

	return (
		<>
			<h1>User could not be found</h1>
			<p>
				Go back to the <Link to="./">list of users</Link>
			</p>
		</>
	);
}

export const USER_DETAILS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <UserDetails />,
	errorElement: <UserErrorBoundary />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return [
				{
					label: `Customer Users`,
					path: `/customers/${data.user.customerId}/`,
				},
				{ label: `${data.user.firstName} ${data.user.lastName}` },
			];
		},
	},
};
