import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import reportNotificationsAPI from '../../api/ReportNotificationsAPI';

type ExecuteNotificationModalProps = {
	onSubmit?: () => void;
} & Omit<ModalProps, 'title'>;

const ExecuteNotificationModal = ({
	onSubmit,
	...modalProps
}: ExecuteNotificationModalProps) => {
	const handleSubmit = async () => {
		await reportNotificationsAPI.executeReportNotifications();

		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<ConfirmationModal
			fieldName="confirm-notification"
			title="Start Schedule"
			submitText="Start Schedule"
			confirmationText="confirm"
			onSubmit={handleSubmit}
			{...modalProps}
		>
			<p>Are you sure you wish to start schedule?</p>
		</ConfirmationModal>
	);
};

export default ExecuteNotificationModal;
