import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import * as React from 'react';

const AccordionTrigger = React.forwardRef<
	React.ElementRef<typeof AccordionPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & {
		hint?: React.ReactNode;
	}
>(({ className, children, hint, ...props }, ref) => (
	<AccordionPrimitive.Header className="accordion__header">
		<AccordionPrimitive.Trigger
			ref={ref}
			className="accordion__trigger"
			{...props}
		>
			<div>
				<div className="accordion__trigger-content">{children}</div>
				{hint && <div className="accordion__trigger-hint">{hint}</div>}
			</div>
			<ChevronDown size={20} />
		</AccordionPrimitive.Trigger>
	</AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

export default AccordionTrigger;
