import { Download, PlusCircle, RefreshCcw, Search } from 'lucide-react';
import React, { useId, useRef } from 'react';
import toast from 'react-hot-toast';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useNavigate,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CountryPicker from 'ui/components/CountryPicker';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import APIError from 'utils/errors/APIError';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import CustomerAPI from '../../api/CustomerAPI';
import QuickFilters from '../../components/QuickFilters/QuickFilters';
import * as customerColumns from '../../util/columns/customerColumns';
import { getSearchParamsFromButtonForm } from '../../util/forms';
import { DropdownField } from '../../forms/DropdownField';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getCustomersResponse = await CustomerAPI.getCustomers(
		new URL(request.url).searchParams
	);

	return {
		getCustomersResponse,
	};
};

async function handleDownloadData(e: React.MouseEvent<HTMLButtonElement>) {
	e.preventDefault();

	toast.loading('Exporting...');

	const searchParams = getSearchParamsFromButtonForm(e.currentTarget);
	const getCustomersResponse = await CustomerAPI.getCustomersDownloadData(
		searchParams
	);

	downloadFile(getCustomersResponse.url, getCustomersResponse.fileName);
}

export function CustomersListPage() {
	const formId = useId();
	const formRef = useRef<HTMLFormElement>(null);

	const data = useLoaderData() as LoaderData<typeof loader>;
	const queryParams = new URLSearchParams(window.location.search);
	const navigate = useNavigate();

	const columns = [
		customerColumns.nameColumn,
		customerColumns.idColumn,
		customerColumns.salesForceIdColumn,
		customerColumns.typeColumn,
		customerColumns.statusColumn,
		customerColumns.accountManagerColumn,
		customerColumns.countryColumn,
		customerColumns.regionColumn,
		customerColumns.subscriptionsColumn,
		customerColumns.usersColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Customers">
					<QuickFilters
						options={data.getCustomersResponse.filterOptions}
						formRef={formRef}
					/>
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						type="button"
						variant="secondary"
						form={formId}
						icon={Download}
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							let close = true;
							handleDownloadData(e)
								.catch((reason: APIError) => {
									toast.remove();
									toast.error(reason.message);

									close = false;
								})
								.finally(() => {
									if (close) {
										toast.remove();
									}
								});
						}}
					>
						Download data
					</Button>
					<Button variant="secondary" icon={PlusCircle} to="/customers/create">
						Add Customer
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					data={data.getCustomersResponse.customers.items}
					columns={columns}
				/>

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.getCustomersResponse.customers.page}
					pageParameterName="page"
					pageSize={data.getCustomersResponse.customers.pageSize}
					itemCount={data.getCustomersResponse.customers.totalCount}
				/>
			</div>

			<Sidebar title="Customer Filter">
				<ValidatedForm method="get" id={formId} ref={formRef} resetOnNavigation>
					<TextField
						label="Keyword Search"
						name="keyword"
						defaultValue={queryParams.get('keyword') ?? ''}
					/>

					<MultiSelectField
						label="Customer Type"
						name="customertypes"
						identifierKey="id"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.name}`}
						options={data.getCustomersResponse.customerTypeOptions}
						pillName={(item) => item.name}
						initialValues={data.getCustomersResponse.customerTypeOptions.filter(
							(item) =>
								data.getCustomersResponse.customerTypes.includes(item.id)
						)}
					/>

					<MultiSelectField
						label="Customer Status"
						name="customerstatus"
						options={data.getCustomersResponse.customerStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.getCustomersResponse.customerStatusOptions.filter(
							(item) =>
								data.getCustomersResponse.customerStatus.includes(item.value)
						)}
					/>

					<MultiSelectField
						label="Account Manager"
						name="accountmanagers"
						options={data.getCustomersResponse.accountManagerOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.getCustomersResponse.accountManagerOptions.filter(
							(item) =>
								data.getCustomersResponse.accountManagers.includes(item.value)
						)}
					/>

					<CountryPicker
						label="Country"
						name="countries"
						initialValue={data.getCustomersResponse.countriesSelected[0]}
					/>

					<MultiSelectField
						label="CargoIS Region"
						name="regions"
						options={data.getCustomersResponse.regionOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.getCustomersResponse.regionOptions.filter(
							(item) => data.getCustomersResponse.regions.includes(item.value)
						)}
					/>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_CUSTOMERS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <CustomersListPage />,
};
