import { createColumnHelper } from '@tanstack/react-table';
import { NotificationItem } from '../../api/MonitoringAPI';
import Button from 'ui/components/Button/Button';
import { Pencil } from 'lucide-react';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import {
	getDayOffsetForLocalHour,
	getUTCOffsetInHours,
	modDaysOfWeek,
	modHours,
} from 'utils/helpers/timezone';
import { addDays, addHours, format, startOfWeek } from 'date-fns';

const columnHelper = createColumnHelper<NotificationItem>();

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => {
		if (info.row.original.name) return info.row.original.name;
		return '-';
	},
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const updatedAtColumn = columnHelper.accessor('updatedAt', {
	header: 'Updated Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const typeColumn = columnHelper.accessor('schedule', {
	header: 'Type',
	cell: (info) => {
		if (info.row.original.notifications.length === 1) {
			return `${info.row.original.notifications[0].notificationType.name} (${info.row.original.notifications[0].notificationOption.name})`;
		} else {
			return `Multiple selections`;
		}
	},
});

export const scheduleColumn = columnHelper.accessor('schedule', {
	header: 'Frequency',
	cell: (info) => {
		const currentDate = new Date();
		const schedule = info.getValue();

		switch (schedule.frequency) {
			case 'weekly': {
				const hourlyDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate(),
					schedule.time
				);
				const utcOffset = getUTCOffsetInHours();
				const dayOffset = getDayOffsetForLocalHour(
					modHours(schedule.time - utcOffset)
				);

				const localDayOfWeek = modDaysOfWeek(schedule.dayOfWeek - dayOffset);
				const thisWeek = startOfWeek(currentDate);
				const dayOfWeekDate = addDays(thisWeek, localDayOfWeek);
				const formattedDayOfWeek = format(dayOfWeekDate, 'EEEE');
				const localDateTime = addHours(hourlyDate, -utcOffset);
				const formattedHour = format(localDateTime, 'HH');

				return `Weekly (${formattedDayOfWeek} @ ${formattedHour}:00)`;
			}
			case 'daily': {
				const utcOffset = getUTCOffsetInHours();
				const hourlyDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate(),
					schedule.time
				);
				const localDateTime = addHours(hourlyDate, -utcOffset);
				const formattedHour = format(localDateTime, 'HH');

				return `Daily (${formattedHour}:00)`;
			}
			default:
				return 'Immediate Notification';
		}
	},
});

export const lastExecutionColumn = columnHelper.accessor('schedule', {
	header: 'Last Execution (UTC)',
	cell: ({ row }) => {
		return (
			<DateFragment
				timezone="utc"
				date={row.original.schedule.lastExecution}
				includeTime={true}
				emptyText="-"
			/>
		);
	},
});
export const nextExecutionColumn = columnHelper.accessor('schedule', {
	header: 'Next Execution (UTC)',
	cell: ({ row }) => {
		return (
			<DateFragment
				timezone="utc"
				date={row.original.schedule.nextExecution}
				includeTime={true}
				emptyText="-"
			/>
		);
	},
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => (
		<Button
			variant="secondary"
			size="small"
			icon={Pencil}
			to={`/system-notifications/${info.getValue()}`}
		>
			Edit
		</Button>
	),
});
