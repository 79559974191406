import Flex from 'ui/components/Flex';
import { UserOption } from '../../api/UserAPI';

type UserPickerItemProps = {
	user: UserOption;
};

const UserPickerItem = ({ user }: UserPickerItemProps) => {
	return (
		<Flex className="user-picker__item" direction="column" gap={2}>
			<div className="user-picker__info">
				<span className="user-picker__name">{user.name}</span>
				<span className="user-picker__email">&nbsp;({user.email})</span>
			</div>
			<span className="user-picker__customer">
				Customer: {user.customerName}
			</span>
		</Flex>
	);
};

export default UserPickerItem;
