import { Table2 } from 'lucide-react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import WebToolWorksheetAPI from '../../api/WebToolWorksheetAPI';
import * as webToolReportColumns from '../../util/columns/webToolReportsColumns';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const subscriptionId = params.subscriptionId as string;
	const queryString = new URL(request.url).search;
	const searchParams = new URLSearchParams(queryString);

	searchParams.append('subscriptionIds', subscriptionId);

	const response = await WebToolWorksheetAPI.getWorksheets(searchParams);

	return { ...response, subscriptionId };
};

const SubscriptionWebToolReports = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		webToolReportColumns.nameColumn,
		webToolReportColumns.idColumn,
		webToolReportColumns.userColumn,
		webToolReportColumns.createdAtColumn,
		webToolReportColumns.updatedAtColumn,
	];

	return (
		<Flex direction="column" gap={16}>
			<Table
				identifierKey="id"
				columns={columns}
				data={data.worksheets.items}
			/>

			<TabActions>
				<Button
					variant="secondary"
					size="small"
					to={`/web-tool-reports?subscriptionIds=${data.subscriptionId}`}
					icon={Table2}
					type="button"
				>
					Advanced
				</Button>
			</TabActions>

			{data.worksheets.totalCount > data.worksheets.pageSize && (
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.worksheets.page}
					pageParameterName="page"
					pageSize={data.worksheets.pageSize}
					itemCount={data.worksheets.totalCount}
				/>
			)}
		</Flex>
	);
};

export const SUBSCRIPTION_DETAILS_WEB_TOOL_REPORTS_ROUTE: LayoutRouteProps = {
	loader,
	element: <SubscriptionWebToolReports />,
};
