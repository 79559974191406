import { Download, PlusCircle, RefreshCcw, Search } from 'lucide-react';
import React, { useId, useRef } from 'react';
import toast from 'react-hot-toast';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import LocationPicker from 'ui/components/LocationPicker/LocationPicker';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import APIError from 'utils/errors/APIError';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI from '../../api/SubscriptionAPI';
import CustomerPicker from '../../components/CustomerPicker';
import { DropdownField } from '../../forms/DropdownField';
import * as subscriptionColumns from '../../util/columns/subscriptionColumns';
import { getSearchParamsFromButtonForm } from '../../util/forms';
import QuickFilters from '../../components/QuickFilters/QuickFilters';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	return SubscriptionAPI.getSubscriptions(url.searchParams);
};

async function handleDownloadData(e: React.MouseEvent<HTMLButtonElement>) {
	e.preventDefault();

	toast.loading('Exporting...');

	const searchParams = getSearchParamsFromButtonForm(e.currentTarget);
	const getSubscriptionsResponse =
		await SubscriptionAPI.getSubscriptionsDownloadData(searchParams);

	downloadFile(getSubscriptionsResponse.url, getSubscriptionsResponse.fileName);
}

export function SubscriptionsListPage() {
	const formId = useId();
	const formRef = useRef<HTMLFormElement>(null);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		subscriptionColumns.nameColumn,
		subscriptionColumns.customerColumn,
		subscriptionColumns.customerStatusColumn,
		subscriptionColumns.productNameColumn,
		subscriptionColumns.startPeriodColumn,
		subscriptionColumns.endPeriodColumn,
		subscriptionColumns.statusColumn,
		subscriptionColumns.licensesColumn,
		subscriptionColumns.originsColumn,
		subscriptionColumns.destinationsColumn,
		subscriptionColumns.subscriptionUsersColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Subscriptions">
					<QuickFilters options={data.filterOptions} formRef={formRef} />
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						type="button"
						variant="secondary"
						form={formId}
						icon={Download}
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							let close = true;
							handleDownloadData(e)
								.catch((reason: APIError) => {
									toast.remove();
									toast.error(reason.message);

									close = false;
								})
								.finally(() => {
									if (close) {
										toast.remove();
									}
								});
						}}
					>
						Download data
					</Button>
					<Button
						to="/subscriptions/create"
						variant="secondary"
						icon={PlusCircle}
					>
						Add Subscription
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.subscriptions.items}
				/>

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.subscriptions.page}
					pageParameterName="page"
					pageSize={data.subscriptions.pageSize}
					itemCount={data.subscriptions.totalCount}
				/>
			</div>
			<Sidebar title="Subscription Filter">
				<ValidatedForm method="get" id={formId} ref={formRef} resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />

					<DropdownField
						label="Delivery Status"
						name="subscriptionDeliveryStatuses"
						options={data.subscriptionDeliveryStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
					/>

					<MultiSelectField
						label="Customer Type"
						name="customertypes"
						options={data.customerTypeOptions}
						contentSource={(option) => option.name}
						identifierKey="id"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>

					<MultiSelectField
						label="Customer Status"
						name="customerStatuses"
						options={data.customerStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<MultiSelectField
						label="Product"
						name="productIds"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="id"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<LocationPicker
						label="Origin"
						name="origins"
						initialValues={data.originCountriesSelected}
						isMulti={true}
					/>

					<LocationPicker
						label="Destination"
						name="destinations"
						initialValues={data.destinationCountriesSelected}
						isMulti={true}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_SUBSCRIPTIONS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <SubscriptionsListPage />,
};
