import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import PageHeader from 'ui/components/PageHeader';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import React from 'react';
import Grid from 'ui/components/Grid';
import MailAPI from '../../api/MailApi';
import { LoaderData } from 'utils/types/loaderData';
import * as mailColumns from './mailColumns';
import Table from 'ui/components/Table/Table';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return await MailAPI.getEmailContents(new URLSearchParams());
};

export function EmailTemplatesIndexPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const columns = [
		mailColumns.nameColumn,
		mailColumns.idColumn,
		mailColumns.subjectColumn,
		mailColumns.createdAtColumn,
		mailColumns.updatedAtColumn,
	];

	return (
		<div className="content">
			<Grid>
				<PageHeader title="Email Templates"></PageHeader>
				<Table identifierKey="id" columns={columns} data={data.emails} />
			</Grid>
		</div>
	);
}

export const EMAIL_TEMPLATES_MAIN_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <EmailTemplatesIndexPage />,
};
