import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetAdminUserResponse } from '../../api/AdminUserAPI';

type DeleteUserModalProps = {
	user: GetAdminUserResponse;
} & Omit<ModalProps, 'title'>;

const DeleteAdminUserModal = ({
	user,
	...modalProps
}: DeleteUserModalProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete User"
			submitText="Delete User"
			confirmationText={user.name}
			submitIcon={Trash2}
			intent="danger"
			formAction={`/admin-users/${user.id}`}
			formMethod="delete"
			{...modalProps}
		>
			<p>
				Are you sure you wish to delete the admin user <b>{user.name}</b>? This
				action cannot be undone.
			</p>
		</ConfirmationModal>
	);
};

export default DeleteAdminUserModal;
