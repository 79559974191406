import { ColumnDef } from '@tanstack/react-table';
import { Check, Settings2, X } from 'lucide-react';
import { useEffect, useId, useMemo } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import AuditCommentField from 'ui/components/AuditCommentField';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import ValidatedForm from 'ui/components/ValidatedForm';
import * as genericColumns from 'utils/columns/genericColumns';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import useSubmitting from 'utils/hooks/useSubmitting';
import useToggle from 'utils/hooks/useToggle';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { zAuditable } from 'utils/zod/zodValidation';
import UserAPI, { AvailableSubscriptionItem } from '../../api/UserAPI';
import * as subscriptionColumns from '../../util/columns/subscriptionColumns';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	let searchParams = new URL(request.url).searchParams;

	const userId = params.userId as string;

	const availableSubscriptionsData =
		await UserAPI.getAvailableSubscriptionsForUser(userId, searchParams);

	return {
		availableSubscriptionsData,
	};
};

const action = async ({ params, request }: ActionFunctionArgs) => {
	const userId = params.userId as string;
	const formData = await request.formData();

	const auditComment = formData.get('auditComment') as string;
	const subscriptionIds = formData.getAll('selectedRows') as string[];
	await UserAPI.updateSubscriptionsForUser(
		userId,
		subscriptionIds,
		auditComment
	);

	return null;
};

const UserSubscriptions = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const formId = useId();
	const isSubmitting = useSubmitting();
	const [isEditing, toggleEditing] = useToggle(false);

	useEffect(() => {
		if (!isSubmitting && isEditing) {
			toggleEditing();
		}
	}, [isSubmitting]);

	const subscriptions = isEditing
		? data.availableSubscriptionsData.subscriptions.items
		: data.availableSubscriptionsData.subscriptions.items.filter(
				(s) => s.isSubscribed
		  );

	const initialSelectedIds = useMemo(
		() =>
			data.availableSubscriptionsData.subscriptions.items
				.filter((s) => s.isSubscribed)
				.map((s) => s.id),
		[data.availableSubscriptionsData.subscriptions.items]
	);

	const columns = [
		isEditing && genericColumns.selectColumn,
		subscriptionColumns.nameColumn,
		subscriptionColumns.statusColumn,
		subscriptionColumns.productNameColumn,
		subscriptionColumns.startPeriodColumn,
		subscriptionColumns.endPeriodColumn,
		subscriptionColumns.originsColumn,
		subscriptionColumns.destinationsColumn,
		subscriptionColumns.subscriptionUsersColumn,
	];

	return (
		<>
			<Flex direction="column" gap={16}>
				<ValidatedForm id={formId} method="post" validator={zAuditable}>
					<Flex direction="column" gap={16}>
						{isEditing && <AuditCommentField />}

						<Table
							identifierKey="id"
							data={subscriptions}
							columns={columns as ColumnDef<AvailableSubscriptionItem, any>[]}
							initialSelection={initialSelectedIds}
							emptyText="No subscriptions found"
						/>
					</Flex>
				</ValidatedForm>

				<TabActions>
					{isEditing ? (
						<>
							<Button
								variant="secondary"
								size="small"
								onClick={toggleEditing}
								icon={X}
								type="button"
								isDisabled={isSubmitting}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								size="small"
								icon={Check}
								type="submit"
								form={formId}
								isLoading={isSubmitting}
								loadingText="Saving"
							>
								Save changes
							</Button>
						</>
					) : (
						<>
							<Button
								variant="secondary"
								size="small"
								onClick={toggleEditing}
								icon={Settings2}
								type="button"
							>
								Manage subscriptions
							</Button>
						</>
					)}
				</TabActions>

				{data.availableSubscriptionsData.subscriptions.totalCount >
					data.availableSubscriptionsData.subscriptions.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.availableSubscriptionsData.subscriptions.page}
						pageParameterName="page"
						pageSize={data.availableSubscriptionsData.subscriptions.pageSize}
						itemCount={data.availableSubscriptionsData.subscriptions.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const USER_DETAILS_SUBSCRIPTIONS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <UserSubscriptions />,
};
