import format from 'date-fns/format';
import { YearAndMonth } from 'utils/types/helpers';

type YearMonthAndWeekFragmentProps = {
	value?: YearAndMonth;
	emptyText?: string;
};

const YearMonthAndWeekFragment = ({
	value,
	emptyText = '–',
}: YearMonthAndWeekFragmentProps) => {
	const dateFormat = 'MMM, yyyy';
	const date = value ? new Date(value.year, value.month - 1) : undefined;
	const week = value ? String(value.week).padStart(2, '0') : undefined;
	const isEmpty = date === null || date === undefined;

	return <>{isEmpty ? emptyText : `W${week} ${format(date, dateFormat)}`}</>;
};

export default YearMonthAndWeekFragment;
