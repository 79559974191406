import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'lucide-react';
import { ComponentProps } from 'react';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import { ReportDistributionItem } from '../../api/ReportDistributionAPI';

const columnHelper = createColumnHelper<ReportDistributionItem>();

type BulletIndicatorIntent = ComponentProps<typeof BulletIndicator>['intent'];

export const distributionStatusIntentMap: {
	[key: string]: BulletIndicatorIntent;
} = {
	Succeeded: 'success',
	Failed: 'error',
	Pending: 'info',
	Started: 'info',
	Delivered: 'info',
};

export const distributionStatusColumn = columnHelper.accessor(
	'distributionStatus',
	{
		header: 'Status',
		cell: (info) => {
			const value = info.getValue();

			return (
				<BulletIndicator
					intent={distributionStatusIntentMap[value]}
					label={value}
				/>
			);
		},
	}
);

export const accountsColumn = columnHelper.accessor('accountNames', {
	header: 'Accounts',
	cell: (info) => info.getValue(),
});

export const filesColumn = columnHelper.accessor('fileNames', {
	header: 'Files',
	cell: (info) => info.getValue(),
});

export const createdDateColumn = columnHelper.accessor('createdAt', {
	header: 'Created At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const startedDateColumn = columnHelper.accessor('startedAt', {
	header: 'Started At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const deliveredDateColumn = columnHelper.accessor('deliveredAt', {
	header: 'Delivered At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const completedDateColumn = columnHelper.accessor('completedAt', {
	header: 'Completed At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const viewDetailsColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => {
		return (
			<Button
				size="small"
				variant="secondary"
				icon={Eye}
				to={`/static-report-distribution/${info.row.original.id}`}
			>
				View
			</Button>
		);
	},
});
