import { DecentralizedRouteProps } from 'utils/types/common';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import MonitoringAPI, {
	CreateNotificationModel,
} from '../../api/MonitoringAPI';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import ValidatedForm from 'ui/components/ValidatedForm';
import React, { useState } from 'react';
import PageHeader from 'ui/components/PageHeader';
import Button from 'ui/components/Button/Button';
import { X } from 'lucide-react';
import Grid from 'ui/components/Grid';
import ErrorList from 'ui/components/ErrorList';
import NotificationScheduleForm, {
	DEFAULT_WORKSHEET_SCHEDULE,
} from './NotificationScheduleForm';
import APIError from 'utils/errors/APIError';
import TextField from 'ui/components/TextField';
import { formDataAsObject } from 'utils/formData/formData';
import {
	CreateSystemNotificationSubscriptionModel,
	createSystemSubscriptionSchema,
} from 'utils/schemas/systemNotificationSchema';
import toast from 'react-hot-toast';
import AdminUserAPI from '../../api/AdminUserAPI';
import {
	getDayOffsetForLocalHour,
	localHourToUTC,
	modDaysOfWeek,
} from 'utils/helpers/timezone';
import NotificationSelector from './NotificationsSelector';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const url = new URL(request.url);
	const searchParams = url.searchParams;
	const adminId = searchParams.get('adminId') as string;
	const adminUser = await AdminUserAPI.getAdminUser(adminId);
	return {
		adminUser: { id: adminUser.id, name: adminUser.name },
		options: await MonitoringAPI.getNotificationOptions(),
	};
};

const payloadFromData = (model: CreateSystemNotificationSubscriptionModel) => {
	let payload: CreateNotificationModel = {
		adminId: model.adminId,
		name: model.name,
		frequency: model.schedule.frequency,
		dayOfWeek: 0,
		time: 0,
		notifications: model.notifications,
	};

	if (model.schedule.frequency === 'weekly') {
		const dow = model.schedule.dayOfWeek ?? '0';
		payload.dayOfWeek = parseInt(dow) as Day;
	}
	if (
		model.schedule.frequency === 'daily' ||
		model.schedule.frequency === 'weekly'
	) {
		const time = model.schedule.time ?? '';
		const seg = time.split(':');

		payload.time = parseInt(seg[0]);

		const utcHour = localHourToUTC(payload.time);

		if (model.schedule.frequency === 'weekly') {
			const dayOffset = getDayOffsetForLocalHour(payload.time);

			payload.dayOfWeek = modDaysOfWeek(payload.dayOfWeek + dayOffset) as Day;
		}

		payload.time = utcHour;
	}

	return payload;
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();
	const obj = formDataAsObject(formData);
	const model: CreateSystemNotificationSubscriptionModel =
		createSystemSubscriptionSchema.parse(obj);

	const payload: CreateNotificationModel = payloadFromData(model);
	const result = await MonitoringAPI.createNotification(payload);

	if (result instanceof APIError) {
		return result;
	}

	toast.success('Subscription successfully created');

	return redirect(`/admin-users/${model.adminId}`);
};

export function NotificationCreatePage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();
	const actionData = useActionData() as ActionData<typeof action>;
	const [schedule, setSchedule] = useState(DEFAULT_WORKSHEET_SCHEDULE);

	return (
		<div className="content">
			<ValidatedForm method="POST" validator={createSystemSubscriptionSchema}>
				<input type="hidden" name="adminId" value={data.adminUser.id} />
				<Grid>
					<PageHeader title="Add Notification">
						<Button
							isDisabled={navigation.state !== 'idle'}
							variant="secondary"
							to={`/admin-users/${data.adminUser.id}`}
							icon={X}
							data-testid="cancel-button"
						>
							Cancel
						</Button>
						<Button
							type="submit"
							isDisabled={navigation.state !== 'idle'}
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Subscription"
							data-testid="create-button"
						>
							Create
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<TextField label="Name" name="name" isRequired={true} />
							<NotificationScheduleForm
								schedule={schedule}
								onScheduleChange={setSchedule}
							/>
						</Grid>

						<Grid columns={2} isBox>
							<NotificationSelector types={data.options.notificationTypes} />
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const NOTIFICATION_CREATE_ROUTE: DecentralizedRouteProps = {
	loader,
	action: action,
	element: <NotificationCreatePage />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return [
				{
					label: 'Admin Users',
					path: '/admin-users',
				},
				{
					label: data.adminUser.name,
					path: `/admin-users/${data.adminUser.id}`,
				},
			];
		},
	},
};
