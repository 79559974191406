import { ENVIRONMENT_ADMIN } from 'environment';
import API from 'utils/api/API';
import { Paginated } from 'utils/types/common';
import { StatusOption } from './ReportNotificationsAPI';

type GetReportsRequest = { pageSize?: number; page?: number };

export type GetReportsResponse = {
	status: string[];
	createdBeforeDate: Date | null;
	createdAfterDate: Date | null;
	completedBeforeDate: Date | null;
	completedAfterDate: Date | null;
	reports: Paginated<ReportDistributionItem>;
	statusOptions: StatusOption[];
};

export type ReportDistributionItem = {
	id: string;
	distributionStatus: string;
	createdAt: Date;
	startedAt: Date;
	deliveredAt: Date;
	completedAt: Date;
	accountNames: string[];
	fileNames: string[];
};

export type ReportDistributionFile = {
	id: string;
	fileName: string;
	accountName: string;
	status: string;
	failed: boolean;
	message: string;
	startedAt: Date;
	completedAt: Date;
};

export type ReportDistributionDetail = {
	id: string;
	distributionStatus: string;
	message: string;
	createdAt: Date;
	startedAt: Date;
	deliveredAt: Date;
	completedAt: Date;
	files: ReportDistributionFile[];
	validateMessage: string;
	transmitMessage: string;
	reportMessage: string;
};
export type DeleteReportsResponse = { id: string };
export type ExecuteReportsResponse = {
	arn: string;
	success: boolean;
};

class ReportDistributionAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_ADMIN.staticReportEndpoint;

	static getReportDistributions(request: GetReportsRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return ReportDistributionAPI.get<GetReportsResponse>(
			`/report-distribution/v1?${params}`
		);
	}
	static getReportDistribution(id: string) {
		return ReportDistributionAPI.get<ReportDistributionDetail>(
			`/report-distribution/${id}/v1?`
		);
	}
	static deleteReportDistribution(id: string) {
		return ReportDistributionAPI.delete<DeleteReportsResponse>(
			`/report-distribution/${id}/v1?`
		);
	}
	static executeReportDistribution() {
		return ReportDistributionAPI.post<ExecuteReportsResponse>(
			'/report-distribution/execute/v1'
		);
	}
}

export default ReportDistributionAPI;
