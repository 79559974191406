import { format } from 'date-fns';
import { memo } from 'react';

export function getYearMonthFromQueryStringOrDefault(
	request: Request,
	queryKey: string = 'yearMonth'
): number | null {
	const queryParams = new URL(request.url).searchParams;

	let yearMonth = Number(queryParams.get(queryKey));

	if (Number.isNaN(yearMonth) || yearMonth < 202001) {
		return null;
	}

	return yearMonth;
}

type YearMonthFragmentProps = {
	yearMonth: number;
};

export const YearMonthFragment = memo(
	({ yearMonth }: YearMonthFragmentProps) => {
		const yearMonthStr = yearMonth.toString();
		const year = yearMonthStr.substring(0, 4);
		const month = yearMonthStr.substring(4, 6);
		const dte = new Date(Number(year), Number(month) - 1, 1);

		return <>{`${format(dte, 'LLLL')} ${year}`}</>;
	}
);
