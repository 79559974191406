import { Command, RefreshCcw, Search } from 'lucide-react';
import { useState } from 'react';
import { LoaderFunctionArgs, Outlet, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import reportNotificationsAPI from '../../api/ReportNotificationsAPI';
import CustomerPicker from '../../components/CustomerPicker';
import ExecuteNotificationModal from '../../components/ExecuteNotificationModal/ExecuteNotificationModal';
import * as reportNotificationsColumns from '../../util/columns/reportNotificationsColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const response = await reportNotificationsAPI.getReportNotifications(
		new URL(request.url).searchParams
	);

	return {
		customersSelected: response.customersSelected,
		status: response.status,
		createdBeforeDate: response.createdBeforeDate,
		createdAfterDate: response.createdAfterDate,
		completedBeforeDate: response.completedBeforeDate,
		completedAfterDate: response.completedAfterDate,
		notifications: response.notifications,
		statusOptions: response.statusOptions,
	};
};

export function ReportNotificationsListPage() {
	const [isModalOpen, setModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		reportNotificationsColumns.reportColumn,
		reportNotificationsColumns.subscriptionCodeColumn,
		reportNotificationsColumns.createdDateColumn,
		reportNotificationsColumns.statusColumn,
		reportNotificationsColumns.completedDateColumn,
		reportNotificationsColumns.releaseDateColumn,
		reportNotificationsColumns.viewDetailsColumn,
	];

	return (
		<>
			<Sidebar.Wrapper>
				<div className="content">
					<PageHeader title="Report Notifications">
						<RevalidationButton>Refresh</RevalidationButton>
						<Button
							variant="secondary"
							icon={Command}
							onClick={() => setModalOpen(true)}
						>
							Start Schedule
						</Button>
					</PageHeader>

					<ExecuteNotificationModal
						isOpen={isModalOpen}
						onSubmit={() => {
							setModalOpen(false);
							createToast(ToastType.SUCCESS, 'Reports Schedule Running');
						}}
						onClose={() => setModalOpen(false)}
					/>

					<Grid>
						<Grid>
							<Table
								columns={columns}
								data={data.notifications.items}
								identifierKey="id"
							/>

							<Pagination
								baseUrl={new URL(window.location.href)}
								page={data.notifications.page}
								pageParameterName="page"
								pageSize={data.notifications.pageSize}
								itemCount={data.notifications.totalCount}
							/>
						</Grid>
						<Outlet />
					</Grid>
				</div>
				<Sidebar title="Filter">
					<ValidatedForm method="get" resetOnNavigation>
						<CustomerPicker
							name="customerIds"
							label="Customer"
							initialValues={
								data.customersSelected ? data.customersSelected : []
							}
							isMulti={true}
						/>
						<TextField label="Subscription Code" name="subscriptionNumber" />
						<MultiSelectField
							label="Status"
							name="status"
							identifierKey="id"
							placeholder="Please select..."
							contentSource={(opt) => `${opt.name}`}
							options={data.statusOptions}
							pillName={(item) => item.name}
							initialValues={data.statusOptions.filter((item) =>
								data.status.includes(item.id)
							)}
						/>
						<Grid columns={2}>
							<DateField
								label="Created After"
								name="createdAfterDate"
								initialValue={data.createdAfterDate}
								timezone="utc"
							/>
							<DateField
								label="Created Before"
								name="createdBeforeDate"
								initialValue={data.createdBeforeDate}
								timezone="utc"
							/>
						</Grid>
						<Grid columns={2}>
							<DateField
								label="Finished After"
								name="completedAfterDate"
								initialValue={data.completedAfterDate}
								timezone="utc"
							/>
							<DateField
								label="Finished Before"
								name="completedBeforeDate"
								initialValue={data.completedBeforeDate}
								timezone="utc"
							/>
						</Grid>
						<Sidebar.Actions>
							<Button variant="secondary" icon={RefreshCcw} type="reset">
								Clear search
							</Button>
							<Button variant="primary" icon={Search} type="submit">
								Search
							</Button>
						</Sidebar.Actions>
					</ValidatedForm>
				</Sidebar>
			</Sidebar.Wrapper>
		</>
	);
}

export const REPORTS_NOTIFICATIONS_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <ReportNotificationsListPage />,
};
