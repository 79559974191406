import { FileSpreadsheet, Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useActionData,
	useLoaderData,
	useNavigate,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps, LabeledValue } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	RunWeeklyReportsRequest,
} from '../../api/SubscriptionAPI';
import CustomerPicker from '../../components/CustomerPicker';
import * as subscriptionColumns from '../../util/columns/weeklyReportsColumns';
import { DropdownField } from '../../forms/DropdownField';
import WeeklyReportsModal from '../../components/WeeklyReportsModal';
import BulletIndicator from 'ui/components/BulletIndicator';
import SystemProcessToggle from '../monitor/SystemProcessToggle';
import { listenOrPausedIntent } from '../monitor/system-status';
import DataManagementAPI from '../../api/DataManagementAPI';

type ActionData = Awaited<ReturnType<typeof action>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
	const yearMonthWeek = document.getElementsByName(
		'yearMonthWeek'
	)[0] as HTMLInputElement;

	const url = new URL(request.url);

	const searchParams = url.searchParams;
	if (yearMonthWeek) {
		searchParams.set('yearMonthWeek', yearMonthWeek.value);
	}

	await requireAuthentication(request);
	return SubscriptionAPI.getWeeklyReports(searchParams);
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	if (formData.get('systemProcessCode')) {
		const systemProcessCode =
			(formData.get('systemProcessCode') as string) || null;
		const statusCode = (formData.get('statusCode') as string) || null;

		if (!systemProcessCode || !statusCode) {
			return {
				errorCode: 'missing-fields',
				message: 'Missing fields',
			};
		}

		await DataManagementAPI.updateProcessStatus(systemProcessCode, statusCode);

		return null;
	}

	const payload: RunWeeklyReportsRequest = {
		yearMonthWeek: parseInt(formData.get('yearMonthWeek') as string),
		productTypes: formData.getAll('productTypes') as string[],
		customerIds: formData.getAll('customerIds') as string[],
		runOnNewDataSchedule: formData.has('runOnNewDataSchedule'),
	};

	const toastId = createToast(ToastType.LOADING, 'Queuing reports...');

	const response = await SubscriptionAPI.runWeeklyReports(payload);

	if (response.reportBatchId === null) {
		toast.error(`${response.message}`, {
			id: toastId,
		});

		return null;
	} else {
		toast.success('Reports queued successfully', {
			id: toastId,
		});

		return { batchId: response.reportBatchId };
	}
};

export function WeeklyReports() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [isRunReportModalOpen, setIsRunReportModalOpen] = useState(false);
	const navigate = useNavigate();

	const columns = [
		subscriptionColumns.deliveryStatusColumn,
		subscriptionColumns.nameColumn,
		subscriptionColumns.customerColumn,
		subscriptionColumns.customerStatusColumn,
		subscriptionColumns.productNameColumn,
		subscriptionColumns.startPeriodColumn,
		subscriptionColumns.endPeriodColumn,
		subscriptionColumns.licensesColumn,
		subscriptionColumns.originsColumn,
		subscriptionColumns.destinationsColumn,
		subscriptionColumns.usersColumn,
	];

	const [reportYearMonthAndWeek, setReportYearMonthAndWeek] =
		useState<LabeledValue>({
			label: data.yearMonthWeek.label,
			value: data.yearMonthWeek.value,
		});

	// Close the modal once actionData changes (and modal is open)
	const actionData = useActionData() as ActionData;
	useEffect(() => {
		if (actionData && isRunReportModalOpen) {
			setIsRunReportModalOpen(false);

			navigate(`/reports/monitor?batchIds=${actionData.batchId}`);
		}
	}, [actionData]);

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Weekly Report Distribution">
					<ValidatedForm method="post">
						<input
							type="hidden"
							id="productTypes"
							name="productTypes"
							value={data.productTypes}
						/>
						<input
							type="hidden"
							id="customerIds"
							name="customerIds"
							value={data.customerIds}
						/>
						<Flex gap={12}>
							<DropdownField
								name="yearMonthWeek"
								options={data.yearMonthWeekOptions}
								key="value"
								contentSource={(data) => data.label}
								initialValue={data.yearMonthWeek}
								identifierKey="value"
								isClearable={false}
								onOptionSelected={(option) => {
									if (option) {
										const selectedOption = data.yearMonthWeekOptions.find(
											(o) => o.value === option.value
										);
										if (selectedOption) {
											setReportYearMonthAndWeek(selectedOption);
										}
									}
								}}
							/>

							<Button
								variant="primary"
								icon={FileSpreadsheet}
								isDisabled={data.totalEnabledReports === 0}
								onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
									setIsRunReportModalOpen(true);
									e.preventDefault();
								}}
							>
								Run reports
							</Button>
						</Flex>
					</ValidatedForm>
				</PageHeader>

				<div className="statistics" data-testid="statistics">
					<div className="statistics__item">
						<b>{data.totalEnabledSubscriptions}</b>
						&nbsp;
						<span>
							Enabled Subscriptions (<b>{data.totalEnabledReports}</b> Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalDisabledSubscriptions}</b>&nbsp;
						<span>
							Disabled Subscriptions (<b>{data.totalDisabledReports}</b>{' '}
							Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalSubscriptions}</b>
						&nbsp;
						<span>
							Total Subscriptions (<b>{data.totalReports}</b> Reports)
						</span>
					</div>
					<div className="statistics__item">
						<b>{data.totalInactiveCustomers}</b>&nbsp;
						<span>Inactive Customers</span>
					</div>

					<ValidatedForm method="post">
						<Flex gap={16} alignItems="center">
							<h4>Automatic Delivery:</h4>
							<BulletIndicator
								intent={listenOrPausedIntent(
									data.weeklyReportGenerationProcess.status.code
								)}
								label={data.weeklyReportGenerationProcess.status.name}
							/>
							<SystemProcessToggle
								systemProcessCode={data.weeklyReportGenerationProcess.code}
								status={data.weeklyReportGenerationProcess.status}
								activeStatus="listening"
								validStatus={data.weeklyReportGenerationProcess.validStatus}
							/>
						</Flex>
					</ValidatedForm>
				</div>

				<WeeklyReportsModal
					totalReports={data.totalEnabledReports}
					productTypes={data.productTypes}
					customerIds={data.customerIds}
					yearMonthWeek={reportYearMonthAndWeek.value}
					isOpen={isRunReportModalOpen}
					onClose={() => setIsRunReportModalOpen(false)}
				/>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.subscriptions.items}
				/>

				{data.subscriptions.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.subscriptions.page}
						pageParameterName="page"
						pageSize={data.subscriptions.pageSize}
						itemCount={data.subscriptions.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Subscription Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<MultiSelectField
						label="Product"
						name="productTypes"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="productType"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>

					<MultiSelectField
						label="Delivery Status"
						name="subscriptionDeliveryStatus"
						options={data.subscriptionDeliveryStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<MultiSelectField
						label="Customer Status"
						name="customerStatus"
						options={data.customerStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const SUBSCRIPTIONS_WEEKLY_REPORTS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <WeeklyReports />,
};
