import { Editor } from '@tinymce/tinymce-react';

import { ENVIRONMENT_SHARED } from 'environment';
import { ComponentProps, useRef } from 'react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { flattenErrors } from 'utils/zod/zodErrors';
import FormField from '../FormField';
import useValidation from '../ValidatedForm/useValidation';

type EditorProps = ComponentProps<typeof Editor>;

export type HTMLEditorFieldProps = {
	name?: string;
	label?: string;
	defaultValue?: string;
	isRequired?: boolean;
	editorOptions?: EditorProps['init'];
	onChange?: (content: string) => void;
	onKeyDown?: EditorProps['onKeyDown'];
	onPaste?: EditorProps['onPaste'];
};

const HTMLEditorField = ({
	name,
	defaultValue,
	label,
	isRequired,
	editorOptions,
	onChange,
	onKeyDown,
	onPaste,
}: HTMLEditorFieldProps) => {
	const editorRef = useRef<TinyMCEEditor | null>(null);

	const { errorTree } = useValidation(name);

	const commitChanges = (value: string, editor: TinyMCEEditor) => {
		editorRef.current = editor;
		editor.save();
	};

	return (
		<FormField
			label={label}
			isRequired={isRequired}
			errors={flattenErrors(errorTree)}
		>
			<Editor
				apiKey={ENVIRONMENT_SHARED.tinyMce.apiKey}
				cloudChannel="7.2.1"
				initialValue={defaultValue}
				onInit={(evt, editor) => (editorRef.current = editor)}
				onEditorChange={commitChanges}
				textareaName={name}
				onKeyUp={() => {
					onChange?.(editorRef.current?.getContent() ?? '');
				}}
				onKeyDown={onKeyDown}
				onPaste={onPaste}
				init={{
					content_style: `body {
                        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
                            Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        font-size: 14px;
                    }`,
					promotion: false,
					menubar: false,
					branding: false,
					plugins: ['lists', 'link', 'code', 'autoresize'],
					statusbar: false,
					min_height: 200,
					max_height: 400,
					toolbar:
						'bold italic underline | bullist numlist | indent outdent | link unlink | removeformat code',
					valid_elements:
						'strong,em,span,a[href|target=_blank|rel|title],ul,ol,li,p,br',
					...editorOptions,
				}}
			/>
		</FormField>
	);
};

export default HTMLEditorField;
