import classNames from 'classnames';
import { memo } from 'react';
import { HotFile } from '../../api/DataManagementAPI';
import HotFileDetails from './HotFileDetails';

interface HotfileMonthAreaProps {
	files: Array<HotFile>;
	isDimmed?: boolean;
	isCompact?: boolean;
	isVisible?: boolean;
}

const HotfileMonthArea = ({
	files,
	isDimmed = false,
	isCompact = false,
	isVisible = true,
}: HotfileMonthAreaProps) => {
	return (
		<div
			className={classNames(
				'hotfilemonth__files',
				isDimmed && 'hotfilemonth__files--dimmed',

				// Need to do this instead of filtering because of Tooltip render performance
				!isVisible && 'hotfilemonth__files--hidden'
			)}
		>
			{files.map((file, fileIndex) => (
				<HotFileDetails key={fileIndex} compact={isCompact} file={file} />
			))}
		</div>
	);
};

export default memo(HotfileMonthArea);
