import {
	CheckCircle2,
	CircleDot,
	Loader2,
	LucideIcon,
	XCircle,
} from 'lucide-react';
import { ComponentProps } from 'react';
import { StepStatus } from './MultiStepProgress';

type MultiStepProgressStepIconProps = {
	status: StepStatus;
} & ComponentProps<LucideIcon>;

const MultiStepProgressStepIcon = ({
	status,
	...iconProps
}: MultiStepProgressStepIconProps) => {
	switch (status) {
		case StepStatus.Pending:
			return <CircleDot {...iconProps} />;
		case StepStatus.InProgress:
			return <Loader2 {...iconProps} />;
		case StepStatus.Completed:
			return <CheckCircle2 {...iconProps} />;
		case StepStatus.Error:
			return <XCircle {...iconProps} />;
	}
};

export default MultiStepProgressStepIcon;
