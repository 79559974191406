import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createUserSchema } from 'utils/schemas/userSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import AuditCommentField from 'ui/components/AuditCommentField';
import DateField from 'ui/components/DateField/DateField';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import CustomerPicker from '../../components/CustomerPicker';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const url = new URL(request.url);

	const userOptions = await UserAPI.getUserOptions(
		url.searchParams.get('customerId')
	);

	return {
		options: userOptions,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();
	const createUserModel = createUserSchema.parse(formDataAsObject(formData));

	const data = await UserAPI.createUser(createUserModel);

	if ('userId' in data) {
		createToast(ToastType.SUCCESS, 'User created successfully');
		return redirect(`/users/${data.userId}`);
	}

	return data;
};

export function CreateUser() {
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const prePopulatedCustomer = data.options.customerOptions[0];

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createUserSchema}>
				<Grid>
					<PageHeader title="Create New User">
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating User"
							type="submit"
						>
							Create User
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<CustomerPicker
									label="Customer *"
									name={'customerId'}
									initialValues={
										prePopulatedCustomer ? [prePopulatedCustomer] : undefined
									}
								/>
								<TextField label="Email" name="email" isRequired={true} />
								<TextField
									label="First Name"
									name="firstName"
									isRequired={true}
								/>

								<TextField
									label="Last Name"
									name="lastName"
									isRequired={true}
								/>
							</Grid>
							<Grid>
								<DateField
									label="Active from"
									name="activeFrom"
									isRequired={true}
									timezone="utc"
								/>
								<DateField
									label="Expires on"
									name="activeTo"
									isRequired={true}
									timezone="utc"
								/>

								<AuditCommentField />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_USER_ROUTE: DecentralizedRouteProps = {
	loader,
	action: action,
	element: <CreateUser />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
