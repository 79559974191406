import { Check, Trash2, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import DateField from 'ui/components/DateField/DateField';
import ErrorList from 'ui/components/ErrorList';
import FileField from 'ui/components/FileField';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import useSubmitting from 'utils/hooks/useSubmitting';
import { updateAdhocReportSchema } from 'utils/schemas/adhocReportSchema';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import AdhocReportsAPI from '../../api/AdhocReportsAPI';
import { UserOption } from '../../api/UserAPI';
import DeleteAdhocReportModal from '../../components/DeleteAdhocReportModal';

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	const searchParams = url.searchParams;
	const userId = searchParams.get('userId') as string;

	const reportDetails = await AdhocReportsAPI.getAdhocReport(
		params.reportId as string,
		searchParams
	);

	return { ...reportDetails, userId: userId };
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const reportId = params.reportId as string;
	const formData = await request.formData();
	const redirectUrl = await RedirectUrl(formData);

	if (request.method === 'DELETE') {
		await AdhocReportsAPI.deleteAdhocReport(reportId);

		createToast(ToastType.SUCCESS, 'Adhoc Report deleted successfully');

		return redirect(redirectUrl);
	}

	if (request.method === 'POST') {
		const updateModel = updateAdhocReportSchema.parse(
			formDataAsObject(formData)
		);

		const reportEntry = formData.get('reportFile') as File;

		const report = {
			fileName: reportEntry.name,
			length: reportEntry.size,
		};

		const data = await AdhocReportsAPI.updateAdhocReport(
			reportId,
			updateModel,
			report
		);

		if ('id' in data) {
			if (data.reportUrl !== '') {
				await AdhocReportsAPI.uploadFile(data.reportUrl, reportEntry);
			}

			createToast(ToastType.SUCCESS, 'Adhoc report updated successfully');

			return redirect(redirectUrl);
		}

		return data;
	}

	return null;
};

const AdhocReportEdit = () => {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const isSubmitting = useSubmitting();

	const defaultFile = new File([], data.file.originalName);
	Object.defineProperty(defaultFile, 'size', { value: data.file.fileLength });

	const cancelUrl =
		data.userId !== null
			? `/users/${data.userId}/reports`
			: `/customers/${data.customerId}/reports`;

	return (
		<div className="content">
			<ValidatedForm
				method="post"
				validator={updateAdhocReportSchema}
				encType="multipart/form-data"
			>
				<input type="hidden" name="customerId" value={data.customerId} />
				<input type="hidden" name="userId" value={data.userId} />
				<Grid>
					<PageHeader title={`Edit ${data.title}`}>
						<Button
							isDisabled={navigation.state !== 'idle'}
							variant="secondary"
							to={cancelUrl}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isDisabled={navigation.state !== 'idle'}
							type="button"
							variant="secondary"
							icon={Trash2}
							onClick={() => setDeleteModalOpen(true)}
							data-cy="adhocDeleteButton"
						>
							Delete
						</Button>
						<Button
							isLoading={isSubmitting}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					{isDeleteModalOpen && (
						<DeleteAdhocReportModal
							report={data}
							userId={data.userId}
							isOpen={isDeleteModalOpen}
							onClose={() => setDeleteModalOpen(false)}
						/>
					)}

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Title"
									name="title"
									defaultValue={data.title}
									isRequired={true}
								/>

								<FileField
									label="Report"
									name="reportFile"
									defaultValue={[defaultFile]}
									isRequired={true}
									isMultiple={false}
								/>
							</Grid>

							<Grid>
								<DateField
									label="Release Date"
									name="releaseDate"
									isRequired={false}
									initialValue={data.releaseDate}
									timezone="utc"
								/>
								<MultiSelectField<UserOption>
									label="Users"
									name="userIds"
									options={data.users}
									isRequired={true}
									placeholder="Please select..."
									contentSource={(option) => option.name}
									identifierKey="id"
									initialValues={data.selectedUsers}
									pillName={(item) => item.name}
								/>
								<CheckboxField
									name="notifyUsers"
									label="Notify Users"
									value="true"
									defaultChecked={data.notifyUsers}
								/>
								<CheckboxField
									name="isArchived"
									label="Is Archived"
									value="true"
									defaultChecked={data.isArchived}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
};

async function RedirectUrl(formData: FormData) {
	const customerId = formData.get('customerId') as string;
	const userId = formData.get('userId') as string;

	return userId !== null && userId !== ''
		? `/users/${userId}/reports`
		: `/customers/${customerId}/reports`;
}

export const ADHOC_REPORTS_EDIT_ROUTE: LayoutRouteProps = {
	loader: loader,
	action: action,
	element: <AdhocReportEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			if (data.userId) {
				return [
					{
						label: 'Users',
						path: '/users',
					},
					{
						label: `Customer Users`,
						path: `/customers/${data.customerId}`,
					},
					{
						label: data.user,
						path: `/users/${data.userId}`,
					},
					{
						label: 'Reports',
						path: `/users/${data.userId}/reports`,
					},
				];
			} else {
				return [
					{
						label: 'Customers',
						path: '/customers',
					},
					{
						label: data.customer,
						path: `/customers/${data.customerId}`,
					},
					{
						label: 'Reports',
						path: `/customers/${data.customerId}/reports`,
					},
				];
			}
		},
	},
};
