/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';
import { AccountTypeCode } from '../api/common';
import { zAuditable } from '../zod/zodValidation';

export const customerSchema = z.object({
	id: z.string(),
	customerNumber: z
		.string()
		.regex(
			/^$|CargoIS\d{5}$/,
			'Customer Number must be empty or in the format CargoIS99999'
		),
	name: z
		.string({ required_error: 'Name is required' })
		.min(3, { message: 'Name must be at least 3 characters' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	customerStatusId: z
		.string({ required_error: 'Customer status is required' })
		.min(1, 'Customer status cannot be empty'),
	customerTypeId: z
		.string({ required_error: 'Customer type is required' })
		.min(1, 'Customer type cannot be empty'),
	accountManagerId: z.string().optional(),
	countryCode: z.string().optional(),
	notes: z.string().max(1000, 'Notes must not be more than 1000 characters'),
	contacts: z
		.array(
			z.object({
				name: z
					.string()
					.min(3, { message: 'Name must be at least 3 characters' }),
				email: z.string(),
				phone: z.string(),
			})
		)
		.optional(),
	freightForwarders: z.array(z.string()).optional(),
	airlines: z.array(z.string()).optional(),
	version: z.number(), // Provided by the backend
});

export const salesforceCustomerSchema = z
	.object({
		salesForceId: z
			.string({ required_error: 'Salesforce ID is required' })
			.min(1, 'Salesforce ID cannot be empty'),
	})
	.merge(customerSchema);

export type CustomerModel = z.infer<typeof salesforceCustomerSchema>;
export type DemoCustomerModel = z.infer<typeof customerSchema>;

// CREATE CUSTOMER SCHEMA

export const createCustomerSchema = salesforceCustomerSchema
	.pick({
		name: true,
		customerNumber: true,
		customerStatusId: true,
		accountManagerId: true,
		countryCode: true,
		customerTypeId: true,
		salesForceId: true,
		contacts: true,
		notes: true,
	})
	.merge(zAuditable);

export const createDemoCustomerSchema = createCustomerSchema.omit({
	salesForceId: true,
});

export type CreateCustomerModel = z.infer<typeof createCustomerSchema>;
export type CreateDemoCustomerModel = z.infer<typeof createDemoCustomerSchema>;

export const createAirlineCustomerSchema = createCustomerSchema.extend(
	customerSchema.pick({
		airlines: true,
	}).shape
);

export const createFreightForwarderCustomerSchema = createCustomerSchema.extend(
	customerSchema.pick({
		freightForwarders: true,
	}).shape
);

export function pickCreateCustomerValidator(
	accountType: AccountTypeCode | unknown
) {
	switch (accountType) {
		case 'demo':
			return createDemoCustomerSchema;
		case 'airline':
			return createAirlineCustomerSchema;
		case 'freight-forwarder':
			return createFreightForwarderCustomerSchema;
		default:
			return createCustomerSchema;
	}
}

// UPDATE CUSTOMER SCHEMA

export const updateCustomerSchema = customerSchema
	.pick({
		name: true,
		customerStatusId: true,
		accountManagerId: true,
		countryCode: true,
		notes: true,
		contacts: true,
	})
	.merge(zAuditable);

export type UpdateCustomerModel = z.infer<typeof updateCustomerSchema>;

export const updateAirlineCustomerSchema = updateCustomerSchema.extend(
	customerSchema.pick({
		airlines: true,
	}).shape
);

export const updateFreightForwarderCustomerSchema = updateCustomerSchema.extend(
	customerSchema.pick({
		freightForwarders: true,
	}).shape
);

export function pickUpdateCustomerValidator(accountType: AccountTypeCode) {
	switch (accountType) {
		case 'airline':
			return updateAirlineCustomerSchema;
		case 'freight-forwarder':
			return updateFreightForwarderCustomerSchema;
		default:
			return updateCustomerSchema;
	}
}
