import { Download, Upload } from 'lucide-react';
import toast from 'react-hot-toast';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useNavigate,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import freightForwarderAPI from 'utils/api/FreightForwarderAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import * as freightForwarderColumns from '../../util/columns/freightForwarderColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	return await freightForwarderAPI.getFreightForwarderExports();
};

export function FreightForwarderIndexPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	let navigate = useNavigate();

	const handleClick = async () => {
		toast.success('Starting Export...');

		const rt = await freightForwarderAPI.createAndRunExport();

		toast.remove();

		if (rt instanceof Error) {
			toast.error('An error has occurred when starting the export');
		} else {
			navigate('/freight-forwarders');
		}
	};

	const columns = [
		freightForwarderColumns.statusColumn,
		freightForwarderColumns.createdAtColumn,
		freightForwarderColumns.startedAtColumn,
		freightForwarderColumns.completedAtColumn,
		freightForwarderColumns.exportFileNameColumn,
		freightForwarderColumns.titleColumn,
	];

	return (
		<>
			<div className="content">
				<PageHeader title="Freight Forwarders">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button variant="secondary" icon={Download} onClick={handleClick}>
						Export
					</Button>
					<Button variant="secondary" icon={Upload} to="upload">
						Upload
					</Button>
				</PageHeader>

				<Table identifierKey="id" columns={columns} data={data.exports.items} />

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.exports.page}
					pageParameterName="page"
					pageSize={data.exports.pageSize}
					itemCount={data.exports.totalCount}
				/>
			</div>
		</>
	);
}

export const MAIN_FREIGHT_FORWARDERS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <FreightForwarderIndexPage />,
};
