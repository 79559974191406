type TrafficLightProps = {
	greenCount: number;
	onGreenClick?: () => void;

	amberCount: number;
	onAmberClick?: () => void;

	redCount: number;
	onRedClick?: () => void;
};

const TrafficLight = ({
	greenCount,
	onGreenClick,
	amberCount,
	onAmberClick,
	redCount,
	onRedClick,
}: TrafficLightProps) => {
	return (
		<div className="traffic-light">
			<div
				className="traffic-light__item traffic-light__item--green"
				onClick={() => (onGreenClick ? onGreenClick() : () => {})}
			>
				{greenCount}
			</div>
			<div
				className="traffic-light__item traffic-light__item--amber"
				onClick={() => (onAmberClick ? onAmberClick() : () => {})}
			>
				{amberCount}
			</div>
			<div
				className="traffic-light__item traffic-light__item--red"
				onClick={() => (onRedClick ? onRedClick() : () => {})}
			>
				{redCount}
			</div>
		</div>
	);
};

export default TrafficLight;
