import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetCustomerResponse } from '../../api/CustomerAPI';

type DeleteCustomerModalProps = {
	customer: GetCustomerResponse;
} & Omit<ModalProps, 'title'>;

const DeleteCustomerModal = ({
	customer,
	...modalProps
}: DeleteCustomerModalProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete Customer"
			submitText="Delete Customer"
			submitIcon={Trash2}
			intent="danger"
			confirmationText={customer.name}
			formAction={`/customers/${customer.id}`}
			formMethod="delete"
			requiresAuditComment
			{...modalProps}
		>
			<p>
				Are you sure you wish to delete the customer <b>{customer.name}</b>?
				This action cannot be undone.
			</p>
		</ConfirmationModal>
	);
};

export default DeleteCustomerModal;
