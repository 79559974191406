import { addYears } from 'date-fns';
import { Save } from 'lucide-react';
import AuditCommentField from 'ui/components/AuditCommentField';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField';
import Flex from 'ui/components/Flex';
import FormField from 'ui/components/FormField';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import ValidatedForm from 'ui/components/ValidatedForm';
import pluralize from 'utils/helpers/pluralize';
import useSubmitting from 'utils/hooks/useSubmitting';
import {
	zArrayOfStringRequired,
	zAuditable,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import { z } from 'zod';
import { UserItem } from '../../api/UserAPI';

export const updateUserExpirySchema = z
	.object({
		expiryDate: z.preprocess(
			(arg) => {
				if (typeof arg === 'string' || arg instanceof Date) {
					const date = new Date(arg);
					if (isNaN(date.getTime())) return null;
					return date;
				} else return arg;
			},
			z.date(),
			{ required_error: 'Expiry Date is required' }
		),
		action: z.literal('updateExpiry'),
		userIds: z.preprocess(
			zEnsureArrayPreprocessor,
			zArrayOfStringRequired('At least 1 user is required')
		),
	})
	.merge(zAuditable);

export type UpdateUserExpiryModel = z.infer<typeof updateUserExpirySchema>;

interface UpdateUserExpiryModalProps extends Omit<ModalProps, 'title'> {
	users: Array<UserItem>;
}

const UpdateUserExpiryModal = ({
	users,
	...modalProps
}: UpdateUserExpiryModalProps) => {
	const isSubmitting = useSubmitting();

	return (
		<Modal title="Update User Access Expiry" {...modalProps}>
			<ValidatedForm
				method="POST"
				validator={updateUserExpirySchema}
				onSubmit={modalProps.onClose}
			>
				<Modal.Body>
					<Flex direction="column" gap={16}>
						<p>
							You are about to update the access expiry date for {users.length}{' '}
							{pluralize('user', users.length)}.
						</p>
						<FormField label={`Affected Users (${users.length})`}>
							<b>
								{users.map((user, userIndex) => (
									<span key={user.id}>
										{user.firstName} {user.lastName}
										{userIndex < users.length - 1 ? ', ' : ''}
									</span>
								))}
							</b>
						</FormField>
						<DateField
							label="Expiry Date"
							name="expiryDate"
							timezone="utc"
							initialValue={addYears(
								new Date(
									Date.UTC(
										new Date().getUTCFullYear(),
										new Date().getUTCMonth(),
										new Date().getUTCDate()
									)
								),
								1
							)}
						/>
						<input type="hidden" name="action" value="updateExpiry" />
						{users.map((user) => (
							<input
								key={user.id}
								type="hidden"
								name="userIds"
								value={user.id}
							/>
						))}
						<AuditCommentField />
					</Flex>
				</Modal.Body>
				<Modal.Actions alignment="between">
					<Button variant="secondary" onClick={modalProps.onClose}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="primary"
						isLoading={isSubmitting}
						icon={Save}
					>
						Update Access Expiry
					</Button>
				</Modal.Actions>
			</ValidatedForm>
		</Modal>
	);
};

export default UpdateUserExpiryModal;
