import { PlusCircle } from 'lucide-react';
import React from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useLoaderData,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import AdminUserAPI from '../../api/AdminUserAPI';
import MonitoringAPI from '../../api/MonitoringAPI';
import * as notificationsColumns from '../../util/columns/notificationsColumns';
import Table from 'ui/components/Table/Table';
import Flex from 'ui/components/Flex';
import TabActions from 'ui/components/Tabs/TabActions';

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		await AdminUserAPI.deleteAdminUser(params.userId as string);
		createToast(ToastType.SUCCESS, 'Admin user deleted successfully');
		return redirect('/admin-users');
	}

	return null;
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const userId = params.userId as string;

	const userData = await AdminUserAPI.getAdminUser(userId);
	const notifications = await MonitoringAPI.getNotifications(userId);

	return {
		user: userData,
		notifications: notifications.results,
	};
};

export function UserDetails() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		notificationsColumns.nameColumn,
		notificationsColumns.typeColumn,
		notificationsColumns.scheduleColumn,
		notificationsColumns.lastExecutionColumn,
		notificationsColumns.nextExecutionColumn,
		notificationsColumns.createdAtColumn,
		notificationsColumns.updatedAtColumn,
		notificationsColumns.editButtonColumn,
	];

	return (
		<Flex direction="column" gap={16}>
			{data.notifications.totalCount === 0 && (
				<div>There are currently no System Notifications to show</div>
			)}
			{data.notifications.totalCount !== 0 && (
				<Table
					identifierKey="id"
					columns={columns}
					data={data.notifications.items}
				/>
			)}

			<TabActions>
				<Button
					variant="secondary"
					size="small"
					to={`/system-notifications/create?adminId=${data.user.id}`}
					icon={PlusCircle}
				>
					Add Notification
				</Button>
			</TabActions>
		</Flex>
	);
}

export const ADMIN_USER_DETAILS_NOTIFICATIONS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <UserDetails />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return { label: `${data.user.name}` };
		},
	},
};
