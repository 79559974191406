import { PropsWithChildren, useEffect, useId, useState } from 'react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import { zAuditable } from 'utils/zod/zodValidation';
import AuditCommentField from '../AuditCommentField';
import ValidatedForm from '../ValidatedForm';

type AuditModalProps = PropsWithChildren<{
	onClose: (comment?: string) => void;
}> &
	Omit<ModalProps, 'onClose'>;

const AuditModal = ({ children, ...modalProps }: AuditModalProps) => {
	const formId = useId();
	const [auditComment, setAuditComment] = useState('');

	useEffect(() => {
		if (modalProps.isOpen) {
			setAuditComment('');
		}
	}, [modalProps.isOpen]);

	return (
		<Modal {...modalProps}>
			<Modal.Body>
				<ValidatedForm
					id={formId}
					validator={zAuditable}
					onSubmit={(e) => {
						e.preventDefault();
						const formData = new FormData(e.target as HTMLFormElement);
						const auditComment = formData.get('auditComment') as string;
						modalProps.onClose(auditComment);
					}}
				>
					<AuditCommentField
						value={auditComment}
						onChange={(e) => setAuditComment(e.target.value)}
					/>
				</ValidatedForm>
			</Modal.Body>
			<Modal.Actions>
				<Button
					type="button"
					variant="secondary"
					onClick={() => modalProps.onClose()}
				>
					Cancel
				</Button>
				<Button type="submit" variant="primary" form={formId}>
					Confirm
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default AuditModal;
