import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import AuditCommentField from 'ui/components/AuditCommentField';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextAreaField from 'ui/components/TextAreaField';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { pickCreateCustomerValidator } from 'utils/schemas/customerSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import CountryPicker from 'ui/components/CountryPicker';
import { ToastType } from 'ui/components/Toaster/Toast';
import { AccountTypeCode, CustomerTypeOption } from 'utils/api/common';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import CustomerAPI from '../../api/CustomerAPI';
import ContactManager from '../../components/ContactManager';
import { DropdownField } from '../../forms/DropdownField';
import { CustomerTypeFields } from './CustomerTypeFields';
import SalesforceIdPicker from './SalesforceIdPicker';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const customerOptions = await CustomerAPI.getCustomerOptions();

	return {
		customerOptions,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();
	const createCustomerValidator = pickCreateCustomerValidator(
		formData.get('accountType') as AccountTypeCode
	);
	const createCustomerModel = createCustomerValidator.parse(
		formDataAsObject(formData)
	);

	const data = await CustomerAPI.createCustomer(createCustomerModel);

	if ('customerId' in data) {
		createToast(ToastType.SUCCESS, 'Customer created successfully');
		return redirect(`/customers/${data.customerId}`);
	}

	return data;
};

export function CustomerCreate() {
	const [customerType, setCustomerType] = useState<CustomerTypeOption | null>(
		null
	);

	const [displaySalesforceId, setDisplaySalesforceId] = useState(true);
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();

	const data = useLoaderData() as LoaderData<typeof loader>;

	const createCustomerValidator = pickCreateCustomerValidator(
		customerType?.accountType
	);

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createCustomerValidator}>
				<input
					type="hidden"
					name="accountType"
					value={customerType?.accountType}
				/>
				<Grid>
					<PageHeader title="Create New Customer">
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating customer"
							type="submit"
						>
							Create customer
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<DropdownField<CustomerTypeOption>
									label="Customer Type"
									name="customerTypeId"
									isRequired
									onOptionSelected={(newValue) => {
										setCustomerType(newValue);
										setDisplaySalesforceId(newValue?.accountType !== 'demo');
									}}
									options={data.customerOptions.customerTypeOptions}
									identifierKey="id"
									contentSource={(customer) => customer.name}
									isClearable={false}
								/>

								{displaySalesforceId && (
									<SalesforceIdPicker
										name="salesForceId"
										options={data.customerOptions.salesforceIdOptions}
									/>
								)}

								<TextField
									label="Name"
									name="name"
									defaultValue={actionData?.name}
									isRequired
								/>
								<TextField
									label="Customer Number"
									name="customerNumber"
									placeholder="CargoIS12345"
								/>
								<DropdownField
									label="Customer Status"
									name="customerStatusId"
									isRequired
									placeholder="Please select..."
									options={data.customerOptions.customerStatusOptions}
									identifierKey="value"
									contentSource={(data) => data.label}
									isClearable={false}
								/>
							</Grid>
							<Grid>
								<DropdownField
									label="Account Manager"
									name="accountManagerId"
									placeholder="Please select..."
									options={data.customerOptions.accountManagerOptions}
									identifierKey="value"
									contentSource={(data) => data.label}
									typeaheadSource={(data) => data.label}
									isClearable={false}
								/>
								<CountryPicker name="countryCode" label="Country" />
								<TextAreaField
									label="Notes"
									placeholder="Notes..."
									name="notes"
								/>

								<CustomerTypeFields customerType={customerType} />

								<ContactManager name="contacts" />

								<AuditCommentField />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_CUSTOMER_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <CustomerCreate />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
