import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import TrafficLight from 'ui/components/TrafficLight';
import { CustomerItem } from '../../api/CustomerAPI';

const columnHelper = createColumnHelper<CustomerItem>();

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/customers/${info.row.original.id}`}>{info.getValue()}</Link>
	),
	enableSorting: true,
});

export const idColumn = columnHelper.accessor('customerNumber', {
	header: 'Customer Number',
	cell: (info) => (
		<Link to={`/customers/${info.row.original.id}`}>{info.getValue()}</Link>
	),
	enableSorting: true,
});

export const salesForceIdColumn = columnHelper.accessor('salesforceId', {
	header: 'Salesforce ID',
});

export const typeColumn = columnHelper.accessor('customerTypeName', {
	header: 'Customer Type',
});

export const statusColumn = columnHelper.accessor('customerStatus', {
	header: 'Customer Status',
	cell: (info) => (
		<BulletIndicator
			intent={
				info.getValue().name.toLocaleLowerCase() === 'active'
					? 'success'
					: 'error'
			}
			label={info.getValue().name}
		/>
	),
});

export const accountManagerColumn = columnHelper.accessor(
	'accountManagerName',
	{
		header: 'Account Manager',
	}
);

export const countryColumn = columnHelper.accessor('countryName', {
	header: 'Country',
});

export const regionColumn = columnHelper.accessor('regionName', {
	header: 'CargoIS Region',
});

function SubscriptionCountsColumnDisplay(
	info: CellContext<CustomerItem, unknown>
) {
	const navigate = useNavigate();
	return (
		<TrafficLight
			onGreenClick={() =>
				navigate(`/customers/${info.row.id}/subscriptions?show=active`)
			}
			onAmberClick={() =>
				navigate(`/customers/${info.row.id}/subscriptions?show=expiring`)
			}
			onRedClick={() =>
				navigate(`/customers/${info.row.id}/subscriptions?show=expired`)
			}
			greenCount={info.row.original.subscriptionCount.active}
			amberCount={info.row.original.subscriptionCount.expiring}
			redCount={info.row.original.subscriptionCount.expired}
		/>
	);
}

function UserCountsColumnDisplay(info: CellContext<CustomerItem, unknown>) {
	const navigate = useNavigate();
	return (
		<TrafficLight
			onGreenClick={() => navigate(`/customers/${info.row.id}/?show=active`)}
			onAmberClick={() => navigate(`/customers/${info.row.id}/?show=expiring`)}
			onRedClick={() => navigate(`/customers/${info.row.id}/?show=expired`)}
			greenCount={info.row.original.userCount.active}
			amberCount={info.row.original.userCount.expiring}
			redCount={info.row.original.userCount.expired}
		/>
	);
}

export const subscriptionsColumn = columnHelper.display({
	header: 'Subscriptions',
	meta: {
		contentAlignment: 'center',
	},
	cell: SubscriptionCountsColumnDisplay,
});

export const usersColumn = columnHelper.display({
	header: 'Users',
	meta: {
		contentAlignment: 'center',
	},
	cell: UserCountsColumnDisplay,
});
