import { Check, X } from 'lucide-react';
import {
	ActionFunction,
	LayoutRouteProps,
	redirect,
	useActionData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import FileField from 'ui/components/FileField';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import FreightForwarderAPI from 'utils/api/FreightForwarderAPI';
import APIError from 'utils/errors/APIError';
import { createToast } from 'utils/helpers/toast';
import { ActionData } from 'utils/types/loaderData';

function createFormError(property: string, message: string) {
	return new APIError('Form Validation Failed', 400, {
		errors: {
			[property]: message,
		},
	});
}

export const action: ActionFunction = async ({ request }) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const fileEntry = formData.get('filename') as File;

		if (!fileEntry?.name) {
			return createFormError('filename', 'Please select a file to upload');
		}

		const linkResponse = await FreightForwarderAPI.generateUploadLink(
			fileEntry.name
		);

		if ('uploadLink' in linkResponse) {
			const uploadResponse = await FreightForwarderAPI.uploadFile(
				linkResponse.uploadLink,
				fileEntry
			);

			if (uploadResponse.ok) {
				createToast(
					ToastType.SUCCESS,
					'File uploaded - processing will begin shortly...'
				);
				return redirect('/freight-forwarders');
			}

			return createFormError(
				'File Upload Error',
				'An unexpected problem occurred while uploading the file, please try again. If the problem persists, please contact support.'
			);
		}

		return linkResponse;
	}
};

export function FreightForwarderUploadPage() {
	const navigation = useNavigation();
	const actionData = useActionData() as ActionData<typeof action>;

	return (
		<>
			<div className="content">
				<ValidatedForm method="post" encType="multipart/form-data">
					<Grid>
						<PageHeader title="Freight Forwarders">
							<Button variant="secondary" to={`/freight-forwarders`} icon={X}>
								Cancel
							</Button>
							<Button
								isLoading={navigation.state !== 'idle' && !!navigation.formData}
								loadingText="Uploading File"
								type="submit"
								icon={Check}
							>
								Upload
							</Button>
						</PageHeader>
						<Grid>
							<ErrorList error={actionData} />
							<FileField
								label="Upload"
								name="filename"
								allowedExtensions={['xlsx']}
							/>
						</Grid>
					</Grid>
				</ValidatedForm>
			</div>
		</>
	);
}

export const UPLOAD_FREIGHT_FORWARDERS_ROUTE: LayoutRouteProps = {
	action,
	element: <FreightForwarderUploadPage />,
	handle: {
		breadcrumbs: { label: 'Upload' },
	},
};
