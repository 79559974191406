import { ColumnDef } from '@tanstack/react-table';
import { Check, Settings2, X } from 'lucide-react';
import { useEffect, useId, useMemo } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import AuditCommentField from 'ui/components/AuditCommentField';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import ValidatedForm from 'ui/components/ValidatedForm';
import * as genericColumns from 'utils/columns/genericColumns';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import useSubmitting from 'utils/hooks/useSubmitting';
import useToggle from 'utils/hooks/useToggle';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { zAuditable } from 'utils/zod/zodValidation';
import SubscriptionAPI, { AvailableUserItem } from '../../api/SubscriptionAPI';
import * as userColumns from '../../util/columns/userColumns';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	let searchParams = new URL(request.url).searchParams;

	const subscriptionId = params.subscriptionId as string;

	const availableUsersData =
		await SubscriptionAPI.getAvailableUsersForSubscription(
			subscriptionId,
			searchParams
		);

	return {
		availableUsersData,
	};
};

const action = async ({ params, request }: ActionFunctionArgs) => {
	const subscriptionId = params.subscriptionId as string;
	const formData = await request.formData();

	const auditComment = formData.get('auditComment') as string;
	const userIds = formData.getAll('selectedRows') as string[];
	await SubscriptionAPI.updateUsersForSubscription(
		subscriptionId,
		userIds,
		auditComment
	);

	return null;
};

const SubscriptionUsers = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const formId = useId();
	const isSubmitting = useSubmitting();
	const [isEditing, toggleEditing] = useToggle(false);

	useEffect(() => {
		if (!isSubmitting && isEditing) {
			toggleEditing();
		}
	}, [isSubmitting]);

	const users = isEditing
		? data.availableUsersData.users.items
		: data.availableUsersData.users.items.filter((s) => s.isSubscribed);

	const initialSelectedIds = useMemo(
		() =>
			data.availableUsersData.users.items
				.filter((s) => s.isSubscribed)
				.map((s) => s.id),
		[data.availableUsersData.users.items]
	);

	const columns = [
		isEditing && genericColumns.selectColumn,
		userColumns.fullNameColumn,
		userColumns.emailColumn,
		userColumns.activeFromColumn,
		userColumns.activeToColumn,
		userColumns.subscriptionsColumn,
	];

	return (
		<>
			<Flex direction="column" gap={16}>
				<ValidatedForm id={formId} method="post" validator={zAuditable}>
					<Flex direction="column" gap={16}>
						{isEditing && <AuditCommentField />}

						<Table
							identifierKey="id"
							data={users}
							initialSelection={initialSelectedIds}
							columns={columns as ColumnDef<AvailableUserItem, any>[]}
							emptyText="No users found"
						/>
					</Flex>
				</ValidatedForm>

				<TabActions>
					{isEditing ? (
						<>
							<Button
								variant="secondary"
								size="small"
								onClick={toggleEditing}
								icon={X}
								type="button"
								isDisabled={isSubmitting}
							>
								Cancel
							</Button>
							<Button
								variant="primary"
								size="small"
								icon={Check}
								type="submit"
								isLoading={isSubmitting}
								loadingText="Saving"
								form={formId}
							>
								Save changes
							</Button>
						</>
					) : (
						<>
							<Button
								variant="secondary"
								size="small"
								onClick={toggleEditing}
								icon={Settings2}
								type="button"
							>
								Manage users
							</Button>
						</>
					)}
				</TabActions>

				{data.availableUsersData.users.totalCount >
					data.availableUsersData.users.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.availableUsersData.users.page}
						pageParameterName="page"
						pageSize={data.availableUsersData.users.pageSize}
						itemCount={data.availableUsersData.users.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const SUBSCRIPTION_DETAILS_USERS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <SubscriptionUsers />,
};
