import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetUserResponse } from '../../api/UserAPI';

type DeleteUserModalProps = {
	user: GetUserResponse;
} & Omit<ModalProps, 'title'>;

const DeleteUserModal = ({ user, ...modalProps }: DeleteUserModalProps) => {
	const fullUserName = user.firstName + ' ' + user.lastName;

	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete User"
			submitText="Delete User"
			submitIcon={Trash2}
			confirmationText={fullUserName}
			intent="danger"
			formAction={`/users/${user.id}`}
			formMethod="delete"
			requiresAuditComment
			{...modalProps}
		>
			<p>
				Are you sure you wish to delete the user <b>{fullUserName}</b>? This
				action cannot be undone.
			</p>
		</ConfirmationModal>
	);
};

export default DeleteUserModal;
