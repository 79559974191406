import { Pencil, Trash2 } from 'lucide-react';
import React, { useState } from 'react';
import {
	ActionFunctionArgs,
	isRouteErrorResponse,
	Link,
	LoaderFunctionArgs,
	redirect,
	useLoaderData,
	useRouteError,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import AdminUserAPI from '../../api/AdminUserAPI';
import DeleteAdminUserModal from '../../components/DeleteAdminUserModal';
import Grid from 'ui/components/Grid';
import Tabs from 'ui/components/Tabs';

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		await AdminUserAPI.deleteAdminUser(params.userId as string);
		createToast(ToastType.SUCCESS, 'Admin user deleted successfully');
		return redirect('/admin-users');
	}

	return null;
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const userId = params.userId as string;

	const userData = await AdminUserAPI.getAdminUser(userId);

	return {
		user: userData,
	};
};

export function UserDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	return (
		<div className="content">
			<PageHeader title={`${data.user.name}`}>
				<Button
					variant="secondary"
					icon={Trash2}
					onClick={() => setDeleteModalOpen(true)}
					data-cy="userDetailsDeleteButton"
				>
					Delete
				</Button>
				<Button
					variant="secondary"
					icon={Pencil}
					to={`/admin-users/${data.user.id}/edit`}
				>
					Edit
				</Button>
			</PageHeader>

			<DeleteAdminUserModal
				user={data.user}
				isOpen={isDeleteModalOpen}
				onClose={() => setDeleteModalOpen(false)}
			/>

			<KeyValuePairs
				entries={[
					{ key: 'Name', value: data.user.name },
					{ key: 'Email', value: data.user.email },
					{ key: 'Role', value: data.user.adminRole.name },
				]}
			/>
			<Grid columns="2fr 1fr">
				<Tabs
					baseUrl={`/admin-users/${data.user.id}`}
					tabs={[{ name: 'Notifications', to: '' }]}
				/>
			</Grid>
		</div>
	);
}

export function UserErrorBoundary() {
	const error = useRouteError();

	// re-throw to let the RootErrorBoundary handle it if it's a route error
	if (!isRouteErrorResponse(error)) {
		throw error;
	}

	return (
		<>
			<h1>User could not be found</h1>
			<p>
				Go back to the <Link to="./">list of users</Link>
			</p>
		</>
	);
}

export const ADMIN_USER_DETAILS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <UserDetails />,
	errorElement: <UserErrorBoundary />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return { label: `${data.user.name}` };
		},
	},
};
