import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import CustomerAPI from '../../api/CustomerAPI';

import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import * as customerColumns from '../../util/columns/customerColumns';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const accountManagerId = params.accountManagerId as string;

	const customersData = await CustomerAPI.getCustomers({
		accountManagers: [accountManagerId],
	});

	return {
		customersData: customersData,
	};
};

const AccountManagerCustomers = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		customerColumns.nameColumn,
		customerColumns.idColumn,
		customerColumns.salesForceIdColumn,
		customerColumns.typeColumn,
		customerColumns.statusColumn,
		customerColumns.accountManagerColumn,
		customerColumns.countryColumn,
		customerColumns.regionColumn,
		customerColumns.subscriptionsColumn,
		customerColumns.usersColumn,
	];

	return (
		<>
			<Table
				identifierKey="id"
				columns={columns}
				data={data.customersData.customers.items}
			/>

			<TabActions></TabActions>
		</>
	);
};

export const ACCOUNT_MANAGER_DETAILS_CUSTOMERS_ROUTE: DecentralizedRouteProps =
	{
		loader,
		id: 'account-manager-customers',
		element: <AccountManagerCustomers />,
	};
