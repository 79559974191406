import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { updateUserSchema } from 'utils/schemas/userSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import AuditCommentField from 'ui/components/AuditCommentField';
import DateField from 'ui/components/DateField/DateField';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const userData = await UserAPI.getUser(params.userId as string);

	return {
		user: userData,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const userId = params.userId as string;
	const formData = await request.formData();

	const updateUserModel = updateUserSchema.parse(formDataAsObject(formData));

	const data = await UserAPI.updateUser(userId, updateUserModel);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'User updated successfully');
	return redirect(`/users/${data.id}`);
};

export function UserEdit() {
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateUserSchema}>
				<Grid>
					<PageHeader
						title={`Edit ${data.user.firstName} ${data.user.lastName}`}
					>
						<Button variant="secondary" to={`/users/${data.user.id}`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="First Name"
									name="firstName"
									isRequired={true}
									defaultValue={data.user.firstName}
								/>

								<TextField
									label="Last Name"
									name="lastName"
									isRequired={true}
									defaultValue={data.user.lastName}
								/>
							</Grid>
							<Grid>
								<DateField
									label="Active from"
									name="activeFrom"
									isRequired={true}
									initialValue={data.user.activeFrom}
									timezone="utc"
								/>

								<DateField
									label="Expires on"
									name="activeTo"
									isRequired={true}
									initialValue={data.user.activeTo}
									timezone="utc"
								/>

								<AuditCommentField />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_USER_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <UserEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return { label: `Edit: ${data.user.firstName} (${data.user.lastName})` };
		},
	},
};
