import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'ui/components/Flex';
import { LocationNaturalKeyList } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import Toggle from 'ui/components/Toggle';
import TrafficLight from 'ui/components/TrafficLight';
import SubscriptionAPI, { MonthlyReportItem } from '../../api/SubscriptionAPI';

const columnHelper = createColumnHelper<MonthlyReportItem>();

const DeliveryToggle = ({
	subscriptionId,
	deliveryEnabled,
}: {
	subscriptionId: string;
	deliveryEnabled: boolean;
}) => {
	const [isChecked, setChecked] = useState(deliveryEnabled);
	const [isLoading, setLoading] = useState(false);
	const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoading(true);

		await SubscriptionAPI.toggleDeliveryStatus(subscriptionId);

		setLoading(false);
		setChecked(!isChecked);
	};

	return (
		<Flex alignItems="center">
			<Toggle
				isChecked={isChecked}
				loading={isLoading}
				onChange={handleChange}
			/>
		</Flex>
	);
};

export const deliveryStatusColumn = columnHelper.accessor('deliveryEnabled', {
	header: '',
	cell: (info) => (
		<DeliveryToggle
			subscriptionId={info.row.original.id}
			deliveryEnabled={info.getValue()}
		/>
	),
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Subscription',
	cell: (info) => (
		<Link to={`/subscriptions/${info.row.original.id}`}>
			{info.row.original.name || info.row.original.productName} (
			{info.row.original.subscriptionNumber})
		</Link>
	),
	enableSorting: true,
});

export const customerColumn = columnHelper.accessor('customer', {
	header: 'Customer',
	cell: (info) => (
		<Link to={`/customers/${info.row.original.customerId}`}>
			{info.getValue()} ({info.row.original.customerNumber})
		</Link>
	),
});

export const customerStatusColumn = columnHelper.accessor('customerStatus', {
	header: 'Customer Status',
});

export const productNameColumn = columnHelper.accessor('productName', {
	header: 'Product',
});

export const startPeriodColumn = columnHelper.accessor('startPeriod', {
	header: 'Start Period',
	cell: ({ getValue }) =>
		`${getValue().month.toString().padStart(2, '0')}/${getValue().year}`,
	enableSorting: true,
});

export const endPeriodColumn = columnHelper.accessor('endPeriod', {
	header: 'End Period',
	cell: ({ getValue }) =>
		`${getValue().month.toString().padStart(2, '0')}/${getValue().year}`,
	enableSorting: true,
});

export const licensesColumn = columnHelper.accessor('numberOfLicenses', {
	header: 'Licenses',
});

export const originsColumn = columnHelper.display({
	id: 'origins',
	header: 'Origin',
	cell: (info) => {
		const data = info.row.original;
		if (!data.origin) return '-';
		return (
			<>
				<LocationNaturalKeyList locations={data.origin} />
				{data.origin2 && <LocationNaturalKeyList locations={data.origin2} />}
				{data.destination2 && !data.origin2 && <div>World</div>}
				{data.origin3 && <LocationNaturalKeyList locations={data.origin3} />}
				{data.destination3 && !data.origin3 && <div>World</div>}
			</>
		);
	},
});

export const destinationsColumn = columnHelper.display({
	id: 'destinations',
	header: 'Destination',
	cell: (info) => {
		const data = info.row.original;
		if (!data.destination) return '-';
		return (
			<>
				<LocationNaturalKeyList locations={data.destination} />
				{data.destination2 && (
					<LocationNaturalKeyList locations={data.destination2} />
				)}
				{data.origin2 && !data.destination2 && <div>World</div>}
				{data.destination3 && (
					<LocationNaturalKeyList locations={data.destination3} />
				)}
				{data.origin3 && !data.destination3 && <div>World</div>}
			</>
		);
	},
});

function UserCountsColumnDisplay(
	info: CellContext<MonthlyReportItem, unknown>
) {
	const navigate = useNavigate();
	return (
		<TrafficLight
			onGreenClick={() => navigate(`/subscriptions/${info.row.id}?show=active`)}
			onAmberClick={() =>
				navigate(`/subscriptions/${info.row.id}?show=expiring`)
			}
			onRedClick={() => navigate(`/subscriptions/${info.row.id}?show=expired`)}
			greenCount={info.row.original.userCount.active}
			amberCount={info.row.original.userCount.expiring}
			redCount={info.row.original.userCount.expired}
		/>
	);
}

export const usersColumn = columnHelper.display({
	id: 'users',
	header: 'Users',
	cell: UserCountsColumnDisplay,
});
