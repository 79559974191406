import NotificationToggle from './NotificationToggle';
import Flex from 'ui/components/Flex/Flex';
import React from 'react';
import { NotificationTypeAndOptionItem } from '../../api/MonitoringAPI';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import { flattenErrors } from 'utils/zod/zodErrors';

export type NotificationSelectorProps = {
	types: NotificationTypeAndOptionItem[];
};

const NotificationSelector = ({ types }: NotificationSelectorProps) => {
	const validation = useValidation('notifications');
	const flattened = flattenErrors(validation.errorTree);

	return (
		<Flex direction="column" gap={16}>
			<Flex alignItems="center" justifyContent="space-between">
				<b>Notifications *</b>
				<b>Options</b>
			</Flex>
			{types.map((notificationType, index) => {
				return (
					<Flex
						key={notificationType.notificationType.value}
						alignItems="center"
						justifyContent="space-between"
					>
						<NotificationToggle
							index={index}
							notificationType={notificationType}
						></NotificationToggle>
					</Flex>
				);
			})}
			{flattened.map((error) => (
				<div className="control__error" key={error}>
					{error}
				</div>
			))}
		</Flex>
	);
};

export default NotificationSelector;
