import { X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createAdminUserSchema } from 'utils/schemas/adminUserSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import { ToastType } from 'ui/components/Toaster/Toast';
import { AdminRoleOption } from 'utils/api/common';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import AdminUserAPI from '../../api/AdminUserAPI';
import { DropdownField } from '../../forms/DropdownField';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const adminRoles = await AdminUserAPI.getAdminOptions();

	return {
		roles: adminRoles.adminRoles,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createUserModel = createAdminUserSchema.parse(
			formDataAsObject(formData)
		);

		const data = await AdminUserAPI.createAdminUser(createUserModel);

		if ('userAdminId' in data) {
			createToast(ToastType.SUCCESS, 'Admin user created successfully');
			return redirect(`/admin-users/${data.userAdminId}`);
		}

		return data;
	}

	return null;
};

export function CreatedAdminUser() {
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createAdminUserSchema}>
				<Grid>
					<PageHeader title="Create New Admin User">
						<Button variant="secondary" to={`/admin-users`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Admin User"
							type="submit"
						>
							Create Admin User
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField label="Name" name="name" isRequired={true} />

								<TextField label="Email" name="email" isRequired={true} />

								<DropdownField<AdminRoleOption>
									label="Role"
									name="adminRole"
									options={data.roles}
									placeholder="Please select..."
									contentSource={(option) => option.name}
									identifierKey="value"
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_ADMIN_USER_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <CreatedAdminUser />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
