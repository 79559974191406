import { createColumnHelper } from '@tanstack/react-table';
import { WebToolReportRunResult } from 'iata-cargois-client-portal/src/api/ReportHubAPI';
import WorksheetScheduleHistoryModal from 'iata-cargois-client-portal/src/components/WorksheetScheduleModal/WorksheetScheduleHIstoryModal';
import { Calendar } from 'lucide-react';
import { useState } from 'react';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button/Button';
import DateFragment from 'ui/components/DateFragment';
import Flex from 'ui/components/Flex/Flex';
import WebToolStatusIndicator from 'ui/components/WebToolStatusIndicator/WebToolStatusIndicator';
import webToolAPI, { SchedulesDataRow } from 'utils/api/WebToolAPI';

export const loadHistory = async (id: string) => {
	const data = await webToolAPI.getScheduledWebToolHistory(id);

	return data.history;
};

const columnHelper = createColumnHelper<SchedulesDataRow>();

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
});

export const scheduleColumn = columnHelper.accessor('schedule', {
	header: 'Frequency',
	cell: (info) => {
		const schedule = info.row.original.schedule;

		return schedule.frequencyDisplay;
	},
});

export const lastExecutionStatusColumn = columnHelper.accessor(
	'lastExecutionStatus',
	{
		header: 'Last Execution Status',
		cell: ({ row }) => {
			if (row.original.lastExecutionStatus === null) return '–';

			return (
				<WebToolStatusIndicator
					intent={row.original.lastExecutionStatus.value}
					label={row.original.lastExecutionStatus.label}
				/>
			);
		},
	}
);

export const lastExecutionColumn = columnHelper.accessor('lastExecution', {
	header: 'Last Execution',
	cell: (info) => <DateFragment includeTime={true} date={info.getValue()} />,
});

export const nextExecutionColumn = columnHelper.accessor('nextExecution', {
	header: 'Next Execution',
	cell: (info) => {
		if (info.row.original.suspended) {
			return (
				<BulletIndicator
					intent={'warning'}
					label="Suspended"
					toolTip={info.row.original.suspendedMessage}
				/>
			);
		}
		return <DateFragment includeTime={true} date={info.getValue()} />;
	},
});

export const userColumn = columnHelper.accessor('user', {
	header: 'User',
});

export const customerColumn = columnHelper.accessor('customer', {
	header: 'Customer',
});

const WebToolScheduleRowActions = ({ row }: { row: SchedulesDataRow }) => {
	let defaultHistory: WebToolReportRunResult[] = [];
	let [historyItems, setHistory] = useState(defaultHistory);
	const [isHistoryOpen, setIsHistoryOpen] = useState(false);

	return (
		<>
			<Flex gap={8}>
				<Button
					size="small"
					variant="secondary"
					icon={Calendar}
					onClick={async () => {
						const data = await loadHistory(row.id);

						setHistory(data);
						setIsHistoryOpen(true);
					}}
					data-testid="viewHistoryButton"
				>
					{'View History'}
				</Button>
			</Flex>
			<WorksheetScheduleHistoryModal
				isOpen={isHistoryOpen}
				onClose={() => setIsHistoryOpen(false)}
				worksheetId={row.id}
				getDownloadUrl={(id) => `/web-tool-schedules/${id}/download`}
				history={historyItems}
			/>
		</>
	);
};

export const actionsColumn = columnHelper.display({
	id: 'actions',
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => {
		return <WebToolScheduleRowActions row={info.row.original} />;
	},
});
