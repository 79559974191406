import { Check, Copy, Trash2, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import HTMLEditorField from 'ui/components/HTMLEditorField';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { updateWebAnnouncementSchema } from 'utils/schemas/webAnnouncementSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import DateField from 'ui/components/DateField/DateField';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import WebAnnouncementsAPI from '../../api/WebAnnouncementsAPI';
import CustomerTypePicker from '../../components/CustomerTypePicker/CustomerTypePicker';
import DeleteWebAnnouncementModal from '../../components/DeleteWebAnnouncementModal';
import ProductPicker from '../../components/ProductPicker/ProductPicker';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const announcement = await WebAnnouncementsAPI.getAnnouncement(
		params.id as string
	);
	const options = await WebAnnouncementsAPI.getAnnouncementOptions();
	return {
		webAnnouncement: announcement,
		productOptions: options.productOptions,
		customerTypeOptions: options.customerTypeOptions,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		createToast(ToastType.SUCCESS, 'Web announcement deleted successfully');
		return redirect('/web-announcements');
	}

	if (request.method === 'POST') {
		const formData = await request.formData();
		const id = params.id as string;
		const updateModel = updateWebAnnouncementSchema.parse(
			formDataAsObject(formData)
		);

		const data = await WebAnnouncementsAPI.updateWebAnnouncement(
			id,
			updateModel
		);

		if (data instanceof Error) return data;

		createToast(ToastType.SUCCESS, 'Web announcement updated successfully');
		return redirect(`/web-announcements/${id}`);
	}

	return null;
};

export function CreateWebAnnouncement() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateWebAnnouncementSchema}>
				<Grid>
					<PageHeader title={`Edit ${data.webAnnouncement.title}`}>
						<Button variant="secondary" to={`/web-announcements`} icon={X}>
							Cancel
						</Button>
						<Button
							isDisabled={navigation.state !== 'idle'}
							type="button"
							variant="secondary"
							icon={Trash2}
							onClick={() => setDeleteModalOpen(true)}
							data-cy="announcementDeleteButton"
						>
							Delete
						</Button>

						<Button
							to={`/web-announcements/create?templateId=${data.webAnnouncement.id}`}
							icon={Copy}
							variant="secondary"
						>
							Duplicate
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<DeleteWebAnnouncementModal
						announcement={data.webAnnouncement}
						isOpen={isDeleteModalOpen}
						onClose={() => setDeleteModalOpen(false)}
					/>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Title"
									name="title"
									isRequired={true}
									defaultValue={data.webAnnouncement.title}
								/>
								<HTMLEditorField
									label="Body"
									name="body"
									isRequired={true}
									defaultValue={data.webAnnouncement.body}
								/>
							</Grid>
							<Grid>
								<DateField
									label="Release Date"
									name="releaseDate"
									isRequired={true}
									initialValue={data.webAnnouncement.releaseDate}
									timezone="utc"
								/>
								<DateField
									label="Expiry Date"
									name="expiryDate"
									isRequired={true}
									initialValue={data.webAnnouncement.expiryDate}
									timezone="utc"
								/>
								<ProductPicker
									label="Product"
									name="products"
									options={data.productOptions}
									initialValues={data.webAnnouncement.products}
								/>
								<CustomerTypePicker
									label="Customer Type"
									name="customerTypes"
									options={data.customerTypeOptions}
									initialValues={data.webAnnouncement.customerTypes}
								/>
								<CheckboxField
									name="isArchived"
									label="Hide Announcement"
									value="true"
									defaultChecked={data.webAnnouncement.isArchived}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_WEB_ANNOUNCEMENT_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <CreateWebAnnouncement />,
	handle: {
		breadcrumbs: { label: 'Edit' },
	},
};
