import { RouteProps, redirect } from 'react-router-dom';
import Card from 'ui/components/Card/Card';
import Flex from 'ui/components/Flex/Flex';

const loader = async () => {
	if (window.msal.redirectResult === undefined) {
		return redirect('/');
	} else {
		// MSAL will be doing the redirecting
		return null;
	}
};

export default function LoggedInPage() {
	return (
		<Flex justifyContent="center" alignItems="center">
			<Card>Redirecting...</Card>
		</Flex>
	);
}

export const LOGGED_IN_ROUTE: RouteProps = {
	element: <LoggedInPage />,
	loader,
};
