import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { AdminUserItem } from '../../api/AdminUserAPI';

const columnHelper = createColumnHelper<AdminUserItem>();

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => (
		<Link to={`/admin-users/${info.row.original.id}`}>{info.getValue()}</Link>
	),
});

export const emailColumn = columnHelper.accessor('email', {
	header: 'Email',
});

export const roleColumn = columnHelper.accessor('adminRole', {
	header: 'Role',
	cell: (info) => info.getValue().name,
});
