import { LogIn } from 'lucide-react';
import { LoaderFunctionArgs, redirect, RouteProps } from 'react-router-dom';
import Button from 'ui/components/Button';
import { ToastType } from 'ui/components/Toaster/Toast';
import { performLoginRedirect } from 'utils/helpers/msal';
import { createToast } from 'utils/helpers/toast';
import { LOGIN_REQUEST, msalInstance } from '../../auth/authConfig';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	if (msalInstance.getAllAccounts().length > 0) {
		return redirect('/');
	}

	const isUnauthorized = sessionStorage.getItem('notAuthorized');
	if (isUnauthorized) {
		sessionStorage.removeItem('notAuthorized');
		createToast(
			ToastType.ERROR,
			{
				message: "You don't have permission to access this page",
				hint: (
					<>
						If you think this is a mistake, contact{' '}
						<a href="mailto:cargois@iata.org">cargois@iata.org</a>
					</>
				),
			},
			{ duration: Infinity }
		);
	}

	return null;
};

export default function LoginPage() {
	return (
		<div className="landing">
			<div className="landing__content">
				<div className="landing__box">
					<h1 className="landing__title">
						Analyse Air Cargo from
						<br />
						your unique perspective
					</h1>
					<div className="landing__details">
						<div className="landing__text">
							<p>Stay ahead of competition with IATA CargoIS.</p>
							<p>
								For over 20 years, IATA's CargoIS is the air cargo industry's
								premier market intelligence tool, used by the full range of air
								cargo stakeholders: airlines, general sales agents, freight
								forwarders, ground handlers, airports and many others!
							</p>
							<p>View more about IATA CargoIS.</p>
						</div>
					</div>
					<div className="landing__signin">
						<Button
							icon={LogIn}
							variant="primary"
							onClick={() => performLoginRedirect(msalInstance, LOGIN_REQUEST)}
						>
							Login
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export const LOGIN_ROUTE: RouteProps = {
	loader: loader,
	element: <LoginPage />,
};
