import { PlusCircle, RefreshCcw, Search } from 'lucide-react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import AccountManagerAPI from '../../api/AccountManagersAPI';
import * as accountManagerColumns from '../../util/columns/accountManagerColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getAccountManagersResponse = await AccountManagerAPI.getAccountManagers(
		new URL(request.url).searchParams
	);

	return {
		getAccountManagersResponse: getAccountManagersResponse,
	};
};

export function AccountManagersListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		accountManagerColumns.fullNameColumn,
		accountManagerColumns.emailColumn,
		accountManagerColumns.titleColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Account Managers">
					<RevalidationButton>Refresh</RevalidationButton>

					<Button
						variant="secondary"
						icon={PlusCircle}
						to="/account-managers/create"
					>
						Add Account Manager
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.getAccountManagersResponse.accountManagers.items}
				/>

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.getAccountManagersResponse.accountManagers.page}
					pageParameterName="page"
					pageSize={data.getAccountManagersResponse.accountManagers.pageSize}
					itemCount={data.getAccountManagersResponse.accountManagers.totalCount}
				/>
			</div>

			<Sidebar title="Account Manager Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_ACCOUNT_MANAGERS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <AccountManagersListPage />,
	handle: {
		breadcrumbs: { label: 'Users' },
	},
};
