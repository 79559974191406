import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import { useCallback, useMemo } from 'react';
import Flex from 'ui/components/Flex/Flex';
import FormField from 'ui/components/FormField/FormField';
import Select from 'ui/components/Select/Select';
import { modDaysOfWeek, modHours } from 'utils/helpers/timezone';
import { LabeledValue } from 'utils/types/common';
import { NotificationSchedule } from '../../api/MonitoringAPI';

export const DEFAULT_WORKSHEET_SCHEDULE: NotificationSchedule = {
	frequency: 'immediate-notification',
	lastExecution: null,
	nextExecution: null,
};

type NotificationScheduleFormProps = {
	schedule: NotificationSchedule;
	onScheduleChange: (schedule: NotificationSchedule) => void;
};

const NotificationScheduleForm = ({
	schedule,
	onScheduleChange,
}: NotificationScheduleFormProps) => {
	const hoursOfDay = useMemo(() => {
		// We use format instead of string generation so we can localize easily
		return Array.from({ length: 24 }, (_, i) => {
			const date = new Date();
			date.setHours(i, 0, 0, 0);
			return { value: i, label: format(date, 'HH:mm') };
		});
	}, []);

	const daysOfWeek = useMemo(() => {
		const currentDate = new Date();
		const weekOptions = { weekStartsOn: 1 as const };
		const daysOfWeek = eachDayOfInterval({
			start: startOfWeek(currentDate, weekOptions),
			end: endOfWeek(currentDate, weekOptions),
		});

		return daysOfWeek.map((day, dayIndex) => {
			return { value: dayIndex + 1, label: format(day, 'EEEE') };
		});
	}, []);

	type FrequencyOption = {
		label: string;
		value: NotificationSchedule['frequency'];
	};
	const frequencyOptions = useMemo<FrequencyOption[]>(
		() => [
			{ label: 'Immediate Notification', value: 'immediate-notification' },
			{ label: 'Daily', value: 'daily' },
			{ label: 'Weekly', value: 'weekly' },
		],
		[]
	);

	const DEFAULT_TIME = 11;
	const handleFrequencyChange = (option: FrequencyOption | null) => {
		if (!option) return;

		switch (option.value) {
			case 'immediate-notification':
				onScheduleChange({
					...schedule,
					frequency: 'immediate-notification',
				});
				break;
			case 'daily':
				onScheduleChange({
					...schedule,
					frequency: 'daily',
					time: DEFAULT_TIME,
				});
				break;
			case 'weekly':
				onScheduleChange({
					...schedule,
					frequency: 'weekly',
					dayOfWeek: 1,
					time: DEFAULT_TIME,
				});
				break;

			default:
				break;
		}
	};

	// Time

	const handleTimeSelected = (option: LabeledValue<number> | null) => {
		if (!('time' in schedule)) return;
		const value = option?.value ?? DEFAULT_TIME - 1;

		if (schedule.frequency === 'weekly') {
			onScheduleChange({
				...schedule,
				time: value,
				dayOfWeek: modDaysOfWeek(selectedDayOfWeekOption.value) as Day,
			});
		} else if (schedule.frequency === 'daily') {
			onScheduleChange({
				...schedule,
				time: value,
			});
		}
	};

	const selectedTimeOption = useMemo<LabeledValue<number>>(() => {
		const utcHour = 'time' in schedule ? schedule.time : DEFAULT_TIME;

		const value = modHours(utcHour);

		return hoursOfDay[value];
	}, [schedule, hoursOfDay]);

	// Weekly

	const selectedDayOfWeekOption = useMemo<LabeledValue<number>>(() => {
		if (!('dayOfWeek' in schedule)) return daysOfWeek[0];

		const dayOfWeek = modDaysOfWeek(schedule.dayOfWeek);
		return daysOfWeek[dayOfWeek - 1];
	}, [schedule, daysOfWeek]);

	const handleDayOfWeekSelected = useCallback(
		(option: LabeledValue<number> | null) => {
			if (!('dayOfWeek' in schedule)) return;
			const value = option?.value ?? 1;
			const utcDay = modDaysOfWeek(value);

			onScheduleChange({
				...schedule,
				dayOfWeek: utcDay as Day,
			});
		},
		[schedule]
	);

	// Monthly

	return (
		<Flex direction="column" gap={20}>
			<Flex gap={16}>
				<FormField label="Frequency" style={{ flex: 2 }} isRequired={true}>
					<Select
						name="schedule.frequency"
						options={frequencyOptions}
						selectedOption={frequencyOptions.find(
							(itm) => itm.value === schedule.frequency
						)}
						onOptionSelected={handleFrequencyChange}
						isClearable={false}
						identifierKey="value"
						contentSource="label"
					/>
				</FormField>
			</Flex>

			{schedule?.frequency === 'daily' && (
				<Flex gap={16}>
					<FormField label="Time" style={{ width: '100%' }}>
						<Select
							name="schedule.time"
							options={hoursOfDay}
							initialValue={hoursOfDay[DEFAULT_TIME - 1]}
							selectedOption={selectedTimeOption}
							isClearable={false}
							identifierKey="label"
							contentSource="label"
							onOptionSelected={handleTimeSelected}
						/>
					</FormField>
				</Flex>
			)}

			{schedule?.frequency === 'weekly' && (
				<Flex gap={16}>
					<FormField label="Day Of Week" style={{ width: '100%' }}>
						<Select
							name="schedule.dayOfWeek"
							options={daysOfWeek}
							initialValue={daysOfWeek[0]}
							selectedOption={selectedDayOfWeekOption}
							isClearable={false}
							identifierKey="value"
							contentSource="label"
							onOptionSelected={handleDayOfWeekSelected}
						/>
					</FormField>

					<FormField label="Time" style={{ width: '100%' }}>
						<Select
							name="schedule.time"
							options={hoursOfDay}
							initialValue={hoursOfDay[DEFAULT_TIME - 1]}
							selectedOption={selectedTimeOption}
							isClearable={false}
							identifierKey="label"
							contentSource="label"
							onOptionSelected={handleTimeSelected}
						/>
					</FormField>
				</Flex>
			)}
		</Flex>
	);
};

export default NotificationScheduleForm;
