import { createColumnHelper } from '@tanstack/react-table';
import { Pencil } from 'lucide-react';
import { Link, useParams } from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import { WebAnnouncementItem } from '../../api/WebAnnouncementsAPI';

const columnHelper = createColumnHelper<WebAnnouncementItem>();

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
	cell: (info) => {
		const { id } = useParams<{ id: string }>();

		return (
			<Link to={`/web-announcements/${info.row.original.id}`}>
				{id === info.row.original.id ? (
					<b>{info.getValue()}</b>
				) : (
					info.getValue()
				)}
			</Link>
		);
	},
});

export const releaseDateColumn = columnHelper.accessor('releaseDate', {
	header: 'Release Date',
	cell: (info) => <DateFragment date={info.getValue()} timezone="utc" />,
});

export const expiryDateColumn = columnHelper.accessor('expiryDate', {
	header: 'Expiry Date',
	cell: (info) => <DateFragment date={info.getValue()} timezone="utc" />,
});

export const isActiveColumn = columnHelper.accessor('isActive', {
	header: 'Active',
	cell: (info) => (
		<BulletIndicator
			intent={info.getValue() ? 'success' : 'error'}
			label={info.getValue() ? 'Yes' : 'No'}
		/>
	),
});

export const isArchivedColumn = columnHelper.accessor('isArchived', {
	header: 'Archived',
	cell: (info) => (info.getValue() ? 'Yes' : 'No'),
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => (
		<Button
			variant="secondary"
			size="small"
			icon={Pencil}
			to={`/web-announcements/${info.getValue()}/edit`}
		>
			Edit
		</Button>
	),
});
