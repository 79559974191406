import { LucideIcon } from 'lucide-react';
import React, { PropsWithChildren, useId, useMemo, useState } from 'react';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import TextField from 'ui/components/TextField';
import useSubmitting from 'utils/hooks/useSubmitting';
import { zAuditable, zEmptyObject } from 'utils/zod/zodValidation';
import { AnyZodObject, z } from 'zod';
import AuditCommentField from '../AuditCommentField';
import ValidatedForm from '../ValidatedForm';
import ConfirmationModalContext from './ConfirmationModalContext';
import ConfirmationModalForm from './ConfirmationModalForm';

type ConfirmationModalProps = PropsWithChildren<{
	confirmationText: string;
	intent?: 'default' | 'danger';

	onSubmit?: () => void;
	submitText?: string;
	submitIcon?: LucideIcon;
	fieldName?: string;

	children?: React.ReactNode;
	secondaryChildren?: React.ReactNode;

	formAction?: string;
	formMethod?: 'get' | 'post' | 'put' | 'delete';

	requiresAuditComment?: boolean;

	additionalValidation?: AnyZodObject;
}> &
	ModalProps;

const ConfirmationModal = ({
	confirmationText,
	intent = 'default',
	submitText = 'Confirm',
	fieldName = 'confirmation',
	submitIcon,
	onSubmit,
	children,
	secondaryChildren,
	formAction,
	formMethod,
	requiresAuditComment = false,
	additionalValidation,
	...modalProps
}: ConfirmationModalProps) => {
	const formId = useId();
	const [auditComment, setAuditComment] = useState('');
	const [confirmationValue, setConfirmationValue] = useState('');
	const isSubmitting = useSubmitting();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmationValue(e.target.value);
	};

	const validationSchema = z
		.object({
			[fieldName]: z.string().refine((value) => value === confirmationText, {
				message: 'Confirmation text does not match',
			}),
		})
		.merge(requiresAuditComment ? zAuditable : zEmptyObject)
		.merge(additionalValidation ? additionalValidation : zEmptyObject);

	const contextValue = useMemo(() => ({ formId }), [formId]);

	return (
		<Modal {...modalProps}>
			<ConfirmationModalContext.Provider value={contextValue}>
				<Modal.Body>
					<ValidatedForm
						id={formId}
						onSubmit={onSubmit}
						validator={validationSchema}
						action={formAction}
						method={formMethod}
					>
						<Flex direction="column" gap={12}>
							{children}

							<p>
								To confirm, type "<b>{confirmationText}</b>" into the textbox
								below:
							</p>

							<TextField
								label="Confirmation box"
								name={fieldName}
								value={confirmationValue}
								onChange={handleChange}
								data-testid="confirmModalValue"
							/>

							{requiresAuditComment && (
								<AuditCommentField
									form={formId}
									value={auditComment}
									onChange={(e) => setAuditComment(e.target.value)}
								/>
							)}

							{secondaryChildren}
						</Flex>
					</ValidatedForm>
				</Modal.Body>
				<Modal.Actions>
					<Button
						type="submit"
						intent={intent}
						icon={submitIcon}
						isLoading={isSubmitting}
						form={formId}
						data-testid="confirmModalButton"
					>
						{submitText}
					</Button>
				</Modal.Actions>
			</ConfirmationModalContext.Provider>
		</Modal>
	);
};

ConfirmationModal.Form = ConfirmationModalForm;

export default ConfirmationModal;
