/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file
import z, { RefinementCtx } from 'zod';
import { zBoolPreprocessor } from '../zod/zodValidation';

const checkNotifications = (
	data: { value: string; selected: boolean; option: string }[],
	ctx: RefinementCtx
) => {
	let errored = true;
	for (const notification of data) {
		if (notification.selected) {
			errored = false;
		}
	}
	if (errored) {
		ctx.addIssue({
			code: z.ZodIssueCode.custom,
			path: ['notifications'],
			message: 'At least one notification is required',
			fatal: true,
		});
	}
};

export const subscriptionScheduleSchema = z.object({
	frequency: z.string(),
	dayOfWeek: z.string().optional(),
	time: z.string().optional(),
});

export const systemNotificationSubscriptionSchema = z.object({
	id: z.string(),
	adminId: z.string(),
	name: z
		.string()
		.min(1, 'A name must be provided')
		.max(120, 'The length of name cannot exceed 120 characters'),
	notifications: z.array(
		z.object({
			value: z.string(),
			selected: z.preprocess(zBoolPreprocessor, z.boolean()),
			option: z.string(),
		})
	),

	schedule: subscriptionScheduleSchema,
});

export const createSystemSubscriptionSchema =
	systemNotificationSubscriptionSchema
		.pick({
			adminId: true,
			name: true,
			schedule: true,
			notifications: true,
		})
		.superRefine((data, ctx) => {
			checkNotifications(data.notifications, ctx);
		});

export type CreateSystemNotificationSubscriptionModel = z.infer<
	typeof createSystemSubscriptionSchema
>;

export const editSystemSubscriptionSchema = systemNotificationSubscriptionSchema
	.pick({
		id: true,
		adminId: true,
		name: true,
		schedule: true,
		notifications: true,
	})
	.superRefine((data, ctx) => {
		checkNotifications(data.notifications, ctx);
	});

export type EditSystemNotificationSubscriptionModel = z.infer<
	typeof editSystemSubscriptionSchema
>;
