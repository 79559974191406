import classNames from 'classnames';
import { PropsWithChildren } from 'react';

interface HotfileAreaButtonProps extends PropsWithChildren {
	isSelected: boolean;
	onToggleHighlighted: () => void;
}

const HotfileAreaButton = ({
	children,
	isSelected,
	onToggleHighlighted,
}: HotfileAreaButtonProps) => {
	return (
		<button
			className={classNames(
				'hotfilemonitor__area',
				isSelected && 'hotfilemonitor__area--selected'
			)}
			onClick={onToggleHighlighted}
			type="button"
			tabIndex={0}
		>
			{children}
		</button>
	);
};

export default HotfileAreaButton;
