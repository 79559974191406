import { Trash2 } from 'lucide-react';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';

import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { GetAccountManagerResponse } from '../../api/AccountManagersAPI';

type DeleteAccountManagerModalProps = {
	accountManager: GetAccountManagerResponse;
	hasCustomers: boolean;
} & Omit<ModalProps, 'title'>;

const DeleteAccountManagerModal = ({
	accountManager,
	hasCustomers,
	...modalProps
}: DeleteAccountManagerModalProps) => {
	const accountManagerFullName =
		accountManager.firstName + ' ' + accountManager.lastName;

	if (hasCustomers) {
		return (
			<Modal title="Delete Account Manager" {...modalProps}>
				<Modal.Body>
					<p>
						Unable to delete account manager <b>{accountManagerFullName}</b>.
						You must disassociate this account manager from any customers before
						they can be deleted.
					</p>
				</Modal.Body>
			</Modal>
		);
	} else {
		return (
			<ConfirmationModal
				fieldName="delete"
				title="Delete Account Manager"
				submitText="Delete Account Manager"
				submitIcon={Trash2}
				intent="danger"
				confirmationText={accountManagerFullName}
				formAction={`/account-managers/${accountManager.id}`}
				formMethod="delete"
				{...modalProps}
			>
				<p>
					Are you sure you wish to delete the account manager{' '}
					<b>{accountManagerFullName}</b>? This action cannot be undone.
				</p>
			</ConfirmationModal>
		);
	}
};

export default DeleteAccountManagerModal;
