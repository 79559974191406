import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { updateAdminUserSchema } from 'utils/schemas/adminUserSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import { ToastType } from 'ui/components/Toaster/Toast';
import { AdminRoleOption } from 'utils/api/common';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import AdminUserAPI from '../../api/AdminUserAPI';
import { DropdownField } from '../../forms/DropdownField';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const userData = await AdminUserAPI.getAdminUser(params.userId as string);
	const roles = await AdminUserAPI.getAdminOptions();

	return {
		user: userData,
		roles: roles.adminRoles,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const userId = params.userId as string;
	const formData = await request.formData();

	const updateUserModel = updateAdminUserSchema.parse(
		formDataAsObject(formData)
	);

	const data = await AdminUserAPI.updateAdminUser(userId, updateUserModel);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'Admin user updated successfully');
	return redirect(`/admin-users/${userId}`);
};

export function UserEdit() {
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateAdminUserSchema}>
				<Grid>
					<PageHeader title={`Edit ${data.user.name}`}>
						<Button
							variant="secondary"
							to={`/admin-users/${data.user.id}`}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Name"
									name="name"
									isRequired={true}
									defaultValue={data.user.name}
								/>

								<DropdownField<AdminRoleOption>
									label="Role"
									name="adminRole"
									options={data.roles}
									placeholder="Please select..."
									contentSource={(option) => option.name}
									identifierKey="value"
									initialValue={data.user.adminRole}
									inputValue={data.user.adminRole.value}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_ADMIN_USER_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <UserEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return { label: `Edit: ${data.user.name}` };
		},
	},
};
