import { PropsWithChildren, useContext } from 'react';
import Portal from '../Portal';
import TabsContext from './TabsContext';

type TabActionsProps = PropsWithChildren<{}>;

const TabActions = ({ children }: TabActionsProps) => {
	const { actionsPortalId } = useContext(TabsContext);

	return <Portal wrapperId={actionsPortalId}>{children}</Portal>;
};

export default TabActions;
