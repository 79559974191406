import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink';
import BulletIndicator from 'ui/components/BulletIndicator';
import DateFragment from 'ui/components/DateFragment';
import Tooltip from 'ui/components/Tooltip';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import { SubscriptionReportItem } from '../../api/SubscriptionAPI';

import {
	ReportBulletIndicatorIntent,
	ReportDistributionBulletIndicatorIntent,
} from './reportColumnHelpers';
import YearMonthAndWeekFragment from 'ui/components/YearMonthAndWeekFragment';

const columnHelper = createColumnHelper<SubscriptionReportItem>();

export const idColumn = columnHelper.accessor('jobId', {
	header: 'Report Id',
	enableSorting: true,
});

export const generationStatusColumn = columnHelper.accessor(
	'generationStatus',
	{
		header: 'Generation Status',
		cell: (info) => (
			<BulletIndicator
				intent={ReportBulletIndicatorIntent(info.getValue())}
				label={<ReportGenerationStatus report={info.row.original} />}
			/>
		),
		enableSorting: true,
	}
);

export const distributionStatusColumn = columnHelper.accessor(
	'distributionStatus',
	{
		header: 'Distribution Status',
		cell: (info) =>
			info.row.original.distributionStatus ? (
				<BulletIndicator
					intent={ReportDistributionBulletIndicatorIntent(info.getValue())}
					label={info.row.original.distributionStatus}
				/>
			) : (
				'-'
			),
		enableSorting: true,
	}
);

export const reportDateColumn = columnHelper.accessor('reportDate', {
	header: 'Report Date',
	cell: (info) =>
		info.row.original.reportDate.week ? (
			<YearMonthAndWeekFragment value={info.getValue()} />
		) : (
			<YearAndMonthFragment value={info.getValue()} />
		),
	enableSorting: true,
});

export const versionColumn = columnHelper.display({
	id: 'version',
	header: 'Version',
	cell: (info) => {
		const data = info.row.original;
		if (data.variant)
			return (
				<span title={`Country: ${data.variant.key}`}>{data.variant.key}</span>
			);
		return '-';
	},
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const jobStartedAtColumn = columnHelper.accessor('jobStartedAt', {
	header: 'Job Started (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const jobCompletedAtColumn = columnHelper.accessor('jobCompletedAt', {
	header: 'Job Completed (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const attachmentColumn = columnHelper.accessor('filename', {
	header: 'Attachment',
	cell: (info) => {
		return info.row.original.filename ? (
			<>
				<AttachmentLink
					url={`/subscriptions/reports/download/${info.row.original.id}`}
					target="_blank"
					name={info.row.original.filename}
					size={info.row.original.fileSizeInBytes}
				/>
				{info.row.original.suppressDistortion ? (
					<span title="Distortion NOT applied!"> ***</span>
				) : (
					''
				)}
			</>
		) : (
			'-'
		);
	},
});

const ReportGenerationStatus = ({
	report,
}: {
	report: SubscriptionReportItem;
}) => {
	if (report.failureMessage)
		return (
			<Tooltip placement="top">
				<Tooltip.Trigger>
					<span style={{ cursor: 'help' }}>{report.generationStatus}</span>
				</Tooltip.Trigger>
				<Tooltip.Content>{report.failureMessage}</Tooltip.Content>
			</Tooltip>
		);

	if (report.generationDuration) return <span>{report.generationStatus}</span>;

	return <span>{report.generationStatus}</span>;
};
