import { LIST_AIR_IMPACT_REPORTS_ROUTE } from './air-impact-reports-list';
import { CREATE_AIR_IMPACT_REPORTS_ROUTE } from './air-impact-reports-create';
import { EDIT_AIR_IMPACT_REPORTS_ROUTE } from './air-impact-reports-edit';
import { DETAILS_AIR_IMPACT_REPORTS_ROUTE } from './air-impact-reports-details';

export const AIR_IMPACT_REPORTS_ROUTES = {
	list: LIST_AIR_IMPACT_REPORTS_ROUTE,
	details: DETAILS_AIR_IMPACT_REPORTS_ROUTE,
	create: CREATE_AIR_IMPACT_REPORTS_ROUTE,
	edit: EDIT_AIR_IMPACT_REPORTS_ROUTE,
};
