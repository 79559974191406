import {
	BarChart,
	BarChartHorizontal,
	Binary,
	Building2,
	CalendarClock,
	ClipboardSignature,
	FileSpreadsheet,
	Hammer,
	Mail,
	Megaphone,
	Monitor,
	Newspaper,
	Package2,
	PlusCircle,
	Send,
	Siren,
	Table,
	Table2,
	UserPlus,
	Users,
} from 'lucide-react';
import { Outlet } from 'react-router-dom';
import Footer from 'ui/components/Footer';
import Header from 'ui/components/Header';
import { NavigationRootItem } from 'ui/components/Navigation/Navigation';
import Toaster from 'ui/components/Toaster';
import TransitionSpinner from 'ui/components/TransitionSpinner';
import {
	performLoginRedirect,
	performLogoutRedirect,
} from 'utils/helpers/msal';
import useRootLoader from 'utils/hooks/useRootLoader';
import useUpdateNotifications from 'utils/hooks/useUpdateNotifications';
import { LoaderData } from 'utils/types/loaderData';
import '../src/styles/base.scss';
import '../src/styles/layout.scss';
import { LOGIN_REQUEST, msalInstance } from './auth/authConfig';
import { rootLoader } from './main';

function App() {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;

	const NAVIGATION_ITEMS: NavigationRootItem<'cargois-team' | 'sales-team'>[] =
		[
			{
				name: 'Home',
				path: '/',
				allowedRoles: ['cargois-team', 'sales-team'],
			},
			{
				name: 'Customers',
				path: '/customers',
				allowedRoles: ['cargois-team', 'sales-team'],
				sections: [
					{
						name: 'Customers',
						items: [
							{
								name: 'View customers',
								path: '/customers',
								icon: Building2,
							},
							{
								name: 'Add customer',
								path: '/customers/create',
								icon: PlusCircle,
							},
						],
					},
					{
						name: 'Subscriptions',
						items: [
							{
								name: 'View subscriptions',
								path: '/subscriptions',
								icon: Newspaper,
							},
							{
								name: 'Add subscription',
								path: '/subscriptions/create',
								icon: PlusCircle,
							},
						],
					},
					{
						name: 'Users',
						items: [
							{
								name: 'View users',
								path: '/users',
								icon: Users,
							},
							{
								name: 'Add user',
								path: '/users/create',
								icon: UserPlus,
							},
						],
					},
				],
			},
			{
				name: 'Reports',
				allowedRoles: ['cargois-team'],
				sections: [
					{
						name: 'Publish',
						items: [
							{
								name: 'Publish Summary',
								path: '/publish/summary',
								icon: ClipboardSignature,
							},
						],
					},
					{
						name: 'Reports',
						items: [
							{
								name: 'Monitor',
								path: '/reports/monitor',
								icon: FileSpreadsheet,
							},
							{
								name: 'Monthly Distribution',
								path: '/reports/monthly-reports',
								icon: FileSpreadsheet,
							},
							{
								name: 'Weekly Distribution',
								path: '/reports/weekly-reports',
								icon: FileSpreadsheet,
							},
							{
								name: 'CASS Hotfile Monitor',
								path: '/monitoring/hotfile-monitor',
								icon: Siren,
							},
							{
								name: 'CDD Monitor',
								path: '/monitoring/cdd-monitor',
								icon: Binary,
							},
						],
					},
				],
			},
			{
				name: 'Reference Data',
				allowedRoles: ['cargois-team'],
				sections: [
					{
						name: 'IATA',
						items: [
							{
								name: 'Admin Users',
								path: '/admin-users',
								icon: Users,
								description: 'Manage your admin users',
							},
							{
								name: 'Account Managers',
								path: '/account-managers',
								icon: Users,
								description: 'Manage your account managers',
							},
							{
								name: 'Web Announcements',
								path: '/web-announcements',
								icon: Megaphone,
								description: 'Manage your web announcements',
							},
							{
								name: 'Air Cargo Impact Reports',
								path: '/air-impact-reports',
								icon: Monitor,
								description: 'Manage your impact reports',
							},
						],
					},
					{
						name: 'External',
						items: [
							{
								name: 'Freight Forwarders',
								path: '/freight-forwarders',
								icon: Package2,
							},
						],
					},
				],
			},
			{
				name: 'System',
				allowedRoles: ['cargois-team'],
				sections: [
					{
						items: [
							{
								name: 'System Status',
								path: '/monitoring/system-status',
								icon: Monitor,
							},
							{
								name: 'CDD Submissions',
								path: '/cdd-contributor',
								icon: Table,
							},
							{
								name: 'iiNet Scheduler',
								path: '/static-report-distribution',
								icon: Send,
							},
							{
								name: 'Report Notifications',
								path: '/report-notifications',
								icon: Mail,
							},
							{
								name: 'Job Monitor',
								path: '/monitoring',
								icon: Monitor,
							},
							{
								name: 'Admin Audit Log',
								path: '/admin-audit-log',
								icon: BarChartHorizontal,
							},
							{
								name: 'Usage Tracking',
								path: '/usage-tracking',
								icon: BarChartHorizontal,
							},
							{
								name: 'Web Tool Usage Tracking',
								path: '/web-tool-usage-tracking',
								icon: Hammer,
							},
							{
								name: 'Web Tool Schedules',
								path: '/web-tool-schedules',
								icon: CalendarClock,
							},
							{
								name: 'Web Tool Reports',
								path: '/web-tool-reports',
								icon: Table2,
							},
							{
								name: 'Admin Exports',
								path: '/admin-exports',
								icon: BarChart,
							},
							{
								name: 'Email Templates',
								path: '/email-templates',
								icon: Mail,
							},
						],
					},
				],
			},
		];

	useUpdateNotifications();

	return (
		<>
			<div className="page-wrapper">
				<Header
					navigationItems={NAVIGATION_ITEMS}
					activeRole={rootLoaderData?.profile?.adminRole?.value}
					onLogin={() => performLoginRedirect(msalInstance, LOGIN_REQUEST)}
					onLogout={() => performLogoutRedirect(msalInstance)}
					activeAccount={msalInstance.getActiveAccount() ?? undefined}
				/>
				<main>
					<Outlet />
				</main>
				<Footer
					showQuickLinks={false}
					isAuthenticated={msalInstance.getAllAccounts().length > 0}
				/>
			</div>
			<Toaster />
			<TransitionSpinner />
		</>
	);
}

export default App;
