export function getYearMonthWeekFromQueryStringOrDefault(
	request: Request,
	queryKey: string = 'yearMonthWeek'
): number | null {
	const queryParams = new URL(request.url).searchParams;

	let yearMonthWeek = Number(queryParams.get(queryKey));

	if (Number.isNaN(yearMonthWeek) || yearMonthWeek < 202001) {
		return null;
	}

	return yearMonthWeek;
}
