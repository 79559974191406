import { UploadCloud } from 'lucide-react';
import { useId } from 'react';
import { Form, LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Alert from 'ui/components/Alert';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import AdhocReportsAPI from '../../api/AdhocReportsAPI';
import * as adhocReportColumns from '../../util/columns/adhocReportColumns';

const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const customerId = params.customerId as string;
	const searchParams = new URL(request.url).searchParams;

	searchParams.append('customerId', customerId);

	return await AdhocReportsAPI.getAdhocReports(searchParams);
};

const AdhocReportsList = () => {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		adhocReportColumns.reportIdColumn,
		adhocReportColumns.statusColumn,
		adhocReportColumns.titleColumn,
		adhocReportColumns.userCountColumn,
		adhocReportColumns.createdAtColumn,
		adhocReportColumns.releaseDateColumn,
		adhocReportColumns.attachmentColumn,
		adhocReportColumns.editButtonColumn,
	];

	return (
		<>
			<Flex direction="column" gap={16}>
				{data.totalCustomerUsers == 0 && (
					<div>
						<Alert title="Insufficient Users" intent="info">
							To upload an Adhoc Report the customer must have at least 1 user.
						</Alert>
						<div>&nbsp;</div>
					</div>
				)}

				<Form id={formId} method="post">
					<Table
						identifierKey="id"
						data={data.reports.items}
						columns={columns}
						emptyText="No adhoc reports found"
					/>
				</Form>

				{data.totalCustomerUsers > 0 && (
					<TabActions>
						<>
							<Button
								variant="secondary"
								size="small"
								icon={UploadCloud}
								type="button"
								to={`/adhoc-reports/create?customerId=${data.customerId}`}
							>
								Upload Adhoc Report
							</Button>
						</>
					</TabActions>
				)}

				{data.reports.totalCount > data.reports.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const CUSTOMER_DETAILS_REPORTS_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <AdhocReportsList />,
};
