import FormField from 'ui/components/FormField';
import MultiSelect from 'ui/components/MultiSelect';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import { ProductOption } from '../../api/SubscriptionAPI';

type ProductPickerProps = {
	name: string;
	label?: string;
	isRequired?: boolean;
	options: ProductOption[];
	initialValues?: ProductOption[];
};
const ProductPicker = ({
	name,
	label,
	options,
	initialValues,
	isRequired,
}: ProductPickerProps) => {
	const { errorTree } = useValidation(name);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			<MultiSelect
				name={name}
				identifierKey="id"
				placeholder="Select..."
				contentSource={(opt) => `${opt.name}`}
				pillComponent={({ value, onRemove }) => (
					<Pill name={value.name} onRemove={onRemove} />
				)}
				options={options}
				initialValues={initialValues}
			/>
		</FormField>
	);
};

export default ProductPicker;
