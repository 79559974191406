import { Command, RefreshCcw, Search } from 'lucide-react';
import { useState } from 'react';
import {
	LoaderFunctionArgs,
	Outlet,
	useLoaderData,
	useParams,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import reportDistributionAPI from '../../api/ReportDistributionAPI';
import ExecuteDistributionModal from '../../components/ExecuteDistributionModal/ExecuteDistributionModal';
import * as reportDistributionColumns from '../../util/columns/reportDistributionColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const response = await reportDistributionAPI.getReportDistributions(
		new URL(request.url).searchParams
	);

	return {
		status: response.status,
		createdBeforeDate: response.createdBeforeDate,
		createdAfterDate: response.createdAfterDate,
		completedBeforeDate: response.completedBeforeDate,
		completedAfterDate: response.completedAfterDate,
		reports: response.reports,
		statusOptions: response.statusOptions,
	};
};

export function StaticReportDistributionListPage() {
	const [isModalOpen, setModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		reportDistributionColumns.filesColumn,
		reportDistributionColumns.accountsColumn,
		reportDistributionColumns.createdDateColumn,
		reportDistributionColumns.distributionStatusColumn,
		reportDistributionColumns.startedDateColumn,
		reportDistributionColumns.deliveredDateColumn,
		reportDistributionColumns.completedDateColumn,
		reportDistributionColumns.viewDetailsColumn,
	];

	const params = useParams();

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="iiNet Scheduler">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="secondary"
						icon={Command}
						onClick={() => setModalOpen(true)}
					>
						Start Distribution
					</Button>
				</PageHeader>

				<ExecuteDistributionModal
					isOpen={isModalOpen}
					onSubmit={() => {
						setModalOpen(false);
						createToast(ToastType.SUCCESS, 'Reports Distribution Running');
					}}
					onClose={() => setModalOpen(false)}
				/>

				<Grid columns={params.id ? '2fr 1fr' : undefined}>
					<Grid>
						<Table
							columns={columns}
							data={data.reports.items}
							identifierKey="id"
						/>

						<Pagination
							baseUrl={new URL(window.location.href)}
							page={data.reports.page}
							pageParameterName="page"
							pageSize={data.reports.pageSize}
							itemCount={data.reports.totalCount}
						/>
					</Grid>
					<Outlet />
				</Grid>
			</div>
			<Sidebar title="Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<MultiSelectField
						label="Status"
						name="status"
						identifierKey="id"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.name}`}
						options={data.statusOptions}
						pillName={(item) => item.name}
						initialValues={data.statusOptions.filter((item) =>
							data.status.includes(item.id)
						)}
					/>
					<TextField label="File Name" name="fileName" />
					<TextField label="Account" name="account" />
					<Grid columns={2}>
						<DateField
							label="Created After"
							name="createdAfterDate"
							initialValue={data.createdAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Created Before"
							name="createdBeforeDate"
							initialValue={data.createdBeforeDate}
							timezone="utc"
						/>
					</Grid>
					<Grid columns={2}>
						<DateField
							label="Finished After"
							name="completedAfterDate"
							initialValue={data.completedAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Finished Before"
							name="completedBeforeDate"
							initialValue={data.completedBeforeDate}
							timezone="utc"
						/>
					</Grid>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const STATIC_REPORT_DISTRIBUTION_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <StaticReportDistributionListPage />,
};
