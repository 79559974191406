import { ClipboardSignature } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { CassCalendarSummary, PublishMonth } from '../../api/DataManagementAPI';
import { MonthlyPublishAction } from './summary-dashboard';

type PublishMonthlyDataModalProps = {
	cassCalendarSummary: CassCalendarSummary;
	publishMonth: PublishMonth;
} & Omit<ModalProps, 'title'>;

const PublishMonthlyDataModal = ({
	cassCalendarSummary,
	publishMonth,
	...modalProps
}: PublishMonthlyDataModalProps) => {
	return (
		<ConfirmationModal
			fieldName="month"
			title="Publish Monthly Data"
			submitIcon={ClipboardSignature}
			submitText="Publish Monthly Data"
			confirmationText={publishMonth.description}
			formMethod="post"
			formAction="/publish/summary"
			{...modalProps}
		>
			<input type="hidden" name="action" value={MonthlyPublishAction.Publish} />
			<input type="hidden" name="yearMonth" value={publishMonth.yearMonth} />

			{cassCalendarSummary.billingFilesMissingCount > 0 && (
				<p>
					<b>
						{cassCalendarSummary.billingFilesMissingCount} billing file(s) are
						missing
					</b>
					:{' '}
					{cassCalendarSummary.billingFilesMissing
						.map((m) => `${m.cassArea} (${m.submission})`)
						.join(', ')}
				</p>
			)}

			{cassCalendarSummary.correctionFilesMissingCount > 0 && (
				<p>
					{cassCalendarSummary.correctionFilesMissingCount} correction file(s)
					are missing.
				</p>
			)}

			<p>
				Are you sure you wish to publish the data for{' '}
				<b>{publishMonth.description}</b>?
			</p>
		</ConfirmationModal>
	);
};

export default PublishMonthlyDataModal;
