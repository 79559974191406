/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';

export const accountManagerSchema = z.object({
	id: z.string(),
	firstName: z
		.string()
		.min(1, 'First Name is required')
		.max(50, { message: 'First Name must not be more than 50 characters' }),
	lastName: z
		.string()
		.min(1, 'Last Name is required')
		.max(50, { message: 'Last Name must not be more than 50 characters' }),
	title: z
		.string()
		.min(1, 'Title is required')
		.max(50, { message: 'Title must not be more than 50 characters' }),
	email: z
		.string({ required_error: 'Email is required' })
		.max(250, 'Email must not be more than 250 characters')
		.email(),
});

export type AccountManagerModel = z.infer<typeof accountManagerSchema>;

// CREATE ACCOUNT MANAGER SCHEMA

export const createAccountManagerSchema = accountManagerSchema.pick({
	firstName: true,
	lastName: true,
	title: true,
	email: true,
});

export type CreateAccountManagerModel = z.infer<
	typeof createAccountManagerSchema
>;

// UPDATE ACCOUNT MANAGER SCHEMA

export const updateAccountManagerSchema = accountManagerSchema.pick({
	firstName: true,
	lastName: true,
	title: true,
	email: true,
});

export type UpdateAccountManagerModel = z.infer<
	typeof updateAccountManagerSchema
>;
