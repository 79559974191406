import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import SubscriptionAPI, { SubscriptionOption } from '../../api/SubscriptionAPI';
import SubscriptionPickerItem from './SubscriptionPickerItem';

export type SubscriptionPickerProps = {
	name: string;
	label?: string;
	isMulti?: boolean;
	isRequired?: boolean;
	initialValues?: SubscriptionOption[];
	productTypes?: string[];
	onChange?: (subscription: SubscriptionOption | null) => void;
};

const SubscriptionPicker = ({
	name,
	label,
	isMulti = false,
	initialValues,
	isRequired,
	productTypes,
	onChange,
}: SubscriptionPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);

	const getSubscriptions = async (searchTerm: string) => {
		const data = await SubscriptionAPI.lookup({
			keyword: searchTerm,
			productTypes: productTypes,
		});
		return data.options;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{isMulti ? (
				<AsyncMultiSelect<SubscriptionOption>
					onSearch={getSubscriptions}
					identifierKey="id"
					initialValues={initialValues ?? []}
					onOptionsSelected={revalidate}
					contentSource={(subscription) => (
						<SubscriptionPickerItem subscription={subscription} />
					)}
					pillComponent={({ value, onRemove }) => (
						<Pill
							name={
								value.name.length > 0 ? value.name : value.subscriptionNumber
							}
							title={`${value.name}\nSubscription Number: ${value.subscriptionNumber}`}
							onRemove={onRemove}
						/>
					)}
					placeholder="Select subscriptions..."
					name={name}
				/>
			) : (
				<AsyncSelect<SubscriptionOption>
					onSearch={getSubscriptions}
					initialValue={initialValues ? initialValues[0] : null}
					onOptionSelected={(newValue) => {
						if (onChange) {
							onChange(newValue);
						}
						revalidate();
					}}
					identifierKey="id"
					contentSource={(subscription) => (
						<SubscriptionPickerItem subscription={subscription} />
					)}
					isClearable={true}
					placeholder="Select a subscription..."
					name={name}
				/>
			)}
		</FormField>
	);
};

export default SubscriptionPicker;
