import classNames from 'classnames';

type PrettyJSONProps = {
	className?: string;
	jsonObject: Record<string, any>;
	emptyText?: string;
	indentation?: number;
	gap?: number;
};

const PrettyJSON = ({
	className,
	jsonObject,
	emptyText,
	indentation = 0,
	gap = 0,
}: PrettyJSONProps) => {
	const formatLowerCamelCase = (key: string) => {
		return key
			.replace(/([A-Z][a-z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase());
	};

	return (
		<div
			style={{
				paddingLeft: `calc(${indentation} * var(--space-3))`,
				gap: `${gap}px`,
			}}
			className={classNames('pretty-json', className)}
		>
			{Object.entries(jsonObject).map(([key, value]) => {
				if (
					value === undefined ||
					value === null ||
					value === '' ||
					(typeof value === 'object' && Object.entries(value).length === 0)
				) {
					if (!emptyText) return null;

					value = emptyText;
				}

				return (
					<div className="pretty-json__row" key={key}>
						<span className="pretty-json__key">
							{formatLowerCamelCase(key)}:{' '}
						</span>
						<span className="pretty-json__value">
							{typeof value === 'object' ? (
								<PrettyJSON
									jsonObject={value}
									indentation={indentation + 1}
									emptyText={emptyText}
								/>
							) : (
								value.toString()
							)}
						</span>
					</div>
				);
			})}
		</div>
	);
};

export default PrettyJSON;
