import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetSubscriptionResponse } from '../../api/SubscriptionAPI';

type DeleteSubscriptionModalProps = {
	subscription: GetSubscriptionResponse;
} & Omit<ModalProps, 'title'>;

const DeleteSubscriptionModal = ({
	subscription,
	...modalProps
}: DeleteSubscriptionModalProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete Subscription"
			submitText="Delete Subscription"
			submitIcon={Trash2}
			intent="danger"
			confirmationText={subscription.subscriptionNumber}
			formAction={`/subscriptions/${subscription.id}`}
			formMethod="delete"
			requiresAuditComment
			{...modalProps}
		>
			<input type="hidden" name="_action" value="delete" />

			<p>
				Are you sure you wish to delete the subscription{' '}
				<b>{subscription.subscriptionNumber}</b>? This action cannot be undone.
			</p>
		</ConfirmationModal>
	);
};

export default DeleteSubscriptionModal;
