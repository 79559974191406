import { BarChartHorizontal, Download, Pencil, Trash2 } from 'lucide-react';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	Link,
	LoaderFunctionArgs,
	isRouteErrorResponse,
	redirect,
	useLoaderData,
	useRouteError,
} from 'react-router-dom';
import { AirlinePill } from 'ui/components/AirlinePicker';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import Flex from 'ui/components/Flex';
import { FreightForwarderPill } from 'ui/components/FreightForwarderPicker';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import Tabs from 'ui/components/Tabs';
import { ToastType } from 'ui/components/Toaster/Toast';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import CustomerAPI from '../../api/CustomerAPI';
import CustomerContacts from '../../components/CustomerContacts';
import DeleteCustomerModal from '../../components/DeleteCustomerModal';
import { deleteSchema } from 'utils/api/common';
import { formDataAsObject } from 'utils/formData/formData';

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		const formData = await request.formData();
		const deleteModel = deleteSchema.parse(formDataAsObject(formData));

		const response = await CustomerAPI.deleteCustomer(
			params.customerId as string,
			deleteModel
		);

		if (response instanceof Error) {
			createToast(ToastType.ERROR, 'Deletion failed');
			return response;
		}

		createToast(ToastType.SUCCESS, 'Customer deleted successfully');

		return redirect('/customers');
	}

	return null;
};

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	let searchParams = new URL(request.url).searchParams;
	const customerId = params.customerId as string;
	const show = searchParams.get('show') as string;

	const customerData = await CustomerAPI.getCustomer({
		id: customerId,
		show: show,
	});

	return {
		customer: customerData,
	};
};

export function CustomerDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const handleDownload = async (customerId: string) => {
		toast.loading('Exporting...');

		const response = await CustomerAPI.getCustomerDownloadData(customerId);

		downloadFile(response.url, response.fileName);
	};

	return (
		<Fragment>
			<div className="content">
				<PageHeader
					title={`${data.customer.name} (${data.customer.customerNumber})`}
				>
					<Button
						to={`/admin-audit-log/?customerIds=${data.customer.id}&dateRange=0`}
						variant="secondary"
						icon={BarChartHorizontal}
					>
						Audit Log
					</Button>
					<Button
						icon={Download}
						onClick={() => {
							handleDownload(data.customer.id).finally(() => {
								toast.remove();
							});
						}}
						variant="secondary"
					>
						Download data
					</Button>
					<Button
						variant="secondary"
						icon={Trash2}
						onClick={() => setDeleteModalOpen(true)}
					>
						Delete
					</Button>
					<Button
						variant="secondary"
						icon={Pencil}
						to={`/customers/${data.customer.id}/edit`}
					>
						Edit
					</Button>
				</PageHeader>

				<DeleteCustomerModal
					customer={data.customer}
					isOpen={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
				/>

				<KeyValuePairs
					entries={[
						{ key: 'Customer Type', value: data.customer.customerType.name },
						{
							key: 'Customer Status',
							value: (
								<BulletIndicator
									intent="success"
									label={data.customer.customerStatus.label}
								/>
							),
						},
						{
							key: 'Account Manager',
							value: data.customer.accountManager.label,
						},
						{
							key: 'Country',
							value: data.customer.country
								? `${data.customer.country.name} (${data.customer.country.code})`
								: `–`,
						},
					]}
				/>

				<Grid columns="3fr 1fr">
					<Tabs
						tabs={[
							{ name: 'Users', to: '' },
							{ name: 'Subscriptions', to: '/subscriptions' },
							{ name: 'Adhoc Reports', to: '/reports' },
							{ name: 'Teams', to: '/teams' },
						]}
						baseUrl={`/customers/${data.customer.id}`}
					/>

					<Flex direction="column" gap={20}>
						<Card label="Details">
							<KeyValuePairs
								layout="vertical"
								entries={[
									{
										key: 'Airlines',
										value: data.customer.airlines?.map((airline) => (
											<Fragment key={airline.code}>
												<AirlinePill airline={airline} />{' '}
											</Fragment>
										)),
									},
									{
										key: 'Notes',
										value: data.customer.notes,
									},
									{
										key: 'Freight Forwarders',
										value: data.customer.freightForwarders?.map(
											(freightForwarder) => (
												<Fragment key={freightForwarder.code}>
													<FreightForwarderPill
														freightForwarder={freightForwarder}
													/>{' '}
												</Fragment>
											)
										),
									},
								]}
							/>
						</Card>

						{data.customer.contacts.length > 0 && (
							<CustomerContacts contacts={data.customer.contacts} />
						)}
					</Flex>
				</Grid>
			</div>
		</Fragment>
	);
}

export function CustomerErrorBoundary() {
	const error = useRouteError();

	// re-throw to let the RootErrorBoundary handle it if it's a route error
	if (!isRouteErrorResponse(error)) {
		throw error;
	}

	return (
		<>
			<h1>Customer could not be found</h1>
			<p>
				Go back to the <Link to="./">list of customers</Link>
			</p>
		</>
	);
}

export const CUSTOMER_DETAILS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <CustomerDetails />,
	errorElement: <CustomerErrorBoundary />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return {
				label: `${data.customer.name} (${data.customer.customerNumber})`,
			};
		},
	},
};
