import { useSearchParams } from 'react-router-dom';
import { LabeledValue } from 'utils/types/common';
import Select from '../Select';

const PAGE_SIZE_OPTIONS = [
	{ label: '20', value: 20 },
	{ label: '40', value: 40 },
	{ label: '60', value: 60 },
	{ label: '80', value: 80 },
	{ label: '100', value: 80 },
	{ label: 'All', value: -1 },
];

type PaginationPageSizePickerProps = {
	pageSize: number;
	totalItems: number;
};

const PaginationPageSizePicker = ({
	pageSize,
	totalItems,
}: PaginationPageSizePickerProps) => {
	const [, setSearchParams] = useSearchParams();

	if (totalItems < 20) return null;

	const availableOptions = PAGE_SIZE_OPTIONS.filter(
		(option) => option.value <= totalItems
	);

	const handlePageSizeChange = (option: LabeledValue<number> | null) => {
		if (!option) return;

		setSearchParams((prev) => {
			const newSearchParams = new URLSearchParams(prev);
			newSearchParams.set('pageSize', option.value.toString());
			newSearchParams.delete('page');
			return newSearchParams;
		});
	};

	return (
		<div className="pagination__page-size">
			<Select
				options={availableOptions}
				identifierKey="value"
				contentSource={(o) => `${o.label} items`}
				initialValue={
					availableOptions.find((option) => option.value === pageSize) ??
					availableOptions[0]
				}
				onOptionSelected={handlePageSizeChange}
				isClearable={false}
			/>
		</div>
	);
};

export default PaginationPageSizePicker;
