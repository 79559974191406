import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue, Paginated } from 'utils/types/common';
import { CustomerOption } from './CustomerAPI';
import { UserOption } from './UserAPI';
import { SubscriptionOption } from './SubscriptionAPI';

export type WorksheetRow = {
	id: string;
	name: string;
	supportWorksheet: boolean;
	sharedWorksheet: boolean;
	modifiedSharedWorksheet: boolean;
	createdAt: Date;
	updatedAt?: Date;
	user: string;
	userId: string;
	customerId: string;
	customerName: string;
	customerNumber: string;
	subscriptionId: string;
	subscriptionName: string;
	subscriptionNumber: string;
	productName: string;
};

export type GetWorksheetsResponse = {
	worksheets: Paginated<WorksheetRow>;
	subscriptionsSelected: SubscriptionOption[];
	usersSelected: UserOption[];
	customersSelected: CustomerOption[];
	reportTypeOptions: LabeledValue[];
	reportTypesSelected: string[];
	createdBeforeDate?: Date;
	createdAfterDate?: Date;
};

class WebToolWorksheetAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getWorksheets(request: URLSearchParams) {
		return WebToolWorksheetAPI.get<GetWorksheetsResponse>(
			`/web-tool/worksheets-admin?${request}`
		);
	}

	static beginAdminWorksheetExport(format: string, request: URLSearchParams) {
		return WebToolWorksheetAPI.post<GetWorksheetsResponse>(
			`/web-tool/worksheets-admin/export?exportType=${format}&${request}`
		);
	}
}

export default WebToolWorksheetAPI;
