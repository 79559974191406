/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';

export const emailContentSchema = z.object({
	id: z.string(),
	name: z
		.string({ required_error: 'Name is required' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	subject: z
		.string()
		.transform((data) => data.replace(/(<([^>]+)>)/gi, '').replace('\n', ''))
		.pipe(
			z
				.string({ required_error: 'Subject is required' })
				.max(250, { message: 'Subject must not be more than 250 characters' })
		),
	content: z.string({ required_error: 'Content is required' }).max(15000, {
		message: 'Content must not be more than 15000 characters',
	}),
	comments: z
		.string({})
		.max(5000, { message: 'Comments must not be more than 5000 characters' }),
	version: z.number(), // Provided by the backend
});

// UPDATE SCHEMA

export const updateEmailContentSchema = emailContentSchema.pick({
	name: true,
	subject: true,
	content: true,
	comments: true,
});

export type UpdateEmailContentModel = z.infer<typeof updateEmailContentSchema>;
