import { PropsWithChildren } from 'react';
import BulletIndicator, {
	BulletIndicatorProps,
} from 'ui/components/BulletIndicator/BulletIndicator';
import Card from 'ui/components/Card';
import Flex from 'ui/components/Flex';
import SystemProcessToggle, {
	SystemProcessToggleProps,
} from './SystemProcessToggle';

type SystemProcessCardProps = PropsWithChildren<{
	title: string;
	label?: string;

	bulletIndicatorProps: BulletIndicatorProps;
	toggleProps: SystemProcessToggleProps;
}>;

const SystemProcessCard = ({
	title,
	label,
	bulletIndicatorProps,
	toggleProps,
	children,
}: SystemProcessCardProps) => {
	return (
		<Card className="system-process">
			<Flex gap={12} justifyContent="space-between" alignItems="center">
				<Flex direction="column" gap={4}>
					<Flex gap={4}>
						<p className="system-process__name">{title}</p>
						<p className="system-process__label">{label}</p>
					</Flex>
					<BulletIndicator {...bulletIndicatorProps} />
				</Flex>

				<SystemProcessToggle {...toggleProps} />
			</Flex>
			<div className="system-process__content">{children}</div>
		</Card>
	);
};

export default SystemProcessCard;
