import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LabeledValue } from 'utils/types/common';
import { DropdownField } from '../../forms/DropdownField';

interface QuickFiltersProps {
	options: LabeledValue[];

	paramName?: string;
	formRef?: React.RefObject<HTMLFormElement>;
}

const QuickFilters = ({
	options,
	paramName = 'quickFilter',
	formRef,
}: QuickFiltersProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const hasOtherSearchParameters = [...searchParams.entries()].some(
		([key, value]) => key !== paramName && value !== ''
	);

	useEffect(() => {
		if (hasOtherSearchParameters) {
			setSelectedOption(null);
		} else {
			setSelectedOption(
				options.find(
					(option) => option.value === searchParams.get(paramName)
				) || null
			);
		}
	}, [searchParams]);

	const [selectedOption, setSelectedOption] = useState<LabeledValue | null>(
		options.find((option) => option.value === searchParams.get(paramName)) ||
			null
	);

	const handleOptionSelected = (option: LabeledValue | null) => {
		setSelectedOption(option);
		navigate({
			search: option ? `?${paramName}=${option.value}` : '',
		});

		if (formRef?.current && option) {
			formRef.current.reset();
		}
	};

	return (
		<DropdownField
			name="quickfilters"
			selectedOption={selectedOption}
			options={options}
			contentSource={(option) => option.label}
			placeholder="Quick filters..."
			identifierKey="value"
			onOptionSelected={handleOptionSelected}
		/>
	);
};

export default QuickFilters;
