import classNames from 'classnames';
import { Loader2, LucideIcon } from 'lucide-react';

type ListItemAction = {
	altText: string;
	onClick: () => void;
	isLoading?: boolean;
	icon: LucideIcon;
};

type ListItemProps = {
	className?: string;
	onClick?: () => void;
	label: string;
	description?: string;
	actions?: ListItemAction[];
};

const ListItem = ({
	label,
	description,
	onClick,
	actions,
	className,
}: ListItemProps) => {
	return (
		<li
			className={classNames(
				'list__item',
				onClick && 'list__item--clickable',
				className
			)}
			onClick={onClick}
		>
			<div className="list__wrapper">
				<div className="list__item-content">
					<span className="list__label">{label}</span>
					{description && (
						<span className="list__description">{description}</span>
					)}
				</div>
				{actions && actions.length > 0 && (
					<div className="list__actions">
						{actions?.map(({ isLoading, altText, onClick, icon: Icon }) => (
							<button
								key={altText}
								className={classNames(
									'list__action',
									isLoading && 'list__action--loading'
								)}
								onClick={onClick}
								aria-label={altText}
							>
								{isLoading ? <Loader2 size="16" /> : <Icon size={16} />}
							</button>
						))}
					</div>
				)}
			</div>
		</li>
	);
};

export default ListItem;
