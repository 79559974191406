import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import { matchIntent } from 'ui/components/BulletIndicator/BulletIndicator';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import RevalidationButton from 'ui/components/RevalidationButton';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import DataManagementAPI, {
	CommonLambdaResponse,
	StatusCodes,
} from '../../api/DataManagementAPI';
import SystemProcess from './SystemProcess';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return await DataManagementAPI.systemProcesses();
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	const systemProcessCode =
		(formData.get('systemProcessCode') as string) || null;
	const statusCode = (formData.get('statusCode') as string) || null;

	if (!systemProcessCode || !statusCode) {
		return {
			errorCode: 'missing-fields',
			message: 'Missing fields',
		};
	}

	const apiResponse = await DataManagementAPI.updateProcessStatus(
		systemProcessCode,
		statusCode
	);

	if ('fieldErrors' in apiResponse) {
		return {
			errorCode: 'field-errors',
			successCode: null,
			message: 'There were errors with the data you submitted.',
			fieldErrors: apiResponse.fieldErrors,
		} as CommonLambdaResponse;
	}

	if (apiResponse instanceof Error) {
		return null;
	}

	return apiResponse;
};

export function blockingProcessIntent(statusCode: StatusCodes) {
	return matchIntent(statusCode, {
		success: 'idle',
		warning: 'paused',
		catchAll: 'info',
		error: 'failed',
	});
}

export function listenOrPausedIntent(statusCode: StatusCodes) {
	return matchIntent(statusCode, {
		success: 'listening',
		warning: 'paused',
	});
}

export function systemOverviewIntent(statusCode: StatusCodes) {
	return matchIntent(statusCode, {
		success: 'idle',
		warning: 'paused',
		catchAll: 'info',
	});
}

export function SystemStatusPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	return (
		<>
			<div className="content">
				<PageHeader
					title={
						<Flex alignItems="start" direction="column" gap={12}>
							<span>{data.systemProcess.name}</span>
							<BulletIndicator
								intent={systemOverviewIntent(data.systemProcess.status.code)}
								label={`${data.systemProcess.status.name}`}
							/>
						</Flex>
					}
				>
					<Flex alignItems="center" gap={16}>
						<RevalidationButton>Refresh</RevalidationButton>
					</Flex>
				</PageHeader>

				<h3>Data Processes</h3>
				<Grid columns={2}>
					{data.processes
						.filter((f) => f.type === 'blocking-process')
						.map((process) => (
							<SystemProcess systemProcess={process} key={process.code} />
						))}
				</Grid>

				{data.processes
					.filter((f) => f.type === 'regulator')
					.map((process) => (
						<SystemProcess systemProcess={process} key={process.code} />
					))}
			</div>
		</>
	);
}

export const SYSTEM_STATUS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <SystemStatusPage />,
	handle: {
		breadcrumbs: () => {
			return { label: 'System Status' };
		},
	},
};
