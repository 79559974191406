import { Editor } from '@tinymce/tinymce-react';

import { ComponentProps } from 'react';

import HTMLEditorField from 'ui/components/HTMLEditorField';
import { HTMLEditorFieldProps } from 'ui/components/HTMLEditorField/HTMLEditorField';
import { ToastType } from 'ui/components/Toaster/Toast';
import { createToast } from 'utils/helpers/toast';

type EditorProps = ComponentProps<typeof Editor>;

export type EditorTag = {
	title: string;
	value: string;
};

type MailSubjectEditorProps = Omit<HTMLEditorFieldProps, 'editorOptions'> & {
	tags?: EditorTag[];
};

const MailSubjectEditor = ({ tags, ...restProps }: MailSubjectEditorProps) => {
	const handleKeyDown: EditorProps['onKeyDown'] = (e) => {
		// Prevent the user from entering new lines
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

	const handlePaste: EditorProps['onPaste'] = (e) => {
		// Prevent the user from pasting new lines
		const textContainsNewLine =
			e.clipboardData?.getData('text/plain').includes('\n') ?? false;
		const htmlContainsNewLine =
			e.clipboardData?.getData('text/html').includes('\n') ?? false;

		if (textContainsNewLine || htmlContainsNewLine) {
			e.preventDefault();
			createToast(ToastType.ERROR, 'You cannot paste multi-line text here');
		}
	};

	return (
		<HTMLEditorField
			{...restProps}
			onKeyDown={handleKeyDown}
			onPaste={handlePaste}
			editorOptions={{
				formats: {},
				paste_auto_cleanup_on_paste: true,
				paste_remove_styles: true,
				paste_remove_styles_if_webkit: true,
				paste_strip_class_attributes: true,
				plugins: ['mergetags'],
				max_height: 54,
				toolbar: false,
				mergetags_prefix: '{{',
				mergetags_suffix: '}}',
				mergetags_list: tags ?? [],
				valid_elements:
					'span[data-mce-cef-wrappable,data-mce-mergetag,data-mce-selected,class,contenteditable]',
			}}
		/>
	);
};

export default MailSubjectEditor;
