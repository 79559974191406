import API from 'utils/api/API';
import FreightForwarderAPI from 'utils/api/FreightForwarderAPI';
import { ENVIRONMENT_SHARED } from 'environment';
import { Paginated } from 'utils/types/common';

export type AdminExportsResponse = {
	exports: Paginated<AdminExportItem>;
};

export type AdminExportItem = {
	id: string;
	exportType: string;
	createdByAdminUser: string;
	createdAt: Date;
	startedAt?: Date;
	completedAt?: Date;
	exportFileName?: string;
	exportFileSize?: string;
	exportFileUrl?: string;
	exportFileSizeInBytes?: number;
	status: string;
	failureMessage?: string;
};

class AdminExportsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;
	static getAdminExports() {
		return FreightForwarderAPI.get<AdminExportsResponse>(`/admin-exports`);
	}
}

export default AdminExportsAPI;
