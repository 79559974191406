import AsyncMultiSelect from 'ui/components/AsyncMultiSelect';
import AsyncSelect from 'ui/components/AsyncSelect';
import FormField from 'ui/components/FormField';
import Pill from 'ui/components/Pill';
import useValidation from 'ui/components/ValidatedForm/useValidation';
import AdminUserPickerItem from './AdminUserPickerItem';
import AdminUserAPI, { AdminUserItem } from '../../api/AdminUserAPI';

export type AdminUserPickerProps = {
	name: string;
	label?: string;
	isMulti?: boolean;
	isRequired?: boolean;
	initialValues?: AdminUserItem[];
	onChange?: (user: AdminUserItem | null) => void;
};

export const AdminUserContentSource = (user: AdminUserItem) => {
	return <AdminUserPickerItem user={user} />;
};

export const AdminUserPickerPill = ({
	value,
	onRemove,
}: {
	value: AdminUserItem;
	onRemove?: () => void;
}) => {
	return (
		<Pill
			name={value.name}
			title={`${value.email}\n${value.adminRole.name}`}
			onRemove={onRemove}
		/>
	);
};

const AdminUserPicker = ({
	name,
	label,
	isMulti = false,
	initialValues,
	isRequired,
	onChange,
}: AdminUserPickerProps) => {
	const { errorTree, revalidate } = useValidation(name);

	const getUsers = async (searchTerm: string) => {
		const data = await AdminUserAPI.lookup({ keyword: searchTerm });
		return data.options;
	};

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={isRequired}
		>
			{isMulti ? (
				<AsyncMultiSelect<AdminUserItem>
					onSearch={getUsers}
					identifierKey="id"
					initialValues={initialValues ?? []}
					onOptionsSelected={revalidate}
					contentSource={AdminUserContentSource}
					pillComponent={AdminUserPickerPill}
					placeholder="Select users..."
					name={name}
				/>
			) : (
				<AsyncSelect<AdminUserItem>
					onSearch={getUsers}
					initialValue={initialValues ? initialValues[0] : null}
					onOptionSelected={(newValue) => {
						if (onChange) {
							onChange(newValue);
						}
						revalidate();
					}}
					identifierKey="id"
					contentSource={AdminUserContentSource}
					isClearable={true}
					placeholder="Select a user..."
					name={name}
				/>
			)}
		</FormField>
	);
};

export default AdminUserPicker;
