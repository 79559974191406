import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import ListItem from './ListItem';

type ListProps = PropsWithChildren<{
	className?: string;
	type?: 'ordered' | 'unordered';
}>;

const List = ({ children, type, className }: ListProps) => {
	const ListComponent = type === 'ordered' ? 'ol' : 'ul';

	return (
		<ListComponent className={classNames('list', className)}>
			{children}
		</ListComponent>
	);
};

List.Item = ListItem;

export default List;
