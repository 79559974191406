import { Download, PlusCircle, RefreshCcw, Search } from 'lucide-react';
import React, { useId, useRef } from 'react';
import toast from 'react-hot-toast';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import CountryPicker from 'ui/components/CountryPicker';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import APIError from 'utils/errors/APIError';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UserAPI from '../../api/UserAPI';
import * as userColumns from '../../util/columns/userColumns';
import { getSearchParamsFromButtonForm } from '../../util/forms';
import QuickFilters from '../../components/QuickFilters/QuickFilters';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getUsersResponse = await UserAPI.getUsers(
		new URL(request.url).searchParams
	);

	return {
		getUsersResponse,
	};
};

async function handleDownloadData(e: React.MouseEvent<HTMLButtonElement>) {
	e.preventDefault();

	toast.loading('Exporting...');

	const searchParams = getSearchParamsFromButtonForm(e.currentTarget);
	const getUsersResponse = await UserAPI.getUsersDownloadData(searchParams);

	downloadFile(getUsersResponse.url, getUsersResponse.fileName);
}

export function UsersListPage() {
	const formId = useId();
	const formRef = useRef<HTMLFormElement>(null);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		userColumns.fullNameColumn,
		userColumns.customerNameColumn,
		userColumns.customerTypeColumn,
		userColumns.emailColumn,
		userColumns.activeFromColumn,
		userColumns.activeToColumn,
		userColumns.subscriptionsColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Users">
					<QuickFilters
						options={data.getUsersResponse.filterOptions}
						formRef={formRef}
					/>
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						type="button"
						variant="secondary"
						form={formId}
						icon={Download}
						onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
							let close = true;
							handleDownloadData(e)
								.catch((reason: APIError) => {
									toast.remove();
									toast.error(reason.message);

									close = false;
								})
								.finally(() => {
									if (close) {
										toast.remove();
									}
								});
						}}
					>
						Download data
					</Button>
					<Button variant="secondary" icon={PlusCircle} to="/users/create">
						Add User
					</Button>
				</PageHeader>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.getUsersResponse.users.items}
					formId={formId}
				/>

				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.getUsersResponse.users.page}
					pageParameterName="page"
					pageSize={data.getUsersResponse.users.pageSize}
					itemCount={data.getUsersResponse.users.totalCount}
				/>
			</div>

			<Sidebar title="User Filter">
				<ValidatedForm method="get" id={formId} resetOnNavigation>
					<TextField
						label="Keyword Search"
						name="keyword"
						defaultValue={data.getUsersResponse.keyword ?? undefined}
					/>

					<MultiSelectField
						label="Customer Type"
						name="customertypes"
						options={data.getUsersResponse.customerTypeOptions}
						contentSource={(option) => option.name}
						identifierKey="id"
						placeholder="Please select..."
						pillName={(item) => item.name}
					/>

					<MultiSelectField
						label="Customer Status"
						name="customerstatus"
						options={data.getUsersResponse.customerStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<MultiSelectField
						label="Account Manager"
						name="accountmanagers"
						options={data.getUsersResponse.accountManagerOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<CountryPicker
						label="Country"
						name="countries"
						initialValue={data.getUsersResponse.countriesSelected[0]}
					/>

					<MultiSelectField
						label="CargoIS Region"
						name="regions"
						options={data.getUsersResponse.regionOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_USERS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <UsersListPage />,
	handle: {
		breadcrumbs: { label: 'Users' },
	},
};
