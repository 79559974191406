import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { createSubscriptionSchema } from 'utils/schemas/subscriptionSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import AuditCommentField from 'ui/components/AuditCommentField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import {
	DEFAULT_WEIGHT_BREAKS_CASS,
	DEFAULT_WEIGHT_BREAKS_CDD,
} from 'ui/components/WeightBreaks/WeightBreaks';
import { WeightBreaksField } from 'ui/forms/WeightBreaksField';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	AllProductDetails,
	GetSubscriptionResponse,
	ProductOption,
	isDeliveryStatusApplicable,
	isProductTypeWebTool,
} from '../../api/SubscriptionAPI';
import CustomerPicker from '../../components/CustomerPicker';
import { DropdownField } from '../../forms/DropdownField';
import ProductConfigurationEditor, {
	doesProductHave,
	productTypesWithSettings,
} from './ProductConfigurationEditor';
import { WebToolConfigurationEditor } from './WebToolConfigurationEditor';
import {
	WebToolConfigurationEditorProvider,
	WebToolSubscriptionPeriodCalculated,
} from './WebToolConfigurationEditorProvider';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	const searchParams = url.searchParams;

	const subscriptionOptions = await SubscriptionAPI.getSubscriptionOptions(
		searchParams.get('customerId')
	);

	const templateId = searchParams.get('templateId');
	const templateSubscription = templateId
		? await SubscriptionAPI.getSubscription(templateId)
		: null;

	return {
		templateSubscription,
		options: subscriptionOptions,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	const subscriptionModel = formDataAsObject(formData);

	const createSubscriptionModel =
		createSubscriptionSchema.parse(subscriptionModel);

	const data = await SubscriptionAPI.createSubscription(
		createSubscriptionModel
	);

	if ('subscriptionId' in data) {
		createToast(ToastType.SUCCESS, 'Subscription created successfully');
		return redirect(`/subscriptions/${data.subscriptionId}`);
	}

	return data;
};

export function CreateSubscription() {
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const { templateSubscription, ...data } = useLoaderData() as LoaderData<
		typeof loader
	>;

	const [showProductsForAllCustomerTypes, setShowProductsForAllCustomerTypes] =
		useState(false);

	const [selectedAccountType, setSelectedAccountType] = useState<string | null>(
		templateSubscription?.customer.accountType ??
			data.options.customerOptions[0]?.accountType
	);

	const [product, setProduct] = useState<ProductOption | null>(
		templateSubscription?.product ?? null
	);

	const activeProducts = data.options.productOptions.filter(
		(p) =>
			productTypesWithSettings.includes(p.productType) &&
			(p.forAccountType === selectedAccountType ||
				(selectedAccountType && p.forAccountType === null) ||
				(showProductsForAllCustomerTypes &&
					p.forAccountType !== 'demo' &&
					p.id !== product?.id))
	);

	const shouldShowWeightBreaks = doesProductHave(
		product?.productType,
		'weightBreaks'
	);
	const initialWeightBreaks = getInitialWeightBreaks(templateSubscription);

	const shouldShowWeightBreaksCdd = doesProductHave(
		product?.productType,
		'weightBreaksCdd'
	);
	const initialWeightBreaksCdd =
		getInitialCddWeightBreaks(templateSubscription);

	const isProductWebTool = isProductTypeWebTool(product);

	const showDeliveryStatusOptions = isDeliveryStatusApplicable(product);

	let initialCustomer = templateSubscription
		? [templateSubscription.customer]
		: undefined;

	if (!initialCustomer && data.options.customerOptions[0]) {
		initialCustomer = [data.options.customerOptions[0]];
	}

	const productConfiguration = templateSubscription?.productConfiguration as
		| Partial<AllProductDetails>
		| undefined;

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createSubscriptionSchema}>
				<WebToolConfigurationEditorProvider
					productType={product?.productType}
					dataGroups={productConfiguration?.webToolDataGroups ?? [{}]}
				>
					<Grid>
						<PageHeader title={getPageTitle(templateSubscription)}>
							<Button
								isDisabled={navigation.state !== 'idle'}
								data-testid="create-button"
							>
								Create subscription
							</Button>
						</PageHeader>
						<Grid>
							<ErrorList error={actionData} />
							<Grid columns={shouldShowWeightBreaks ? '1fr 1fr auto' : 2} isBox>
								<Grid>
									<TextField label="Name" name="name" maxLength={100} />
									<TextField
										label="Subscription Number"
										name="subscriptionNumber"
										placeholder="S1234567"
									/>
									<CustomerPicker
										name="customerId"
										label="Customer"
										initialValues={initialCustomer}
										isRequired
										onChange={(customer) => {
											if (customer !== null) {
												if (customer.accountType !== selectedAccountType) {
													setProduct(null);
												}
												setSelectedAccountType(customer.accountType);
											}
										}}
									/>

									{showDeliveryStatusOptions ? (
										<DropdownField
											options={data.options.subscriptionDeliveryStatusOptions}
											name="subscriptionDeliveryStatusCode"
											identifierKey="value"
											isClearable={false}
											label="Delivery Status"
											isRequired
											placeholder="Please select a delivery status"
											contentSource={(data) => data.label}
											initialValue={templateSubscription?.deliveryStatus}
										/>
									) : (
										<input
											type="hidden"
											name="subscriptionDeliveryStatusCode"
											value="disabled"
										/>
									)}

									{isProductWebTool ? (
										<WebToolSubscriptionPeriodCalculated />
									) : (
										<Grid columns={2}>
											<Grid>
												<YearMonthPicker
													name="startPeriod"
													label="Start Period"
													isRequired
													initialValue={templateSubscription?.startPeriod}
												/>
											</Grid>
											<Grid>
												<YearMonthPicker
													name="endPeriod"
													label="End Period"
													isRequired
													initialValue={templateSubscription?.endPeriod}
												/>
											</Grid>
										</Grid>
									)}

									<TextField
										label="Number of Licenses"
										name="numberOfLicenses"
										type="number"
										defaultValue={
											templateSubscription?.numberOfLicenses ?? undefined
										}
										min={0}
									/>

									<AuditCommentField />
								</Grid>
								<Grid>
									<DropdownField
										label="Product"
										name="productId"
										isClearable={false}
										isRequired
										isDisabled={
											selectedAccountType === null ||
											activeProducts.length === 0
										}
										onOptionSelected={(product) => {
											setProduct(product);
										}}
										initialValue={templateSubscription?.product}
										options={activeProducts}
										selectedOption={product}
										identifierKey="id"
										contentSource={(data) => data.name}
										placeholder="Select"
									/>

									<CheckboxField
										name="showAllProducts"
										onChange={() => {
											if (
												showProductsForAllCustomerTypes &&
												product?.forAccountType !== selectedAccountType
											) {
												setProduct(null);
											}

											setShowProductsForAllCustomerTypes(
												!showProductsForAllCustomerTypes
											);
										}}
										label="Show products for all customer types"
									/>

									{product &&
										(isProductWebTool ? (
											<WebToolConfigurationEditor
												product={product}
												options={data.options}
												productConfig={
													templateSubscription?.productConfiguration
												}
											/>
										) : (
											<ProductConfigurationEditor
												product={product}
												options={data.options}
												productConfig={
													templateSubscription?.productConfiguration
												}
											/>
										))}
								</Grid>
								<Grid>
									{shouldShowWeightBreaks && (
										<WeightBreaksField
											label="CASS Weight Breaks"
											name="productConfiguration.weightBreaks"
											weightBreaks={DEFAULT_WEIGHT_BREAKS_CASS}
											initialValue={initialWeightBreaks}
										/>
									)}
									{shouldShowWeightBreaksCdd && (
										<WeightBreaksField
											label="CDD Weight Breaks"
											name="productConfiguration.weightBreaksCdd"
											weightBreaks={DEFAULT_WEIGHT_BREAKS_CDD}
											initialValue={initialWeightBreaksCdd}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</WebToolConfigurationEditorProvider>
			</ValidatedForm>
		</div>
	);
}

function getPageTitle(subscription: GetSubscriptionResponse | null) {
	return subscription
		? `Duplicate:
		${subscription.name || subscription.product.name} from
		${subscription.customer.name}
		(${subscription.customer.customerNumber})`
		: 'Create new subscription';
}

function getInitialWeightBreaks(
	subscription: GetSubscriptionResponse | null
): number[] | undefined {
	if (subscription && 'weightBreaks' in subscription.productConfiguration) {
		return subscription.productConfiguration.weightBreaks;
	}

	return undefined;
}

function getInitialCddWeightBreaks(
	subscription: GetSubscriptionResponse | null
): number[] | undefined {
	if (subscription && 'weightBreaksCdd' in subscription.productConfiguration) {
		return subscription.productConfiguration.weightBreaksCdd;
	}

	return undefined;
}

export const CREATE_SUBSCRIPTION_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <CreateSubscription />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
