import ConfirmationModal from 'ui/components/ConfirmationModal/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import reportDistributionAPI from '../../api/ReportDistributionAPI';

type ExecuteDistributionModalProps = {
	onSubmit?: () => void;
} & Omit<ModalProps, 'title'>;

const ExecuteDistributionModal = ({
	onSubmit,
	...modalProps
}: ExecuteDistributionModalProps) => {
	const handleSubmit = async () => {
		await reportDistributionAPI.executeReportDistribution();

		if (onSubmit) {
			onSubmit();
		}
	};

	return (
		<ConfirmationModal
			fieldName="confirm-distribution"
			title="Execute Distribution"
			submitText="Start Distribution"
			confirmationText="confirm"
			onSubmit={handleSubmit}
			{...modalProps}
		>
			<p>Are you sure you wish to start schedule?</p>
		</ConfirmationModal>
	);
};

export default ExecuteDistributionModal;
