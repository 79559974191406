import { createColumnHelper } from '@tanstack/react-table';
import { FileArchive } from 'lucide-react';
import { useState } from 'react';
import AttachmentLink from 'ui/components/AttachmentLink';
import BulletIndicator from 'ui/components/BulletIndicator';
import DateFragment from 'ui/components/DateFragment';
import SplitButton from 'ui/components/SplitButton/SplitButton';
import { ToastType } from 'ui/components/Toaster/Toast';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import CddContributorAPI, {
	CddErrorCode,
	CddSubmissionModel,
} from 'utils/api/CddContributorAPI';
import { downloadFile } from 'utils/helpers/file';
import { createToast, createToastFromError } from 'utils/helpers/toast';

const columnHelper = createColumnHelper<CddSubmissionModel>();

export const contributorCodeColumn = columnHelper.accessor('contributorCode', {
	header: 'Code',
});

export const contributorPrefixColumn = columnHelper.accessor(
	'contributorPrefix',
	{
		header: 'Prefix',
	}
);

export const externalFileColumn = columnHelper.display({
	id: 'file',
	header: 'External File',
	cell: (info) => {
		if (!info.row.original.canDownload) {
			return <> {info.row.original.externalFile}</>;
		}
		return (
			<AttachmentLink
				showFileName={true}
				url={async () => {
					const downloadData = await CddContributorAPI.downloadFileAdmin(
						info.row.original.externalFileId
					);

					if (downloadData instanceof Error) {
						throw downloadData;
					}

					return downloadData.url;
				}}
				name={info.row.original.externalFile}
			/>
		);
	},
});

export const receivedAtColumn = columnHelper.accessor('receivedAt', {
	header: 'Received At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export function statusColumn(codeDescriptions: Record<string, CddErrorCode>) {
	return columnHelper.accessor('status', {
		header: 'Status',
		cell: (info) => {
			const errorCodes = info.row.original.errorCodes;
			if (errorCodes.length > 0) {
				const codeDescription = (code: string) => {
					return codeDescriptions[code]?.description ?? code;
				};
				return (
					<BulletIndicator
						intent="error"
						label={
							<Tooltip>
								<Tooltip.Trigger>
									<span>{info.row.original.status}</span>
								</Tooltip.Trigger>
								<Tooltip.Content>
									{errorCodes
										.map((errorCode) => codeDescription(errorCode))
										.join(', ')}
								</Tooltip.Content>
							</Tooltip>
						}
					/>
				);
			}

			if (info.row.original.completedAt) {
				return (
					<BulletIndicator intent="success" label={info.row.original.status} />
				);
			}

			return (
				<BulletIndicator intent="warning" label={info.row.original.status} />
			);
		},
	});
}

export const statusContributorColumn = columnHelper.accessor(
	'statusContributor',
	{
		header: 'Contributor Status',
	}
);

export const startedAtColumn = columnHelper.accessor('startedAt', {
	header: 'Started At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const completedAtColumn = columnHelper.accessor('completedAt', {
	header: 'Completed At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const rowCountColumn = columnHelper.display({
	id: 'rowCount',
	header: 'Valid / Invalid Rows (Percentage)',
	cell: (info) => {
		return info.row.original.rowCount ? (
			<>
				{info.row.original.validRowCount?.toLocaleString()} {' / '}
				{info.row.original.invalidRowCount?.toLocaleString()}
				{info.row.original.invalidRowCount ? (
					<>
						{' ('}
						{(
							(100 / info.row.original.rowCount) *
							(info.row.original.invalidRowCount ?? 0)
						)?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
						{'%)'}
					</>
				) : null}
			</>
		) : (
			<>-</>
		);
	},
});

const exportCddReport = async (
	id: string,
	format: 'full' | 'error' | 'alert'
) => {
	const downloadData = await CddContributorAPI.downloadReportAdmin(id, format);

	if (downloadData instanceof Error) {
		createToastFromError(downloadData);
		return;
	}

	if (downloadData.delayed) {
		createToast(
			ToastType.SUCCESS,
			'Report generation moved to background. You will receive an email upon completion.'
		);
		return;
	}

	downloadFile(downloadData.url, downloadData.filename);
};

const ExportCddSplitButton = ({ id }: { id: string }) => {
	const [isLoading, setLoading] = useState(false);

	const exportReport = async (format: 'full' | 'error' | 'alert') => {
		setLoading(true);
		await exportCddReport(id, format);
		setLoading(false);
	};

	return (
		<SplitButton
			variant="secondary"
			size="small"
			defaultActionId="error-only"
			isLoading={isLoading}
			loadingText="Exporting..."
			actions={[
				{
					id: 'full',
					label: 'Export Full Report',
					icon: FileArchive,
					onClick: () => exportReport('full'),
				},
				{
					id: 'error-only',
					label: 'Export Errors',
					icon: FileArchive,
					onClick: () => exportReport('error'),
				},
				{
					id: 'alert-only',
					label: 'Export Alerts',
					icon: FileArchive,
					onClick: () => exportReport('alert'),
				},
			]}
		/>
	);
};

export const downloadReportColumn = columnHelper.accessor('canDownload', {
	header: 'Export Report',
	cell: (info) => {
		if (!info.row.original.rowCount) return null;
		return <ExportCddSplitButton id={info.row.original.externalFileId} />;
	},
});
