import classNames from 'classnames';
import { memo } from 'react';
import DateFragment from 'ui/components/DateFragment';
import Tooltip from 'ui/components/Tooltip/Tooltip';
import { HotFile } from '../../api/DataManagementAPI';

type HotFileDetailsProps = {
	file: HotFile;
	className?: string;
	compact?: boolean;
};

const HotFileDetails = ({
	file,
	className,
	compact = false,
}: HotFileDetailsProps) => {
	const { submissionKey, type, exists } = file;

	const typeClass = `hotfile--${type}`;
	const existsClass = exists ? 'hotfile--exists' : 'hotfile--missing';

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<div
					className={classNames(
						'hotfile',
						compact && 'hotfile--compact',
						typeClass,
						existsClass,
						className
					)}
				>
					{compact ? null : submissionKey}
				</div>
			</Tooltip.Trigger>
			<Tooltip.Content className="hotfile__tooltip">
				{compact ? (
					submissionKey
				) : (
					<ul>
						<li>
							<b>File name:</b> {file.fileName}
						</li>
						<li>
							<b>Delivered on:</b>{' '}
							<DateFragment date={file.deliveryDate} timezone="utc" />
						</li>
					</ul>
				)}
			</Tooltip.Content>
		</Tooltip>
	);
};

export default memo(HotFileDetails);
