import classNames from 'classnames';
import { ReactNode } from 'react';
import StepIcon from './MultiStepProgressStepIcon';

export enum StepStatus {
	Pending = 'pending',
	InProgress = 'in-progress',
	Completed = 'completed',
	Error = 'error',
}

type Step = {
	label: string;
	status: StepStatus;
	info?: string | ReactNode;
};

type MultiStepProgressProps = {
	steps: Step[];
};

const MultiStepProgress = ({ steps }: MultiStepProgressProps) => {
	return (
		<div className="multi-step-progress">
			<div className="multi-step-progress__steps">
				{steps.map((step, index) => (
					<div
						key={index}
						className={classNames(
							'multi-step-progress__step step',
							`step--${step.status}`
						)}
					>
						<div className="step__icon">
							<StepIcon status={step.status} size={16} />
						</div>

						<div className="step__label">{step.label}</div>
						{step.info && <div className="step__info">{step.info}</div>}
					</div>
				))}
			</div>
			<div className="multi-step-progress__bar" />
		</div>
	);
};

export default MultiStepProgress;
