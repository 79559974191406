import Flex from 'ui/components/Flex';
import { SubscriptionOption } from '../../api/SubscriptionAPI';

type SubscriptionPickerItemProps = {
	subscription: SubscriptionOption;
};

const SubscriptionPickerItem = ({
	subscription,
}: SubscriptionPickerItemProps) => {
	return (
		<Flex className="subscription-picker__item" direction="column" gap={2}>
			<div className="subscription-picker__info">
				<span className="subscription-picker__name">{subscription.name}</span>
				<div className="subscription-picker__number">
					Subscription Number: {subscription.subscriptionNumber}
				</div>
			</div>
		</Flex>
	);
};

export default SubscriptionPickerItem;
