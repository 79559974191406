export function msToMinutesAndSeconds(ms: number) {
	const seconds = Math.floor((ms / 1000) % 60);
	const minutes = Math.floor((ms / 1000 / 60) % 60);

	return `${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`;
}

export function ReportBulletIndicatorIntent(status: string) {
	if (status === null) {
		return 'info';
	}
	switch (status.toLocaleLowerCase()) {
		case 'started': {
			return 'info';
		}
		case 'running': {
			return 'info';
		}
		case 'success': {
			return 'success';
		}
		case 'failed': {
			return 'error';
		}
		default: {
			return 'warning';
		}
	}
}
export function ReportDistributionBulletIndicatorIntent(status: string) {
	if (status === null) {
		return 'info';
	}
	switch (status.toLocaleLowerCase()) {
		case 'started': {
			return 'info';
		}
		case 'running': {
			return 'info';
		}
		case 'succeeded': {
			return 'success';
		}
		case 'failed': {
			return 'error';
		}
		default: {
			return 'warning';
		}
	}
}
