import { createColumnHelper } from '@tanstack/react-table';
import { Eye } from 'lucide-react';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import { ReportNotificationsItem } from '../../api/ReportNotificationsAPI';

type BulletIndicatorIntent = ComponentProps<typeof BulletIndicator>['intent'];

export const notificationStatusIntentMap: {
	[key: string]: BulletIndicatorIntent;
} = {
	Completed: 'success',
	Failed: 'error',
	Pending: 'info',
	Started: 'info',
	Delivered: 'success',
};

const columnHelper = createColumnHelper<ReportNotificationsItem>();

export const statusColumn = columnHelper.accessor('status', {
	header: 'Status',
	cell: (info) => {
		return (
			<BulletIndicator
				intent={notificationStatusIntentMap[info.getValue()]}
				label={info.getValue()}
			/>
		);
	},
});

export const createdDateColumn = columnHelper.accessor('createdAt', {
	header: 'Created At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const completedDateColumn = columnHelper.accessor('completedAt', {
	header: 'Completed At (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const releaseDateColumn = columnHelper.display({
	header: 'Report/Release Date',
	cell: (info) => {
		const data = info.row.original;
		if (data.reportDate === null || data.reportDate === undefined)
			return (
				<>
					<DateFragment
						includeTime={false}
						date={data.releaseDate}
						timezone="utc"
					/>
				</>
			);
		return (
			<>
				<YearAndMonthFragment value={data.reportDate} />
			</>
		);
	},
});

export const subscriptionCodeColumn = columnHelper.accessor('subscriptionId', {
	header: 'Subscription Code',
	cell: (info) => (
		<Link to={`/subscriptions/${info.row.original.subscriptionId}`}>
			{info.row.original.subscriptionNumber}
		</Link>
	),
});

export const reportColumn = columnHelper.accessor('report', {
	header: 'Report',
	cell: (info) => info.getValue(),
});

export const viewDetailsColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: (info) => {
		return (
			<Button
				size="small"
				variant="secondary"
				icon={Eye}
				to={`/report-notifications/${info.row.original.id}`}
			>
				View
			</Button>
		);
	},
});
