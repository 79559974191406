import classNames from 'classnames';
import { ComponentProps, useId, useRef } from 'react';
import FormField, { FormFieldProps } from '../FormField';
import useValidation from '../ValidatedForm/useValidation';

export type TextAreaFieldProps = {
	name: string;
	className?: string;
} & ComponentProps<'textarea'> &
	FormFieldProps;

const TextAreaField = (props: TextAreaFieldProps) => {
	let { label } = props;
	let ref = useRef(null);
	const id = useId();

	const { errorTree } = useValidation(props.name);

	return (
		<FormField label={label} id={id} {...props} errors={errorTree?._errors}>
			<textarea
				{...props}
				id={id}
				ref={ref}
				className={classNames('control__input', props.className)}
			/>
		</FormField>
	);
};

export default TextAreaField;
