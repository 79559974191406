import { Trash2 } from 'lucide-react';
import Button from 'ui/components/Button';
import Modal, { ModalProps } from 'ui/components/Modal/Modal';
import ValidatedForm from 'ui/components/ValidatedForm';
import { AirImpactReportModel } from 'utils/schemas/airImpactReportSchema';

type DeleteAirImpactReportProps = {
	report: AirImpactReportModel;
} & Omit<ModalProps, 'title'>;

const DeleteAirImpactReportModal = ({
	report,
	...modalProps
}: DeleteAirImpactReportProps) => {
	return (
		<Modal title="Delete Air Cargo Impact Report" {...modalProps}>
			<ValidatedForm
				action={`/air-impact-reports/${report.id}`}
				method="delete"
			>
				<Modal.Body>
					<p>
						Are you sure you wish to delete the report <b>{report.title}</b>?
						This action cannot be undone.
					</p>
				</Modal.Body>
				<Modal.Actions>
					<Button
						type="submit"
						intent="danger"
						icon={Trash2}
						data-cy="deleteButton"
					>
						Delete Air Cargo Impact Report
					</Button>
				</Modal.Actions>
			</ValidatedForm>
		</Modal>
	);
};

export default DeleteAirImpactReportModal;
