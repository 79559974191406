import { RefreshCcw, Search } from 'lucide-react';
import { useId } from 'react';
import toast from 'react-hot-toast';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import ExportButton from 'ui/components/ExportButton';
import { ExportFormat } from 'ui/components/ExportButton/ExportButton';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { catchSilently } from 'utils/helpers/catchHandlers';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UsageTrackingApi from '../../api/UsageTrackingApi';
import CustomerPicker from '../../components/CustomerPicker';
import UserPicker from '../../components/UserPicker';
import { DropdownField } from '../../forms/DropdownField';
import * as usageTrackingColumns from '../../util/columns/usageTrackingColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const response = await UsageTrackingApi.getUsageTrackingData(
		new URL(request.url).searchParams
	);

	return {
		trackingData: response.trackingData,
		eventType: response.eventType,
		customersSelected: response.customersSelected,
		usersSelected: response.usersSelected,
		dateRangeDefault: response.dateRangeDefault,
		dateOptions: response.dateRangeOptions,
		eventOptions: response.eventTypeOptions,
	};
};

async function handleDownloadData(format: string) {
	const toastId = toast.loading('Starting Export...');

	const searchParams = new URLSearchParams(location.search);

	const rt = await UsageTrackingApi.beginUsageTrackingDataExport(
		format,
		searchParams
	);

	toast.remove();

	if (rt instanceof Error) {
		toast.error('An error has occurred when starting the export', {
			id: toastId,
		});
	} else {
		toast.success(
			'Export started. The file will be available in System > Admin Exports',
			{ id: toastId }
		);
	}
}

export function UsageTrackingListPage() {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const queryParams = new URLSearchParams(window.location.search);

	const columns = [
		usageTrackingColumns.timeStampColumn,
		usageTrackingColumns.userColumn,
		usageTrackingColumns.customerColumn,
		usageTrackingColumns.eventTypeColumn,
		usageTrackingColumns.eventDetailColumn,
		usageTrackingColumns.parametersColumn,
	];

	const availableFormats: ExportFormat[] = [
		{ id: 'xlsx', name: 'XLSX' },
		{ id: 'csv', name: 'CSV' },
	];
	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Usage Tracking">
					<RevalidationButton>Refresh</RevalidationButton>
					<ExportButton
						onExport={(format) =>
							handleDownloadData(format).catch(catchSilently)
						}
						fallbackFormatId="xlsx"
						availableFormats={availableFormats}
					/>
				</PageHeader>
				<Table
					identifierKey="id"
					columns={columns}
					data={data.trackingData.items}
				/>
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.trackingData.page}
					pageParameterName="page"
					pageSize={data.trackingData.pageSize}
					itemCount={data.trackingData.totalCount}
				/>
			</div>
			<Sidebar title="Usage Tracking Filter">
				<ValidatedForm method="get" id={formId} resetOnNavigation>
					<DropdownField
						label="Date Range"
						name="dateRange"
						placeholder="Show all"
						options={data.dateOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.dateRangeDefault}
						isClearable={false}
					/>
					<UserPicker
						name="userIds"
						label="User"
						initialValues={data.usersSelected ? data.usersSelected : []}
						isMulti={true}
					/>
					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>
					<MultiSelectField
						label="Event Type"
						name="eventType"
						identifierKey="id"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.name}`}
						options={data.eventOptions}
						pillName={(item) => item.name}
						initialValues={data.eventOptions.filter((item) =>
							data.eventType.includes(item.id)
						)}
					/>
					<TextField
						label="Event Detail"
						name="detail"
						defaultValue={queryParams.get('detail') ?? ''}
					/>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}
export const USAGE_TRACKING_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <UsageTrackingListPage />,
};
