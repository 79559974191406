import { MutableRefObject, SyntheticEvent, useRef, useState } from 'react';

type ConfirmationPromise = (comment?: string) => void;

const useAuditModal = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const confirmationPromise = useRef<ConfirmationPromise | null>(
		null
	) as MutableRefObject<ConfirmationPromise | null>;

	const confirmWithModal: () => Promise<string | undefined> = () => {
		setIsModalOpen(true);
		return new Promise<string | undefined>((resolve) => {
			confirmationPromise.current = resolve;
		});
	};

	const handleModalClose = (auditCommentOrEvent?: string | SyntheticEvent) => {
		if (confirmationPromise.current) {
			const stringAuditComment =
				typeof auditCommentOrEvent === 'string'
					? auditCommentOrEvent
					: undefined;

			confirmationPromise.current(stringAuditComment);
		}
		setIsModalOpen(false);
		confirmationPromise.current = null;
	};

	return {
		isOpen: isModalOpen,
		confirm: confirmWithModal,
		onClose: handleModalClose,
	};
};

export default useAuditModal;
