import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { AdminRoleOption, Country, CustomerTypeOption } from 'utils/api/common';
import {
	CreateAdminUserModel,
	UpdateAdminUserModel,
} from 'utils/schemas/adminUserSchema';
import { LabeledValue, Paginated } from 'utils/types/common';

export type AdminUserItem = {
	id: string;
	name: string;
	email: string;
	adminRole: AdminRoleOption;
};

type GetAdminUsersRequest = {
	keyword?: string;
	customerTypes?: string[];
	customerStatus?: string[];
	accountManagers?: string[];
	countries?: string[];
	regions?: string[];
	pageSize?: number;
	page?: number;
};

export type GetAdminUsersResponse = {
	keyword: string;
	customerTypes: string[];
	customerTypeOptions: CustomerTypeOption[];
	customerStatus: string[];
	customerStatusOptions: LabeledValue[];
	accountManagers: string;
	accountManagerOptions: LabeledValue[];
	countries: string[];
	countriesSelected: Country[];
	regions: string[];
	regionOptions: LabeledValue[];
	roles: AdminRoleOption[];
	adminUsers: Paginated<AdminUserItem>;
};

export type GetAdminUserResponse = {
	id: string;
	name: string;
	email: string;
	adminRole: AdminRoleOption;
};

export type GetAdminProfileResponse = {
	id: string;
	name: string;
	email: string;
	adminRole: AdminRoleOption;
};

export type GetAdminOptionsResponse = {
	adminRoles: AdminRoleOption[];
};

export type CreateAdminUserResponse = {
	userAdminId: string;
};

export type AdminUserLookupResponse = {
	keyword: string;
	options: AdminUserItem[];
};

class AdminUserAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getAdminUsers(request: GetAdminUsersRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return AdminUserAPI.get<GetAdminUsersResponse>(`/admin-users?${params}`);
	}

	static getAdminUser(userId: string) {
		return AdminUserAPI.get<GetAdminUserResponse>(`/admin-users/${userId}`);
	}

	static deleteAdminUser(id: string) {
		return AdminUserAPI.delete(`/admin-users/${id}`);
	}

	static createAdminUser(data: CreateAdminUserModel) {
		return AdminUserAPI.post<CreateAdminUserResponse>('/admin-users', data);
	}

	static updateAdminUser(id: string, data: UpdateAdminUserModel) {
		return AdminUserAPI.put(`/admin-users/${id}`, data);
	}

	static getAdminOptions() {
		return AdminUserAPI.get<GetAdminOptionsResponse>(`/admin-users/options`);
	}

	static getAdminProfile() {
		return AdminUserAPI.get<GetAdminProfileResponse>(`/admin-users/profile`);
	}

	static lookup({ keyword }: { keyword: string }) {
		return AdminUserAPI.get<AdminUserLookupResponse>(
			`/admin-users/lookup?keyword=${keyword}`
		);
	}
}

export default AdminUserAPI;
