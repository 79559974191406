import { useFetcher } from 'react-router-dom';
import Toggle from 'ui/components/Toggle';
import { ProcessStatus, ValidStatus } from '../../api/DataManagementAPI';

export type SystemProcessToggleProps = {
	systemProcessCode: string;
	status: ProcessStatus;
	activeStatus: 'idle' | 'listening';
	validStatus: ValidStatus[];
};

const SystemProcessToggle = ({
	systemProcessCode,
	status,
	activeStatus,
	validStatus,
}: SystemProcessToggleProps) => {
	const fetcher = useFetcher();

	const isDisabled =
		!validStatus.find((status) => status.code === 'paused') ||
		!['paused', 'idle', 'listening'].includes(status.code);

	const handleChange = async () => {
		fetcher.submit(
			{
				systemProcessCode: systemProcessCode,
				statusCode: status.code === 'paused' ? activeStatus : 'paused',
			},
			{ method: 'post' }
		);
	};

	return (
		<Toggle
			isDisabled={isDisabled}
			isChecked={status.code !== 'paused'}
			loading={fetcher.state !== 'idle'}
			onChange={handleChange}
		/>
	);
};

export default SystemProcessToggle;
