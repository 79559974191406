import { X } from 'lucide-react';
import {
	ActionFunctionArgs,
	redirect,
	useActionData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { createAccountManagerSchema } from 'utils/schemas/accountManagerSchema';
import { DecentralizedRouteProps } from 'utils/types/common';
import { ActionData } from 'utils/types/loaderData';

import AccountManagerAPI from '../../api/AccountManagersAPI';

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createAccountManagerModel = createAccountManagerSchema.parse(
			formDataAsObject(formData)
		);

		const data = await AccountManagerAPI.createAccountManager(
			createAccountManagerModel
		);

		if ('accountManagerId' in data) {
			createToast(ToastType.SUCCESS, 'Account manager created successfully');
			return redirect(`/account-managers/${data.accountManagerId}`);
		}

		return data;
	}

	return null;
};

export function CreateAccountManager() {
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm method="post" validator={createAccountManagerSchema}>
				<Grid>
					<PageHeader title="Create New Account Manager">
						<Button variant="secondary" to={`/account-managers`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Account Manager"
							type="submit"
						>
							Create Account Manager
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="First Name"
									name="firstName"
									isRequired={true}
								/>
								<TextField
									label="Last Name"
									name="lastName"
									isRequired={true}
								/>
							</Grid>
							<Grid>
								<TextField label="Title" name="title" isRequired={true} />
								<TextField label="Email" name="email" isRequired={true} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_ACCOUNT_MANAGER_ROUTE: DecentralizedRouteProps = {
	action,
	element: <CreateAccountManager />,
	handle: {
		breadcrumbs: { label: 'Create' },
	},
};
