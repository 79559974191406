import { createColumnHelper } from '@tanstack/react-table';
import AttachmentLink from 'ui/components/AttachmentLink';
import DateFragment from 'ui/components/DateFragment';
import { AdminExportItem } from '../../api/AdminExportsAPI';

const columnHelper = createColumnHelper<AdminExportItem>();

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const startedAtColumn = columnHelper.accessor('startedAt', {
	header: 'Started (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const completedAtColumn = columnHelper.accessor('completedAt', {
	header: 'Completed (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const statusColumn = columnHelper.accessor('status', {
	header: 'Status',
	cell: ({ getValue }) => `${getValue()}`,
});

export const exportTypeColumn = columnHelper.accessor('exportType', {
	header: 'Export Type',
	cell: ({ getValue }) => `${getValue()}`,
});

export const createdByAdminUserColumn = columnHelper.accessor(
	'createdByAdminUser',
	{
		header: 'Created  By',
		cell: ({ getValue }) => `${getValue()}`,
	}
);

export const exportFileNameColumn = columnHelper.accessor('exportFileName', {
	header: 'File',
	cell: ({ getValue }) => `${getValue() ?? '-'}`,
});

export const titleColumn = columnHelper.accessor('exportFileSize', {
	header: 'Download',
	cell: (info) => {
		if (!info.row.original.exportFileName || !info.row.original.exportFileUrl) {
			return '-';
		}
		return (
			<AttachmentLink
				name={info.row.original.exportFileName}
				title={info.row.original.exportFileName}
				url={info.row.original.exportFileUrl}
				size={info.row.original.exportFileSizeInBytes}
			/>
		);
	},
});
