import { useMemo, useState } from 'react';
import CheckboxField from 'ui/components/CheckboxField';
import Flex from 'ui/components/Flex';
import { SalesforceIdOption } from '../../api/CustomerAPI';
import { DropdownField } from '../../forms/DropdownField';

type SalesforceIdPickerProps = {
	options: SalesforceIdOption[];
	name: string;
	label?: string;
};

const SalesforceIdPicker = ({
	options,
	name,
	label = 'Salesforce Organisation Id',
}: SalesforceIdPickerProps) => {
	const [searchValue, setSearchValue] = useState('');
	const [showAssociatedCustomers, setShowAssociatedCustomers] = useState(false);

	const filteredOptions = useMemo(() => {
		return options.filter((option) => {
			const label = option.label.toLowerCase();
			const customer = option.customer?.toLowerCase() ?? '';

			const search = searchValue.toLowerCase();
			return label.includes(search) || customer.includes(search);
		});
	}, [options, searchValue]);

	return (
		<Flex direction="column" gap={6}>
			<DropdownField
				label={label}
				name={name}
				isRequired
				placeholder="Please select..."
				identifierKey="value"
				hideDisabled={!showAssociatedCustomers}
				inputValue={searchValue}
				onInputChange={setSearchValue}
				contentSource={(data) =>
					showAssociatedCustomers && data.customer
						? `${data.label} (${data.customer})`
						: data.label
				}
				options={filteredOptions}
				optionDisabledSource={(data) => data.customer !== null}
				isClearable={false}
				isSearchable
			/>
			<CheckboxField
				checked={showAssociatedCustomers}
				label="Show Associated Customers"
				name="showAssociatedCustomers"
				onChange={() => setShowAssociatedCustomers(!showAssociatedCustomers)}
			/>
		</Flex>
	);
};

export default SalesforceIdPicker;
