import { createColumnHelper } from '@tanstack/react-table';
import DateFragment from 'ui/components/DateFragment/DateFragment';
import { MailContentListing } from '../../api/MailApi';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<MailContentListing>();

export const idColumn = columnHelper.accessor('id', {
	header: 'ID',
	cell: (info) => info.getValue(),
});

export const nameColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => {
		return (
			<Link to={`/email-templates/${info.row.original.id}`}>
				{info.getValue()}
			</Link>
		);
	},
});

export const subjectColumn = columnHelper.accessor('subject', {
	header: 'Subject',
	cell: (info) => info.getValue(),
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});

export const updatedAtColumn = columnHelper.accessor('updatedAt', {
	header: 'Updated Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
	enableSorting: true,
});
