import { ColumnDef } from '@tanstack/react-table';
import { CalendarDays, PlusCircle } from 'lucide-react';
import { useCallback, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom';
import Button from 'ui/components/Button';
import Flex from 'ui/components/Flex';
import Pagination from 'ui/components/Pagination';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import { ToastType } from 'ui/components/Toaster/Toast';
import { selectColumn } from 'utils/columns/genericColumns';
import { formDataAsObject } from 'utils/formData/formData';
import pluralize from 'utils/helpers/pluralize';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import {
	TOAST_FAILURE_DEFAULT_DURATION,
	TOAST_SUCCESS_DEFAULT_DURATION,
	createToast,
	updateToast,
} from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import UserAPI, { UserItem } from '../../api/UserAPI';
import * as userColumns from '../../util/columns/userColumns';
import UpdateUserExpiryModal, {
	updateUserExpirySchema,
} from './UpdateUserExpiryModal';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const customerId = params.customerId as string;

	let searchParams = new URL(request.url).searchParams;
	searchParams.set('customerIds', [customerId].toString());

	const usersData = await UserAPI.getUsers(searchParams);

	return {
		usersData: usersData,
	};
};

const action = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const formData = await request.formData();
	const action = formData.get('action') as string;

	if (action === 'updateExpiry') {
		const data = updateUserExpirySchema.parse(formDataAsObject(formData));

		const toastId = createToast(
			ToastType.LOADING,
			'Renewing subscriptions...',
			{
				duration: Infinity,
			}
		);

		const responseData = await UserAPI.updateUserExpiry(data);

		if ('expiryDate' in responseData) {
			updateToast(
				toastId,
				ToastType.SUCCESS,
				`Successfully updated the expiry for ${
					responseData.totalUpdatedUsers
				} ${pluralize('user', responseData.totalUpdatedUsers)}`,
				{
					duration: TOAST_SUCCESS_DEFAULT_DURATION,
				}
			);
		} else {
			updateToast(toastId, ToastType.ERROR, 'Failed to user expiry dates', {
				duration: TOAST_FAILURE_DEFAULT_DURATION,
			});
		}
	}

	return null;
};

const CustomerUsers = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const params = useParams();
	const customerId = params.customerId as string;

	const [isExpiryModalOpen, setIsExpiryModalOpen] = useState(false);
	const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

	const handleRowSelection = useCallback(
		(rowSelection: Record<string, boolean>) => {
			setSelectedUserIds(Object.keys(rowSelection));
		},
		[setSelectedUserIds]
	);

	return (
		<>
			<Flex direction="column" gap={16}>
				<Table
					identifierKey="id"
					columns={[
						selectColumn as ColumnDef<UserItem, void>,
						userColumns.fullNameColumn,
						userColumns.emailColumn,
						userColumns.activeFromColumn,
						userColumns.activeToColumn,
						userColumns.subscriptionsColumn,
					]}
					data={data.usersData.users.items}
					onRowSelectionChange={handleRowSelection}
				/>

				<UpdateUserExpiryModal
					isOpen={isExpiryModalOpen}
					onClose={() => setIsExpiryModalOpen(false)}
					users={data.usersData.users.items.filter((user) =>
						selectedUserIds.includes(user.id)
					)}
				/>

				<TabActions>
					<Button
						variant="secondary"
						size="small"
						icon={CalendarDays}
						disabled={selectedUserIds.length === 0}
						onClick={() => setIsExpiryModalOpen(true)}
					>
						Update Access Expiry
						{selectedUserIds.length > 0
							? ` (${selectedUserIds.length}/${data.usersData.users.totalCount})`
							: ''}
					</Button>

					<Button
						variant="secondary"
						size="small"
						to={`/users/create?customerId=${customerId}`}
						icon={PlusCircle}
					>
						Add Users
					</Button>
				</TabActions>

				{data.usersData.users.totalCount > data.usersData.users.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.usersData.users.page}
						pageParameterName="page"
						pageSize={data.usersData.users.pageSize}
						itemCount={data.usersData.users.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const CUSTOMER_DETAILS_USERS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <CustomerUsers />,
};
