import { PlusCircle, RefreshCcw, Search } from 'lucide-react';
import {
	LoaderFunctionArgs,
	Outlet,
	useLoaderData,
	useParams,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import DateField from 'ui/components/DateField/DateField';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import webAnnouncementsAPI from '../../api/WebAnnouncementsAPI';
import CustomerTypePicker from '../../components/CustomerTypePicker';
import ProductPicker from '../../components/ProductPicker';
import * as webAnnouncementColumns from '../../util/columns/webAnnouncementColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getAnnouncementsResponse =
		await webAnnouncementsAPI.getWebAnnouncements(
			new URL(request.url).searchParams
		);

	return {
		getAnnouncementsResponse: getAnnouncementsResponse,
	};
};

export function WebAnnouncementsListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		webAnnouncementColumns.releaseDateColumn,
		webAnnouncementColumns.titleColumn,
		webAnnouncementColumns.expiryDateColumn,
		webAnnouncementColumns.isActiveColumn,
		webAnnouncementColumns.isArchivedColumn,
		webAnnouncementColumns.editButtonColumn,
	];

	const params = useParams();

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Web Announcements">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="secondary"
						icon={PlusCircle}
						to="/web-announcements/create"
					>
						Add Web Announcement
					</Button>
				</PageHeader>

				<Grid columns={params.id ? '2fr 1fr' : undefined}>
					<Grid>
						<Table
							columns={columns}
							data={data.getAnnouncementsResponse.announcements.items}
							identifierKey="id"
						/>

						<Pagination
							baseUrl={new URL(window.location.href)}
							page={data.getAnnouncementsResponse.announcements.page}
							pageParameterName="page"
							pageSize={data.getAnnouncementsResponse.announcements.pageSize}
							itemCount={data.getAnnouncementsResponse.announcements.totalCount}
						/>
					</Grid>
					<Outlet />
				</Grid>
			</div>
			<Sidebar title="Announcements Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />
					<CheckboxField
						name="includeArchived"
						label="Include Hidden Announcements"
						value="true"
						defaultChecked={false}
					/>
					<DateField
						label="Active Date"
						name="activeDate"
						isRequired={false}
						timezone="utc"
					/>
					<ProductPicker
						label="Product"
						name="products"
						options={data.getAnnouncementsResponse.products}
					/>
					<CustomerTypePicker
						label="Customer Type"
						name="customerTypes"
						options={data.getAnnouncementsResponse.customerTypes}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_WEB_ANNOUNCEMENTS_LIST: DecentralizedRouteProps = {
	loader: loader,
	element: <WebAnnouncementsListPage />,
	handle: {
		breadcrumbs: { label: 'Web Announcements' },
	},
};
