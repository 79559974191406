import { Check, Trash2, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import TextAreaField from 'ui/components/TextAreaField';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import useSubmitting from 'utils/hooks/useSubmitting';
import { updateCustomerTeamSchema } from 'utils/schemas/customerTeamSchema';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import CustomerTeamsAPI from '../../api/CustomerTeamsAPI';
import { UserOption } from '../../api/UserAPI';
import DeleteCustomerTeamModal from '../../components/DeleteCustomerTeamModal';

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const url = new URL(request.url);
	const searchParams = url.searchParams;
	const userId = searchParams.get('userId') as string;

	const teamDetails = await CustomerTeamsAPI.getCustomerTeam(
		params.teamId as string,
		searchParams
	);

	return { ...teamDetails, userId: userId };
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const teamId = params.teamId as string;
	const formData = await request.formData();
	const redirectUrl = await RedirectUrl(formData);

	if (request.method === 'DELETE') {
		await CustomerTeamsAPI.deleteCustomerTeam(teamId);

		createToast(ToastType.SUCCESS, 'Team deleted successfully');

		return redirect(redirectUrl);
	}

	if (request.method === 'POST') {
		const updateModel = updateCustomerTeamSchema.parse(
			formDataAsObject(formData)
		);

		const data = await CustomerTeamsAPI.updateCustomerTeam(teamId, updateModel);

		if ('id' in data) {
			createToast(ToastType.SUCCESS, 'Team updated successfully');

			return redirect(redirectUrl);
		}

		return data;
	}

	return null;
};

const CustomerTeamEdit = () => {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const isSubmitting = useSubmitting();

	const cancelUrl =
		data.userId !== null
			? `/users/${data.userId}/teams`
			: `/customers/${data.customerId}/teams`;

	return (
		<div className="content">
			<ValidatedForm
				method="post"
				validator={updateCustomerTeamSchema}
				encType="multipart/form-data"
			>
				<input type="hidden" name="customerId" value={data.customerId} />
				<input type="hidden" name="userId" value={data.userId} />
				<Grid>
					<PageHeader title={`Edit ${data.name}`}>
						<Button
							isDisabled={navigation.state !== 'idle'}
							variant="secondary"
							to={cancelUrl}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							isDisabled={navigation.state !== 'idle'}
							type="button"
							variant="secondary"
							icon={Trash2}
							onClick={() => setDeleteModalOpen(true)}
							data-cy="teamDeleteButton"
						>
							Delete
						</Button>
						<Button
							isLoading={isSubmitting}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					{isDeleteModalOpen && (
						<DeleteCustomerTeamModal
							team={data}
							userId={data.userId}
							isOpen={isDeleteModalOpen}
							onClose={() => setDeleteModalOpen(false)}
						/>
					)}

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Name"
									name="name"
									defaultValue={data.name}
									isRequired={true}
								/>
								<TextAreaField
									label="Description"
									name="description"
									defaultValue={data.description}
								/>
							</Grid>

							<Grid>
								<MultiSelectField<UserOption>
									label="Members"
									name="userIds"
									options={data.users}
									isRequired={true}
									placeholder="Please select..."
									contentSource={(option) => option.name}
									identifierKey="id"
									initialValues={data.selectedUsers}
									pillName={(item) => item.name}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
};

async function RedirectUrl(formData: FormData) {
	const customerId = formData.get('customerId') as string;
	const userId = formData.get('userId') as string;

	return userId !== null && userId !== ''
		? `/users/${userId}/teams`
		: `/customers/${customerId}/teams`;
}

export const CUSTOMER_TEAMS_EDIT_ROUTE: LayoutRouteProps = {
	loader: loader,
	action: action,
	element: <CustomerTeamEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			if (data.userId) {
				return [
					{
						label: 'Users',
						path: '/users',
					},
					{
						label: `Customer Users`,
						path: `/customers/${data.customerId}`,
					},
					{
						label: data.user,
						path: `/users/${data.userId}`,
					},
					{
						label: 'Teams',
						path: `/users/${data.userId}/teams`,
					},
				];
			} else {
				return [
					{
						label: 'Customers',
						path: '/customers',
					},
					{
						label: data.customer,
						path: `/customers/${data.customerId}`,
					},
					{
						label: 'Teams',
						path: `/customers/${data.customerId}/teams`,
					},
				];
			}
		},
	},
};
