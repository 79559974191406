import { sub } from 'date-fns';
import { Search } from 'lucide-react';
import {
	LoaderFunctionArgs,
	useLoaderData,
	useLocation,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import DataManagementAPI from '../../api/DataManagementAPI';
import HotfileMonitor from '../../components/HotfileMonitor';
import { YearMonthFragment } from '../../components/YearMonth/YearMonthFragment';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const queryParams = new URL(request.url).searchParams;

	let year = Number(queryParams.get('reportDate.year'));
	let month = Number(queryParams.get('reportDate.month'));

	if (
		Number.isNaN(year) ||
		Number.isNaN(month) ||
		year < 2010 ||
		month < 1 ||
		month > 12
	) {
		const defaultDate = sub(new Date(), { months: 1 });
		year = defaultDate.getFullYear();
		month = defaultDate.getMonth() + 1;
	}
	const cassData = await DataManagementAPI.cassHotFileMonitor(year, month);

	return {
		cassData,
	};
};

export function useCurrentActionIsLoading() {
	const location = useLocation();
	const navigation = useNavigation();
	return (
		navigation.state !== 'idle' && navigation.formAction === location.pathname
	);
}

export function useCurrentLoaderIsLoading() {
	const location = useLocation();
	const navigation = useNavigation();
	return (
		navigation.state !== 'idle' &&
		navigation.location.pathname === location.pathname
	);
}

export function HotfileMonitorPage() {
	const { cassData: data } = useLoaderData() as LoaderData<typeof loader>;
	const pageIsLoading = useCurrentLoaderIsLoading();

	return (
		<>
			<div className="content">
				<PageHeader
					title={
						<>
							{'CASS Hotfile Monitor - '}{' '}
							<YearMonthFragment yearMonth={data.yearAndMonth.yearMonth} />{' '}
						</>
					}
				>
					<ValidatedForm>
						<Grid columns="2fr 1fr">
							<YearMonthPicker
								name="reportDate"
								initialValue={data.yearAndMonth}
							/>
							<Button type="submit" icon={Search} isLoading={pageIsLoading}>
								Search
							</Button>
						</Grid>
					</ValidatedForm>
				</PageHeader>
				<HotfileMonitor
					months={data.months}
					selectedMonth={data.yearAndMonth}
				/>
			</div>
		</>
	);
}

export const HOTFILE_MONITOR_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <HotfileMonitorPage />,
	handle: {
		breadcrumbs: () => {
			return { label: 'CASS HotFile Monitor' };
		},
	},
};
