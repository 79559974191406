import parse from 'html-react-parser';
import { Check, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextAreaField from 'ui/components/TextAreaField';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { ActionData, LoaderData } from 'utils/types/loaderData';

import { updateEmailContentSchema } from 'utils/schemas/emailContentSchema';
import MailAPI from '../../api/MailApi';
import MailEditor from '../../components/MailEditor';
import MailSubjectEditor from '../../components/MailEditor/MailSubjectEditor';

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const { email, layout } = await MailAPI.getEmailContent(
		params.emailId as string,
		'/mail'
	);

	return {
		email,
		layout,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const emailId = params.emailId as string;
	const formData = await request.formData();

	const updateUserModel = updateEmailContentSchema.parse(
		formDataAsObject(formData)
	);

	const data = await MailAPI.updateEmailContent(emailId, updateUserModel);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'Content updated successfully');
	return redirect(`/email-templates/${data.emailId}`);
};

const parsedHtml = (content: string, template: string) => {
	return parse(template.replace('#Mail-Content-Body#', content));
};

export function EmailTemplatesEditPage() {
	const actionData = useActionData() as ActionData<typeof action>;
	const { email, layout } = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();
	const [mailContents, setMailContents] = useState(email.content);

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateEmailContentSchema}>
				<Grid>
					<PageHeader title={`Edit ${email.name}`}>
						<Button variant="secondary" to={`/email-templates`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2}>
							<Grid isBox gap="4">
								<TextField
									label="Name"
									name="name"
									isRequired={true}
									defaultValue={email.name}
								/>

								<MailSubjectEditor
									label="Subject"
									name="subject"
									isRequired={true}
									defaultValue={email.subject}
									tags={email.subjectTags}
								/>

								<MailEditor
									label="Body"
									name="content"
									isRequired={true}
									defaultValue={email.content}
									tags={email.tags}
									onChange={setMailContents}
								/>

								<TextAreaField
									label="Comments"
									name="comments"
									defaultValue={email.comments}
								/>
							</Grid>

							<Grid isBox>
								<div style={{ overflow: 'auto' }}>
									{parsedHtml(mailContents, layout.content)}
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EMAIL_TEMPLATES_EDIT_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <EmailTemplatesEditPage />,
};
