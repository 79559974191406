import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import { Download, FileSpreadsheet, RefreshCcw, Search } from 'lucide-react';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	useActionData,
	useLoaderData,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import DateField from 'ui/components/DateField/DateField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import Sidebar from 'ui/components/Sidebar/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import * as genericColumns from 'utils/columns/genericColumns';
import { catchWithMessage } from 'utils/helpers/catchHandlers';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	AllSubscriptionReportsItem,
	ReRunReportsRequest,
} from '../../api/SubscriptionAPI';
import RunReportsModal from '../../components/RunReportsModal';
import * as reportColumns from '../../util/columns/allSubscriptionReportsColumns';

type ActionData = Awaited<ReturnType<typeof action>>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return SubscriptionAPI.getAllSubscriptionReports(
		new URL(request.url).searchParams
	);
};

async function handleDownloadData() {
	toast.loading('Starting Export...');

	const searchParams = new URLSearchParams(location.search);

	const rt = await SubscriptionAPI.beginReportsDataExport(searchParams);

	toast.remove();

	if (rt instanceof Error) {
		toast.error('An error has occurred when starting the export');
	} else {
		toast.success(
			'Export started. The file will be available in System > Admin Exports'
		);
	}
}

export const action = async ({ request }: ActionFunctionArgs) => {
	const formData = await request.formData();

	const ids = formData.getAll('selectedRows') as string[];

	const toastId = createToast(ToastType.LOADING, 'Queuing reports...');

	const req = { id: ids } as ReRunReportsRequest;
	const response = await SubscriptionAPI.reRunReports(req);

	if (response.reportBatchId === null) {
		toast.error(`${response.message}`, {
			id: toastId,
		});

		return null;
	} else {
		toast.success('Reports queued successfully', {
			id: toastId,
		});

		return { batchId: response.reportBatchId };
	}
};

export function SubscriptionReportsListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [isRunReportModalOpen, setRunReportModalOpen] = useState(false);
	const [selectedRows, setSelectedRows] = useState<RowSelectionState>();
	const navigate = useNavigate();
	const location = useLocation();

	// Creating a key for the table so the checkbox selection is persisted across pages but not when new search filters are applied
	const searchParams = new URLSearchParams(location.search);
	searchParams.delete('page');
	const tableKey = searchParams.toString();

	// Close the modal once actionData changes (and modal is open)
	const actionData = useActionData() as ActionData;
	useEffect(() => {
		if (actionData && isRunReportModalOpen) {
			setRunReportModalOpen(false);

			navigate(`/reports/monitor?batchIds=${actionData.batchId}`);
		}
	}, [actionData]);

	const columns = [
		genericColumns.selectColumn,
		reportColumns.jobIdColumn,
		reportColumns.reportBatchColumn,
		reportColumns.customerCodeColumn,
		reportColumns.customerNameColumn,
		reportColumns.subscriptionCodeColumn,
		reportColumns.productNameColumn,
		reportColumns.reportDateColumn,
		reportColumns.versionColumn,
		reportColumns.createdAtColumn,
		reportColumns.jobStartedAtColumn,
		reportColumns.jobCompletedAtColumn,
		reportColumns.reportGenerationDurationColumn,
		reportColumns.generationStatusColumn,
		reportColumns.distributionStatusColumn,
		reportColumns.attachmentColumn,
	];

	const selectedIds = Object.keys(selectedRows ?? {});
	const queryParams = new URLSearchParams(window.location.search);

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="All Subscription Reports">
					<Flex gap={12}>
						<Button
							isDisabled={selectedIds.length == 0}
							variant="primary"
							icon={FileSpreadsheet}
							onClick={() => setRunReportModalOpen(true)}
						>
							Re-run selected reports!
						</Button>
						<Button
							variant="secondary"
							icon={Download}
							onClick={() => {
								handleDownloadData().catch(
									catchWithMessage(
										'An error has occurred when starting the export'
									)
								);
							}}
						>
							Download data
						</Button>
					</Flex>
				</PageHeader>

				{isRunReportModalOpen && (
					<RunReportsModal
						totalReports={selectedIds.length}
						ids={selectedIds}
						isOpen={isRunReportModalOpen}
						onClose={() => setRunReportModalOpen(false)}
					/>
				)}

				<Table
					identifierKey="id"
					key={tableKey}
					onRowSelectionChange={setSelectedRows}
					columns={columns as ColumnDef<AllSubscriptionReportsItem, any>[]}
					data={data.reports.items}
				/>

				{data.reports.totalCount > 0 && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.reports.page}
						pageParameterName="page"
						pageSize={data.reports.pageSize}
						itemCount={data.reports.totalCount}
					/>
				)}
			</div>
			<Sidebar title="Subscription Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField
						label="Job Id"
						name="jobId"
						defaultValue={queryParams.get('jobId') ?? ''}
					/>

					<TextField
						label="Subscription Code"
						name="subscriptionNumber"
						defaultValue={queryParams.get('subscriptionNumber') ?? ''}
					/>

					<MultiSelectField
						label="Product"
						name="productTypes"
						options={data.productOptions}
						contentSource={(option) => option.name}
						identifierKey="productType"
						placeholder="Please select..."
						pillName={(item) => item.name}
						initialValues={data.productOptions.filter((item) =>
							data.productTypes?.includes(item.productType)
						)}
					/>

					<MultiSelectField
						label="Period Type"
						name="periodTypes"
						options={data.periodTypeOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.periodTypeOptions.filter((item) =>
							data.periodTypes?.includes(item.value)
						)}
					/>

					<MultiSelectField
						key={searchParams.getAll('batchIds').join('|') ?? 'batchIds'}
						label="Report Batch"
						name="batchIds"
						options={data.reportBatchOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.reportBatchOptions.filter((item) =>
							data.reportBatches?.includes(item.value)
						)}
					/>

					<MultiSelectField
						label="Generation Status"
						name="generationStatuses"
						options={data.generationStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.generationStatusOptions.filter((item) =>
							data.generationStatuses?.includes(item.value)
						)}
					/>

					<MultiSelectField
						label="Distribution Status"
						name="distributionStatuses"
						options={data.distributionStatusOptions}
						contentSource={(option) => option.label}
						identifierKey="value"
						placeholder="Please select..."
						pillName={(item) => item.label}
						initialValues={data.distributionStatusOptions.filter((item) =>
							data.distributionStatuses?.includes(item.value)
						)}
					/>

					<Grid columns={2}>
						<YearMonthPicker label="Start Report Date" name="startReportDate" />
						<YearMonthPicker label="End Report Date" name="endReportDate" />
					</Grid>

					<Grid columns={2}>
						<DateField
							label="Created After"
							name="createdAfterDate"
							initialValue={data.createdAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Created Before"
							name="createdBeforeDate"
							initialValue={data.createdBeforeDate}
							timezone="utc"
						/>
					</Grid>

					<Grid columns={2}>
						<DateField
							label="Started After"
							name="startedAfterDate"
							initialValue={data.startedAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Started Before"
							name="startedBeforeDate"
							initialValue={data.startedBeforeDate}
							timezone="utc"
						/>
					</Grid>

					<Grid columns={2}>
						<DateField
							label="Finished After"
							name="completedAfterDate"
							initialValue={data.completedAfterDate}
							timezone="utc"
						/>
						<DateField
							label="Finished Before"
							name="completedBeforeDate"
							initialValue={data.completedBeforeDate}
							timezone="utc"
						/>
					</Grid>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const ALL_SUBSCRIPTIONS_ROUTE: DecentralizedRouteProps = {
	loader,
	action,
	element: <SubscriptionReportsListPage />,
};
