import TextAreaField from '../TextAreaField';
import { TextAreaFieldProps } from '../TextAreaField/TextAreaField';

type AuditCommentFieldProps = Omit<
	TextAreaFieldProps,
	'label' | 'name' | 'description'
>;

const AuditCommentField = (props: AuditCommentFieldProps) => {
	return (
		<TextAreaField
			label="Audit Comment *"
			name="auditComment"
			description="Explain why you are making this change. This will be recorded in the audit log."
			{...props}
		/>
	);
};

export default AuditCommentField;
