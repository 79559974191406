import { ENVIRONMENT_ADMIN } from 'environment';
import API from 'utils/api/API';
import { UpdateEmailContentModel } from 'utils/schemas/emailContentSchema';

export type MailContentListing = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	subject: string;
};

export type MailContent = {
	id: number;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	subject: string;
};

export type GetEmailContentsResponse = {
	emails: MailContentListing[];
};

export type EmailContentTag = {
	title: string;
	value: string;
	htmlAllowed: boolean;
};

export type EmailSubjectTag = {
	title: string;
	value: string;
};

export type EmailContentDetails = {
	id: string;
	createdAt: Date;
	updatedAt: Date;
	name: string;
	subject: string;
	content: string;
	comments: string;
	tags: EmailContentTag[];
	subjectTags: EmailSubjectTag[];
};

export type EmailLayoutItem = {
	id: string;
	createdAt: Date;
	content: string;
};

export type EmailContentEdit = {
	email: EmailContentDetails;
	layout: EmailLayoutItem;
};

export type UpdateEmailContentResponse = {
	emailId: string;
};

class MailAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_ADMIN.emailServiceEndpoint;
	static getEmailContents(request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return MailAPI.get<GetEmailContentsResponse>(`/emails/content?${params}`);
	}

	static getEmailContent(id: string, prefix: string) {
		return MailAPI.get<EmailContentEdit>(
			`/emails/content/${id}?prefix=${prefix}`
		);
	}

	static updateEmailContent(emailId: string, data: UpdateEmailContentModel) {
		return MailAPI.put<UpdateEmailContentResponse>(
			`/emails/content/${emailId}`,
			data
		);
	}

	static lookup() {
		const mails: MailContent[] = [
			{
				id: 1,
				createdAt: new Date(),
				updatedAt: new Date(),
				name: 'CargoIS New Reports Available',
				subject: '#Customer-Name# - CargoIS New Reports Available',
			},
			{
				id: 2,
				createdAt: new Date(),
				updatedAt: new Date(),
				name: 'CargoIS Report Successful',
				subject: 'CargoIS Webtool Report - Successfully Shared',
			},
			{
				id: 3,
				createdAt: new Date(),
				updatedAt: new Date(),
				name: 'CDD Submission File Submitted',
				subject: 'New CDD Submission File Submitted',
			},
		];
		return mails;
	}
}

export default MailAPI;
