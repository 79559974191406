import { X } from 'lucide-react';
import { useId } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import TextAreaField from 'ui/components/TextAreaField';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { createCustomerTeamSchema } from 'utils/schemas/customerTeamSchema';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import CustomerTeamsAPI from '../../api/CustomerTeamsAPI';
import { UserOption } from '../../api/UserAPI';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const searchParams = new URL(request.url).searchParams;

	return await CustomerTeamsAPI.getCustomerTeamOptions(searchParams);
};

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createTeamModel = createCustomerTeamSchema.parse(
			formDataAsObject(formData)
		);

		const data = await CustomerTeamsAPI.createCustomerTeam(createTeamModel);

		if ('id' in data) {
			createToast(ToastType.SUCCESS, 'Team created successfully');

			const url = new URL(request.url);
			const searchParams = url.searchParams;

			const customerId = searchParams.get('customerId') as string;
			if (customerId !== null)
				return redirect(`/customers/${customerId}/teams`);

			const userId = searchParams.get('userId') as string;
			if (userId !== null) return redirect(`/users/${userId}/teams`);
		}
		return data;
	}

	return null;
};

const CustomerTeamCreate = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const navigation = useNavigation();
	const formId = useId();

	const pageTitle = 'Create New Team';

	const cancelUrl =
		data.userId !== null
			? `/users/${data.userId}/teams`
			: `/customers/${data.customerId}/teams`;

	return (
		<div className="content">
			<ValidatedForm
				id={formId}
				encType="multipart/form-data"
				method="post"
				validator={createCustomerTeamSchema}
			>
				<input type="hidden" name="customerId" value={data.customerId} />
				<Grid>
					<PageHeader title={pageTitle}>
						<Button
							isDisabled={navigation.state !== 'idle'}
							variant="secondary"
							to={cancelUrl}
							icon={X}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							form={formId}
							isDisabled={navigation.state !== 'idle'}
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Team"
						>
							Create Team
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField label="Name" name="name" isRequired={true} />
								<TextAreaField label="Description" name="description" />
							</Grid>

							<Grid>
								<MultiSelectField<UserOption>
									label="Members"
									name="userIds"
									options={data.users}
									isRequired={true}
									placeholder="Please select..."
									contentSource={(option) => option.name}
									identifierKey="id"
									initialValues={data.selectedUsers}
									pillName={(item) => item.name}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
};

export const CUSTOMER_TEAMS_CREATE_ROUTE: LayoutRouteProps = {
	loader: loader,
	action: action,
	element: <CustomerTeamCreate />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			if (data.userId) {
				return [
					{
						label: 'Users',
						path: '/users',
					},
					{
						label: `Customer Users`,
						path: `/customers/${data.customerId}`,
					},
					{
						label: data.user,
						path: `/users/${data.userId}`,
					},
					{
						label: 'Teams',
						path: `/users/${data.userId}/teams`,
					},
				];
			} else {
				return [
					{
						label: 'Customers',
						path: '/customers',
					},
					{
						label: data.customer,
						path: `/customers/${data.customerId}`,
					},
					{
						label: 'Teams',
						path: `/customers/${data.customerId}/teams`,
					},
				];
			}
		},
	},
};
