/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';
import { monthPickerSchema } from './subscriptionSchema';

export const reportSchema = z.object({
	subscriptionId: z.string(),
	reportGenerationType: z.string(),
	startReportDate: monthPickerSchema('Start report date'),
	endReportDate: monthPickerSchema('End report date').optional(),
	preserveFilename: z.string().optional(),
	suppressDistortion: z.string().optional(),
});

export type ReportModel = z.infer<typeof reportSchema>;

// CREATE REPORT SCHEMA
export const generateReportSchema = reportSchema
	.pick({
		reportGenerationType: true,
		startReportDate: true,
		endReportDate: true,
		preserveFilename: true,
		suppressDistortion: true,
	})
	.superRefine((data, ctx) => {
		if (
			data.reportGenerationType === 'dateRange' &&
			data.endReportDate === undefined
		) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['endReportDate'],
				message: 'End report date is required',
				fatal: true,
			});
		}
	});

export type GenerateReportModel = z.infer<typeof generateReportSchema>;
