import { Trash2 } from 'lucide-react';
import ConfirmationModal from 'ui/components/ConfirmationModal';
import { ModalProps } from 'ui/components/Modal/Modal';
import { GetAdhocReportResponse } from '../../api/AdhocReportsAPI';

type DeleteAdhocReportProps = {
	report: GetAdhocReportResponse;
	userId: string;
} & Omit<ModalProps, 'title'>;

const DeleteAdhocReportModal = ({
	report,
	userId,
	...modalProps
}: DeleteAdhocReportProps) => {
	return (
		<ConfirmationModal
			fieldName="delete"
			title="Delete Adhoc Report"
			confirmationText="Delete"
			submitText="Delete Adhoc Report"
			submitIcon={Trash2}
			intent="danger"
			formAction={`/adhoc-reports/${report.id}/edit`}
			formMethod="delete"
			{...modalProps}
		>
			<input type="hidden" name="customerId" value={report.customerId} />
			<input type="hidden" name="userId" value={userId} />

			<p>
				Are you sure you wish to delete the report <b>{report.title}</b>?
			</p>
		</ConfirmationModal>
	);
};

export default DeleteAdhocReportModal;
