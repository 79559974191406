import { Command, RefreshCcw, Search } from 'lucide-react';
import { useId, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button/Button';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import WebToolAPI from 'utils/api/WebToolAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import CustomerPicker from '../../components/CustomerPicker';
import ExecuteWebToolScheduledModal from '../../components/ExecuteWebToolScheduledModal';
import UserPicker from '../../components/UserPicker';
import { DropdownField } from '../../forms/DropdownField';
import * as webToolSchedulesColumns from '../../util/columns/webToolSchedulesColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const response = await WebToolAPI.getWorksheetSchedules(
		new URL(request.url).searchParams
	);

	return {
		schedulesData: response.schedulesData,
		subscriptionsSelected: response.subscriptionsSelected,
		usersSelected: response.usersSelected,
		customersSelected: response.customersSelected,
		executedDateDefault: response.executedDateDefault,
		executedDateOptions: response.executedDateOptions,
		executionDateDefault: response.executionDateDefault,
		executionDateOptions: response.executionDateOptions,
		scheduleTypeOptions: response.scheduleTypeOptions,
	};
};

export function SchedulesListPage() {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [isModalOpen, setModalOpen] = useState(false);

	const columns = [
		webToolSchedulesColumns.nameColumn,
		webToolSchedulesColumns.scheduleColumn,
		webToolSchedulesColumns.lastExecutionStatusColumn,
		webToolSchedulesColumns.lastExecutionColumn,
		webToolSchedulesColumns.nextExecutionColumn,
		webToolSchedulesColumns.userColumn,
		webToolSchedulesColumns.customerColumn,
		webToolSchedulesColumns.actionsColumn,
	];

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Web Tool Schedules">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						variant="secondary"
						icon={Command}
						onClick={() => setModalOpen(true)}
					>
						Run 'On New Data' Schedule
					</Button>
				</PageHeader>

				<ExecuteWebToolScheduledModal
					isOpen={isModalOpen}
					onSubmit={() => {
						setModalOpen(false);
						createToast(ToastType.SUCCESS, 'Web Tool Schedule Running');
					}}
					onClose={() => setModalOpen(false)}
				/>

				<Table
					identifierKey="id"
					columns={columns}
					data={data.schedulesData.items}
				/>
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.schedulesData.page}
					pageParameterName="page"
					pageSize={data.schedulesData.pageSize}
					itemCount={data.schedulesData.totalCount}
				/>
			</div>
			<Sidebar title="Web Tool Schedules Filter">
				<ValidatedForm method="get" id={formId} resetOnNavigation>
					<DropdownField
						label="Last Execution"
						name="lastExecution"
						placeholder="Show all"
						options={data.executedDateOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.executedDateDefault}
						isClearable={false}
					/>
					<DropdownField
						label="Next Execution"
						name="nextExecution"
						placeholder="Show all"
						options={data.executionDateOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.executionDateDefault}
						isClearable={false}
					/>
					<UserPicker
						name="userIds"
						label="User"
						initialValues={data.usersSelected ? data.usersSelected : []}
						isMulti={true}
					/>
					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>
					<MultiSelectField
						label="Schedule Type"
						name="scheduleType"
						placeholder="Show all"
						inputValue=""
						options={data.scheduleTypeOptions}
						identifierKey="id"
						contentSource={(opt) => `${opt.name}`}
						pillName={(item) => item.name}
					/>
					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const WEB_TOOL_SCHEDULES_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <SchedulesListPage />,
};
