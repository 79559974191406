import { Check, X } from 'lucide-react';
import {
	ActionFunction,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import DateField from 'ui/components/DateField/DateField';
import ErrorList from 'ui/components/ErrorList';
import FileField from 'ui/components/FileField/FileField';
import Grid from 'ui/components/Grid';
import HTMLEditorField from 'ui/components/HTMLEditorField';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { createAirImpactReportSchema } from 'utils/schemas/airImpactReportSchema';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	return null;
};

export const action: ActionFunction = async ({ request }) => {
	if (request.method === 'POST') {
		const formData = await request.formData();
		const createReportModel = createAirImpactReportSchema.parse(
			formDataAsObject(formData)
		);

		const bannerEntry = formData.get('banner') as File;
		const pdfEntry = formData.get('pdf') as File;

		const banner = {
			fileName: bannerEntry.name,
			length: bannerEntry.size,
		};
		const pdf = {
			fileName: pdfEntry.name,
			length: pdfEntry.size,
		};

		const data = await AirImpactReportsAPI.createAirImpactReport(
			createReportModel,
			banner,
			pdf
		);

		if ('reportId' in data) {
			if (data.bannerUrl !== '') {
				await AirImpactReportsAPI.uploadFile(data.bannerUrl, bannerEntry);
			}
			if (data.pdfUrl !== '') {
				await AirImpactReportsAPI.uploadFile(data.pdfUrl, pdfEntry);
			}

			createToast(ToastType.SUCCESS, 'Impact report created successfully');

			return redirect(`/air-impact-reports/${data.reportId}`);
		}

		return data;
	}

	return null;
};

export function CreateAirImpactReport() {
	const actionData = useActionData();
	const navigation = useNavigation();

	return (
		<div className="content">
			<ValidatedForm
				encType="multipart/form-data"
				method="post"
				validator={createAirImpactReportSchema}
			>
				<Grid>
					<PageHeader title="Create Air Cargo Impact Report">
						<Button variant="secondary" to={`/air-impact-reports`} icon={X}>
							Cancel
						</Button>
						<Button
							isLoading={navigation.state !== 'idle' && !!navigation.formData}
							loadingText="Creating Air Cargo Impact Report"
							type="submit"
							icon={Check}
						>
							Create
						</Button>
					</PageHeader>
					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField label="Edition" name="edition" isRequired={true} />
								<TextField label="Title" name="title" isRequired={true} />
								<HTMLEditorField
									label="Summary"
									name="summary"
									isRequired={true}
								/>
							</Grid>
							<Grid>
								<FileField
									label="Banner"
									name="banner"
									allowedExtensions={['jpg', 'jpeg', 'png']}
								/>
								<FileField
									label="PDF"
									name="pdf"
									allowedExtensions={['pdf']}
									isMultiple
								/>
								<DateField
									label="Publication Date"
									name="publicationDate"
									isRequired={true}
									timezone="utc"
								/>
								<CheckboxField
									name="showInHomepage"
									label="Show in Homepage"
									value="true"
									defaultChecked={true}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const CREATE_AIR_IMPACT_REPORTS_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <CreateAirImpactReport />,
	handle: {
		crumb: () => 'Create',
	},
};
