import { LayoutRouteProps, LoaderFunctionArgs } from 'react-router-dom';
import DownloadPage from 'ui/pages/DownloadPage/DownloadPage';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import SubscriptionAPI from '../../api/SubscriptionAPI';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const reportId = params.reportId as string;

	const file = await SubscriptionAPI.downloadReport(reportId);

	downloadFile(file.url, file.filename);

	return null;
};

function downloadFile(exportFileUrl: string, fileName: string): void {
	const link = document.createElement('a');
	link.setAttribute('href', exportFileUrl);
	link.setAttribute('download', fileName);
	link.click();
}

export const REPORT_DOWNLOAD_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <DownloadPage />,
};
