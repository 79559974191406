/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import {
	zArrayOfMinStringsRequired,
	zEnsureArrayPreprocessor,
} from 'utils/zod/zodValidation';
import z from 'zod';

export const customerTeamSchema = z.object({
	id: z.string(),
	customerId: z.string({ required_error: 'CustomerId is required' }),
	name: z
		.string()
		.min(2, { message: 'Name must be at least 2 characters' })
		.max(150, { message: 'Name must not be more than 150 characters' }),
	description: z
		.string()
		.max(300, { message: 'Description must not be more than 300 characters' }),
	userIds: z.preprocess(
		zEnsureArrayPreprocessor,
		zArrayOfMinStringsRequired('At least 2 members are required', 2)
	),
});

export type CustomerTeamModel = z.infer<typeof customerTeamSchema>;

export const createCustomerTeamSchema = customerTeamSchema.pick({
	customerId: true,
	name: true,
	description: true,
	userIds: true,
});

export type CreateCustomerTeamModel = z.infer<typeof createCustomerTeamSchema>;

export const updateCustomerTeamSchema = customerTeamSchema.pick({
	name: true,
	description: true,
	userIds: true,
});

export type UpdateCustomerTeamModel = z.infer<typeof updateCustomerTeamSchema>;
