import { ComponentProps } from 'react';
import ValidatedForm from 'ui/components/ValidatedForm';
import { useConfirmationModalContext } from './ConfirmationModalContext';

type ConfirmationModalFormProps = Omit<
	ComponentProps<typeof ValidatedForm>,
	'id'
>;

const ConfirmationModalForm = (props: ConfirmationModalFormProps) => {
	const { formId } = useConfirmationModalContext();

	return <ValidatedForm {...props} id={formId} />;
};

export default ConfirmationModalForm;
