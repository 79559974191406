import { Pencil, Trash2 } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	Link,
	LoaderFunctionArgs,
	isRouteErrorResponse,
	redirect,
	useActionData,
	useLoaderData,
	useRouteError,
	useRouteLoaderData,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import PageHeader from 'ui/components/PageHeader';
import Tabs from 'ui/components/Tabs';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import AccountManagerAPI from '../../api/AccountManagersAPI';
import DeleteAccountManagerModal from '../../components/DeleteAccountManagerModal';
import { loader as customersLoader } from './account-manager-details-customers';

export const action = async ({ params, request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		const data = await AccountManagerAPI.deleteAccountManager(
			params.accountManagerId as string
		);

		if (data instanceof Error) {
			return data;
		}

		createToast(ToastType.SUCCESS, 'Account manager deleted successfully');
		return redirect('/account-managers');
	}

	return null;
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const accountManagerId = params.accountManagerId as string;

	const accountManagerData = await AccountManagerAPI.getAccountManager(
		accountManagerId
	);

	return {
		accountManager: accountManagerData,
	};
};

export function AccountManagerDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;
	const actionData = useActionData() as ActionData<typeof action>;
	const accountManagerData = useRouteLoaderData(
		'account-manager-customers'
	) as LoaderData<typeof customersLoader>;

	return (
		<div className="content">
			<PageHeader title={`${data.accountManager.name}`}>
				<Button
					variant="secondary"
					icon={Trash2}
					onClick={() => setDeleteModalOpen(true)}
					data-testid="delete-button"
				>
					Delete
				</Button>
				<Button
					variant="secondary"
					icon={Pencil}
					to={`/account-managers/${data.accountManager.id}/edit`}
				>
					Edit
				</Button>
			</PageHeader>

			<DeleteAccountManagerModal
				accountManager={data.accountManager}
				hasCustomers={
					accountManagerData.customersData.customers.items.length > 0
				}
				isOpen={isDeleteModalOpen}
				onClose={() => setDeleteModalOpen(false)}
			></DeleteAccountManagerModal>

			<ErrorList error={actionData} />

			<KeyValuePairs
				entries={[
					{ key: 'Email', value: data.accountManager.email },
					{ key: 'Title', value: data.accountManager.title },
				]}
			/>

			<Tabs
				tabs={[{ name: 'Customers', to: '' }]}
				baseUrl={`/account-managers/${data.accountManager.id}/`}
			/>
		</div>
	);
}

export function AccountManagerErrorBoundary() {
	const error = useRouteError();

	// re-throw to let the RootErrorBoundary handle it if it's a route error
	if (!isRouteErrorResponse(error)) {
		throw error;
	}

	return (
		<>
			<h1>Account Manager could not be found</h1>
			<p>
				Go back to the <Link to="./">list of account manager</Link>
			</p>
		</>
	);
}

export const ACCOUNT_MANAGER_DETAILS_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <AccountManagerDetails />,
	errorElement: <AccountManagerErrorBoundary />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return `${data.accountManager.firstName} ${data.accountManager.lastName}`;
		},
	},
};
