import HTMLEditorField from 'ui/components/HTMLEditorField';
import { HTMLEditorFieldProps } from 'ui/components/HTMLEditorField/HTMLEditorField';

export type EditorTag = {
	title: string;
	value: string;
};

type MailEditorProps = Omit<HTMLEditorFieldProps, 'editorOptions'> & {
	tags?: EditorTag[];
};

const MailEditor = ({ tags, ...restProps }: MailEditorProps) => {
	return (
		<HTMLEditorField
			{...restProps}
			editorOptions={{
				plugins: ['lists', 'link', 'code', 'autoresize', 'mergetags'],
				toolbar:
					'bold italic underline | bullist numlist | indent outdent | link unlink | removeformat code mergetags',
				mergetags_prefix: '{{',
				mergetags_suffix: '}}',
				mergetags_list: tags ?? [],
				valid_elements:
					'strong,em,span[data-mce-cef-wrappable,data-mce-mergetag,data-mce-selected,class,contenteditable],a[href|target=_blank|rel|title],ul,ol,li,p,br',
			}}
		/>
	);
};

export default MailEditor;
