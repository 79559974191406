import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { CustomerTypeOption } from 'utils/api/common';
import {
	CreateWebAnnouncementModel,
	UpdateWebAnnouncementModel,
} from 'utils/schemas/webAnnouncementSchema';
import { Paginated } from 'utils/types/common';
import { ProductOption } from './SubscriptionAPI';

type GetWebAnnouncementsRequest = {
	keyword?: string;
	activeDate?: Date;
	includeArchived?: boolean;
	products?: string[];
	customerTypes?: string[];
	pageSize?: number;
	page?: number;
};

export type WebAnnouncementItem = {
	id: string;
	title: string;
	isActive: boolean;
	isArchived: boolean;
	releaseDate: Date;
	expiryDate: Date;
};

export type WebAnnouncementEdit = {
	id: string;
	title: string;
	body: string;
	releaseDate: Date;
	expiryDate: Date;
	products: ProductOption[];
	customerTypes: CustomerTypeOption[];
	isArchived: boolean;
};

export type GetWebAnnouncementsResponse = {
	keyword: string;
	announcements: Paginated<WebAnnouncementItem>;
	products: ProductOption[];
	customerTypes: CustomerTypeOption[];
};

export type CreateWebAnnouncementResponse = {
	announcementId: string;
};

export type UpdateWebAnnouncementResponse = {
	announcementId: string;
};

export type GetWebAnnouncementOptionsResponse = {
	productOptions: ProductOption[];
	customerTypeOptions: CustomerTypeOption[];
};

class WebAnnouncementsAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getWebAnnouncements(
		request: GetWebAnnouncementsRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return WebAnnouncementsAPI.get<GetWebAnnouncementsResponse>(
			`/web-announcements?${params}`
		);
	}

	static getAnnouncement(id: string) {
		return WebAnnouncementsAPI.get<WebAnnouncementEdit>(
			`/web-announcements/${id}`
		);
	}

	static createWebAnnouncement(data: CreateWebAnnouncementModel) {
		return WebAnnouncementsAPI.post<CreateWebAnnouncementResponse>(
			'/web-announcements',
			data
		);
	}

	static updateWebAnnouncement(id: string, data: UpdateWebAnnouncementModel) {
		return WebAnnouncementsAPI.put<UpdateWebAnnouncementResponse>(
			`/web-announcements/${id}`,
			data
		);
	}

	static deleteWebAnnouncement(id: string) {
		return WebAnnouncementsAPI.delete(`/web-announcements/${id}`);
	}

	static getAnnouncementOptions() {
		return WebAnnouncementsAPI.get<GetWebAnnouncementOptionsResponse>(
			`/web-announcements/options`
		);
	}
}

export default WebAnnouncementsAPI;
