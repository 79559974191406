import DateFragment from 'ui/components/DateFragment';
import Grid from 'ui/components/Grid';
import { Queue } from '../../api/DataManagementAPI';
import SystemProcessCard from './SystemProcessCard';
import { listenOrPausedIntent } from './system-status';

type QueueSystemProcessProps = {
	queue: Queue;
};

const QueueSystemProcess = ({ queue }: QueueSystemProcessProps) => {
	return (
		<SystemProcessCard
			title={queue.name}
			label={`(${queue.length} queued files)`}
			toggleProps={{
				systemProcessCode: queue.code,
				status: queue.status,
				activeStatus: 'listening',
				validStatus: queue.validStatus,
			}}
			bulletIndicatorProps={{
				intent: listenOrPausedIntent(queue.status.code),
				label: queue.status.name,
			}}
		>
			{queue.incompleteJobs.length > 0 && (
				<Grid>
					<div className="table">
						<table className="table__main">
							<thead className="table__header">
								<tr>
									<th className="table__column">File</th>
									<th className="table__column">Started At (UTC)</th>
								</tr>
							</thead>
							<tbody className="table__body">
								{queue.incompleteJobs.map((job) => (
									<tr key={job.id}>
										<td className="table__cell">{job.fileName}</td>
										<td className="table__cell">
											<DateFragment
												date={job.startedAt}
												includeTime
												timezone="utc"
											/>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Grid>
			)}
		</SystemProcessCard>
	);
};

export default QueueSystemProcess;
