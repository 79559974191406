import { Check, X } from 'lucide-react';
import {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import { ToastType } from 'ui/components/Toaster/Toast';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';
import { createToast } from 'utils/helpers/toast';
import { updateSubscriptionSchema } from 'utils/schemas/subscriptionSchema';
import { DecentralizedRouteProps } from 'utils/types/common';

import AuditCommentField from 'ui/components/AuditCommentField';
import YearMonthPicker from 'ui/components/DatePicker/YearMonthPicker';
import {
	DEFAULT_WEIGHT_BREAKS_CASS,
	DEFAULT_WEIGHT_BREAKS_CDD,
} from 'ui/components/WeightBreaks/WeightBreaks';
import { WeightBreaksField } from 'ui/forms/WeightBreaksField';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { ActionData, LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	AllProductDetails,
	isDeliveryStatusApplicable,
	isProductTypeWebTool,
} from '../../api/SubscriptionAPI';
import { DropdownField } from '../../forms/DropdownField';
import ProductConfigurationEditor, {
	doesProductHave,
} from './ProductConfigurationEditor';
import { WebToolConfigurationEditor } from './WebToolConfigurationEditor';
import {
	WebToolConfigurationEditorProvider,
	WebToolSubscriptionPeriodCalculated,
} from './WebToolConfigurationEditorProvider';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	if (!params.subscriptionId) return redirect('/subscriptions');

	const subscriptionData = await SubscriptionAPI.getSubscription(
		params.subscriptionId || ''
	);
	const subscriptionOptions = await SubscriptionAPI.getSubscriptionOptions();

	return {
		subscription: subscriptionData,
		options: subscriptionOptions,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const subscriptionId = params.subscriptionId as string;
	const formData = await request.formData();
	const subscriptionModel = formDataAsObject(formData);
	const updateSubscriptionModel =
		updateSubscriptionSchema.parse(subscriptionModel);

	const data = await SubscriptionAPI.updateSubscription(
		subscriptionId,
		updateSubscriptionModel
	);

	if (data instanceof Error) return data;

	createToast(ToastType.SUCCESS, 'Subscription updated successfully');
	return redirect(`/subscriptions/${subscriptionId}`);
};

export function SubscriptionEdit() {
	const actionData = useActionData() as ActionData<typeof action>;
	const data = useLoaderData() as LoaderData<typeof loader>;
	const navigation = useNavigation();

	const shouldShowWeightBreaks = doesProductHave(
		data.subscription.productConfiguration.type,
		'weightBreaks'
	);

	const shouldShowWeightBreaksCdd = doesProductHave(
		data.subscription.productConfiguration.type,
		'weightBreaksCdd'
	);

	const productConfiguration = data.subscription
		.productConfiguration as Partial<AllProductDetails>;

	const isProductWebTool = isProductTypeWebTool(data.subscription.product);

	const showDeliveryStatusOptions = isDeliveryStatusApplicable(
		data.subscription.product
	);

	return (
		<div className="content">
			<ValidatedForm method="post" validator={updateSubscriptionSchema}>
				<WebToolConfigurationEditorProvider
					dataGroups={productConfiguration.webToolDataGroups ?? []}
					productType={data.subscription.product.productType}
				>
					<Grid>
						<PageHeader
							title={`Edit ${data.subscription.name} (${data.subscription.subscriptionNumber})`}
						>
							<Button
								variant="secondary"
								to={`/subscriptions/${data.subscription.id}`}
								icon={X}
							>
								Cancel
							</Button>
							<Button
								isLoading={navigation.state !== 'idle' && !!navigation.formData}
								loadingText="Saving Changes"
								type="submit"
								icon={Check}
							>
								Save
							</Button>
						</PageHeader>
						<Grid>
							<ErrorList error={actionData} />
							<Grid columns={shouldShowWeightBreaks ? '1fr 1fr auto' : 2} isBox>
								<Grid>
									<TextField
										label="Name"
										name="name"
										maxLength={100}
										defaultValue={data.subscription.name}
									/>

									{showDeliveryStatusOptions ? (
										<DropdownField
											options={data.options.subscriptionDeliveryStatusOptions}
											name="subscriptionDeliveryStatusCode"
											identifierKey="value"
											isClearable={false}
											label="Delivery Status"
											isRequired
											placeholder="Please select a delivery status"
											initialValue={data.subscription.deliveryStatus}
											contentSource={(data) => data.label}
										/>
									) : (
										<input
											type="hidden"
											name="subscriptionDeliveryStatusCode"
											value="disabled"
										/>
									)}

									{isProductWebTool ? (
										<WebToolSubscriptionPeriodCalculated />
									) : (
										<Grid columns={2}>
											<Grid>
												<YearMonthPicker
													name="startPeriod"
													label="Start Period"
													isRequired
													initialValue={data.subscription.startPeriod}
												/>
											</Grid>
											<Grid>
												<YearMonthPicker
													name="endPeriod"
													label="End Period"
													isRequired
													initialValue={data.subscription.endPeriod}
												/>
											</Grid>
										</Grid>
									)}

									<TextField
										label="Number of Licenses"
										name="numberOfLicenses"
										type="number"
										min={0}
										defaultValue={
											data.subscription.numberOfLicenses ?? undefined
										}
									/>

									<AuditCommentField />
								</Grid>
								<Grid>
									{isProductWebTool ? (
										<WebToolConfigurationEditor
											product={data.subscription.product}
											productConfig={data.subscription.productConfiguration}
											options={data.options}
										/>
									) : (
										<ProductConfigurationEditor
											product={data.subscription.product}
											productConfig={data.subscription.productConfiguration}
											options={data.options}
										/>
									)}
								</Grid>
								<Grid>
									{shouldShowWeightBreaks && (
										<WeightBreaksField
											label="CASS Weight Breaks"
											name="productConfiguration.weightBreaks"
											weightBreaks={DEFAULT_WEIGHT_BREAKS_CASS}
											initialValue={productConfiguration.weightBreaks}
										/>
									)}
									{shouldShowWeightBreaksCdd && (
										<WeightBreaksField
											label="CDD Weight Breaks"
											name="productConfiguration.weightBreaksCdd"
											weightBreaks={DEFAULT_WEIGHT_BREAKS_CDD}
											initialValue={productConfiguration.weightBreaksCdd}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</WebToolConfigurationEditorProvider>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_SUBSCRIPTION_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <SubscriptionEdit />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return {
				label: `Edit: ${data.subscription.name} (${data.subscription.subscriptionNumber})`,
			};
		},
	},
};
