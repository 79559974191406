import classNames from 'classnames';
import { ComponentProps, useId } from 'react';

interface CheckboxFieldProps extends ComponentProps<'input'> {
	name: string;
	className?: string;
	label?: string;
	description?: string;
	errors?: string | string[];
}

const CheckboxField = (props: CheckboxFieldProps) => {
	let { label, description, errors } = props;
	const id = useId();

	return (
		<label className="checkbox" htmlFor={id}>
			<input
				type="checkbox"
				{...props}
				id={id}
				className={classNames('checkbox__input', props.className)}
			/>
			<span className="checkbox__label">{label}</span>
			{description && <div className="control__description">{description}</div>}
			{errors && (
				<div className="control__error">
					{typeof errors === 'string' ? errors : errors.join(', ')}
				</div>
			)}
		</label>
	);
};

export default CheckboxField;
