import { PlusCircle, RefreshCcw, Search } from 'lucide-react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	Outlet,
	useLoaderData,
	useParams,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import airImpactReportsApi from '../../api/AirImpactReportsAPI';
import * as airImpactReportColumns from '../../util/columns/airImpactReportColumns';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const getAirImpactReportsResponse =
		await airImpactReportsApi.getAirImpactReports(
			new URL(request.url).searchParams
		);

	return {
		getAirImpactReportsResponse: getAirImpactReportsResponse,
	};
};

export function AirImpactReportListPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;

	const columns = [
		airImpactReportColumns.titleColumn,
		airImpactReportColumns.editionColumn,
		airImpactReportColumns.publicationDateColumn,
		airImpactReportColumns.isArchivedColumn,
		airImpactReportColumns.showHomepageColumn,
		airImpactReportColumns.attachmentColumn,
		airImpactReportColumns.editButtonColumn,
	];

	const params = useParams();

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Air Cargo Impact Reports">
					<RevalidationButton>Refresh</RevalidationButton>

					<Button
						variant="secondary"
						icon={PlusCircle}
						to="/air-impact-reports/create"
					>
						Add Air Cargo Impact Report
					</Button>
				</PageHeader>

				<Grid columns={params.id ? 'minmax(0, 2fr) minmax(0, 1fr)' : undefined}>
					<Flex direction="column" gap={16}>
						<Table
							columns={columns}
							data={data.getAirImpactReportsResponse.reports.items}
							identifierKey="id"
						/>

						<Pagination
							baseUrl={new URL(window.location.href)}
							page={data.getAirImpactReportsResponse.reports.page}
							pageParameterName="page"
							pageSize={data.getAirImpactReportsResponse.reports.pageSize}
							itemCount={data.getAirImpactReportsResponse.reports.totalCount}
						/>
					</Flex>
					<Outlet />
				</Grid>
			</div>
			<Sidebar title="Reports Filter">
				<ValidatedForm method="get" resetOnNavigation>
					<TextField label="Keyword Search" name="keyword" defaultValue={''} />
					<CheckboxField
						name="includeArchived"
						label="Include Hidden Reports"
						value="true"
						defaultChecked={false}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}

export const LIST_AIR_IMPACT_REPORTS_ROUTE: LayoutRouteProps = {
	loader: loader,
	element: <AirImpactReportListPage />,
	handle: {
		crumb: () => 'Air Cargo Impact Reports',
	},
};
