import Toggle from 'ui/components/Toggle';
import {
	NotificationOption,
	NotificationTypeAndOptionItem,
} from '../../api/MonitoringAPI';
import React, { useState } from 'react';
import PillSelection from 'ui/components/PillSelection';
import { PillSelectionOption } from 'ui/components/PillSelection/PillSelection';
import Flex from 'ui/components/Flex';

export type NotificationToggleProps = {
	index: number;
	notificationType: NotificationTypeAndOptionItem;
};

const NotificationToggle = ({
	index,
	notificationType,
}: NotificationToggleProps) => {
	const [isChecked, setIsChecked] = useState(notificationType.selected);
	const [option, setOption] = useState(
		notificationType.notificationOption.value
	);

	const getOptions = () => {
		return notificationType.notificationType.acceptableNotificationOptions.map(
			(option) => ({ id: option.value, label: option.name })
		) as PillSelectionOption<string>[];
	};

	return (
		<>
			<input
				type="hidden"
				name={`notifications.${index}.value`}
				value={notificationType.notificationType.value}
			/>
			<input
				type="hidden"
				name={`notifications.${index}.selected`}
				value={`${isChecked}`}
			/>
			<input
				type="hidden"
				name={`notifications.${index}.option`}
				value={option}
			/>
			<Flex alignItems="center" gap={6}>
				<Toggle
					size="small"
					loading={false}
					isDisabled={false}
					onChange={(e) => {
						setIsChecked(e.target.checked);
					}}
					isChecked={isChecked}
				/>{' '}
				{notificationType.notificationType.name}
			</Flex>
			<PillSelection
				options={getOptions()}
				selectedOptions={[option]}
				onOptionSelected={(option) => {
					setOption(option as NotificationOption);
				}}
				isDisabled={!isChecked}
			/>
		</>
	);
};

export default NotificationToggle;
