import { format } from 'date-fns';
import { memo } from 'react';

type YearMonthWeekFragmentProps = {
	value: number;
};

export const YearMonthWeekFragment = memo(
	({ value }: YearMonthWeekFragmentProps) => {
		// first 4 of 8 digits
		const year = value.toString().slice(0, 4);
		// digits 5-6 of 8 digits
		const month = value.toString().slice(4, 6);
		// last 2 of 8 digits
		const weekOfYear = value.toString().slice(6, 8);

		const weekDate = new Date(parseInt(year), parseInt(month) - 1);

		const weekStart = format(weekDate, 'MMMM, yyyy');
		const weekString = `W${weekOfYear} ${weekStart}`;

		return <>{weekString}</>;
	}
);
