/* eslint-disable @typescript-eslint/naming-convention */
// Zod's parameters are snake_case, but we only allow camelCase
// Disable the rule for this file

import z from 'zod';

export const adminUserSchema = z.object({
	id: z.string(),
	name: z
		.string({ required_error: 'Name is required' })
		.min(2, { message: 'Name must be at least 2 characters' })
		.max(100, { message: 'Name must not be more than 100 characters' }),
	email: z
		.string({ required_error: 'Email is required' })
		.max(200, { message: 'Email must not be more than 200 characters' })
		.email(),
	adminRole: z.string({ required_error: 'Role is required' }),
});

export type UserModel = z.infer<typeof adminUserSchema>;

// CREATE USER SCHEMA

export const createAdminUserSchema = adminUserSchema.pick({
	name: true,
	email: true,
	adminRole: true,
});

export type CreateAdminUserModel = z.infer<typeof createAdminUserSchema>;

// UPDATE USER SCHEMA

export const updateAdminUserSchema = adminUserSchema.pick({
	name: true,
	adminRole: true,
});

export type UpdateAdminUserModel = z.infer<typeof updateAdminUserSchema>;
