import * as AccordionPrimitive from '@radix-ui/react-accordion';

import AccordionContent from './AccordionContent';
import AccordionItem from './AccordionItem';
import AccordionTrigger from './AccordionTrigger';

type AccordionType = typeof AccordionPrimitive.Root & {
	Item: typeof AccordionItem;
	Trigger: typeof AccordionTrigger;
	Content: typeof AccordionContent;
};

const Accordion = AccordionPrimitive.Root as AccordionType;

Accordion.Item = AccordionItem;
Accordion.Trigger = AccordionTrigger;
Accordion.Content = AccordionContent;

export default Accordion;
