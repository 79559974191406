import { Check, Trash2, X } from 'lucide-react';
import { useState } from 'react';
import {
	ActionFunction,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useActionData,
	useLoaderData,
	useNavigation,
} from 'react-router-dom';
import Button from 'ui/components/Button';
import CheckboxField from 'ui/components/CheckboxField';
import ErrorList from 'ui/components/ErrorList';
import Grid from 'ui/components/Grid';
import HTMLEditorField from 'ui/components/HTMLEditorField';
import PageHeader from 'ui/components/PageHeader';
import TextField from 'ui/components/TextField';
import ValidatedForm from 'ui/components/ValidatedForm';
import { formDataAsObject } from 'utils/formData/formData';

import DateField from 'ui/components/DateField/DateField';
import FileField from 'ui/components/FileField/FileField';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import useSubmitting from 'utils/hooks/useSubmitting';
import { updateAirImpactReportSchema } from 'utils/schemas/airImpactReportSchema';
import { LoaderData } from 'utils/types/loaderData';
import AirImpactReportsAPI from '../../api/AirImpactReportsAPI';
import DeleteAirImpactReportModal from '../../components/DeleteAirImpactReportModel';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const report = await AirImpactReportsAPI.getAirImpactReport(
		params.id as string
	);
	return {
		report: report,
	};
};

export const action: ActionFunction = async ({ params, request }) => {
	if (request.method === 'DELETE') {
		await AirImpactReportsAPI.deleteAirImpactReport(params.id as string);
		createToast(
			ToastType.SUCCESS,
			'Air Cargo Impact Reports deleted successfully'
		);
		return redirect('/air-impact-reports');
	}

	if (request.method === 'POST') {
		const formData = await request.formData();
		const id = params.id as string;

		const updateModel = updateAirImpactReportSchema.parse(
			formDataAsObject(formData)
		);

		const bannerEntry = formData.get('banner') as File;
		const pdfEntry = formData.get('pdf') as File;

		const banner = {
			fileName: bannerEntry.name,
			length: bannerEntry.size,
		};
		const pdf = {
			fileName: pdfEntry.name,
			length: pdfEntry.size,
		};

		const data = await AirImpactReportsAPI.updateWebAnnouncement(
			id,
			updateModel,
			banner,
			pdf
		);

		if ('reportId' in data) {
			if (data.bannerUrl !== '') {
				await AirImpactReportsAPI.uploadFile(data.bannerUrl, bannerEntry);
			}
			if (data.pdfUrl !== '') {
				await AirImpactReportsAPI.uploadFile(data.pdfUrl, pdfEntry);
			}

			return redirect(`/air-impact-reports/${data.reportId}`);
		}

		return data;
	}

	return null;
};

export function EditReport() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const actionData = useActionData();
	const navigation = useNavigation();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const isSubmitting = useSubmitting();

	const defaultPdf = new File([], data.report.pdf.originalName);
	Object.defineProperty(defaultPdf, 'size', { value: data.report.pdf.length });

	const defaultBanner = new File([], data.report.banner.originalName);
	Object.defineProperty(defaultBanner, 'size', {
		value: data.report.banner.length,
	});

	return (
		<div className="content">
			<ValidatedForm
				method="post"
				validator={updateAirImpactReportSchema}
				encType="multipart/form-data"
			>
				<Grid>
					<PageHeader title={`Edit ${data.report.title}`}>
						<Button variant="secondary" to={`/air-impact-reports`} icon={X}>
							Cancel
						</Button>
						<Button
							isDisabled={navigation.state !== 'idle'}
							type="button"
							variant="secondary"
							icon={Trash2}
							onClick={() => setDeleteModalOpen(true)}
							data-cy="announcementDeleteButton"
						>
							Delete
						</Button>
						<Button
							isLoading={isSubmitting}
							loadingText="Saving Changes"
							type="submit"
							icon={Check}
						>
							Save
						</Button>
					</PageHeader>

					<DeleteAirImpactReportModal
						report={data.report}
						isOpen={isDeleteModalOpen}
						onClose={() => setDeleteModalOpen(false)}
					/>

					<Grid>
						<ErrorList error={actionData} />
						<Grid columns={2} isBox>
							<Grid>
								<TextField
									label="Edition"
									name="edition"
									isRequired={true}
									defaultValue={data.report.edition}
								/>
								<TextField
									label="Title"
									name="title"
									isRequired={true}
									defaultValue={data.report.title}
								/>
								<HTMLEditorField
									label="Summary"
									name="summary"
									isRequired={true}
									defaultValue={data.report.summary}
								/>
							</Grid>
							<Grid>
								<FileField
									label="Banner"
									name="banner"
									allowedExtensions={['jpg', 'jpeg', 'png']}
									defaultValue={[defaultBanner]}
								/>
								<FileField
									label="PDF"
									name="pdf"
									allowedExtensions={['pdf']}
									defaultValue={[defaultPdf]}
								/>
								<DateField
									label="Publication Date"
									name="publicationDate"
									isRequired={true}
									initialValue={data.report.publicationDate}
									timezone="utc"
								/>
								<CheckboxField
									name="showInHomepage"
									label="Show in Homepage"
									value="true"
									defaultChecked={data.report.showInHomepage}
								/>
								<CheckboxField
									name="isArchived"
									label="Is Archived"
									value="true"
									defaultChecked={data.report.isArchived}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</ValidatedForm>
		</div>
	);
}

export const EDIT_AIR_IMPACT_REPORTS_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <EditReport />,
	handle: {
		crumb: () => 'Edit',
	},
};
