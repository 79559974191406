import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import PaginationButton from './PaginationButton';
import PaginationEllipsis from './PaginationEllipsis';
import PaginationPageSizePicker from './PaginationPageSizePicker';
import PaginationStatus from './PaginationStatus';

export type PaginationProps = {
	baseUrl: URL;
	page?: number;
	pageParameterName: string;
	pageSize: number;
	itemCount: number;
	canChangePageSize?: boolean;

	boundaryCount?: number;
	siblingCount?: number;
};

const parsePageNumber = (value: string | null): number => {
	const intValue = parseInt(value || null!);
	return Number.isNaN(intValue) ? 1 : Math.max(1, intValue);
};

const range = (l: number, u: number) => {
	if (l > u) return [];
	const arr = new Array(u - l + 1);
	return arr.fill(0).map((_, i) => i + l);
};

const getPageButtons = ({
	boundaryCount,
	siblingCount,
	pageCount,
	page,
}: {
	boundaryCount: number;
	siblingCount: number;
	pageCount: number;
	page: number;
}) => {
	// [...boundary, ellipsis, ...siblings, cur, ...siblings, ellipsis, ...boundary]
	const displayCount = (boundaryCount + siblingCount) * 2 + 3;

	// If there are less items than the display count, show all items
	if (pageCount <= displayCount) {
		return range(1, pageCount);
	}

	// Create the page references
	let pages: number[] = [];

	// Set boundary references
	for (let i = 0; i < boundaryCount; i++) {
		pages.push(i + 1, pageCount - i);
	}

	// Add siblings and page
	const siblingsStart = Math.max(1, page - siblingCount);
	const siblingsEnd = Math.min(pageCount, page + siblingCount);
	const siblings = range(siblingsStart, siblingsEnd);
	pages.push(...siblings);

	// Remove duplicates
	pages = [...new Set(pages)];

	// From the page, fill outward until the array has displayCount items
	let i = 0;
	while (pages.length < displayCount) {
		const leftFillter = page - siblingCount - 1 - i;
		const rightFillter = page + siblingCount + 1 + i;

		if (leftFillter > 0) {
			pages.push(leftFillter);
		}

		if (rightFillter < pageCount && pages.length < displayCount) {
			pages.push(rightFillter);
		}

		pages = [...new Set(pages)]; // Remove duplicates
		i++;
	}

	// Sort
	pages = pages.sort((a, b) => a - b);

	// If the difference between two items is greater than 1, add an ellipsis
	const ellipsis = Infinity;
	for (let i = 0; i < pages.length - 1; i++) {
		// Differentiate start and end ellipsis
		if (pages[i + 1] - pages[i] > 1) {
			if (i < pages.length / 2) {
				pages.splice(i + 1, 1, ellipsis);
			} else {
				pages.splice(i, 1, ellipsis);
			}
		}
	}

	return pages;
};

const Pagination = ({
	baseUrl,
	page,
	pageParameterName,
	pageSize,
	itemCount,
	canChangePageSize,

	boundaryCount = 1,
	siblingCount = 1,
}: PaginationProps) => {
	const location = useLocation();
	const searchParameters = new URLSearchParams(location.search);
	const totalPages = Math.ceil(itemCount / pageSize);

	const activePage =
		page ?? parsePageNumber(searchParameters.get(pageParameterName));

	const pageButtons = getPageButtons({
		boundaryCount,
		siblingCount,
		pageCount: totalPages,
		page: activePage,
	});

	const urlForPage = (pageNumber: number) => {
		baseUrl.searchParams.set(pageParameterName, pageNumber.toString());
		return `${baseUrl.pathname}?${baseUrl.searchParams.toString()}`;
	};

	return (
		<div className="pagination">
			{canChangePageSize && (
				<PaginationPageSizePicker pageSize={pageSize} totalItems={itemCount} />
			)}

			<PaginationStatus
				page={activePage}
				pageSize={pageSize}
				itemCount={itemCount}
			/>

			{pageButtons.length > 1 && (
				<ul className="pagination__list">
					<PaginationButton
						to={urlForPage(activePage - 1)}
						isDisabled={activePage <= 1}
					>
						<ChevronLeft size={16} />
					</PaginationButton>

					{pageButtons.map((pageNumber) => {
						if (pageNumber === Infinity) {
							return <PaginationEllipsis />;
						} else {
							return (
								<PaginationButton
									key={pageNumber}
									to={urlForPage(pageNumber)}
									isActive={pageNumber === activePage}
								>
									{pageNumber}
								</PaginationButton>
							);
						}
					})}

					<PaginationButton
						to={urlForPage(activePage + 1)}
						isDisabled={activePage === totalPages}
					>
						<ChevronRight size={16} />
					</PaginationButton>
				</ul>
			)}
		</div>
	);
};

export default Pagination;
