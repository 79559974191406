import classNames from 'classnames';
import { format } from 'date-fns';
import { memo } from 'react';
import { HotfileMonthAreas } from '../../api/DataManagementAPI';
import HotfileMonthArea from './HotfileMonthArea';

type HotfileMonthProps = {
	month: HotfileMonthAreas;
	compact?: boolean;
	highlightedAreas?: string[];
	allAreas: string[];
	isHighlighted?: boolean;
	visibleAreas?: string[];
};

const HotfileMonth = ({
	month,
	compact,
	highlightedAreas = [],
	allAreas,
	isHighlighted,
	visibleAreas,
}: HotfileMonthProps) => {
	const formattedMonth = format(
		new Date(month.year, month.month - 1),
		'MMM yyyy'
	);

	return (
		<div className="hotfilemonitor__month hotfilemonth">
			<div
				className={classNames(
					{
						'hotfilemonth__header--highlighted': isHighlighted,
					},
					'hotfilemonth__header'
				)}
			>
				{formattedMonth}
			</div>
			<div className="hotfilemonth__areas">
				{allAreas.map((area) => (
					<HotfileMonthArea
						key={area}
						files={month.areas[area] ?? []}
						isDimmed={
							highlightedAreas.length > 0 && !highlightedAreas.includes(area)
						}
						isCompact={compact}
						isVisible={visibleAreas?.includes(area) ?? true}
					/>
				))}
			</div>
		</div>
	);
};

export default memo(HotfileMonth);
