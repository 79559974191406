import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { AccountManagerItem } from '../../api/AccountManagersAPI';

const columnHelper = createColumnHelper<AccountManagerItem>();

export const fullNameColumn = columnHelper.display({
	id: 'name',
	header: 'Name',
	cell: (item) => (
		<Link to={`/account-managers/${item.row.original.id}`}>
			{`${item.row.original.lastName}, ${item.row.original.firstName}`}
		</Link>
	),
});

export const emailColumn = columnHelper.accessor('email', {
	header: 'Email',
});

export const titleColumn = columnHelper.accessor('title', {
	header: 'Title',
});
