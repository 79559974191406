import DateFragment from 'ui/components/DateFragment';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import { BlockingProcess } from '../../api/DataManagementAPI';
import SystemProcessCard from './SystemProcessCard';
import { blockingProcessIntent } from './system-status';

type BlockingSystemProcessProps = {
	blockingProcess: BlockingProcess;
};

const BlockingSystemProcess = ({
	blockingProcess,
}: BlockingSystemProcessProps) => {
	return (
		<SystemProcessCard
			title={blockingProcess.name}
			toggleProps={{
				systemProcessCode: blockingProcess.code,
				status: blockingProcess.status,
				activeStatus: 'idle',
				validStatus: blockingProcess.validStatus,
			}}
			bulletIndicatorProps={{
				intent: blockingProcessIntent(blockingProcess.status.code),
				label: blockingProcess.status.name,
			}}
		>
			{blockingProcess.lastRun && (
				<KeyValuePairs
					entries={[
						{
							key: 'Last run (UTC)',
							value: (
								<DateFragment
									date={blockingProcess.lastRun.timestamp}
									includeTime
									timezone="utc"
								/>
							),
						},
					]}
				/>
			)}
		</SystemProcessCard>
	);
};

export default BlockingSystemProcess;
