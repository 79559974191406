import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { Pencil } from 'lucide-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Button from 'ui/components/Button';
import DateFragment from 'ui/components/DateFragment';
import Pill from 'ui/components/Pill';
import { CustomerTeamListItem } from '../../api/CustomerTeamsAPI';

const columnHelper = createColumnHelper<CustomerTeamListItem>();

export const nameAndDescColumn = columnHelper.accessor('name', {
	header: 'Name',
	cell: (info) => {
		const descrip = info.row.original.description;
		const { id } = useParams<{ id: string }>();

		return (
			<>
				<Link to={`/customer-teams/${info.row.original.id}/edit`}>
					{id === info.row.original.id ? (
						<b>{info.getValue()}</b>
					) : (
						info.getValue()
					)}
				</Link>
				{descrip ? <p className="wrap-normal">{descrip}</p> : ''}
			</>
		);
	},
	enableSorting: true,
});

export const membersColumn = columnHelper.accessor('members', {
	header: 'Members',
	cell: (info) => {
		const members = info.getValue();
		return (
			<div>
				{members.map((member) => (
					<span key={member}>
						<Pill name={member} />{' '}
					</span>
				))}
			</div>
		);
	},
});

export const createdAtColumn = columnHelper.accessor('createdAt', {
	header: 'Created Date/Time (UTC)',
	cell: (info) => (
		<DateFragment date={info.getValue()} includeTime={true} timezone="utc" />
	),
	enableSorting: true,
});

export const editButtonColumn = columnHelper.accessor('id', {
	header: '',
	meta: {
		shrink: true,
	},
	cell: function CustomerTeamEditColumn(info) {
		return (
			<Button
				variant="secondary"
				size="small"
				icon={Pencil}
				to={CustomerTeamEditUrl(info)}
			>
				Edit
			</Button>
		);
	},
});

function CustomerTeamEditUrl(info: CellContext<CustomerTeamListItem, string>) {
	const location = useLocation();

	const userId = location.pathname.includes('/users/')
		? location.pathname.split('/')[2]
		: null;

	const url =
		userId !== null
			? `/customer-teams/${info.row.original.id}/edit?userId=${userId}`
			: `/customer-teams/${info.row.original.id}/edit`;

	return url;
}
