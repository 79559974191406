import { createColumnHelper } from '@tanstack/react-table';
import {
	ActionFunctionArgs,
	LayoutRouteProps,
	LoaderFunctionArgs,
	redirect,
	useLoaderData,
} from 'react-router-dom';
import BulletIndicator from 'ui/components/BulletIndicator';
import Card from 'ui/components/Card';
import DateFragment from 'ui/components/DateFragment';

import Flex from 'ui/components/Flex';
import Grid from 'ui/components/Grid';
import MultiStepProgress from 'ui/components/MultiStepProgress';
import { StepStatus } from 'ui/components/MultiStepProgress/MultiStepProgress';
import PageHeader from 'ui/components/PageHeader';
import Table from 'ui/components/Table';
import { ToastType } from 'ui/components/Toaster/Toast';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import { LoaderData } from 'utils/types/loaderData';
import ReportDistributionAPI, {
	ReportDistributionFile,
} from '../../api/ReportDistributionAPI';
import { ReportDistributionBulletIndicatorIntent } from '../../util/columns/reportColumnHelpers';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const report = await ReportDistributionAPI.getReportDistribution(
		params.id as string
	);

	return {
		report: report,
	};
};

export const action = async ({ request }: ActionFunctionArgs) => {
	if (request.method === 'DELETE') {
		createToast(ToastType.SUCCESS, 'Report Distribution deleted successfully');
		return redirect('/static-report-distribution');
	}

	return null;
};

export function ReportDistributionDetails() {
	const { report } = useLoaderData() as LoaderData<typeof loader>;

	const reportDistributionFileColumnHelper =
		createColumnHelper<ReportDistributionFile>();

	const reportDistributionFileColumns = [
		reportDistributionFileColumnHelper.accessor('accountName', {
			header: 'Account',
		}),
		reportDistributionFileColumnHelper.accessor('fileName', {
			header: 'File Name',
		}),
		reportDistributionFileColumnHelper.accessor('status', {
			header: 'Status',
			cell: (row) => (
				<BulletIndicator
					intent={ReportDistributionBulletIndicatorIntent(row.getValue())}
					label={row.getValue()}
				/>
			),
		}),
		reportDistributionFileColumnHelper.accessor('message', {
			header: 'Message',
		}),
		reportDistributionFileColumnHelper.accessor('startedAt', {
			header: 'Started At (UTC)',
			cell: (row) => (
				<DateFragment date={row.getValue()} includeTime={true} timezone="utc" />
			),
		}),
		reportDistributionFileColumnHelper.accessor('completedAt', {
			header: 'Completed At (UTC)',
			cell: (row) => (
				<DateFragment date={row.getValue()} includeTime={true} timezone="utc" />
			),
		}),
	];

	return (
		<div className="content">
			<PageHeader
				title={
					<Flex direction="column" alignItems="start" gap={12}>
						<span>Report {report.id}</span>
						<BulletIndicator
							intent={ReportDistributionBulletIndicatorIntent(
								report.distributionStatus
							)}
							label={report.distributionStatus}
						/>
					</Flex>
				}
			/>
			<Flex direction="column" gap={20}>
				<MultiStepProgress
					steps={[
						{
							label: 'Created (UTC)',
							status: report.createdAt
								? StepStatus.Completed
								: StepStatus.Pending,
							info: (
								<DateFragment
									date={report.createdAt}
									includeTime
									timezone="utc"
								/>
							),
						},
						{
							label: 'Started (UTC)',
							status: report.startedAt
								? StepStatus.Completed
								: StepStatus.Pending,
							info: (
								<DateFragment
									date={report.startedAt}
									includeTime
									timezone="utc"
								/>
							),
						},
						{
							label: 'Delivered (UTC)',
							status: report.deliveredAt
								? StepStatus.Completed
								: StepStatus.Pending,
							info: (
								<DateFragment
									date={report.deliveredAt}
									includeTime
									timezone="utc"
								/>
							),
						},
						report.distributionStatus === 'Failed'
							? {
									label: 'Failed (UTC)',
									status: StepStatus.Error,
									info: (
										<DateFragment
											date={report.completedAt}
											includeTime
											timezone="utc"
										/>
									),
							  }
							: {
									label: 'Completed (UTC)',
									status: report.completedAt
										? StepStatus.Completed
										: StepStatus.Pending,
									info: (
										<DateFragment
											date={report.completedAt}
											includeTime
											timezone="utc"
										/>
									),
							  },
					]}
				/>

				<Flex direction="column" gap={12}>
					<b>Included reports</b>
					<Table
						columns={reportDistributionFileColumns}
						data={report.files}
						identifierKey="id"
					/>
				</Flex>

				<Grid columns={2} style={{ alignItems: 'stretch' }}>
					<Card>
						<Flex direction="column" gap={12}>
							<b>Transmit message</b>
							<div
								style={{
									whiteSpace: 'pre-wrap',
									fontFamily: 'monospace',
								}}
							>
								{report.transmitMessage}
							</div>
						</Flex>
					</Card>
					<Card>
						<Flex direction="column" gap={12}>
							<b>Report message</b>
							<div
								style={{
									whiteSpace: 'pre-wrap',
									fontFamily: 'monospace',
								}}
							>
								{report.reportMessage}
							</div>
						</Flex>
					</Card>
					{report.validateMessage && (
						<Card>
							<Flex direction="column" gap={12}>
								<b>Validation message</b>
								<div
									style={{
										whiteSpace: 'pre-wrap',
										fontFamily: 'monospace',
									}}
								>
									{report.validateMessage}
								</div>
							</Flex>
						</Card>
					)}
				</Grid>
			</Flex>
		</div>
	);
}

export const DETAILS_REPORT_DISTRIBUTION_ROUTE: LayoutRouteProps = {
	loader,
	action,
	element: <ReportDistributionDetails />,
	handle: {
		breadcrumbs: { label: 'Details' },
	},
};
